import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ChecklistElementBoarding, WarningChecklistBoat } from "../../types/forms/Checklist";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { checkboatStatuses } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useTranslationProvider } from "../providers/TranslationProvider";
import { TranslationType } from "../../types/Generic";
import info_circle from "../../assets/info_circle.svg";
import $ from "jquery";
import { useOrders } from "../providers/OrderProvider";
import { ChecklistWarning } from "./ChecklistWarning";
const DisplayChecklistElement = ({
	checkListElement,
	checkoutCheckListElement,
	index,
	handleChageInChecklist,
	disabled,
	isUser,
	orderUID,
}: {
	checkListElement: ChecklistElementBoarding;
	checkoutCheckListElement: ChecklistElementBoarding;
	index: number;
	handleChageInChecklist: (checklistElement: ChecklistElementBoarding, index: number) => void;
	disabled: boolean;
	isUser: boolean;
	orderUID: string;
}) => {
	const [checkoutBinaryChoice, setCheckoutBinaryChoice] = useState<boolean | null>(
		checkoutCheckListElement == null || checkoutCheckListElement.binaryChoice == null
			? isUser
				? null
				: true
			: checkoutCheckListElement.binaryChoice
	);
	const [checkoutTextChoice, setCheckoutTextChoice] = useState<string>("");

	const { t } = useTranslation();
	const { language } = useTranslationProvider();

	const handleChangeStatusChecklistElement = () => {
		const newCheckListElement = { ...checkoutCheckListElement };
		if (checkoutCheckListElement.inputType === "binary") {
			newCheckListElement.binaryChoice = checkoutBinaryChoice;
		} else {
			newCheckListElement.textChoice = checkoutTextChoice;
		}

		handleChageInChecklist(newCheckListElement, index);
	};
	useEffect(() => {
		if (checkoutBinaryChoice == null && checkoutTextChoice == "") return;
		handleChangeStatusChecklistElement();

		if (checkoutCheckListElement.inputType === "binary") {
			if (checkoutBinaryChoice != checkListElement.binaryChoice) {
				$("#checklist-checkout-element-" + orderUID + "-" + checkListElement.category + "-" + index).css(
					"background-color",
					"rgba(255, 0, 0, 0.15)"
				);
			} else {
				$("#checklist-checkout-element-" + orderUID + "-" + checkListElement.category + "-" + index).css(
					"background-color",
					"rgba(36, 255, 0, 0.15)"
				);
			}
		}
	}, [checkoutBinaryChoice, checkoutTextChoice]);
	useEffect(() => {
		if (checkoutCheckListElement.category == "binary") {
			if (checkoutCheckListElement.binaryChoice == null) return;
			setCheckoutBinaryChoice(checkoutCheckListElement.binaryChoice);
		} else {
			if (checkoutCheckListElement.textChoice == null) return;
			setCheckoutTextChoice(checkoutCheckListElement.textChoice);
		}
	}, []);

	return (
		<>
			<Grid item xs={12}>
				<Grid container>
					{index == 0 && (
						<>
							<Grid item xs={12} py={1.2} display="flex" alignItems="center">
								<Typography variant="h5" fontWeight={600} marginRight="auto" maxWidth="350px">
									{t("component")}
								</Typography>
								<div className="checklist-checkout-titles">
									<Typography variant="h5" fontWeight={600}>
										{t("boarding-checklist-title")}
									</Typography>
									<section className="yes-no-text-checklist-check-mobile-container">
										<Typography variant="h5" className="yes-no-text-checklist-check-mobile">
											{t("yes")}
										</Typography>

										<Typography variant="h5" className="yes-no-text-checklist-check-mobile">
											{t("no")}
										</Typography>
									</section>
								</div>
								<div className="checklist-checkout-titles">
									<Typography variant="h5" fontWeight={600}>
										{t("landing")}
									</Typography>
									<section className="yes-no-text-checklist-check-mobile-container">
										<Typography variant="h5" className="yes-no-text-checklist-check-mobile">
											{t("yes")}
										</Typography>

										<Typography variant="h5" className="yes-no-text-checklist-check-mobile">
											{t("no")}
										</Typography>
									</section>
								</div>
							</Grid>
						</>
					)}
				</Grid>
				<Divider />
				<Grid container>
					<Grid
						item
						xs={12}
						py={1.2}
						display="flex"
						alignItems="center"
						id={"checklist-checkout-element-" + orderUID + "-" + checkListElement.category + "-" + index}
					>
						<Typography variant="h5" marginRight="auto" maxWidth="350px">
							{checkListElement.name[language as keyof TranslationType]}
							{checkListElement.category === "inventory" && (
								<>
									<span> - </span>
									{checkListElement.price === "deposit" ? (
										<span style={{ color: "red" }}>{t("deposit")}</span>
									) : (
										<>€ {checkListElement.price}</>
									)}
								</>
							)}
						</Typography>
						<div className="checklist-checkout-elemenet-input">
							{checkListElement.inputType === "binary" && (
								<>
									<div className="checklist-choice-container">
										<section style={{ display: "flex", alignItems: "center", gap: "8px" }}>
											<div
												className={`order-checkbox ${
													checkListElement.binaryChoice && checkListElement.binaryChoice != null
														? "order-checkbox-filled"
														: ""
												} checkbox-checklist`}
											>
												{checkListElement.binaryChoice && checkListElement.binaryChoice != null && (
													<CheckIcon className="checkbox-icon" />
												)}
											</div>

											<Typography variant="h5" className="yes-no-text-checklist-check-desktop">
												{t("yes")}
											</Typography>
										</section>
										<section style={{ display: "flex", alignItems: "center", gap: "8px" }}>
											<div
												className={`order-checkbox ${
													!checkListElement.binaryChoice && checkListElement.binaryChoice != null
														? "order-checkbox-filled"
														: ""
												} checkbox-checklist`}
											>
												{!checkListElement.binaryChoice && checkListElement.binaryChoice != null && (
													<CheckIcon className="checkbox-icon" />
												)}
											</div>

											<Typography variant="h5" className="yes-no-text-checklist-check-desktop">
												{t("no")}
											</Typography>
										</section>
									</div>
								</>
							)}
							{checkListElement.inputType === "text" && (
								<>
									<TextField
										className="input-form text-choice-checklist"
										id="notes"
										name="notes"
										label={isUser ? "" : "Inserisci"}
										variant={"filled"}
										size="small"
										disabled={true}
										value={checkListElement.textChoice}
									/>
								</>
							)}
						</div>
						<div className="checklist-checkout-elemenet-input">
							{checkListElement.inputType === "binary" && (
								<>
									<div className="checklist-choice-container">
										<section style={{ display: "flex", alignItems: "center", gap: "8px" }}>
											<a
												onClick={() => {
													if (!disabled) setCheckoutBinaryChoice(true);
												}}
											>
												<div
													className={`order-checkbox ${
														checkoutBinaryChoice && checkoutBinaryChoice != null ? "order-checkbox-filled" : ""
													} checkbox-checklist`}
												>
													{checkoutBinaryChoice && checkoutBinaryChoice != null && <CheckIcon className="checkbox-icon" />}
												</div>
											</a>
											<Typography variant="h5" className="yes-no-text-checklist-check-desktop">
												{t("yes")}
											</Typography>
										</section>
										<section style={{ display: "flex", alignItems: "center", gap: "8px" }}>
											<a
												onClick={() => {
													if (!disabled) setCheckoutBinaryChoice(false);
												}}
											>
												<div
													className={`order-checkbox ${
														!checkoutBinaryChoice && checkoutBinaryChoice != null ? "order-checkbox-filled" : ""
													} checkbox-checklist`}
												>
													{!checkoutBinaryChoice && checkoutBinaryChoice != null && <CheckIcon className="checkbox-icon" />}
												</div>
											</a>
											<Typography variant="h5" className="yes-no-text-checklist-check-desktop">
												{t("no")}
											</Typography>
										</section>
									</div>
								</>
							)}
							{checkListElement.inputType === "text" && (
								<>
									<TextField
										className="input-form text-choice-checklist"
										id="notes"
										name="notes"
										label={isUser ? "" : "Inserisci"}
										variant={"filled"}
										size="small"
										disabled={disabled}
										value={checkoutTextChoice}
										onChange={(e) => {
											setCheckoutTextChoice(e.currentTarget.value);
										}}
										onBlur={(e) => {
											setCheckoutTextChoice(e.currentTarget.value);
										}}
									/>
								</>
							)}
						</div>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export const CheckoutChecklist = ({
	checkBoatChecklistInventory,
	checkBoatChecklistCheckin560,
	checkoutChecklistInventory,
	setCheckoutChecklistInventory,
	checkoutChecklistCheckin560,
	setCheckoutChecklistCheckin560,
	isUser,
	boardingStatus,
	orderUID,
	warnings,
	setWarnings,
}: {
	checkBoatChecklistInventory: ChecklistElementBoarding[];
	checkBoatChecklistCheckin560: ChecklistElementBoarding[];
	checkoutChecklistInventory: ChecklistElementBoarding[];
	setCheckoutChecklistInventory: Dispatch<SetStateAction<ChecklistElementBoarding[]>>;
	checkoutChecklistCheckin560: ChecklistElementBoarding[];
	setCheckoutChecklistCheckin560: Dispatch<SetStateAction<ChecklistElementBoarding[]>>;
	isUser: boolean;
	boardingStatus: string;
	orderUID: string;
	warnings: WarningChecklistBoat[];
	setWarnings: Dispatch<SetStateAction<WarningChecklistBoat[]>>;
}) => {
	const [disableInput, setDisableInput] = useState<boolean>(true);
	const [totalDamage, setTotalDamage] = useState<number>(0);
	const { t } = useTranslation();
	const { language } = useTranslationProvider();
	const { getDepositFromUid } = useOrders();

	const [deposit, setDeposit] = useState<number>(0);
	const handleChageInChecklistInventory = (checklistElement: ChecklistElementBoarding, index: number) => {
		const newChecklist: ChecklistElementBoarding[] = [] as ChecklistElementBoarding[];
		checkoutChecklistInventory.forEach((checklistElement) => newChecklist.push(checklistElement));

		newChecklist[index] = checklistElement;
		setCheckoutChecklistInventory(newChecklist);
	};
	const handleChageInChecklistCheckin560 = (checklistElement: ChecklistElementBoarding, index: number) => {
		const newChecklist: ChecklistElementBoarding[] = [] as ChecklistElementBoarding[];
		checkoutChecklistCheckin560.forEach((checklistElement) => newChecklist.push(checklistElement));
		newChecklist[index] = checklistElement;
		setCheckoutChecklistCheckin560(newChecklist);
	};
	useEffect(() => {
		const deposit = getDepositFromUid(orderUID, !isUser);
		setDeposit(deposit);
	}, []);
	useEffect(() => {
		console.log(deposit);
		if (checkoutChecklistInventory != null && checkoutChecklistInventory.length > 0) {
			let totalDamage = 0;
			for (let i = 0; i < checkoutChecklistInventory.length; i++) {
				if (
					checkBoatChecklistInventory[i].inputType === "binary" &&
					checkBoatChecklistInventory[i].binaryChoice === true &&
					checkoutChecklistInventory[i].binaryChoice === false
				) {
					if (checkoutChecklistInventory[i].price != null) {
						if (
							checkoutChecklistInventory[i].price === "deposit" ||
							totalDamage + (checkoutChecklistInventory[i].price as number) > deposit
						) {
							totalDamage = deposit;
						} else {
							totalDamage += checkoutChecklistInventory[i].price as number;
						}
					}
				}
			}
			setTotalDamage(totalDamage);
		}
	}, [checkoutChecklistInventory]);

	useEffect(() => {
		if (isUser) {
			setDisableInput(true);
		} else if (boardingStatus === checkboatStatuses.COUNTER_SIGNED) {
			setDisableInput(true);
		} else {
			setDisableInput(false);
		}
	}, [boardingStatus, isUser]);

	const handleToggleWarningsCheck = (index: number) => {
		const newWarnings = [...warnings];
		newWarnings[index].checkoutChecked = newWarnings[index].checkoutChecked == null ? true : !newWarnings[index].checkoutChecked;
		setWarnings(newWarnings);
	};
	return (
		<>
			<Grid container rowGap="32px">
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12} marginBottom="8px">
							<Typography variant="h4">{t("control-of-function")}</Typography>
						</Grid>
						{checkBoatChecklistCheckin560 != null &&
							checkBoatChecklistCheckin560.length > 0 &&
							checkBoatChecklistCheckin560.map((checkListElement, index) => (
								<DisplayChecklistElement
									key={"check-list-element-functional" + index}
									index={index}
									handleChageInChecklist={handleChageInChecklistCheckin560}
									checkListElement={checkListElement}
									checkoutCheckListElement={checkoutChecklistCheckin560[index]}
									disabled={disableInput}
									isUser={isUser}
									orderUID={orderUID}
								/>
							))}
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12} marginBottom="8px">
							<Typography variant="h4">{t("inventory")}</Typography>
						</Grid>
						{checkBoatChecklistInventory != null &&
							checkBoatChecklistInventory.length > 0 &&
							checkBoatChecklistInventory.map((checkListElement, index) => (
								<DisplayChecklistElement
									key={"check-list-element-inventory" + index}
									index={index}
									handleChageInChecklist={handleChageInChecklistInventory}
									checkListElement={checkListElement}
									checkoutCheckListElement={checkoutChecklistInventory[index]}
									disabled={disableInput}
									isUser={isUser}
									orderUID={orderUID}
								/>
							))}
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12} marginBottom="8px">
							<Typography variant="h4">Warnings</Typography>
						</Grid>
						{warnings != null &&
							warnings.map((warning, index) => (
								<ChecklistWarning
									key={"check-list-warning" + index}
									index={index}
									handleToggleCheck={handleToggleWarningsCheck}
									value={warning.value[language as keyof TranslationType]}
									checked={warning.checkoutChecked == null ? false : warning.checkoutChecked}
									isUser={isUser}
								/>
							))}
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h4">{t("deposit-title")}</Typography>
					<div className="damage-container">
						<div style={{ display: "flex", gap: 8, alignItems: "center" }}>
							<img src={info_circle} alt="info circle" className="info-circle" />
							<Typography variant="h5">€ {totalDamage}</Typography>
						</div>
						<Typography variant="h5" className="damage-text" sx={{ marginTop: "8px" }}>
							{t("deposit-checkout-hover-text").replace("XXXX", deposit.toString())}
						</Typography>
					</div>
				</Grid>
			</Grid>
		</>
	);
};
