import { Rating, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
export const FeedbackStarElement = ({ title, stars }: { title: string; stars: number }) => {
	return (
		<>
			<div className="rating-star-content">
				<Typography className="rating-star-text" sx={{ width: "100%", flexShrink: 0, xs: { fontSize: "16px !important" } }} variant="h4">
					{title}
				</Typography>
				<Rating
					emptyIcon={<StarIcon fontSize="inherit" />}
					name="simple-controller"
					value={stars}
					className={"rating-star-icon"}
					max={5}
					precision={1}
					size="large"
					sx={{
						color: "#68C5DA",
						opacity: "1 !important",
					}}
					disabled={true}
				/>
			</div>
		</>
	);
};
