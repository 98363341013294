import { Grid, Button, Typography } from "@mui/material";
import { useState, useEffect } from "react";

import logo_menu from "../../assets/logo_menu.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/providers/AuthProvider";

import "../../styles/Menu.scss";
import $ from "jquery";
import { ChangeLanguage } from "../../components/menu/ChangeLanguage";
import { useTranslation } from "react-i18next";

export const UserMenu = (): JSX.Element => {
	const { userProfile, logout } = useAuth();
	const [menuWidth, setMenuWidth] = useState<number>(0);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const myObserver = new ResizeObserver((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
		for (let entry of entries) {
			if (entry.target.id === "menu") {
				setMenuWidth(entry.contentRect.width);
				$(".menu-content").css("width", `calc(100% - ${entry.contentRect.width}px)`);
				$(".menu-container").css("width", `${entry.contentRect.width}`);
			}
		}
	});
	useEffect(() => {
		const menuWidth = $(".menu").width() as number;
		setMenuWidth(menuWidth);
		myObserver.observe(document.getElementById("menu") as Element);
	}, []);
	return (
		<>
			<div style={{ position: "relative", width: menuWidth }}>
				<Grid item className="menu" id="menu">
					<section style={{ position: "relative", height: "100vh", width: "100%" }}>
						<div className="menu-background"></div>
						<div id="menu-open-arrow"></div>

						<Grid container className="menu-header">
							<Grid item display="flex">
								<img
									src={logo_menu}
									alt="logo menu"
									width="50px"
									height="50px"
									className="logo-menu user-menu-logo"
									onClick={() => {
										navigate("/user/landing");
									}}
								/>
								{userProfile && (
									<>
										<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} className="header-menu-name">
											<Typography variant="h4" color="white" className="header-name-menu">
												{userProfile.name} {userProfile.surname}
											</Typography>
											<Typography variant="body1" color="white" className="header-name-menu header-menu-subtitle">
												{t("yourVacationAwaits")}
											</Typography>
										</div>
									</>
								)}
							</Grid>
						</Grid>
						<Grid container className="menu-buttons-container" justifyContent="flex-start" flexDirection="column" gap={2} marginTop="50px">
							<Grid item className="menu-button">
								<Button fullWidth onClick={() => navigate("/user/tutorial")}>
									{t("tutorial")}
								</Button>
							</Grid>
							<Grid item className="menu-button">
								<Button
									fullWidth
									onClick={() => {
										navigate("/user/boarding");
									}}>
									{t("boarding")}
								</Button>
							</Grid>
							<Grid item className="menu-button">
								<Button fullWidth onClick={() => navigate("/user/checkin")}>
									{t("check-in")}
								</Button>
							</Grid>
							<Grid item className="menu-button">
								<Button fullWidth onClick={() => navigate("/user/checkout")}>
									{t("check-out")}
								</Button>
							</Grid>
							<Grid item className="menu-button">
								<Button onClick={() => navigate("/user/feedback")} fullWidth>
									{t("feedback")}
								</Button>
							</Grid>
							<Grid item className="menu-button">
								<Button
									fullWidth
									onClick={() => {
										navigate("/user/settings");
									}}>
									{t("settings")}
								</Button>
							</Grid>
							<Grid item className="menu-button">
								<Button
									fullWidth
									onClick={() => {
										logout();
										navigate("/");
									}}>
									LOG OUT
								</Button>
							</Grid>
						</Grid>
					</section>
					<ChangeLanguage />
				</Grid>
				{/* <button className="toggle-menu-button"></button> */}
				<button className="icon-close-mobile-container">
					<CloseIcon fontSize="large" className="icon-close-mobile" />
				</button>
			</div>
		</>
	);
};
