import { createContext, useContext, useEffect, useState } from "react";
import { AuthProviderType } from "../../types/providers/AuthProvider";
import { User, signInWithEmailAndPassword, updatePassword, setPersistence, browserSessionPersistence, onAuthStateChanged } from "firebase/auth";
import { useFirebase } from "./FirebaseProvider";
import { api } from "../../utils/network/api";

import { onSnapshot, doc, collection, getDocs, getDoc, orderBy, query, addDoc, Unsubscribe, QuerySnapshot, DocumentData } from "firebase/firestore";
import { useNetwork } from "./NetworkProvider";
import { Boat } from "../../types/documents/Boat";
import { ChecklistElement, ChecklistElementBoarding } from "../../types/forms/Checklist";
import { UserProfile } from "../../types/documents/User";
import { useTranslationProvider } from "./TranslationProvider";
import { useNavigate } from "react-router-dom";
import { GeneratePasswordResetLinkResponse } from "../../types/responses/GeneratePasswordResetLinkResponse";
import { generateMessagePasswordReset } from "../../utils/createMailMessages";
import { TranslationType } from "../../types/Generic";

const AuthContext = createContext<AuthProviderType>({} as AuthProviderType);

/**
 * This Provider handle the authentication process
 *		- Authenticate the user and boat
 *		- Check if the user is admin
 *		- Get the corresponding Information
 * 		- Based on his it will redirect it to the corresponding page
 */
export const AuthProvider = ({ children }: { children: JSX.Element }) => {
	const { myAuth, myFS } = useFirebase();
	const { makeGet, makePost } = useNetwork();
	const { changeLanguage, language } = useTranslationProvider();

	const [user, setUser] = useState<User | null>(null);
	const [isBoatAccount, setIsBoatAccount] = useState<boolean>(false);
	const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
	const [boatProfile, setBoatProfile] = useState<Boat | null>(null);
	const [isAdmin, setIsAdmin] = useState<boolean>(false);
	const navigate = useNavigate();
	const isBoat = async (uid: string) => {
		let isBoat = false;
		try {
			let docRef = doc(myFS, "boats", uid);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				isBoat = true;
			}
		} catch (err) {
			console.error(err);
		}

		return isBoat;
	};

	useEffect(() => {
		if (myAuth) {
			let unsubscribe = onAuthStateChanged(myAuth, async (user) => {
				if (user) {
					await setPersistence(myAuth, browserSessionPersistence);
					setUser(user);
					const isABoat = await isBoat(user.uid);
					setIsBoatAccount(isABoat);
					const isAdmin = await isUserAdmin(user.uid);
					setIsAdmin(isAdmin);

					await handleListenerForRelogged();
				} else {
					setUser(null);
					setUserProfile(null);
					setBoatProfile(null);
				}
			});
			return unsubscribe && unsubscribe();
		}
	}, [myAuth]);

	const loginWithEmailAndPassword = async (email: string, password: string): Promise<boolean> => {
		try {
			await setPersistence(myAuth, browserSessionPersistence);

			let userCredential = await signInWithEmailAndPassword(myAuth, email, password);
			let user = userCredential.user;
			if (!user?.uid) {
				let msg = `No UID found after signIn!`;
				console.error(msg);
				throw new Error(msg);
			}
			setUser(user);
			const isAdmin = await isUserAdmin(user.uid);
			setIsBoatAccount(false);
			setIsAdmin(isAdmin);
			return isAdmin;
		} catch (err) {
			console.error(err);
			const error = err as Error;
			if (error.message.includes("auth/wrong-password")) throw new Error("Wrong password");
			if (error.message.includes("auth/user-not-found")) throw new Error("No user with this email");
			throw new Error("Something went wrong");
		}
	};

	const isUserAdmin = async (uid: string): Promise<boolean> => {
		let isAdmin = false;
		try {
			let docRef = doc(myFS, "users", uid);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				if (docSnap.data().role === "ADMIN") {
					isAdmin = true;
				}
			}
		} catch (err) {
			console.error(err);
		}

		return isAdmin;
	};

	const loginWithUsernameAndPassword = async (username: string, password: string): Promise<void> => {
		let email = "";
		try {
			const result = await makeGet(api.getEmailFromUsername + "?&username=" + username);
			if (result.data.ok != null && !result.data.ok) throw new Error(result.data.message);
			email = result.data.email;

			let userCredential = await signInWithEmailAndPassword(myAuth, email, password);
			let user = userCredential.user;
			if (!user?.uid) {
				let msg = `No UID found after signIn!`;
				console.error(msg);
				throw new Error(msg);
			}
			setIsBoatAccount(true);
			setUser(user);
		} catch (err) {
			console.error(err);
			const error = err as Error;
			if (error.message.includes("auth/wrong-password")) throw new Error("Password sbagliata");
			if (error.message.includes("Document not found")) throw new Error("Username sbagliato");

			throw new Error("Something went wrong");
		}
	};

	const handleListenerForBoat = async () => {
		if (user != null) {
			let unsubscribe: any = null;
			let unsubscribeBoatChecklist: Unsubscribe | null = null;
			const listenToBoatDoc = async (uid: string) => {
				try {
					let docRef = doc(myFS, "boats", uid);
					unsubscribe = onSnapshot(docRef, async (docSnap: any): Promise<void> => {
						let profileData = docSnap.data() as Boat;

						if (profileData != null) {
							const checklist = await getChecklistCollectionAssociatedWithBoat(uid);
							profileData["uid"] = uid;
							profileData["emailVerified"] = user.emailVerified;
							profileData["idToken"] = await user.getIdToken();
							profileData["deposit"] = profileData.deposit;
							profileData["checklist"] = checklist;
						}

						let checklistCollectionRef = collection(myFS, `boats/${uid}/checklist`);
						unsubscribeBoatChecklist = onSnapshot(checklistCollectionRef, async (querySnap: QuerySnapshot<DocumentData>): Promise<void> => {
							if (!querySnap.empty) {
								let checklist: ChecklistElement[] = [];
								querySnap.docs.map((doc: any) => {
									checklist.push({ ...(doc.data() as ChecklistElement), uid: doc.id });
								});
								const newBoatProfile = { ...profileData };
								newBoatProfile.checklist = checklist;
								setBoatProfile(newBoatProfile);
							}
						});
					});
				} catch (err) {
					const error = err as Error;
					console.error(`useEffect() failed with: ${error.message}`);
				}
			};
			if (user?.uid) {
				listenToBoatDoc(user.uid);
				return () => {
					unsubscribe && unsubscribe() && unsubscribeBoatChecklist && unsubscribeBoatChecklist();
				};
			} else if (!user) {
				setBoatProfile(null);
			}
		}
	};

	const handleListenerForUser = async () => {
		if (user != null) {
			let unsubscribe: any = null;
			const listenToBoatDoc = async (uid: string) => {
				try {
					let docRef = doc(myFS, "users", uid);
					unsubscribe = onSnapshot(docRef, async (docSnap: any): Promise<void> => {
						let profileData = docSnap.data() as UserProfile;
						if (profileData != null) {
							profileData["uid"] = uid;
							profileData["emailVerified"] = user.emailVerified;
							profileData["idToken"] = await user.getIdToken();
						}
						setUserProfile(profileData);
					});
				} catch (err) {
					const error = err as Error;
					console.error(`useEffect() failed with: ${error.message}`);
				}
			};

			if (user?.uid) {
				listenToBoatDoc(user.uid);
				return () => {
					unsubscribe && unsubscribe();
				};
			} else if (!user) {
				setUserProfile(null);
			}
		}
	};
	const changePasswordUser = async (newPassword: string) => {
		try {
			if (user) {
				await updatePassword(user, newPassword);
			}
		} catch (err) {
			console.error(err);
		}
	};
	//This function will be used from both the UserProvider and the BoatProvider
	const sendResetPasswordEmail = async (email: string) => {
		const result = await makePost<GeneratePasswordResetLinkResponse>(api.generatePasswordResetLink, {
			email: email,
			language: language,
		});

		const { message, subject } = generateMessagePasswordReset({
			info: result.data.info,
			link: result.data.link,
			language: language as keyof TranslationType,
		});

		await addDoc(collection(myFS, "mail"), {
			to: [email],
			message: {
				html: message,
				subject: subject,
			},
		});
	};
	const handleListenerForRelogged = async () => {
		if (user == null) return;
		const isABoat = await isBoat(user.uid);
		if (isABoat) {
			await handleListenerForBoat();

			changeLanguage("it");
		} else {
			await handleListenerForUser();
		}
	};
	useEffect(() => {
		handleListenerForRelogged();
	}, [user, myFS]);
	useEffect(() => {
		if (user != null) {
			if (isBoatAccount) {
				if (window.location.pathname.includes("/login/boat")) {
					navigate("/boat/orders");
				}
			} else if (userProfile != null && (userProfile as unknown as any).role == "ADMIN") {
				navigate("/admin");
			} else {
				if (window.location.pathname === "/") {
					navigate("/user/landing");
				}
			}
		}
	}, [boatProfile, userProfile]);
	const getChecklistCollectionAssociatedWithBoat = async (uid: string) => {
		let checklist: ChecklistElement[] = [];
		const querySnapshot = await getDocs(collection(myFS, "boats", uid, "checklist"));
		querySnapshot.docs.map((doc) => {
			checklist.push({ ...(doc.data() as ChecklistElement), uid: doc.id });
		});
		return checklist;
	};

	const logout = async () => {
		setUserProfile(null);
		setBoatProfile(null);
		await myAuth.signOut();
	};

	const authContextData: AuthProviderType = {
		loginWithUsernameAndPassword,
		sendResetPasswordEmail,
		loginWithEmailAndPassword,
		changePasswordUser,
		getChecklistCollectionAssociatedWithBoat,
		boatProfile,
		userProfile,
		user,
		isAdmin,
		logout,
	};

	return <AuthContext.Provider value={authContextData}>{children}</AuthContext.Provider>;
};
export const useAuth = () => useContext(AuthContext);
