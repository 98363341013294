import { Button, Grid, TextField } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Boat } from "../../types/documents/Boat";
import { useBoatProvider } from "../providers/BoatProvider";
import * as yup from "yup";
import { useFormik } from "formik";
import "../../styles/Buttons.scss";
import { useSnackbar } from "../providers/SnackbackProvider";

const validationSchema = yup.object({
	newPassword: yup.string().required("La password è richista"),
	confirmNewPassword: yup
		.string()
		.oneOf([yup.ref("newPassword"), undefined], "Le password devono essere uguali")
		.required("Conferma la tua password"),
});
export const BoatChangePassword = ({
	boat,
	index,
	expanded,
	handleChangeAccordion,
}: {
	boat: Boat;
	index: number;
	expanded: string | boolean;
	handleChangeAccordion: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}) => {
	const { modifyBoatPassword } = useBoatProvider();
	const { openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();
	const formik = useFormik({
		initialValues: {
			newPassword: "",
			confirmNewPassword: "",
		},

		validationSchema: validationSchema,
		onSubmit: async (values) => await handleSubmit(values),
	});

	const handleSubmit = async (values: any) => {
		openLoadingSnackbar();
		await modifyBoatPassword(boat.uid, values.newPassword);
		closeLoadingSnackbar();
	};
	return (
		<>
			<Accordion className="accordion" expanded={expanded === `panel${index}`} onChange={handleChangeAccordion(`panel${index}`)}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}bh-content`} id={`panel${index}bh-header`}>
					<Typography sx={{ width: "100%", flexShrink: 0 }} variant="h4">
						{boat.username}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
						<Grid container justifyContent={"space-between"} rowGap={4}>
							<Grid item xs={12} md={5}>
								<TextField
									className="input-form"
									sx={{ width: { xs: "100%" } }}
									id="newPassword"
									name="newPassword"
									label="Nuova Password"
									variant={"filled"}
									value={formik.values.newPassword}
									onChange={formik.handleChange}
									error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
									helperText={formik.touched.newPassword && formik.errors.newPassword}
								/>
							</Grid>
							<Grid item xs={12} md={5}>
								<TextField
									className="input-form"
									sx={{ width: { xs: "100%" } }}
									id="confirmNewPassword"
									name="confirmNewPassword"
									label="Conferma la password"
									variant={"filled"}
									value={formik.values.confirmNewPassword}
									onChange={formik.handleChange}
									error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
									helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
								/>
							</Grid>

							<Grid item xs={12} sx={{ marginTop: "16px" }}>
								<Grid container justifyContent={"center"}>
									<Button type="submit" className="form-button admin-button">
										MODIFICA PASSWORD
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</AccordionDetails>
			</Accordion>
		</>
	);
};
