import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useOrders } from "../../../components/providers/OrderProvider";
import { UserMenu } from "../UserMenu";
import { UserCheck } from "../../../components/user/orders/checkBoat/UserCheck";
import { UserCheckShowCheckList } from "../../../components/user/orders/checkBoat/UserCheckShowCheckList";
import { useTranslation } from "react-i18next";
import { ChecklistElementBoarding, WarningChecklistBoat } from "../../../types/forms/Checklist";

export const UserBoatChecks = () => {
	const { futureOrders } = useOrders();
	const [expanded, setExpanded] = useState<string | false>(false);
	const { t } = useTranslation();
	const handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			<Grid container flexWrap="nowrap">
				<Grid item height="100%" className="menu-container">
					<UserMenu />
				</Grid>
				<Grid item xs className="menu-content">
					<Grid container rowGap="16px">
						<Grid item xs={12}>
							<Typography variant="h3">{t("your-bookings")}</Typography>
						</Grid>

						{futureOrders.map((value, index) => {
							return (
								<Grid item xs={12} marginY="2px" key={`${value.orderUID}-order-index-${index}`}>
									<UserCheck
										order={value}
										index={index}
										expanded={expanded}
										handleChangeAccordion={handleChangeAccordion}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
