import { Order } from "../../../../types/documents/Order";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ChecklistElementBoarding, WarningChecklistBoat } from "../../../../types/forms/Checklist";
import { CheckBoatNotesAndImages } from "../../../common/CheckBoatNotesAndImages";
import { useOrders } from "../../../providers/OrderProvider";
import { checkboatStatuses } from "../../../../utils/constants";
import { CheckoutChecklist } from "../../../common/CheckoutChecklist";
import { BoatOrderCheckInContent } from "../BoatOrderCheckInContent";

export const BoatPastOrderContent = ({ order, isCompleted, expanded }: { order: Order; isCompleted: boolean; expanded: boolean }) => {
	const [checklistInventory, setChecklistInventory] = useState<ChecklistElementBoarding[]>([] as ChecklistElementBoarding[]);
	const [checklistCheckin560, setChecklistCheckin560] = useState<ChecklistElementBoarding[]>([] as ChecklistElementBoarding[]);
	const [checkoutchecklistInventory, setCheckoutChecklistInventory] = useState<ChecklistElementBoarding[]>([] as ChecklistElementBoarding[]);
	const [checkoutchecklistCheckin560, setCheckoutChecklistCheckin560] = useState<ChecklistElementBoarding[]>([] as ChecklistElementBoarding[]);
	const [checkBoatToBeDone, setCheckBoatToBeDone] = useState<boolean>(true);
	const [notesCheckBoat, setNotesCheckBoat] = useState<string>("");
	const [notesCheckout, setNotesCheckout] = useState<string>("");
	const [imagesCheckout, setImagesCheckout] = useState<File[]>([] as File[]);
	const [imagesCheckBoat, setImagesCheckBoat] = useState<File[]>([] as File[]);
	const [warnings, setWarnings] = useState<WarningChecklistBoat[]>([]);
	useEffect(() => {
		if (order != null) {
			(async () => {
				if (order.checkBoat?.status === "pending") setCheckBoatToBeDone(true);
				if (
					order.checkBoat != null &&
					order.checkout != null &&
					order.checkBoat.status === checkboatStatuses.COUNTER_SIGNED &&
					order.checkout.status === checkboatStatuses.COUNTER_SIGNED
				) {
					setNotesCheckout(order.checkout.note);
					setNotesCheckBoat(order.checkBoat.note);
					const checklist = order.checkBoat.checkList;
					setChecklistInventory(checklist.filter((checklistElement) => checklistElement.category === "inventory"));
					setChecklistCheckin560(checklist.filter((checklistElement) => checklistElement.category === "checkin560"));

					const checklistCheckout = order.checkout.checkList;
					setCheckoutChecklistInventory(checklistCheckout.filter((checklistElement) => checklistElement.category === "inventory"));
					setCheckoutChecklistCheckin560(checklistCheckout.filter((checklistElement) => checklistElement.category === "checkin560"));
				}
				setWarnings(order.warnings);
			})();
		}
	}, [order]);
	return (
		<>
			{expanded && order.checkBoat != null && order.checkBoat.status != "pending" && (
				<>
					<Grid container rowGap="32px">
						<Grid item xs={12}>
							<BoatOrderCheckInContent order={order} disabled={true} />
						</Grid>
						<Grid item xs={12}>
							<CheckBoatNotesAndImages
								type="check-in"
								isUser={true}
								disabled={true}
								notes={notesCheckBoat}
								setNotes={setNotesCheckBoat}
								images={imagesCheckBoat}
								setImages={setImagesCheckBoat}
								imagesName={order.checkBoat.images}
								orderId={order.orderUID}
							/>
						</Grid>
						<Grid item xs={12}>
							<CheckBoatNotesAndImages
								type="check-out"
								isUser={true}
								disabled={true}
								notes={notesCheckout}
								setNotes={setNotesCheckout}
								images={imagesCheckout}
								setImages={setImagesCheckout}
								imagesName={order.checkout!.images}
								orderId={order.orderUID}
							/>
						</Grid>
						<Grid item xs={12}>
							{/**TODO check if warnings are selectable in order history */}
							<CheckoutChecklist
								isUser={true}
								boardingStatus={order.checkBoat.status}
								checkBoatChecklistInventory={checklistInventory}
								checkBoatChecklistCheckin560={checklistCheckin560}
								checkoutChecklistInventory={checkoutchecklistInventory}
								checkoutChecklistCheckin560={checkoutchecklistCheckin560}
								setCheckoutChecklistCheckin560={setCheckoutChecklistCheckin560}
								setCheckoutChecklistInventory={setCheckoutChecklistInventory}
								orderUID={order.orderUID}
								warnings={warnings}
								setWarnings={setWarnings}
							/>
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
};
