import { Grid, Typography } from "@mui/material";
import { ExtraElementInOrder } from "../../../types/ExtraElementInOrder";
import { ExtraComponent } from "./ExtraComponent";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { sortExtras } from "../../../utils/sorting";
import { getDayFromTimestamp } from "../../../utils/dateOperation";

const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24; // milliseconds * seconds * minutes * hours

export const OrderExtra = ({
	extras,
	setExtras,
	orderStatus,
	isBoat,
	extrasRejected,
	crewlistLenght,
	disabled,
	orderStartDate,
	orderEndDate,
	changeCostAndTypeExtra,
}: {
	extras: ExtraElementInOrder[];
	setExtras: Dispatch<SetStateAction<ExtraElementInOrder[]>>;
	orderStatus: string;
	crewlistLenght: number;
	isBoat?: boolean;
	extrasRejected?: boolean;
	disabled?: boolean;
	orderStartDate: number;
	orderEndDate: number;
	changeCostAndTypeExtra?: ({
		extra,
		newCost,
		newType,
	}: {
		extra: ExtraElementInOrder;
		newCost: number;
		newType: ExtraElementInOrder["type"];
	}) => Promise<void>;
}) => {
	const [totalCost, setTotalCost] = useState<number>(0);
	const { t } = useTranslation();

	const handleSelectExtra = (extra: ExtraElementInOrder, index: number) => {
		const newExtras = [...extras];
		newExtras[index] = extra;
		setExtras(newExtras);
	};

	useEffect(() => {
		let totalCost = 0;
		for (let i = 0; i < extras.length; i++) {
			const extra = extras[i];
			if (!extra.checked) continue;
			if (extra.type === "piece") {
				if (extra.quantity != null) {
					totalCost += extra.quantity * extra.cost;
				}
			} else if (extra.type === "person") {
				totalCost += extra.cost * crewlistLenght;
			} else if (extra.type === "week") {
				const startDate = new Date(orderStartDate * 1000);
				const endDate = new Date(orderEndDate * 1000);

				const days = Math.round(Math.abs(startDate.getTime() - endDate.getTime()) / ONE_DAY_IN_MILLISECONDS);

				if (days >= 7) {
					totalCost += extra.cost * Math.floor(days / 7);
				}
			} else totalCost += extra.cost;

			extras[i].cost = extra.cost;
		}
		setTotalCost(totalCost);
	}, [extras]);

	return (
		<>
			<Grid container rowGap={2}>
				<Grid item xs={12}>
					<Typography variant="h4" sx={{ color: extrasRejected ? "red" : "inherit" }}>
						{t("extra-booking")}
					</Typography>
					{extrasRejected && (
						<Typography
							variant="h4"
							sx={{ color: extrasRejected ? "red" : "inherit", marginTop: "5px" }}
							className="check-in-section-rejected-message">
							{t("toBeRecompiled")}
						</Typography>
					)}
				</Grid>
				{extras.sort(sortExtras).map((extra, index) => {
					return (
						<ExtraComponent
							handleSelectExtra={handleSelectExtra}
							changeCostAndTypeExtra={changeCostAndTypeExtra}
							extra={extra}
							key={`check-in-order-extra-${index}`}
							index={index}
							orderStatus={orderStatus}
							isBoat={isBoat}
							disabled={disabled}
						/>
					);
				})}
				<Grid item xs={12}>
					<Typography variant="body1" fontWeight={600}>
						{t("total-amount")} = {totalCost}€
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};
