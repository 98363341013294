import { useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
	Button,
	RadioGroup,
	FormControlLabel,
	Radio,
	TextField,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import * as yup from "yup";
import { Form, Formik } from "formik";
import { TranslationType } from "../../../types/Generic";
import { WarningChecklistBoat } from "../../../types/forms/Checklist";

const validationSchema = yup.object({
	nameInItalian: yup.string().when("categoryOfChecklistElement", ([categoryOfChecklistElement], schema) => {
		return categoryOfChecklistElement !== 2 ? yup.string().required("Il nome in italiano è richiesto") : yup.string().notRequired();
	}),
	nameInEnglish: yup.string().when("categoryOfChecklistElement", ([categoryOfChecklistElement], schema) => {
		return categoryOfChecklistElement !== 2 ? yup.string().required("Il nome in inglese è richiesto") : yup.string().notRequired();
	}),
	nameInCzech: yup.string().when("categoryOfChecklistElement", ([categoryOfChecklistElement], schema) => {
		return categoryOfChecklistElement !== 2 ? yup.string().required("Il nome in ceco è richiesto") : yup.string().notRequired();
	}),

	categoryOfChecklistElement: yup.number(),
	inventoryPrice: yup.number().when("categoryOfChecklistElement", ([categoryOfChecklistElement], schema) => {
		return categoryOfChecklistElement === 0 ? yup.number().required("Il prezzo ínventario è richiesto") : yup.number().notRequired();
	}),
	warningValueItalian: yup.string().when("categoryOfChecklistElement", ([categoryOfChecklistElement], schema) => {
		return categoryOfChecklistElement === 2 ? yup.string().required("Inserisci Warning in Italiano") : yup.string().notRequired();
	}),
	warningValueEnglish: yup.string().when("categoryOfChecklistElement", ([categoryOfChecklistElement], schema) => {
		return categoryOfChecklistElement === 2 ? yup.string().required("Inserisci un Warning in Inglese") : yup.string().notRequired();
	}),
	warningValueCzech: yup.string().when("categoryOfChecklistElement", ([categoryOfChecklistElement], schema) => {
		return categoryOfChecklistElement === 2 ? yup.string().required("Inserisci un Warning in Ceco") : yup.string().notRequired();
	}),
});
export const AddCheckListPopUp = ({
	isPopUpOpen,
	handleClosePopup,
	handleAddCheckListElement,
}: {
	isPopUpOpen: boolean;
	handleClosePopup: () => void;
	handleAddCheckListElement: (
		type: string,
		name: TranslationType,
		category: string,
		price?: number,
		warning?: WarningChecklistBoat
	) => Promise<void>;
}) => {
	const [expanded, setExpanded] = useState<string | false>(false);
	const radioButtonType = ["Check box", "Campo libero"];
	const radioButtonCategory = ["Inventario", "Prove di funzionamento", "Warning"];

	/**
	 *
	 * @param values.type identifies 0:A settimana, 1: A pezzo ,2: A prenotazione
	 */
	const handleSubmit = async (values: any, resetForm: any) => {
		handleAddCheckListElement(
			values.typeOfChecklistElement === "0" ? "binary" : "text",
			{
				it: values.nameInItalian,
				en: values.nameInEnglish,
				cs: values.nameInCzech,
			},
			values.categoryOfChecklistElement === "0" ? "inventory" : values.categoryOfChecklistElement === "1" ? "checkin560" : "warning",
			values.categoryOfChecklistElement === "0" ? values.inventoryPrice : undefined,
			values.categoryOfChecklistElement === "2"
				? {
						value: {
							it: values.warningValueItalian,
							en: values.warningValueEnglish,
							cs: values.warningValueCzech,
						},
				  }
				: undefined
		);
		resetForm();
		handleClosePopup();
	};
	const handleExpandAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			<Dialog open={isPopUpOpen} onClose={handleClosePopup} fullWidth maxWidth="md">
				<DialogTitle mb={3}>
					<Typography variant="h4">Aggiungi una voce</Typography>
				</DialogTitle>
				<DialogContent>
					<>
						<Formik
							initialValues={{
								nameInItalian: "",
								nameInEnglish: "",
								nameInCzech: "",
								typeOfChecklistElement: 0,
								categoryOfChecklistElement: "0",
								inventoryPrice: 0,
								warningValueItalian: "",
								warningValueEnglish: "",
								warningValueCzech: "",
							}}
							onSubmit={async (values, { resetForm }) => {
								await handleSubmit(values, resetForm);
							}}
							validationSchema={validationSchema}
						>
							{({ initialValues, touched, errors, values, setFieldValue, handleChange }) => (
								<Form>
									<Grid container rowGap={2}>
										<Grid item xs={12}>
											<TextField
												className="input-form"
												sx={{ width: { xs: "100%" } }}
												id="nameInItalian"
												name="nameInItalian"
												label="Nome in italiano"
												variant={"filled"}
												value={values.nameInItalian}
												onChange={handleChange}
												error={touched.nameInItalian && Boolean(errors.nameInItalian)}
												helperText={touched.nameInItalian && errors.nameInItalian}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												className="input-form"
												sx={{ width: { xs: "100%" } }}
												id="nameInEnglish"
												name="nameInEnglish"
												label="Nome in inglese"
												variant={"filled"}
												value={values.nameInEnglish}
												onChange={handleChange}
												error={touched.nameInEnglish && Boolean(errors.nameInEnglish)}
												helperText={touched.nameInEnglish && errors.nameInEnglish}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												className="input-form"
												sx={{ width: { xs: "100%" } }}
												id="nameInCzech"
												name="nameInCzech"
												label="Nome in ceco"
												variant={"filled"}
												value={values.nameInCzech}
												onChange={handleChange}
												error={touched.nameInCzech && Boolean(errors.nameInCzech)}
												helperText={touched.nameInCzech && errors.nameInCzech}
											/>
										</Grid>

										<Grid item xs={12}>
											<Accordion
												className="accordion"
												sx={{ background: "#FFFFFF !important" }}
												expanded={expanded === "panel1"}
												onChange={handleExpandAccordion("panel1")}
											>
												<AccordionSummary
													expandIcon={<ExpandMoreIcon />}
													aria-controls="panel1bh-content"
													id="panel1bh-header"
												>
													<Typography sx={{ width: "33%", flexShrink: 0 }} variant="h4">
														Tipo di voce
													</Typography>
												</AccordionSummary>
												<AccordionDetails>
													<RadioGroup defaultValue={initialValues.typeOfChecklistElement}>
														{radioButtonType.map((label, index) => (
															<FormControlLabel
																key={`form-checklist-type-radio-${label}-${index}`}
																value={index}
																name="typeOfChecklistElement"
																onChange={handleChange}
																control={
																	<Radio
																		icon={<CheckBoxOutlineBlankIcon />}
																		checkedIcon={<CheckBoxIcon color="success" />}
																	/>
																}
																label={label}
															/>
														))}
													</RadioGroup>
												</AccordionDetails>
											</Accordion>
										</Grid>
										<Grid item xs={12}>
											<Accordion
												className="accordion"
												sx={{ background: "#FFFFFF !important" }}
												expanded={expanded === "panel2"}
												onChange={handleExpandAccordion("panel2")}
											>
												<AccordionSummary
													expandIcon={<ExpandMoreIcon />}
													aria-controls="panel1bh-content"
													id="panel1bh-header"
												>
													<Typography sx={{ width: "33%", flexShrink: 0 }} variant="h4">
														Elenco della voce
													</Typography>
												</AccordionSummary>
												<AccordionDetails>
													<RadioGroup defaultValue={initialValues.categoryOfChecklistElement}>
														{radioButtonCategory.map((label, index) => (
															<>
																<FormControlLabel
																	key={`form-checklist-category-radio-${label}-${index}`}
																	value={index}
																	name="categoryOfChecklistElement"
																	onChange={handleChange}
																	control={
																		<Radio
																			icon={<CheckBoxOutlineBlankIcon />}
																			checkedIcon={<CheckBoxIcon color="success" />}
																		/>
																	}
																	label={label}
																/>
																{label === "Inventario" && values.categoryOfChecklistElement === "0" && (
																	<>
																		<TextField
																			className="input-form"
																			sx={{ width: { xs: "100%" } }}
																			id="inventoryPrice"
																			name="inventoryPrice"
																			label="Prezzo"
																			variant={"filled"}
																			type="number"
																			placeholder="0"
																			value={values.inventoryPrice}
																			onChange={handleChange}
																			onClick={() => {
																				if(values.inventoryPrice === 0)
																					setFieldValue("inventoryPrice", "")
																			}}
																			error={touched.inventoryPrice && Boolean(errors.inventoryPrice)}
																			helperText={touched.inventoryPrice && errors.inventoryPrice}
																		/>
																	</>
																)}
																{label === "Warning" && values.categoryOfChecklistElement === "2" && (
																	<>
																		<div style={{ display: "flex", rowGap: "16px", flexDirection: "column" }}>
																			<TextField
																				multiline
																				rows={4}
																				className="input-form multiline gray-background"
																				sx={{ width: { xs: "100%" } }}
																				id="warningValueItalian"
																				name="warningValueItalian"
																				label="Inserisci Il Warning in Italiano"
																				variant={"filled"}
																				value={values.warningValueItalian}
																				onChange={handleChange}
																				error={
																					touched.warningValueItalian && Boolean(errors.warningValueItalian)
																				}
																				helperText={touched.warningValueItalian && errors.warningValueItalian}
																			/>
																			<TextField
																				multiline
																				rows={4}
																				className="input-form multiline gray-background"
																				sx={{ width: { xs: "100%" } }}
																				id="warningValueEnglish"
																				name="warningValueEnglish"
																				label="Inserisci Il Warning in Inglese"
																				variant={"filled"}
																				value={values.warningValueEnglish}
																				onChange={handleChange}
																				error={
																					touched.warningValueEnglish && Boolean(errors.warningValueEnglish)
																				}
																				helperText={touched.warningValueEnglish && errors.warningValueEnglish}
																			/>
																			<TextField
																				multiline
																				rows={4}
																				className="input-form multiline gray-background"
																				sx={{ width: { xs: "100%" } }}
																				id="warningValueCzech"
																				name="warningValueCzech"
																				label="Inserisci Il Warning in Ceco"
																				variant={"filled"}
																				value={values.warningValueCzech}
																				onChange={handleChange}
																				error={touched.warningValueCzech && Boolean(errors.warningValueCzech)}
																				helperText={touched.warningValueCzech && errors.warningValueCzech}
																			/>
																		</div>
																	</>
																)}
															</>
														))}
													</RadioGroup>
												</AccordionDetails>
											</Accordion>
										</Grid>
										<Grid item xs={12} sx={{ marginTop: "16px" }} display={"flex"} justifyContent={"center"}>
											<Button type="submit" className="form-button ">
												CREA
											</Button>
										</Grid>
									</Grid>
								</Form>
							)}
						</Formik>
					</>
				</DialogContent>
			</Dialog>
		</>
	);
};
