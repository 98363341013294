import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Order } from "../../../../types/documents/Order";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CheckBoatChecklist } from "../../../common/CheckBoatChecklist";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ChecklistElementBoarding, WarningChecklistBoat } from "../../../../types/forms/Checklist";
import { CheckBoatNotesAndImages } from "../../../common/CheckBoatNotesAndImages";
import { useOrders } from "../../../providers/OrderProvider";
import { useAuth } from "../../../providers/AuthProvider";
import { checkboatStatuses } from "../../../../utils/constants";
import { constructOrder } from "../../../../utils/orderChecklist";

export const UserCheckShowCheckList = ({
	order,
	boardingChecklist,
	boardingNotes,
	boardingImages,
	boardingWarnings,
	setBoardingChecklist,
	setBoardingNotes,
	setBoardingImages,
	setBoardingWarnings,
}: {
	order: Order;
	boardingChecklist: ChecklistElementBoarding[];
	boardingNotes: string;
	boardingImages: File[];
	boardingWarnings: WarningChecklistBoat[];
	setBoardingChecklist: Dispatch<SetStateAction<ChecklistElementBoarding[]>>;
	setBoardingNotes: Dispatch<SetStateAction<string>>;
	setBoardingImages: Dispatch<SetStateAction<File[]>>;
	setBoardingWarnings: Dispatch<SetStateAction<WarningChecklistBoat[]>>;
}) => {
	const [checklistInventory, setChecklistInventory] = useState<ChecklistElementBoarding[]>([] as ChecklistElementBoarding[]);
	const [checklistCheckin560, setChecklistCheckin560] = useState<ChecklistElementBoarding[]>([] as ChecklistElementBoarding[]);
	const [checkBoatToBeDone, setCheckBoatToBeDone] = useState<boolean>(false);
	// const [warnings, setWarnings] = useState<WarningChecklistBoat[]>([] as WarningChecklistBoat[]);

	// const [notes, setNotes] = useState<string>("");
	// const [images, setImages] = useState<File[]>([] as File[]);

	const { t } = useTranslation();
	const { getChecklistCollectionAssociatedWithBoat } = useAuth();

	const setChecklistOfBoat = async (order: Order) => {
		let checklist: ChecklistElementBoarding[] = [] as ChecklistElementBoarding[];

		if (order.checkBoat && order.checkBoat.checkList.length > 0) {
			updateChecklistFromOrder(order.checkBoat.checkList);
			return;
		}

		checklist = (await getChecklistCollectionAssociatedWithBoat(order.boatUID)) as ChecklistElementBoarding[];

		if (checklist !== null) {
			checklist.forEach((checklistElement) => {
				if (checklistElement.inputType === "binary") {
					checklistElement.binaryChoice = true;
				}
			});
			const newChecklistInventory = checklist.filter((checklistElement) => checklistElement.category === "inventory");
			const newChecklistCheckin560 = checklist.filter((checklistElement) => checklistElement.category === "checkin560");
			const newChecklistInventoryOrdered = constructOrder(newChecklistInventory);
			const newChecklistCheckin560Ordered = constructOrder(newChecklistCheckin560);
			setChecklistInventory(newChecklistInventoryOrdered);
			setChecklistCheckin560(newChecklistCheckin560Ordered);
		}
	};

	const updateChecklistFromOrder = (checklist: ChecklistElementBoarding[]) => {
		console.log("order checklist update");
		setChecklistInventory(constructOrder(checklist.filter((checklistElement) => checklistElement.category === "inventory")));
		setChecklistCheckin560(constructOrder(checklist.filter((checklistElement) => checklistElement.category === "checkin560")));
	};

	useEffect(() => {
		if (order != null) {
			(async () => {
				setChecklistOfBoat(order);
				setBoardingWarnings(order.warnings);

				if (!order.checkBoat) return;
				setBoardingNotes(order.checkBoat.note);

				setCheckBoatToBeDone(order.checkBoat.status !== checkboatStatuses.COUNTER_SIGNED);
			})();
		}
	}, [order, checkBoatToBeDone]);

	// useEffect(() => {
	// 	if (setBoardingImages != null) setBoardingImages(images);
	// }, [images]);

	// useEffect(() => {
	// 	if (setBoardingNotes != null) setBoardingNotes(notes);
	// }, [notes]);

	useEffect(() => {
		if (setBoardingChecklist != null) setBoardingChecklist([...checklistCheckin560, ...checklistInventory]);
	}, [checklistInventory, checklistCheckin560]);

	// useEffect(() => {
	// 	if (setBoardingWarnings != null) setBoardingWarnings(warnings);
	// }, [warnings]);

	return (
		<>
			{order.checkBoat && (
				<Grid container rowGap="32px">
					<Grid item xs={12}>
						<CheckBoatChecklist
							orderUID={order.orderUID}
							isAllowedToModifyChecklist={checkBoatToBeDone && order.checkBoat.unlockUserAutoCheckin}
							isUser={true}
							boardingStatus={order.checkBoat!.status}
							setChecklistCheckin560={setChecklistCheckin560}
							checklistCheckin560={checklistCheckin560}
							checklistInventory={checklistInventory}
							setChecklistInventory={setChecklistInventory}
							warnings={boardingWarnings}
							setWarnings={setBoardingWarnings}
						/>
					</Grid>
					<Grid item xs={12}>
						<CheckBoatNotesAndImages
							isUser={true}
							disabled={!checkBoatToBeDone || !order.checkBoat?.unlockUserAutoCheckin}
							notes={boardingNotes}
							setNotes={setBoardingNotes}
							images={boardingImages}
							setImages={setBoardingImages}
							isAllowedToModifyChecklist={order.checkBoat?.unlockUserAutoCheckin}
							type="check-in"
							orderId={order.orderUID}
							imagesName={order.checkBoat?.images}
						/>
					</Grid>
				</Grid>
			)}
		</>
	);
};
