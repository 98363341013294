import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Order } from "../../../types/documents/Order";
import { useUserProvider } from "../../providers/UserProviderImpl";
import { UserDocument } from "../../../types/documents/User";

import "../../../styles/Orders.scss";
import { orderStatuses } from "../../providers/OrderProvider";
import $ from "jquery";
import { UserCheckInOrderContent } from "./UserCheckInOrderContent";
import { useTranslation } from "react-i18next";
import { useTranslationProvider } from "../../providers/TranslationProvider";
import { getDateIntervalFromTimestamps } from "../../../utils/dateOperation";
import { BoatDocument } from "../../../types/documents/Boat";
import { useBoatProvider } from "../../providers/BoatProvider";

export const UserCheckInOrder = ({ order }: { order: Order }) => {
	const { getBoatFromUID } = useBoatProvider();
	const { t } = useTranslation();
	const { language } = useTranslationProvider();
	const [boat, setBoat] = useState<BoatDocument>({} as BoatDocument);

	const statusName =
		order.status === orderStatuses.PENDING
			? t("check-in-pending")
			: order.status === orderStatuses.SENT
			? t("check-in-sent")
			: t("check-in-confirmed");

	const [expanded, setExpanded] = useState<string | false>(false);

	const handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	useEffect(() => {
		(async () => {
			const boat = await getBoatFromUID(order.boatUID);
			setBoat(boat);
		})();
	}, [order]);

	return (
		<>
			<Accordion className="accordion" expanded={expanded === `panel${order.orderUID}`} onChange={handleChangeAccordion(`panel${order.orderUID}`)}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon className={`color-action pending-action`} />}
					aria-controls={`panel${order.orderUID}bh-content`}
					id={`panel${order.orderUID}bh-header`}>
					<Grid container justifyContent="space-between">
						<Grid item xs={12} md={4}>
							<Typography className={`color-action ${order.status === orderStatuses.PENDING ? "pending-action" : ""}`} variant="h4" color="black">
								{boat.username}
							</Typography>
						</Grid>
						<Grid item xs={12} md={3}>
							<Typography className={`color-action ${order.status === orderStatuses.PENDING ? "pending-action" : ""}`} variant="h4" color="black">
								{getDateIntervalFromTimestamps(order.startDate, order.endDate, language)}
							</Typography>
						</Grid>
						<Grid item xs={12} md={5}>
							<Typography className={`color-action ${order.status === orderStatuses.PENDING ? "pending-action" : ""}`} variant="h4" color="black">
								{statusName}
							</Typography>
						</Grid>
					</Grid>
				</AccordionSummary>
				<AccordionDetails className="order-accordion">
					<UserCheckInOrderContent order={order} />
				</AccordionDetails>
			</Accordion>
		</>
	);
};
