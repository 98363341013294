import { Rating, Typography } from "@mui/material";
import { useState } from "react";
import StarIcon from '@mui/icons-material/Star';
import { RatingCategoryAvailable } from "./UserRatings";

export const RatingCategory = ({ title, category, handleSetRatingsValue }: {
    title: string,
    category: RatingCategoryAvailable,
    handleSetRatingsValue: (category: RatingCategoryAvailable, value: number) => void
}) => {
    const [stars, setStars] = useState(0);

    const handleOnChange = (event: any, newValue: number | null) => {
        if (newValue) {
            setStars(newValue);
            handleSetRatingsValue(category, newValue);
        }
    }

    return (
        <>
            <div className="rating-star-content">
                <Typography className="rating-star-text" sx={{ width: "100%", flexShrink: 0, xs: { fontSize: "16px !important" } }} variant="h4">
                    {title}
                </Typography>
                <Rating
                    emptyIcon={<StarIcon fontSize="inherit" />}
                    name="simple-controller"
                    value={stars}
                    className={'rating-star-icon'}
                    max={5}
                    precision={1}
                    size='large'
                    sx={{
                        color: '#68C5DA',
                    }}
                    onChange={handleOnChange}
                />
            </div>

        </>
    );
}