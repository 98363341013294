import { Button, Grid, Typography } from "@mui/material";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { Order } from "../../../../types/documents/Order";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSnackbar } from "../../../providers/SnackbackProvider";
import { useAuth } from "../../../providers/AuthProvider";
import { ContainerTableToPrint } from "./ContainerTableToPrint";

interface IPrintCheckProps {
	isCheckIn?: boolean;
	order: Order;
}
export const PrintCheck = ({ isCheckIn = true, order }: IPrintCheckProps) => {
	const { boatProfile } = useAuth();
	const { openSnackbar } = useSnackbar();

	const handlePrint = useReactToPrint({
		documentTitle: `${isCheckIn ? "CheckIn_" : "CheckOut_"}${order.orderUID}`,
		onBeforeGetContent: () => componentToPrint.current?.style.setProperty("display", "block", "important"),
		content: () => componentToPrint.current,
		onPrintError: (_, error) => {
			console.error(error);
			openSnackbar("Errore", error.message, "error");
		},
		onAfterPrint: () => componentToPrint.current?.style.setProperty("display", "none", "important"),
	});

	const componentToPrint = useRef<HTMLDivElement>(null);

	return (
		<>
			<Grid container gap={2} rowGap={3}>
				<Typography variant="h4">Stampa il {isCheckIn ? "Check-in" : "Check-out"}</Typography>
			</Grid>
			<Grid item xs={12} paddingTop={"16px"}>
				<Button className="print-button" sx={{ maxWidth: "100%" }} onClick={handlePrint}>
					<PrintOutlinedIcon sx={{ fontSize: "3rem" }} />
				</Button>
			</Grid>
			<ContainerTableToPrint isCheckIn={isCheckIn} order={order} boatProfile={boatProfile} ref={componentToPrint} />
		</>
	);
};
