import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
export const FeedbackMessage = ({
	messageSenderName,
	message,
	index,
	expanded,
	handleChangeAccordion,
}: {
	messageSenderName: string;
	message: string;
	index: number;
	expanded: string | boolean;
	handleChangeAccordion: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}) => {
	return (
		<>
			<Accordion
				className="accordion"
				expanded={expanded === `panel-message-${index}`}
				onChange={handleChangeAccordion(`panel-message-${index}`)}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls={`panel-message-${index}bh-content`}
					id={`panel-message-${index}bh-header`}
				>
					<Grid container justifyContent="space-between">
						<Grid item xs={12} md={4}>
							<Typography variant="h4" color="black">
								{messageSenderName}
							</Typography>
						</Grid>
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					<Typography variant="body1" color="black">
						{message}
					</Typography>
				</AccordionDetails>
			</Accordion>
		</>
	);
};
