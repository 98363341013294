import { Button, Grid } from "@mui/material";

import { Order } from "../../../../types/documents/Order";
import { useEffect, useRef, useState } from "react";
import { ChecklistElementBoarding, WarningChecklistBoat } from "../../../../types/forms/Checklist";
import { PopUpMnemonicInsertion } from "../../../popUps/PopUpMnemonicInsertion";
import { useSnackbar } from "../../../providers/SnackbackProvider";
import { useOrders } from "../../../providers/OrderProvider";
import { checkboatStatuses } from "../../../../utils/constants";
import { CheckBoatNotesAndImages } from "../../../common/CheckBoatNotesAndImages";
import { CheckoutChecklist } from "../../../common/CheckoutChecklist";
import { useTranslation } from "react-i18next";
import { useTranslationProvider } from "../../../providers/TranslationProvider";
import { TranslationType } from "../../../../types/Generic";

import { PrintCheck } from "../printCheck/PrintCheck";

export const BoatCheckoutContent = ({ order, isBoarding }: { order: Order; isBoarding: boolean }) => {
	const { t } = useTranslation();
	const { language } = useTranslationProvider();

	const [checkoutNotes, setCheckoutNotes] = useState("");
	const [checkoutImages, setCheckoutImages] = useState<File[]>([]);
	const [notes, setNotes] = useState<string>("");
	const [images, setImages] = useState<File[]>([] as File[]);
	const [checklistInventory, setChecklistInventory] = useState<ChecklistElementBoarding[]>([] as ChecklistElementBoarding[]);
	const [checklistCheckin560, setChecklistCheckin560] = useState<ChecklistElementBoarding[]>([] as ChecklistElementBoarding[]);
	const [checkoutchecklistInventory, setCheckoutChecklistInventory] = useState<ChecklistElementBoarding[]>([] as ChecklistElementBoarding[]);
	const [checkoutchecklistCheckin560, setCheckoutChecklistCheckin560] = useState<ChecklistElementBoarding[]>([] as ChecklistElementBoarding[]);
	const [disabled, setDisabled] = useState<boolean>(false);

	const [mnemonic, setMnemonic] = useState<string[]>(Array.from({ length: 2 }, () => ""));
	const [isOpenPopUpMnemonic, setIsOpenPopUpMnemonic] = useState<boolean>(false);
	const { openSnackbar, openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();
	const { submitBoatCheckoutBoat } = useOrders();
	const [warnings, setWarnings] = useState<WarningChecklistBoat[]>([]);

	useEffect(() => {
		(async () => {
			if (order != null && order.checkBoat != null && order.checkout != null) {
				setNotes(order.checkBoat.note);
				if (order.checkout?.status === checkboatStatuses.PENDING) {
					const checklistCheckOut: ChecklistElementBoarding[] = [] as ChecklistElementBoarding[];
					order.checkBoat.checkList.forEach((checklistElement) => checklistCheckOut.push({ ...checklistElement }));

					setChecklistInventory(order.checkBoat.checkList.filter((checklistElement) => checklistElement.category === "inventory"));
					setChecklistCheckin560(order.checkBoat.checkList.filter((checklistElement) => checklistElement.category === "checkin560"));
					//Clear the choices of the checklist in the boarding
					for (let i = 0; i < checklistCheckOut.length; i++) {
						if (checklistCheckOut[i].inputType === "binary") {
							checklistCheckOut[i].binaryChoice = true;
						} else {
							checklistCheckOut[i].textChoice = "";
						}
					}

					setCheckoutChecklistInventory(checklistCheckOut.filter((checklistElement) => checklistElement.category === "inventory"));
					setCheckoutChecklistCheckin560(checklistCheckOut.filter((checklistElement) => checklistElement.category === "checkin560"));
				} else if (order.checkBoat != null && order.checkout != null && order.checkout.status !== checkboatStatuses.PENDING) {
					setCheckoutNotes(order.checkout.note);
					const checklistBoarding = order.checkBoat.checkList;
					setChecklistInventory(checklistBoarding.filter((checklistElement) => checklistElement.category === "inventory"));
					setChecklistCheckin560(checklistBoarding.filter((checklistElement) => checklistElement.category === "checkin560"));
					const checklistCheckout = order.checkout.checkList;
					setCheckoutChecklistInventory(checklistCheckout.filter((checklistElement) => checklistElement.category === "inventory"));
					setCheckoutChecklistCheckin560(checklistCheckout.filter((checklistElement) => checklistElement.category === "checkin560"));
					if (order.checkout.status === checkboatStatuses.COUNTER_SIGNED) {
						setDisabled(true);
					}
				}
				setWarnings(order.warnings);
			}
		})();
	}, [order]);
	const handleSubmitFirm = async () => {
		try {
			openLoadingSnackbar();

			for (let i = 0; i < mnemonic.length; i++) {
				if (mnemonic[i] === "") {
					throw new Error(t("errorsMnemonicMissing")!);
				}
			}
			const checkList = [...checkoutchecklistCheckin560, ...checkoutchecklistInventory];
			await submitBoatCheckoutBoat({ checkList, notes: checkoutNotes, images: checkoutImages, orderUID: order.orderUID, mnemonic });
			openSnackbar(t("success"), t("snackSignSuccess"), "success");

			closeLoadingSnackbar();
		} catch (err) {
			const error = err as Error;
			closeLoadingSnackbar();
			openSnackbar(t("error"), error.message, "error");
		}
	};
	const handleClickFirm = () => {
		try {
			const checkList = [...checkoutchecklistCheckin560, ...checkoutchecklistInventory];
			checkList
				.filter((el) => el.inputType === "text")
				.forEach((el) => {
					if (el.textChoice === undefined || el.textChoice === null || el.textChoice.length === 0)
						throw new Error(
							`${t("error-missing-field-checklist-1")} \"${el.name[language as keyof TranslationType]}\" ${t(
								"error-missing-field-checklist-2"
							)} \"${el.category === "inventory" ? t("inventory") : t("control-of-function")}\" ${t("error-missing-field-checklist-3")}`
						);
				});
			setIsOpenPopUpMnemonic(true);
		} catch (err) {
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		}
	};

	const handleClosePopUpMnemonic = () => {
		setIsOpenPopUpMnemonic(false);
	};

	return (
		<>
			<PopUpMnemonicInsertion
				isPopUpOpen={isOpenPopUpMnemonic}
				handleClosePopup={handleClosePopUpMnemonic}
				setMnemonic={setMnemonic}
				mnemonic={mnemonic}
				handleSubmit={handleSubmitFirm}
			/>
			<Grid container rowGap={6}>
				{isBoarding && (
					<>
						<Grid item xs={6}>
							<PrintCheck isCheckIn={false} order={order} />
						</Grid>
						{/**Check list */}
						<Grid item xs={12}>
							<CheckoutChecklist
								checkBoatChecklistInventory={checklistInventory}
								checkBoatChecklistCheckin560={checklistCheckin560}
								checkoutChecklistInventory={checkoutchecklistInventory}
								checkoutChecklistCheckin560={checkoutchecklistCheckin560}
								setCheckoutChecklistCheckin560={setCheckoutChecklistCheckin560}
								setCheckoutChecklistInventory={setCheckoutChecklistInventory}
								isUser={false}
								boardingStatus={order.checkout != null ? order.checkout.status : checkboatStatuses.PENDING}
								orderUID={order.orderUID}
								warnings={warnings}
								setWarnings={setWarnings}
							/>
						</Grid>
						{/**Notes and images */}
						<Grid item xs={12}>
							<CheckBoatNotesAndImages
								type="check-in"
								notes={notes}
								images={images}
								setNotes={setNotes}
								setImages={setImages}
								isUser={false}
								disabled={true}
								orderId={order.orderUID}
								imagesName={order.checkBoat?.images}
							/>
						</Grid>
						<Grid item xs={12}>
							<CheckBoatNotesAndImages
								type="check-out"
								notes={checkoutNotes}
								images={checkoutImages}
								setNotes={setCheckoutNotes}
								setImages={setCheckoutImages}
								isUser={false}
								disabled={disabled}
								orderId={order.orderUID}
								imagesName={order.checkout?.images}
							/>
						</Grid>
						{/**SUBMITS */}
						{order.checkout?.status !== checkboatStatuses.COUNTER_SIGNED && (
							<Grid item xs={12}>
								<Grid container gap={2} rowGap={2}>
									<Grid xs item display="flex" justifyContent="center">
										<Button className="form-button" onClick={() => handleClickFirm()}>
											FIRMA
										</Button>
									</Grid>
								</Grid>
							</Grid>
						)}
					</>
				)}
			</Grid>
		</>
	);
};
