import { Grid, Button, Typography } from "@mui/material";
import { useState, useEffect } from "react";

import "../../../styles/Buttons.scss";
import trash_icon from "../../../assets/trash_icon.svg";
import { useAuth } from "../../providers/AuthProvider";
import { useBoatProvider } from "../../providers/BoatProvider";
import { useTranslationProvider } from "../../providers/TranslationProvider";
import { TranslationType } from "../../../types/Generic";
import { Pdf } from "../../../types/forms/Pdf";
import { AddPdfPopUp } from "./AddPdfPopUp";
import { useSnackbar } from "../../providers/SnackbackProvider";

export const BoatModifyPdf = () => {
	const { boatProfile } = useAuth();
	const { language } = useTranslationProvider();
	const { uploadPdf, removePdfFromBoat } = useBoatProvider();
	const { openSnackbar, openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();

	const [pdfs, setPdfs] = useState<Pdf[]>([]);
	const [openPdfPopup, setOpenPdfPopup] = useState<boolean>(false);

	const handleAddPdf = async (name: TranslationType, pdfFile: File) => {
		if (boatProfile) {
			await uploadPdf(boatProfile.uid, pdfFile, name);
		}
	};
	const handleDelete = async (name: TranslationType) => {
		try {
			if (boatProfile) {
				openLoadingSnackbar();
				await removePdfFromBoat(boatProfile.uid, name);
				closeLoadingSnackbar();
			}
		} catch (err) {
			closeLoadingSnackbar();
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		}
	};

	useEffect(() => {
		(async () => {
			if (boatProfile) {
				openLoadingSnackbar();
				const newPdfs: Pdf[] = [];
				if (boatProfile.pdfs) {
					boatProfile.pdfs.map((file) => {
						newPdfs.push(file);
					});
					setPdfs([...newPdfs]);
				}
				closeLoadingSnackbar();
			}
		})();
	}, [boatProfile]);
	const handleClosePopup = () => {
		setOpenPdfPopup(false);
	};
	return (
		<>
			<AddPdfPopUp isPopUpOpen={openPdfPopup} handleClosePopup={handleClosePopup} handleAddPdf={handleAddPdf}></AddPdfPopUp>

			<Grid container justifyContent={"space-between"} rowGap={4}>
				<Grid item xs={12} sx={{ marginTop: "16px" }}>
					<Grid container>
						<Grid item xs>
							<Button
								onClick={() => {
									setOpenPdfPopup(true);
								}}
								className="form-button subbutton"
							>
								Carica un pdf
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12}>
							{pdfs.length > 0 &&
								pdfs.map((value, index) => {
									return (
										<Grid key={value.it} container paddingY="16px" sx={{ borderTop: "1px solid #B7B7B7" }}>
											<Grid item xs={8} display="flex" alignItems="center">
												<Typography variant="body1" fontSize="20px">
													{value[language as keyof TranslationType]}
												</Typography>
											</Grid>
											<Grid item xs={4}>
												<Grid container justifyContent="flex-end" alignItems="center" gap={4}>
													<Grid item>
														<img src={trash_icon} alt="trash icon" onClick={() => handleDelete(value)}></img>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									);
								})}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
