import { createContext, useContext } from "react";
import { BoatProviderType } from "../../types/providers/BoatProvider";
import { useNetwork } from "./NetworkProvider";
import { api } from "../../utils/network/api";
import { CreateBoatResponse } from "../../types/responses/CreateBoatResponse";
import { AddExtraParams, EditExtraParams, Extra } from "../../types/forms/Extras";
import { useFirebase } from "./FirebaseProvider";
import { addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { ChecklistElement, WarningChecklistBoat } from "../../types/forms/Checklist";
import { checkboatStatuses, checklistTypes } from "../../utils/constants";
import { TranslationType } from "../../types/Generic";
import { deleteObject, getDownloadURL, getMetadata, ref, uploadBytes } from "firebase/storage";
import { AddVideoParams, Video } from "../../types/forms/Video";
import { useAuth } from "./AuthProvider";
import { Boat, BoatDocument } from "../../types/documents/Boat";
import { Pdf } from "../../types/forms/Pdf";
import { GetPdfsResponse } from "../../types/responses/GetPdfsResponse";
import { Feedback } from "../../pages/user/ratings/UserRatings";
import { addFeedbackResponse } from "../../types/responses/AddFeedbackResponse";
import { responseMessages } from "../../utils/responseMessages";
import { useTranslation } from "react-i18next";
import { signOut } from "firebase/auth";
import { useSnackbar } from "./SnackbackProvider";

const BoatContext = createContext<BoatProviderType>({} as BoatProviderType);
export const BoatProvider = ({ children }: { children: JSX.Element }) => {
	const { makePost, makeGet } = useNetwork();
	const { openSnackbar } = useSnackbar();
	const { myFS, myStorage, myAuth } = useFirebase();
	const { user } = useAuth();
	const { t } = useTranslation();

	/**
	 * This provider handles all operation related to a boat.
	 * 		- Creating a new Boat
	 * 		- Adding checklist and extra to the boat
	 * 		- Adding video and PDF to the boat
	 * 		- handling the download of the PDF
	 */

	const createBoat = async (
		username: string,
		licensePlate: string,
		password: string,
		deposit: number,
		port: string
	): Promise<CreateBoatResponse | undefined> => {
		if (user == null) {
			throw new Error("Not logged in, please relogg in");
		}
		const idToken = await user.getIdToken();

		const result = await makePost<CreateBoatResponse>(
			api.createBoat,
			{
				username,
				licensePlate,
				password,
				deposit,
				port,
			},
			idToken
		);
		if (result.status === 200) {
			return result.data;
		} else {
			throw new Error(result.data.message);
		}
	};

	const handleUpdateBoatInfo = async (uidBoat: string, oldBoatInfo: Boat, newBoatInfo: Partial<Boat>) => {
		const docRef = doc(myFS, "boats", uidBoat);
		await updateDoc(docRef, newBoatInfo);
	};

	const removeExtraFromBoat = async (uidBoat: string, extraNameInEng: string): Promise<void> => {
		if (user == null) {
			throw new Error("Not logged in, please relogg in");
		}
		const idToken = await user.getIdToken();

		await makePost(
			api.removeExtraFromBoat,
			{
				uidBoat,
				extraNameInEng,
			},
			idToken
		);
	};

	const addExtraToBoat = async (uidBoat: string, extra: AddExtraParams): Promise<void> => {
		if (user == null) {
			throw new Error("Not logged in, please relogg in");
		}
		const idToken = await user.getIdToken();

		await makePost(
			api.addExtraFromBoat,
			{
				uidBoat: uidBoat,
				name: extra.name,
				cost: extra.cost,
				type: extra.type,
			},
			idToken
		);
	};

	const editExtraFromBoat = async (uidBoat: string, extra: EditExtraParams): Promise<void> => {
		if (user == null) {
			throw new Error("Not logged in, please relogg in");
		}

		const docRef = doc(myFS, "boats", uidBoat);

		const boat = await getBoatFromUID(uidBoat);
		const extras = boat.extras as unknown as { name: TranslationType; cost: number; type: string }[];
		if (!extras) throw new Error("No extras found");

		//update extra
		extras[extra.pos] = { name: extra.name, cost: extra.cost, type: extra.type };

		//update document
		await updateDoc(docRef, {
			extras: extras,
		});
	};

	const getExtraByUID = async (uidExtra: string): Promise<Extra> => {
		const docRef = doc(myFS, "extras", uidExtra);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			return docSnap.data() as Extra;
		} else {
			throw new Error("No such document!");
		}
	};

	const addCheckElementToBoat = async (uidBoat: string, newElement: ChecklistElement): Promise<string> => {
		if (!Object.keys(checklistTypes).includes(newElement.inputType)) {
			throw new Error(`Invalid type: ${newElement.inputType}`);
		}
		if (!newElement.name || !newElement.name.it || !newElement.name.en || !newElement.name.cs) {
			throw new Error("Invalid name");
		}
		const isDocAlreadyInCollection = await checkIfElementIsInChecklist(uidBoat, newElement);
		if (isDocAlreadyInCollection) {
			throw new Error("A checklist element with the same name already exists");
		}
		const collectionRef = collection(myFS, "boats", uidBoat, "checklist");
		const docRef = await addDoc(collectionRef, newElement);
		return docRef.id;
	};

	const removeCheckElementFromBoat = async (uidBoat: string, uid: string): Promise<void> => {
		await deleteDoc(doc(myFS, "boats", uidBoat, "checklist", uid));
	};

	const checkIfElementIsInChecklist = async (uidBoat: string, newElement: ChecklistElement): Promise<boolean> => {
		const q = query(collection(myFS, "boats", uidBoat, "checklist"), where("name.en", "==", newElement.name.en));
		const querySnapshot = await getDocs(q);
		return querySnapshot.size > 0;
	};

	const uploadPdf = async (uidBoat: string, pdf: File, names: TranslationType): Promise<void> => {
		try {
			const nameAvailable = await isNameAvailable(uidBoat, names.it);
			if (!nameAvailable) {
				throw new Error(`Name is already in use: ${names.it}`);
			}

			const docRef = doc(myFS, "boats", uidBoat);

			if (!names || !names.it || !names.en || !names.cs) {
				throw new Error("Invalid names");
			}
			await updateDoc(docRef, {
				pdfs: arrayUnion(names),
			});
			const storageRef = ref(myStorage, `boats/${uidBoat}/pdfs/${names.it}`);
			await uploadBytes(storageRef, pdf);
			openSnackbar("Upload PDF", "Caricamento PDF avvenuto con successo", "success");
		} catch (error: any) {
			console.log(error);
			openSnackbar("Upload PDF", "Errore durante il caricamento del PDF", "error");
		}
	};

	const isNameAvailable = async (uidBoat: string, name: string): Promise<boolean> => {
		let isNameAvailable = false;

		try {
			const imageRef = ref(myStorage, `boats/${uidBoat}/pdfs/${name}`);
			await getMetadata(imageRef);
		} catch (error) {
			isNameAvailable = true;
		}

		return isNameAvailable;
	};

	const removePdfFromBoat = async (uidBoat: string, name: TranslationType): Promise<void> => {
		await removePdfFromBoatDoc(uidBoat, name);
		await removeDocumentFromStorage(uidBoat, name.it);
	};

	const removePdfFromBoatDoc = async (uidBoat: string, name: TranslationType): Promise<void> => {
		await updateDoc(doc(myFS, "boats", uidBoat), {
			pdfs: arrayRemove(name),
		});
	};

	const removeDocumentFromStorage = async (uidBoat: string, name: string): Promise<void> => {
		const imageRef = ref(myStorage, `boats/${uidBoat}/pdfs/${name}`);
		await deleteObject(imageRef);
	};
	const addVideoToBoat = async (uidBoat: string, videoParams: AddVideoParams) => {
		const docRef = doc(myFS, "boats", uidBoat);

		if (!videoParams.name || !videoParams.name.it || !videoParams.name.en || !videoParams.name.cs) {
			throw new Error("Invalid names");
		}
		await updateDoc(docRef, {
			videos: arrayUnion({ name: videoParams.name, url: videoParams.url }),
		});
	};
	const removeVideoFromBoat = async (uidBoat: string, videoParams: Video) => {
		const docRef = doc(myFS, "boats", uidBoat);
		await updateDoc(docRef, {
			videos: arrayRemove({ name: videoParams.name, url: videoParams.url }),
		});
	};
	const deleteAccount = async (uidBoat: string) => {
		try {
			if (user == null) {
				throw new Error("Not logged in, please relogg in");
			}
			const idToken = await user.getIdToken();

			await makePost(
				api.deleteBoat,
				{
					boatUid: uidBoat,
				},
				idToken
			);
		} catch (err) {
			console.error(err);
		}
	};

	const getAllBoats = async () => {
		const boats: Boat[] = [] as Boat[];
		const docRef = collection(myFS, "boats");
		const querySnapshot = await getDocs(docRef);

		querySnapshot.forEach((doc) => {
			if (doc.data() != null) {
				boats.push({ ...(doc.data() as BoatDocument), uid: doc.id } as Boat);
			}
		});
		return boats;
	};
	const getBoatFromUsername = async (username: string) => {
		const collSnap = query(collection(myFS, "boats"), where("username", "==", username));
		const querySnap = await getDocs(collSnap);

		if (querySnap.empty) throw new Error(`No boat found with this username ${username}`);

		const boat = querySnap.docs[0].data() as BoatDocument;
		return { ...boat, uid: querySnap.docs[0].id } as Boat;
	};
	const getBoatFromUID = async (boatUID: string) => {
		const docRef = doc(myFS, "boats", boatUID);
		const docSnap = await getDoc(docRef);

		if (!docSnap.exists()) {
			throw new Error("No such document!");
		}
		return docSnap.data() as BoatDocument;
	};
	const modifyBoatPassword = async (boatUid: string, newPassword: string) => {
		try {
			if (user == null) {
				throw new Error("Not logged in, please relogg in");
			}
			const idToken = await user.getIdToken();
			const response = await makePost(api.changePasswordBoat, { boatUid, newPassword }, idToken);

			if (response.status === 200) openSnackbar("Successo", "Password cambiata con successo", "success");
			else throw new Error(response.data.message);

			await signOut(myAuth);
		} catch (err) {
			console.error(err);
			const e = err as Error;
			let message = "Errore durante il cambio della password";

			if (e.message.includes("6 characters")) message = "La password deve essere lunga almeno 6 caratteri";
			else if (e.message.includes("boatUid")) message = "Errore nel riconoscimento della barca";

			openSnackbar("Errore", message, "error");
		}
	};
	const fetchPdfsOfBoat = async (boatUid: string): Promise<Pdf[]> => {
		let pdfs: Pdf[] = [] as Pdf[];

		try {
			if (user == null) {
				throw new Error("Not logged in, please relogg in");
			}
			const idToken = await user.getIdToken();
			const result = await makeGet<GetPdfsResponse>(`${api.getBoatPdfs}?boatUID=${boatUid}`, idToken);
			pdfs = result.data.pdfs;
		} catch (err) {
			console.error(err);
		}

		return pdfs;
	};

	function downloadBlob(blob: Blob, name = "file.pdf") {
		const data = window.URL.createObjectURL(blob);

		const link = document.createElement("a");
		link.href = data;
		link.download = name;

		link.dispatchEvent(
			new MouseEvent("click", {
				bubbles: true,
				cancelable: true,
				view: window,
			})
		);

		setTimeout(() => {
			window.URL.revokeObjectURL(data);
			link.remove();
		}, 100);
	}
	const downlaodPdfOfBoat = async (boatUid: string, nameOfFile: string) => {
		try {
			const pathReference = ref(myStorage, `boats/${boatUid}/pdfs/${nameOfFile}`);
			const url = await getDownloadURL(pathReference);

			const xhr = new XMLHttpRequest();
			xhr.responseType = "blob";
			xhr.onload = (event) => {
				const blob = xhr.response;
				downloadBlob(blob, `${nameOfFile}.pdf`);
			};
			xhr.open("GET", url);
			xhr.send();
		} catch (err) {
			console.error(err);
		}
	};

	const handleCancelCheckin = async (orderUID: string) => {
		try {
			const orderRef = doc(myFS, "orders", orderUID);
			await updateDoc(orderRef, {
				"checkBoat.status": checkboatStatuses.PENDING,
				"checkBoat.unlockUserAutoCheckin": false,
			});
			openSnackbar("Successo", "Checkin annullato con successo", "success");
		} catch (err) {
			console.log(err);
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		}
	};

	const unlockUserSelfCheckin = async (
		orderUID: string,
		lastTwoMnemonicWords: string,
		notes: string,
		images: File[],
		checkList: ChecklistElement[]
	) => {
		try {
			if (lastTwoMnemonicWords.split(" ").length !== 2) throw new Error("Sono due le parole da inserire");
			const tokenId = await user?.getIdToken();
			const response = await makePost(
				api.giveUserAccessToCheckboat,
				{
					orderUID,
					mnemonic: lastTwoMnemonicWords,
					checklist: checkList,
					notes: notes,
					images: images.map((image) => image.name),
				},
				tokenId
			);
			if (response.status !== 200) throw new Error(response.data.message);
			openSnackbar("Operazione di sblocco", "Sblocco effettuato con successo", "success");
		} catch (err: any) {
			const error = err as Error;
			console.error(error);
			const errors = responseMessages.submitCheckBoatForBoat.errors;
			switch (error.message) {
				case errors.checkListMissing:
					openSnackbar("Errore", t("errorsChecklistMissing")!, "error");
					break;
				case errors.imagesMissing:
					openSnackbar("Errore", t("errorsImagesMissing")!, "error");
					break;
				case errors.mnemonicMissing:
					openSnackbar("Errore", t("errorsMnemonicMissing")!, "error");
					break;
				case errors.invalidMnemonic:
					openSnackbar("Errore", t("errrorIncorrectMnemonic")!, "error");
					break;

				default:
					openSnackbar("Errore", t("errorsSomethingWentWrong")!, "error");
			}
		}
	};

	const rateBoat = async (orderUID: string, boatUid: string, feedback: Feedback) => {
		if (user == null) {
			throw new Error("Not logged in, please relogg in");
		}
		try {
			const idToken = await user.getIdToken();
			const response = await makePost<addFeedbackResponse>(`/boats/addFeedback`, { feedback, boatUID: boatUid, orderUID: orderUID }, idToken);
			if (response.status !== 200) throw new Error(response.data.message);
		} catch (err) {
			handleRateBoatError((err as any).message);
		}
	};

	const handleRateBoatError = (message: string) => {
		const errors = responseMessages.addFeedback.errors;
		switch (message) {
			case errors.invalidRatings:
				throw new Error(t("errorInvalidRatings")!);
			case errors.missingRatings:
				throw new Error(t("errorsMissingRatings")!);
			default:
				throw new Error(t("errorsSomethingWentWrong")!);
		}
	};
	const handleUpdateChecklistElement = async (uidBoat: string, checklistElement: ChecklistElement) => {
		if (checklistElement.uid == null) return;
		const docRef = doc(myFS, "boats", uidBoat, "checklist", checklistElement.uid);
		const newChecklistElement: ChecklistElement = { ...checklistElement };

		// remove uid from object
		delete newChecklistElement.uid;

		// remove price if not inventory
		if (newChecklistElement.price === undefined) delete newChecklistElement.price;

		const boat = await getBoatFromUID(uidBoat);

		if ((newChecklistElement.price as number) > boat.deposit) newChecklistElement.price = "deposit";

		await updateDoc(docRef, newChecklistElement);
	};
	const handleUpdateWarningElement = async (uidBoat: string, pos: number, warningElement: WarningChecklistBoat) => {
		if (!warningElement.value || !warningElement.value.it || !warningElement.value.en || !warningElement.value.cs) {
			throw new Error("Invalid value");
		}
		const docRef = doc(myFS, "boats", uidBoat);

		const boat = await getBoatFromUID(uidBoat);
		const warnings = boat.warnings;
		if (!warnings) throw new Error("No warnings found");

		//update warning
		warnings[pos] = warningElement;

		//update document
		await updateDoc(docRef, {
			warnings: warnings,
		});
	};
	const handleRepositioningWarningsBoat = async (uidBoat: string, warnings: WarningChecklistBoat[]) => {
		if (!warnings || warnings.length === 0) throw new Error("No warnings found");
		const docRef = doc(myFS, "boats", uidBoat);
		await updateDoc(docRef, {
			warnings: warnings,
		});
	};
	const addWarningToBoatChecklist = async (uidBoat: string, warningElement: WarningChecklistBoat): Promise<void> => {
		if (!warningElement.value || !warningElement.value.it || !warningElement.value.en || !warningElement.value.cs) {
			throw new Error("Invalid value");
		}

		await updateDoc(doc(myFS, "boats", uidBoat), {
			warnings: arrayUnion(warningElement),
		});
	};

	const removeWarningChecklistFromBoat = async (uidBoat: string, warningElement: WarningChecklistBoat): Promise<void> => {
		await updateDoc(doc(myFS, "boats", uidBoat), {
			warnings: arrayRemove(warningElement),
		});
	};

	const boatContextData: BoatProviderType = {
		createBoat,
		removeExtraFromBoat,
		addExtraToBoat,
		editExtraFromBoat,
		getExtraByUID,
		addCheckElementToBoat,
		removeCheckElementFromBoat,
		uploadPdf,
		removePdfFromBoat,
		addVideoToBoat,
		removeVideoFromBoat,
		deleteAccount,
		getAllBoats,
		getBoatFromUsername,
		modifyBoatPassword,
		fetchPdfsOfBoat,
		downlaodPdfOfBoat,
		getBoatFromUID,
		rateBoat,
		handleUpdateChecklistElement,
		unlockUserSelfCheckin,
		addWarningToBoatChecklist,
		removeWarningChecklistFromBoat,
		handleUpdateWarningElement,
		handleRepositioningWarningsBoat,
		handleUpdateBoatInfo,
		handleCancelCheckin,
	};

	return <BoatContext.Provider value={boatContextData}>{children}</BoatContext.Provider>;
};
export const useBoatProvider = () => useContext(BoatContext);
