import { createContext, useContext, useEffect, useState } from "react";
import { collection, doc, query, where, updateDoc, onSnapshot, orderBy, addDoc, getDoc } from "firebase/firestore";

import { OrderContextType } from "../../types/providers/OrderProvider";
import { useFirebase } from "./FirebaseProvider";
import { useNetwork } from "./NetworkProvider";
import { Order, OrderCreationParams } from "../../types/documents/Order";
import { CreateOrderResponse } from "../../types/responses/CreateOrderResponse";
import { DeleteOrderResponse } from "../../types/responses/DeleteOrderResponse";
import { ChangeBoatResponse } from "../../types/responses/ChangeBoatResponse";
import { useAuth } from "./AuthProvider";
import { api } from "../../utils/network/api";
import { AddCrewMemberParams } from "../../types/forms/CrewMemeber";
import { AddCrewMemberResponse } from "../../types/responses/AddCrewMemberResponse";
import { RemoveCrewMemberResponse } from "../../types/responses/RemoveCrewMemberResponse";
import { RemoveCrewMemberBody } from "../../types/requestBodies.ts/RemoveCrewMemberBody";
import { useBoatProvider } from "./BoatProvider";
import { deleteObject, getBlob, getDownloadURL, getMetadata, listAll, ref, uploadBytes } from "firebase/storage";
import { ExtraElementInOrder, ExtraElementInOrderKeys } from "../../types/ExtraElementInOrder";
import { DocumentToUpload } from "../../types/forms/Documents";
import { SubmitCheckoutBodyForBoat, SubmitCheckoutBodyForUser } from "../../types/requestBodies.ts/SubmitCheckoutBody";
import { responseMessages } from "../../utils/responseMessages";
import { CheckboatParamsForBoatUser, SubmitCheckBoatForUser } from "../../types/requestBodies.ts/CheckboatBody";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "./SnackbackProvider";
import { OrderToDelete } from "../../types/orders/OrderToDelete";
import { TranslationType } from "../../types/Generic";
import { sortExtras } from "../../utils/sorting";
import { generateMessageOrderAccepted, generateMessageOrderDenied } from "../../utils/createMailMessages";
import { UserDocument } from "../../types/documents/User";
import { BoatDocument } from "../../types/documents/Boat";

export const orderStatuses = { PENDING: "pending", ACCEPTED: "accepted", SENT: "sent" };

const OrderContext = createContext<OrderContextType>({} as OrderContextType);
export const OrderProvider = ({ children }: { children: JSX.Element }) => {
	/**
	 * This provider handles the operation to the order
	 * 		- Creates a new order
	 * 		- handle sumbits for the checkin of the user
	 * 		- handle checkboat and checkout for both user and boat
	 * 		- it gets all the order and determines if they are future or past.
	 * 		- handle the upload of the documents related to the orders
	 */

	const { openSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const { myFS, myStorage } = useFirebase();
	const { makePost } = useNetwork();
	const { boatProfile, userProfile, user } = useAuth();
	const { getBoatFromUsername } = useBoatProvider();
	const [boatOrders, setBoatOrders] = useState<Order[]>([] as Order[]);
	const [futureOrders, setFutureOrders] = useState<Order[]>([] as Order[]);
	const [pastOrders, setPastOrders] = useState<Order[]>([] as Order[]);
	const [userOrders, setUserOrders] = useState<Order[]>([] as Order[]);

	useEffect(() => {
		let newPastOrders = [];
		let newFutureOrders = [];
		if (boatOrders.length != 0) {
			for (let i = 0; i < boatOrders.length; i++) {
				if (boatOrders[i].startDate > Date.now() / 1000 || boatOrders[i].endDate > Date.now() / 1000) {
					newFutureOrders.push(boatOrders[i]);
				} else {
					newPastOrders.push(boatOrders[i]);
				}
			}
		} else if (userOrders.length != 0) {
			for (let i = 0; i < userOrders.length; i++) {
				if (userOrders[i].startDate > Date.now() / 1000 || userOrders[i].endDate > Date.now() / 1000) {
					newFutureOrders.push(userOrders[i]);
				} else {
					newPastOrders.push(userOrders[i]);
				}
			}
		}
		setPastOrders(newPastOrders);
		setFutureOrders(newFutureOrders);
	}, [boatOrders, userOrders]);
	useEffect(() => {
		if (boatProfile && boatProfile.uid != null) handleListenerForOrders(false, boatProfile?.uid);
	}, [boatProfile]);

	useEffect(() => {
		if (userProfile && userProfile.uid != null) handleListenerForOrders(true, userProfile?.uid);
	}, [userProfile]);
	const handleListenerForOrders = async (isUser: boolean, uid: string) => {
		if (user != null) {
			let unsubscribe: any = null;
			const listenToOrderCollection = async (isUser: boolean, uid: string) => {
				try {
					const collRef = query(collection(myFS, "orders"), where(isUser ? "userUID" : "boatUID", "==", uid), orderBy("startDate"));
					unsubscribe = onSnapshot(collRef, async (querySnap: any): Promise<void> => {
						if (!querySnap.empty) {
							const orders: Order[] = [] as Order[];

							if (isUser) {
								for (let i = 0; i < querySnap.docs.length; i++) {
									const order = querySnap.docs[i];
									orders.push({ ...(order.data() as Order), orderUID: order.id } as Order);
								}
								setUserOrders(orders);
							} else {
								for (let i = 0; i < querySnap.docs.length; i++) {
									const order = querySnap.docs[i];
									orders.push({ ...(order.data() as Order), orderUID: order.id } as Order);
								}
								setBoatOrders(orders);
							}
						} else {
							setBoatOrders([] as Order[]);
						}
					});
				} catch (err) {
					const error = err as Error;
					console.error(`useEffect() failed with: ${error.message}`);
				}
			};
			listenToOrderCollection(isUser, uid);

			return () => {
				unsubscribe && unsubscribe();
			};
		}
	};

	const createOrder = async (order: OrderCreationParams): Promise<CreateOrderResponse> => {
		if (!user) {
			throw new Error("Not logged in, please relogg in");
		}
		creationOrderInputValidation(order);
		const idToken = await user.getIdToken();

		const creationResponse = await makePost<CreateOrderResponse>(api.createOrder, { ...order }, idToken);
		if (creationResponse.status !== 201) {
			throw new Error((creationResponse.data as any).error);
		} else {
			openSnackbar(t("success"), "Ordine creato con successo", "success");
		}
		return creationResponse.data;
	};
	const creationOrderInputValidation = (order: OrderCreationParams) => {
		if (!order) throw new Error("order is null");
		if (!order.boatUID || !order.userEmail || !order.endDate || !order.availableSeats || !order.startDate || !order.name || !order.surname)
			throw new Error("invalid boat object");
		if (!order.availableSeats == null || order.availableSeats <= 0) throw new Error("available seats value is not valid");
		if (order.startDate > order.endDate) throw new Error("start date must be before end date");
		if (order.startDate < 0 || order.endDate < 0) throw new Error("start date and end date must be positive numbers");
	};
	const deleteOrder = async (orderUID: string): Promise<DeleteOrderResponse> => {
		if (!user) {
			throw new Error("Not logged in, please relogg in");
		}
		const idToken = await user.getIdToken();
		const deleteRes = await makePost<DeleteOrderResponse>(
			api.deleteOrder,
			{
				orderUID: orderUID,
			},
			idToken
		);
		if (deleteRes && deleteRes.status !== 200) {
			openSnackbar("Errore", "Errore durante l'eliminazione dell'ordine", "error");
		} else {
			openSnackbar("Info", "Ordine eliminato con successo", "success");
		}
		return deleteRes.data;
	};
	const changeBoat = async (orderUID: string, username: string): Promise<ChangeBoatResponse> => {
		let response = {} as any;
		try {
			const boat = await getBoatFromUsername(username);
			openSnackbar("Info", "Cambiando barca...", "info");
			if (!boat) throw new Error("barca non trovata");
			if (boat.uid === boatProfile?.uid) throw new Error("questa barca è già associata all'ordine");
			if (!user) {
				throw new Error("Utente non loggato, rieffettua il login");
			}
			const idToken = await user.getIdToken();
			response = await makePost<ChangeBoatResponse>(
				api.changeOrderBoat,
				{
					orderUID: orderUID,
					boatUID: boat.uid,
				},
				idToken
			);
			if (response.status != 200 && response.data != null)
				throw new Error((response.data as any).error != null ? (response.data as any).error : "Errore durante il cambio barca");
			openSnackbar("Successo", "Barca cambiata con successo", "success");
		} catch (error) {
			console.error((error as any).message);
			openSnackbar("Errore", (error as any).message, "error");
		}
		return response;
	};

	const acceptOrder = async (order: Order, language: keyof TranslationType) => {
		try {
			await updateStatus(order.orderUID, orderStatuses.ACCEPTED);
			openSnackbar("Successo", "Ordine accettato", "success");
			await sendEmailAfterAccept(order, language);
			openSnackbar("Successo", "E-mail di conferma inviata", "success");
		} catch (error) {
			openSnackbar("Errore", (error as any).message, "error");
		}
	};
	const denyOrder = async (order: OrderToDelete, language: keyof TranslationType) => {
		let extraOrder: ExtraElementInOrder[] = order.extras;
		extraOrder.map((extra) => {
			Object.keys(extra).forEach((key: string) => {
				if (extra[key as ExtraElementInOrderKeys] === undefined) {
					delete extra[key as ExtraElementInOrderKeys];
				}
			});
		});
		try {
			await updateStatus(order.orderUID, orderStatuses.PENDING);
			await deleteAllFilesInFolder(order.orderUID, order.sectionsToBeReset.nauticalLicense, order.sectionsToBeReset.identityCard);
			order.documents.map((document) => {
				if (order.sectionsToBeReset.nauticalLicense) {
					document.nauticLicenseImg.front = "";
					document.nauticLicenseImg.back = "";
				}
				if (order.sectionsToBeReset.identityCard) {
					document.identityCardImg.front = "";
					document.identityCardImg.back = "";
				}
			});

			if (order.sectionsToBeReset.extras) extraOrder = await resetExtraInOrder(order.orderUID, order.extras);
			await resetOrderFields(order.orderUID, extraOrder, order.articlesToBeEnabled, order.documents, order.sectionsToBeReset);
			await sendEmailAfterDeny(order, language);

			openSnackbar("Successo", "Ordine rifiutato", "success");
		} catch (error) {
			openSnackbar("Errore", (error as any).message, "error");
		}
	};
	const resetExtraInOrder = async (orderUID: string, currentExtras: ExtraElementInOrder[]): Promise<ExtraElementInOrder[]> => {
		currentExtras.map((extra, index) => {
			if (extra.type === "transfer") {
				delete extra.transfer;
			}
			if (extra.type === "piece") {
				delete extra.quantity;
			}
			extra.checked = false;
		});
		return currentExtras;
	};
	const resetOrderFields = async (
		orderUID: string,
		extras: ExtraElementInOrder[],
		articles: Order["articles"],
		documents: Order["documents"],
		sectionsToBeReset: OrderToDelete["sectionsToBeReset"]
	): Promise<void> => {
		const newOrder: any = {
			articles: {
				34: false,
				39: false,
				enabled34: articles[34],
				enabled39: articles[39],
			},
			status: orderStatuses.PENDING,

			extras: extras,
			documents: documents,
			nauticLicenseRejected: sectionsToBeReset.nauticalLicense,
			identityCardRejected: sectionsToBeReset.identityCard,
			extraRejected: sectionsToBeReset.extras,
			lastStatusUpdate: Math.floor(Date.now() / 1000),
		};

		await updateDoc(doc(myFS, "orders", orderUID), newOrder);
	};

	const deleteAllFilesInFolder = async (orderUID: string, deleteNauticLicenseImg: boolean, deleteIdentityCardImg: boolean) => {
		if (deleteNauticLicenseImg) {
			const nauticLicenseImgRef = ref(myStorage, `orders/${orderUID}/documents/nauticLicenseImg`);
			const nauticLicenseImgFiles = await listAll(nauticLicenseImgRef);
			nauticLicenseImgFiles.items.forEach(async (fileRef) => {
				await deleteObject(fileRef);
			});
		}
		if (deleteIdentityCardImg) {
			const identityCardImgRef = ref(myStorage, `orders/${orderUID}/documents/identityCardImg`);

			//get all the files in the folder
			const identityCardImgFiles = await listAll(identityCardImgRef);

			//delete all the files in each folder

			identityCardImgFiles.items.forEach(async (fileRef) => {
				await deleteObject(fileRef);
			});
		}
	};
	const sendEmailAfterDeny = async (order: OrderToDelete, language: keyof TranslationType) => {
		const userSnap = await getDoc(doc(myFS, "users", order.userUID));
		if (!userSnap.exists()) throw new Error("User not found");
		const user = userSnap.data() as UserDocument;

		const { message, subject } = generateMessageOrderDenied({ user, order, language });

		await addDoc(collection(myFS, "mail"), {
			to: [user.email],
			message: {
				html: message,
				subject: subject,
			},
		});
	};
	const sendEmailAfterAccept = async (order: Order, language: keyof TranslationType) => {
		const userSnap = await getDoc(doc(myFS, "users", order.userUID));
		const boatSnap = await getDoc(doc(myFS, "boats", order.boatUID));
		if (!userSnap.exists()) throw new Error("User not found");

		const user = userSnap.data() as UserDocument;
		const boat = boatSnap.data() as BoatDocument;

		const { message, subject } = generateMessageOrderAccepted({ user, boat, order, language });

		await addDoc(collection(myFS, "mail"), {
			to: [user.email],
			message: {
				html: message,
				subject: subject,
			},
		});
	};
	const sendEmailAfterOrderCreation = async (email: string, message: string, subject: string) => {
		await addDoc(collection(myFS, "mail"), {
			to: [email],
			message: {
				html: message,
				subject: subject,
			},
		});
	};
	const updateStatus = async (orderUID: string, newStatus: string) => {
		const docRef = doc(myFS, `orders/${orderUID}`);
		await updateDoc(docRef, {
			status: newStatus,
		});
	};
	const updateDocuments = async (orderUID: string, documents: Order["documents"]) => {
		const docRef = doc(myFS, `orders/${orderUID}`);
		await updateDoc(docRef, {
			documents: documents,
		});
	};

	const addNewMemberToCrewList = async (crewMemberParams: AddCrewMemberParams, orderUID: string): Promise<AddCrewMemberResponse> => {
		if (!user) {
			throw new Error("Not logged in, please relogg in");
		}
		const idToken = await user.getIdToken();
		const res = await makePost<AddCrewMemberResponse>(api.addNewMemberToCrewlist, { ...crewMemberParams, orderUID: orderUID }, idToken);
		return res.data;
	};
	const removeCrewMember = async (removeCrewMemberBody: RemoveCrewMemberBody): Promise<RemoveCrewMemberResponse> => {
		if (!user) {
			throw new Error("Not logged in, please relogg in");
		}
		const idToken = await user.getIdToken();
		const res = await makePost<RemoveCrewMemberResponse>(api.removeMemberFromCrewlist, removeCrewMemberBody, idToken);
		return res.data;
	};

	const uploadDocuments = async (
		orderUID: string,
		document: File,
		name: string,
		type: "identityCardImg" | "nauticLicenseImg",
		front: boolean
	): Promise<void> => {
		const nameAvailable = await isNameAvailable(orderUID, type, name);

		if (!name) {
			throw new Error("Invalid name");
		}
		if (nameAvailable) {
			const storageRef = ref(myStorage, `orders/${orderUID}/documents/${type}/${name}`);
			await uploadBytes(storageRef, document);
		}
	};

	const removeDocument = async (orderUID: string, type: "identityCardImg" | "nauticLicenseImg", name: string) => {
		const storageRef = ref(myStorage, `orders/${orderUID}/documents/${type}/${name}`);
		await deleteObject(storageRef);
	};

	const isNameAvailable = async (orderUID: string, type: "nauticLicenseImg" | "identityCardImg", name: string): Promise<boolean> => {
		let isNameAvailable = false;

		try {
			const imageRef = ref(myStorage, `orders/${orderUID}/documents/${type}/${name}`);
			await getMetadata(imageRef);
		} catch (error) {
			isNameAvailable = true;
		}

		return isNameAvailable;
	};

	const sendCheckIn = async (
		orderUID: string,
		documents: Order["documents"],
		documentsSelected: DocumentToUpload[],
		extras: ExtraElementInOrder[],
		crewList: Order["crewList"],
		articles: Order["articles"],
		payDepositNow: boolean
	) => {
		try {
			const transfer = extras.filter((e) => e.type === "transfer")[0].transfer;
			if (transfer) {
				if (transfer.departure.date < new Date().getTime()) {
					throw new Error(t("departureDateNotValid")!);
				}
				if (transfer.arrival.date < new Date().getTime()) {
					throw new Error(t("arrivalDateNotValid")!);
				}
			}
			for (let i = 0; i < documentsSelected.length; i++) {
				const document = documentsSelected[i];
				await uploadDocuments(orderUID, document.file, document.name, document.type, document.isFront);
			}
		} catch (err) {
			console.error(err);
			throw new Error(t((err as any).message)!);
		}
		try {
			if (!user) {
				throw new Error("Not logged in, please relog in");
			}
			const idToken = await user.getIdToken();
			const res = await makePost(
				api.sendChecking,
				{
					orderUID: orderUID,
					extras: extras,
					articlesAccepted: articles,
					crewList: crewList,
					payNow: payDepositNow,
					documents: documents,
				},
				idToken
			);
			if (res.status !== 200) throw new Error(res.data.message);
			return res.data;
		} catch (err) {
			console.error(err);
			throw new Error(t("errorWhileSendingCheckin")!);
		}
	};
	function downloadBlob(blob: Blob, name = "file.pdf") {
		const data = window.URL.createObjectURL(blob);

		const link = document.createElement("a");
		link.href = data;
		link.download = name;

		link.dispatchEvent(
			new MouseEvent("click", {
				bubbles: true,
				cancelable: true,
				view: window,
			})
		);

		setTimeout(() => {
			window.URL.revokeObjectURL(data);
			link.remove();
		}, 100);
	}
	const downlaodDocumentOfOrder = async (orderUID: string, type: "identityCardImg" | "nauticLicenseImg", nameOfDocument: string) => {
		try {
			const pathReference = ref(myStorage, `orders/${orderUID}/documents/${type}/${nameOfDocument}`);
			const url = await getDownloadURL(pathReference);

			const xhr = new XMLHttpRequest();
			xhr.responseType = "blob";
			xhr.onload = (event) => {
				const blob = xhr.response;
				downloadBlob(blob, `${nameOfDocument}`);
			};
			xhr.open("GET", url);
			xhr.send();
		} catch (err) {
			console.error(err);
		}
	};

	const submitBoatCheckForBoat = async (checkBoatBody: CheckboatParamsForBoatUser) => {
		try {
			if (!user) {
				throw new Error(t("errorsUserNotLoggedIn")!);
			}
			submitBoatcheckforBoatValidateInput(checkBoatBody);
			const imagesName = await uploadImages(checkBoatBody.images, `orders/${checkBoatBody.orderUID}/checkBoatImages/`);
			const idToken = await user.getIdToken();
			const mnemonicString = checkBoatBody.mnemonic.join(" ");
			const res = await makePost(
				api.sendCheckBoatForBoat,
				{
					mnemonic: mnemonicString,
					orderUID: checkBoatBody.orderUID,
					checkList: checkBoatBody.checkList,
					note: checkBoatBody.notes,
					images: imagesName,
				},
				idToken
			);
			if (res.status !== 200) {
				throw new Error(res.data.message);
			} else {
				return res.data;
			}
		} catch (err) {
			console.error((err as any).message);
			handleBoatCheckErrorsForBoat((err as any).message);
		}
	};

	const submitBoatcheckforBoatValidateInput = (checkBoatBody: CheckboatParamsForBoatUser) => {
		if (!checkBoatBody.checkList) throw new Error(t("errorsChecklistMissing")!);
		if (!checkBoatBody.images) throw new Error(t("errorsImagesMissing")!);
		if (!checkBoatBody.mnemonic) throw new Error(t("errorsMnemonicMissing")!);
		if (!checkBoatBody.orderUID) throw new Error(t("errorsOrderUIDMissing")!);
	};

	const handleBoatCheckErrorsForBoat = (errorMessage: string) => {
		const errors = responseMessages.submitCheckBoatForBoat.errors;
		switch (errorMessage) {
			case errors.checkListMissing:
				throw new Error(t("errorsChecklistMissing")!);
			case errors.imagesMissing:
				throw new Error(t("errorsImagesMissing")!);
			case errors.mnemonicMissing:
				throw new Error(t("errorsMnemonicMissing")!);
			case errors.invalidMnemonic:
				throw new Error(t("errrorIncorrectMnemonic")!);
			default:
				throw new Error(t("errorsSomethingWentWrong")!);
		}
	};

	const uploadImages = async (images: File[], folderPath: string): Promise<string[]> => {
		const imagesName: string[] = [];
		//Delet all previous Images, it is done beacuse of the need to re-send the checkin.
		await deleteImagesOfOrder(folderPath);
		for (let i = 0; i < images.length; i++) {
			const name = `image_${i + 1}.${images[i].name.split(".")[1]}`;

			const storageRef = ref(myStorage, `${folderPath}/${name}`);
			await uploadBytes(storageRef, images[i]);
			imagesName.push(name);
		}
		return imagesName;
	};
	const submitBoatCheckForUser = async (submitCheckboatForUser: SubmitCheckBoatForUser) => {
		try {
			if (!user) {
				throw new Error(t("errorsUserNotLogged")!);
			}
			validateSubmitBoatCheckForUser(submitCheckboatForUser);
			const idToken = await user.getIdToken();
			const res = await makePost(api.sendCheckBoatForUser, submitCheckboatForUser, idToken);
			if (res.status !== 200) {
				throw new Error(res.data.message);
			} else {
				openSnackbar(t("success"), t("snackSignSuccess")!, "success");
			}
		} catch (err) {
			const messageToShow = handleBoatCheckErrorsForUser((err as any).message);
			openSnackbar(t("error"), messageToShow, "error");
		}
	};

	const validateSubmitBoatCheckForUser = async (submitCheckboatForUser: SubmitCheckBoatForUser) => {
		if (!submitCheckboatForUser.orderUID) throw new Error(t("errorsOrderUIDMissing")!);
		if (!submitCheckboatForUser.warnings) throw new Error("chekck warnings"); //TODO: ADd to translation
		if (!submitCheckboatForUser.mnemonic) throw new Error(t("errorsMnemonicMissing")!);
	};

	const handleBoatCheckErrorsForUser = (errorMessage: string) => {
		console.error(errorMessage);
		const errors = responseMessages.submitCheckBoatForUser.errors;
		let messageToshow = "";
		switch (errorMessage) {
			case errors.mnemonicMissing:
				messageToshow = t("errorsMnemonicMissing");
				break;
			case errors.walletNotFound:
				messageToshow = t("errorsWalletNotFound");
				break;
			case errors.invalidMnemonic:
				openSnackbar(t("error"), t("errrorIncorrectMnemonic")!, "error");
				break;
			default:
				messageToshow = t("errorsSomethingWentWrong");
		}
		return messageToshow;
	};

	const submitBoatCheckoutBoat = async (submitCheckoutBody: SubmitCheckoutBodyForBoat) => {
		validateSubmitBoatCheckoutBody(submitCheckoutBody);
		try {
			if (user) {
				const imagesName = await uploadImages(submitCheckoutBody.images, `orders/${submitCheckoutBody.orderUID}/checkoutImages/`);

				const idToken = await user.getIdToken();
				const mnemonicString = submitCheckoutBody.mnemonic.join(" ");

				await makePost(
					api.submitCheckoutForBoat,
					{
						mnemonic: mnemonicString,
						orderUID: submitCheckoutBody.orderUID,
						checkList: submitCheckoutBody.checkList,
						note: submitCheckoutBody.notes,
						images: imagesName,
					},
					idToken
				);
			} else {
				console.warn("User is not logged in");
			}
		} catch (error) {
			handleSubmitBoatCheckoutError((error as any).message);
		}
	};

	const handleSubmitBoatCheckoutError = (errorMessage: string) => {
		console.error(errorMessage);
		const errors = responseMessages.submitCheckoutForBoat.errors;
		switch (errorMessage) {
			case errors.mnemonicMissing:
				throw new Error(t("errorsMnemonicMissing")!);
			case errors.checklistMissing:
				throw new Error(t("errorsChecklistMissing")!);
			case errors.imagesMissing:
				throw new Error(t("errorsImagesMissing")!);
			case errors.invalidMnemonic:
				throw new Error(t("errrorIncorrectMnemonic")!);
				break;
			default:
				throw new Error(t("errorsSomethingWentWrong")!);
		}
	};

	const validateSubmitBoatCheckoutBody = (submitCheckoutBody: SubmitCheckoutBodyForBoat) => {
		if (!submitCheckoutBody.mnemonic) throw new Error(t("errorsMnemonicMissing")!);
		if (!submitCheckoutBody.orderUID) throw new Error(t("errorsOrderUIDMissing")!);
		if (!submitCheckoutBody.checkList) throw new Error(t("errorsChecklistMissing")!);
		if (!submitCheckoutBody.images) throw new Error(t("errorsImagesMissing")!);
		if (!user) throw new Error(t("errorsUserNotLogged")!);
	};

	const submitCheckoutUser = async (submitCheckoutBody: SubmitCheckoutBodyForUser) => {
		try {
			if (!user) {
				throw new Error(t("errorsUserNotLogged")!);
			}
			validateSubmitCheckoutUserInput(submitCheckoutBody);
			const idToken = await user.getIdToken();
			const res = await makePost(api.submitCheckoutForUser, submitCheckoutBody, idToken);
			if (res && res.status != 200) {
				handleSubmitCheckoutUserError(res.data.message);
			} else {
				openSnackbar(t("success"), t("snackSignSuccess")!, "success");
			}
		} catch (error) {
			handleSubmitCheckoutUserError((error as any).message);
		}
	};

	const validateSubmitCheckoutUserInput = (submitCheckoutBody: SubmitCheckoutBodyForUser) => {
		if (!submitCheckoutBody.mnemonic) throw new Error(t("errorsMnemonicMissing")!);
		if (!submitCheckoutBody.orderUID) throw new Error(t("errorsOrderUIDMissing")!);
	};

	const handleSubmitCheckoutUserError = (errorMessage: string) => {
		const errors = responseMessages.submitCheckoutForUser.errors;
		switch (errorMessage) {
			case errors.mnemonicMissing:
				openSnackbar(t("error"), t("errorsMnemonicMissing")!, "error");
				break;
			case errors.walletNotFound:
				openSnackbar(t("error"), t("errorsWalletNotFound")!, "error");
				break;
			case errors.invalidMnemonic:
				openSnackbar(t("error"), t("errrorIncorrectMnemonic")!, "error");
				break;
			default:
				openSnackbar(t("error"), t("errorsSomethingWentWrong")!, "error");
		}
	};

	const getImageFromStorage = async (imageName: string, folderPath: string) => {
		try {
			const storageRef = ref(myStorage, `${folderPath}/${imageName}`);

			const blobFile = await getBlob(storageRef);
			const imageFile = new File([blobFile], imageName, { type: blobFile.type });
			return imageFile;
		} catch (err) {
			console.error(err);
			throw new Error(t("errorsSomethingWentWrong")!);
		}
	};
	const modifyExtras = async (orderUID: string, extras: ExtraElementInOrder[]) => {
		const docRef = doc(myFS, `orders/${orderUID}`);

		try {
			await updateDoc(docRef, {
				extras: extras,
			});
			openSnackbar("Successo", "Extra modificati correttamente", "success");
		} catch (err) {
			console.error(err);
			openSnackbar("Errore", "Modifica extra fallita", "error");
		}
	};

	const submitUserSelfBoarding = async (checkBoatBody: CheckboatParamsForBoatUser) => {
		try {
			if (!user) {
				throw new Error(t("errorsUserNotLoggedIn")!);
			}
			submitBoatcheckforBoatValidateInput(checkBoatBody);
			const imagesName = await uploadImages(checkBoatBody.images, `orders/${checkBoatBody.orderUID}/checkBoatImages/`);
			const idToken = await user.getIdToken();
			const mnemonicString = checkBoatBody.mnemonic.join(" ");
			const res = await makePost(
				api.sendUserSelfBoarding,
				{
					mnemonic: mnemonicString,
					orderUID: checkBoatBody.orderUID,
					checkList: checkBoatBody.checkList,
					notes: checkBoatBody.notes,
					images: imagesName,
					warnings: checkBoatBody.warnings,
				},
				idToken
			);
			if (res.status !== 200) {
				throw new Error(res.data.message);
			} else {
				return res.data;
			}
		} catch (err) {
			console.error((err as any).message);
			handleBoatCheckErrorsForBoat((err as any).message);
		}
	};

	const deleteImagesOfOrder = async (path: string) => {
		const nauticLicenseImgRef = ref(myStorage, path);
		const nauticLicenseImgFiles = await listAll(nauticLicenseImgRef);
		nauticLicenseImgFiles.items.forEach(async (fileRef) => {
			await deleteObject(fileRef);
		});
	};

	const getDepositFromUid = (orderUID: string, isBoat: boolean): number => {
		let deposit;
		if (isBoat) {
			deposit = boatOrders.find((order) => order.orderUID === orderUID)?.deposit;
		} else {
			deposit = userOrders.find((order) => order.orderUID === orderUID)?.deposit;
		}
		if (deposit == null) deposit = 0;
		return deposit;
	};

	const OrderContextData: OrderContextType = {
		boatOrders,
		userOrders,
		futureOrders,
		pastOrders,
		createOrder,
		deleteOrder,
		changeBoat,
		acceptOrder,
		denyOrder,
		addNewMemberToCrewList,
		uploadDocuments,
		removeDocument,
		sendCheckIn,
		downlaodDocumentOfOrder,
		submitBoatCheckForBoat,
		submitBoatCheckoutBoat,
		submitCheckoutUser,
		submitBoatCheckForUser,
		getImageFromStorage,
		modifyExtras,
		submitUserSelfBoarding,
		removeCrewMember,
		getDepositFromUid,
		sendEmailAfterOrderCreation,
		updateDocuments,
	};
	return <OrderContext.Provider value={OrderContextData}>{children}</OrderContext.Provider>;
};
export const useOrders = () => useContext(OrderContext);
