import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const LandingTitle = () => {
	const { t } = useTranslation();
	return (
		<>
			<Typography variant="h3">				
				{t("landing-title")}
			</Typography>
			<Typography variant="h5" fontWeight={600}>				
				{t("landing-message")}
			</Typography>
		</>
	);
};
