import { Button, Grid, Tooltip } from "@mui/material";
import { useAuth } from "../../providers/AuthProvider";
import { useBoatProvider } from "../../providers/BoatProvider";
import { useSnackbar } from "../../providers/SnackbackProvider";
import { useState } from "react";
import { BoatDeleteAccountPupUp } from "./BoatDeleteAccountPupUp";
import { useOrders } from "../../providers/OrderProvider";
import { BoatDeleteAccountErrorDialog } from "./BoatDeleteAccountErrorDialog";

export const BoatDeleteAccount = () => {
	const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
	const [openDialogError, setOpenDialogError] = useState(false);

	const { futureOrders } = useOrders();
	const { boatProfile } = useAuth();
	const { deleteAccount } = useBoatProvider();
	const { openSnackbar, openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();

	const handleDeleteAccount = async () => {
		setOpenDialogConfirmation(false);
		try {
			if (boatProfile) {
				openLoadingSnackbar();
				await deleteAccount(boatProfile.uid);
				closeLoadingSnackbar();
			}
		} catch (err) {
			const error = err as Error;
			closeLoadingSnackbar();
			openSnackbar("Errore", error.message, "error");
		}
	};

	const handleCloseDialogConfirmation = () => {
		setOpenDialogConfirmation(false);
	}

	const handleCloseDialogError = () => {
		setOpenDialogError(false);
	}

	return (
		<>
			<BoatDeleteAccountPupUp open={openDialogConfirmation} closeDialog={handleCloseDialogConfirmation} confirmDeletion={handleDeleteAccount} />
			<BoatDeleteAccountErrorDialog open={openDialogError} closeDialog={handleCloseDialogError} />
			<Grid container justifyContent={"space-between"} rowGap={4} gap={2}>
				<Grid item xs={12} sx={{ marginTop: "16px" }}>
					<Grid container>
						<Grid item xs>
							<Button onClick={() => {
								if (futureOrders.length > 0) {
									setOpenDialogError(true);
								} else {
									setOpenDialogConfirmation(true);
								}
							}} className="form-button">
								ELIMINA
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid >
		</>
	);
};
