import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { BoatMenu } from "./BoatMenu";
import { useOrders } from "../../components/providers/OrderProvider";
import { BoatCheckout } from "../../components/boat/orders/checkout/BoatCheckout";

export const BoatCheckouts = () => {
	const { futureOrders } = useOrders();
	const [expanded, setExpanded] = useState<string | false>(false);
	const handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};
	
	return (
		<>
			<Grid container flexWrap="nowrap">
				<Grid item height="100%" className="menu-container">
					<BoatMenu />
				</Grid>
				<Grid item xs className="menu-content">
					<Grid container rowGap="16px">
						<Grid item xs={12}>
							<Typography variant="h3">Check-Out</Typography>
						</Grid>

						{futureOrders.map((value, index) => {
							return (
								<Grid item xs={12} marginY="2px" key={`order-index-${index}`}>
									<BoatCheckout order={value} index={index} expanded={expanded} handleChangeAccordion={handleChangeAccordion} />
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
