import { useEffect } from "react";
import { Grid } from "@mui/material";
import { useTranslationProvider } from "../providers/TranslationProvider";
import IT from "country-flag-icons/react/3x2/IT";
import GB from "country-flag-icons/react/3x2/GB";
import CZ from "country-flag-icons/react/3x2/CZ";
import $ from "jquery";

export const ChangeLanguage = () => {
	const { language, changeLanguage } = useTranslationProvider();
	const handleChangeLanguage = (newLanguage: string) => {
		$(`#${language}`).removeClass("active-language");
		changeLanguage(newLanguage);
		$(`#${newLanguage}`).addClass("active-language");
	};
	useEffect(() => {
		$(`#${language}`).addClass("active-language");
	}, []);
	return (
		<>
			<Grid container className="change-language-buttons" gap={2}>
				<Grid item xs={3} id="it">
					<a
						className="flag-container"
						onClick={() => {
							handleChangeLanguage("it");
						}}
					>
						<IT title="Itlay" className="flag" />
					</a>
				</Grid>
				<Grid item xs={3} id="en">
					<a
						className="flag-container"
						onClick={() => {
							handleChangeLanguage("en");
						}}
					>
						<GB title="England" className="flag" />
					</a>
				</Grid>
				<Grid item xs={3} id="cs">
					<a
						className="flag-container"
						onClick={() => {
							handleChangeLanguage("cs");
						}}
					>
						<CZ title="Czechia" className="flag" />
					</a>
				</Grid>
			</Grid>
		</>
	);
};
