import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { Order } from "../../../../types/documents/Order";
import { ChecklistElementBoarding } from "../../../../types/forms/Checklist";
import { SignatureRows } from "./TableSignature";

type TableCheckin560Props = {
	order: Order;
	isCheckIn: boolean;
};

type BodyTableCheckin560Props = {
	checklist: ChecklistElementBoarding[] | undefined;
	checkoutList: ChecklistElementBoarding[] | undefined;
} & Pick<TableCheckin560Props["order"], "orderUID"> &
	Pick<TableCheckin560Props, "isCheckIn">;

export const TableCheckin560 = ({ order, isCheckIn }: TableCheckin560Props) => (
	<Table className="table-checkin560">
		<HeadTableCheckin560 />
		<BodyTableCheckin560 orderUID={order.orderUID} isCheckIn={isCheckIn} checklist={order.checkBoat?.checkList} checkoutList={order.checkout?.checkList} />
		<TableFooter>
			<SignatureRows colSpanCheckIn={2} colSpanCheckOut={3} />
		</TableFooter>
	</Table>
);

const HeadTableCheckin560 = () => (
	<TableHead>
		<TableRow>
			<TableCell align="center" colSpan={5}>
				<Typography variant="h5" fontWeight="bold">
					CONTROLLI E PROVE DI FUNZIONAMENTO / KONTROLY A FUNKČNÍ ZKOUŠKY / CHECK AND CONTROL OF FUNCTIONS
				</Typography>
			</TableCell>
		</TableRow>
		<TableRow>
			<TableCell align="center" colSpan={1}>
				<Typography variant="h5">PROVE DI FUNZIONAMENTO</Typography>
			</TableCell>
			<TableCell align="center" colSpan={1}>
				<Typography variant="h5">FUNKČNÍ ZKOUŠKY</Typography>
			</TableCell>
			<TableCell align="center" colSpan={1}>
				<Typography variant="h5">CHECK OF FUNCTIONS</Typography>
			</TableCell>
			<TableCell align="center" colSpan={1}>
				<Typography variant="h5">Check-in</Typography>
			</TableCell>
			<TableCell align="center" colSpan={1}>
				<Typography variant="h5">Check-out</Typography>
			</TableCell>
		</TableRow>
	</TableHead>
);

const BodyTableCheckin560 = ({ orderUID, isCheckIn, checklist, checkoutList }: BodyTableCheckin560Props) => (
	<TableBody>
		{checklist &&
			checklist
				.filter((el) => el.category === "checkin560")
				.map((checklistElement, i) => (
					<TableRow key={`${orderUID}-row-checkin-checkin560-${i}`}>
						<TableCell align="left" colSpan={1}>
							<Typography variant="h5">{checklistElement.name.it}</Typography>
						</TableCell>
						<TableCell align="left" colSpan={1}>
							<Typography variant="h5">{checklistElement.name.cs}</Typography>
						</TableCell>
						<TableCell align="left" colSpan={1}>
							<Typography variant="h5">{checklistElement.name.en}</Typography>
						</TableCell>
						<TableCell align="center" colSpan={1}>
							<Typography variant="h5">
								{checklistElement.inputType === "binary" ? (
									checklistElement.binaryChoice ? (
										<CheckIcon fontSize="large" sx={{ color: "green" }} />
									) : (
										<CloseIcon fontSize="large" sx={{ color: "red" }} />
									)
								) : (
									checklistElement.textChoice
								)}
							</Typography>
						</TableCell>
						<TableCell align="center" colSpan={1}>
							<Typography variant="h5">
								{!isCheckIn && checkoutList
									? checkoutList.map((el) => {
											if (
												el.name.en === checklistElement.name.en &&
												el.name.it === checklistElement.name.it &&
												el.name.cs === checklistElement.name.cs
											) {
												return el.inputType === "binary" ? (
													el.binaryChoice ? (
														<CheckIcon fontSize="large" sx={{ color: "green" }} />
													) : (
														<CloseIcon fontSize="large" sx={{ color: "red" }} />
													)
												) : (
													el.textChoice
												);
											}
									  })
									: ""}
							</Typography>
						</TableCell>
					</TableRow>
				))}
	</TableBody>
);
