import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";

type TableFooterProps = {
	port: string | undefined;
	startDate: number;
	endDate: number;
};

export const TableFooter = ({ port, startDate, endDate }: TableFooterProps) => (
	<Table className="table-footer">
		<BodyTableFooter port={port} startDate={startDate} endDate={endDate} />
	</Table>
);

const BodyTableFooter = ({ port, startDate, endDate }: TableFooterProps) => (
	<TableBody>
		<TableRow>
			<TableCell align="left" colSpan={1}>
				<Typography variant="h5">Luogo/Místo/Place {(port || "porto").toUpperCase()}</Typography>
			</TableCell>
			<TableCell align="left" colSpan={1}>
				<Typography variant="h5">Luogo/Místo/Place {(port || "porto").toUpperCase()}</Typography>
			</TableCell>
		</TableRow>
		<TableRow>
			<TableCell align="left" colSpan={1}>
				<Typography variant="h5">Il / Dne {new Date(startDate * 1000).toLocaleDateString()}</Typography>
			</TableCell>
			<TableCell align="left" colSpan={1}>
				<Typography variant="h5">Il / Dne {new Date(endDate * 1000).toLocaleDateString()}</Typography>
			</TableCell>
		</TableRow>
	</TableBody>
);
