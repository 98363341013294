import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { UserMenu } from "../UserMenu";
import $ from "jquery";
import landing_checkin from "../../../assets/landing_checkin_image.png";
import landing_load_documents from "../../../assets/landing_load_documents.png";
import landing_crewlist from "../../../assets/landing_crewlist.png";
import landing_extra from "../../../assets/landing_extra.png";

import "../../../styles/Landing.scss";
import { useTranslation } from "react-i18next";
import { LandingTitle } from "./LandingTitle";
import { LandingOrderInformation } from "./LandingOrderInformation";
import { Order } from "../../../types/documents/Order";
import { useOrders } from "../../../components/providers/OrderProvider";
import { LandingTutorialSection } from "./LandingTutorialSection";
import { LandingDocs } from "./LandingDocs";

const FirstImage = () => {
	const { t } = useTranslation();
	return (
		<>
			<div className="landing-step" id="first-image">
				<img src={landing_checkin} alt="Check in image"></img>
				<div className="image-content">
					<Typography variant="h3" color="white">
						{t("landing-checkIn-title")}
					</Typography>
					<Typography color="white" variant="body1">
						{t("landing-checkIn-content")}
					</Typography>
				</div>
			</div>
		</>
	);
};
const SecondImage = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className="landing-step" id="second-image">
				<img src={landing_crewlist} alt="Crewlist in image"></img>
				<div className="image-content">
					<Typography variant="h3" color="white">
						{t("landing-loadDocuments-title")}
					</Typography>
					<Typography color="white" variant="body1">
						{t("landing-loadDocuments-content")}
					</Typography>
				</div>
			</div>
		</>
	);
};
const ThirdImage = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className="landing-step" id="third-image">
				<img src={landing_load_documents} alt="Documents image"></img>
				<div className="image-content">
					<Typography variant="h3" color="white">
						{t("landing-fillCrewList-title")}
					</Typography>
					<Typography color="white" variant="body1">
						{t("landing-fillCrewList-content")}
					</Typography>
				</div>
			</div>
		</>
	);
};
const FourthImage = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className="landing-step" id="fourth-image">
				<img src={landing_extra} alt="Extra image"></img>
				<div className="image-content">
					<Typography variant="h3" color="white">
						{t("landing-selectExtras-title")}
					</Typography>
					<Typography color="white" variant="body1">
						{t("landing-selectExtras-content")}
					</Typography>
				</div>
			</div>
		</>
	);
};

export const UserLanding = () => {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
	const { futureOrders } = useOrders();

	const [order, setOrder] = useState<Order | null>(null);
	function getWindowDimensions() {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	}
	useEffect(() => {
		if (futureOrders.length == 0) {
			setOrder(null);
		}
		setOrder(futureOrders[0]);
	}, [futureOrders]);
	useEffect(() => {
		// function handleResize() {
		// 	const windowDimensions = getWindowDimensions();
		// 	setWindowDimensions(windowDimensions);
		// 	let mulitplier = 550;
		// 	if (windowDimensions.width > 1700) mulitplier = 750;
		// 	else if (windowDimensions.width > 1550) mulitplier = 650;
		// 	else if (windowDimensions.width > 1450) mulitplier = 600;
		// 	const maxWidth = Math.floor((windowDimensions.height / windowDimensions.width) * mulitplier);
		// 	$(".column-images").css("max-width", maxWidth);
		// }
		// window.addEventListener("resize", handleResize);
		// return () => window.removeEventListener("resize", handleResize);
	}, []);
	return (
		<>
			<Grid container flexWrap="nowrap">
				<Grid item height="100%" className="menu-container">
					<UserMenu />
				</Grid>

				<Grid item xs={10} marginTop="24px !important" className="landing menu-content">
					<Grid container justifyContent="center" maxWidth="1100px" rowGap="42px">
						<Grid item xs={12}>
							<LandingTitle />
						</Grid>
						{order && (
							<Grid item xs={12}>
								<LandingOrderInformation order={order} />
							</Grid>
						)}
						<Grid item xs={12}>
							<LandingDocs />
						</Grid>
						<Grid item xs={12}>
							<LandingTutorialSection />
						</Grid>

						<Grid item xs={12} className="landing-images-conatainer">
							<div className="column-images">
								<FirstImage />
								{windowDimensions.width < 800 ? <SecondImage /> : <ThirdImage />}
							</div>
							<div className="column-images">
								{windowDimensions.width < 800 ? <ThirdImage /> : <SecondImage />}

								<FourthImage />
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
