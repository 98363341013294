import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useOrders } from "../../providers/OrderProvider";
import { useTranslation } from "react-i18next";

export const DeleteAccountConfirmationDialog = ({ open, closeDialog, confirmDeletion }: {
    open: boolean,
    closeDialog: () => void,
    confirmDeletion: () => void
}) => {
    const { futureOrders } = useOrders();
    const { t } = useTranslation();

    return (
        <>
            {
                futureOrders.length === 0 ? (
                    <Dialog open={open} onClose={closeDialog} maxWidth="md">
                        <DialogTitle mb={3}>
                            <Typography variant="h4">{t('deleteUserAccountTitle')}</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant="body1">{t('deleteUserAccountMessage')}</Typography>
                            <Grid container style={{ textAlign: 'center' }}>
                                <Grid item xs={12} sx={{ marginTop: "16px" }}>
                                    <Button onClick={confirmDeletion} className="form-button">
                                        {t('confirm')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                ) : (
                    <Dialog open={open} onClose={closeDialog} maxWidth="md">
                        <DialogTitle mb={3}>
                            <Typography variant="h4">{t('cannotDeleteUserAccount')}</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant="body1">{t('cannotDeleteUserAccountMessage')}</Typography>
                        </DialogContent>
                    </Dialog>
                )
            }

        </>
    )
}