import { Navigate, Outlet } from "react-router";
import { useAuth } from "../providers/AuthProvider";

const AdminProtection = () => {
	const { userProfile, isAdmin } = useAuth();

	if (userProfile == null || userProfile.uid == "" || userProfile.uid == undefined || !isAdmin) {
		return <Navigate to="/" />;
	}

	return <Outlet />;
};

export default AdminProtection;
