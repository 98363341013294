import { Field, Form, Formik } from "formik";
import { Grid, Button, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import * as yup from "yup";
import { useAuth } from "../../providers/AuthProvider";
import { useSnackbar } from "../../providers/SnackbackProvider";
import { useBoatProvider } from "../../providers/BoatProvider";
import { LocalizationProvider, renderTimeViewClock } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { extractDate, formatTime, formatTimeFromDate } from "../../../utils/timeOperation";

const validationSchema = yup.object({
	licensePlate: yup.string().required("La targa della barca è richiesta"),
	boardingTime: yup.string().required("L'orario di imbarco è richiesto"),
	disembarkationTime: yup.string().required("L'orario di sbarco è richiesto"),
});

export const BoatModifyInfo = () => {
	const { boatProfile } = useAuth();
	const { openSnackbar, openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();
	const { handleUpdateBoatInfo } = useBoatProvider();

	const handleSubmit = async (values: { licensePlate: string; boardingTime: Date; disembarkationTime: Date }) => {
		if (boatProfile === null) return;
		try {
			openLoadingSnackbar();

			await handleUpdateBoatInfo(boatProfile.uid, boatProfile, {
				licensePlate: values.licensePlate,
				boardingTime: formatTimeFromDate(values.boardingTime),
				disembarkationTime: formatTimeFromDate(values.disembarkationTime),
			});

			closeLoadingSnackbar();
			openSnackbar("SUCCESSO", "Informazioni modificate con successo", "success");
		} catch (err) {
			closeLoadingSnackbar();
			const error = err as Error;
			console.log(error.message);
			openSnackbar("ERRORE", error.message, "error");
		}
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Formik
				initialValues={{
					licensePlate: boatProfile?.licensePlate || "",
					boardingTime: extractDate(boatProfile?.boardingTime || "00:00"),
					disembarkationTime: extractDate(boatProfile?.disembarkationTime || "00:00"),
				}}
				onSubmit={async (values) => {
					await handleSubmit(values);
				}}
				validationSchema={validationSchema}>
				{({ touched, errors, values, handleChange, setFieldValue }) => (
					<Form>
						<Grid container justifyContent={"space-around"} rowGap={4}>
							<Grid item xs={12} sm={5}>
								<Field
									component={TimePicker}
									id="boardingTime"
									name="boardingTime"
									sx={{ width: { xs: "100%" }, marginTop: "16px" }}
									label="Orario di imbarco"
									value={values.boardingTime}
									onChange={(value: Date) => {
										setFieldValue("boardingTime", value);
									}}
									ampm={false}
									format="HH:mm"
									openTo={"hours"}
									view={"hours"}
									viewRenderers={{
										hours: renderTimeViewClock,
										minutes: renderTimeViewClock,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={5}>
								<Field
									component={TimePicker}
									id="disembarkationTime"
									name="disembarkationTime"
									sx={{ width: { xs: "100%" }, marginTop: "16px" }}
									label="Orario di sbarco"
									value={values.disembarkationTime}
									onChange={(value: Date) => {
										setFieldValue("disembarkationTime", value);
									}}
									ampm={false}
									format="HH:mm"
									openTo={"hours"}
									view={"hours"}
								/>
							</Grid>

							<Grid item xs={12} sm={3}>
								<TextField
									className="input-form"
									sx={{ width: { xs: "100%" }, marginTop: "16px" }}
									id="licensePlate"
									name="licensePlate"
									label="Targa della barca"
									variant={"filled"}
									value={values.licensePlate}
									onChange={handleChange}
									error={touched.licensePlate && Boolean(errors.licensePlate)}
									helperText={touched.licensePlate && errors.licensePlate}
								/>
							</Grid>

							<Grid item xs={12} sx={{ marginTop: "16px" }} display={"flex"} justifyContent={"center"}>
								<Button type="submit" className="form-button ">
									MODIFICA INFORMAZIONI
								</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</LocalizationProvider>
	);
};
