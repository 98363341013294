import { useState } from "react";
import { Grid } from "@mui/material";
import { BoatMenu } from "./BoatMenu";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../styles/boat/Settings.scss";
import { BoatCreationForm } from "../../components/boat/settings/BoatCreationForm";
import { BoatModifyExtraForm } from "../../components/boat/settings/BoatModifyExtraForm";
import { BoatModifyChecklist } from "../../components/boat/settings/BoatModifyChecklist";
import { BoatModifyVideo } from "../../components/boat/settings/BoatModifyVideo";
import { BoatModifyPdf } from "../../components/boat/settings/BoatModifyPdf";
import { BoatDeleteAccount } from "../../components/boat/settings/BoatDeleteAccount";
import { BoatModifyInfo } from "../../components/boat/settings/BoatModifyInfo";

export const BoatSettings = () => {
	const [expanded, setExpanded] = useState<string | false>(false);

	const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			<Grid container flexWrap="nowrap">
				<Grid item height="100%" className="menu-container">
					<BoatMenu />
				</Grid>
				<Grid item xs className="menu-content">
					<Grid container justifyContent={"flex-start"} rowGap="16px" padding={1}>
						<Grid item xs={12}>
							<Typography variant="h3">Impostazioni</Typography>
						</Grid>
						<Grid item xs={12}>
							<Accordion className="accordion" expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
									<Typography sx={{ width: "100%", flexShrink: 0 }} variant="h4">
										Crea un nuovo account barca
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<BoatCreationForm />
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12}>
							<Accordion className="accordion" expanded={expanded === "panel7"} onChange={handleChange("panel7")}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7bh-content" id="panel7bh-header">
									<Typography sx={{ width: "100%", flexShrink: 0 }} variant="h4">
										Modifica informazioni barca
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<BoatModifyInfo />
								</AccordionDetails>
							</Accordion>
						</Grid>

						<Grid item xs={12}>
							<Accordion className="accordion" expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
									<Typography sx={{ width: "100%", flexShrink: 0 }} variant="h4">
										Modifica gli Extra
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<BoatModifyExtraForm />
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12}>
							<Accordion className="accordion" expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
									<Typography sx={{ width: "100%", flexShrink: 0 }} variant="h4">
										Modifica la checklist
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<BoatModifyChecklist />
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12}>
							<Accordion className="accordion" expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
									<Typography sx={{ width: "100%", flexShrink: 0 }} variant="h4">
										Carica un video per la sezione tutorial
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<BoatModifyVideo />
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12}>
							<Accordion className="accordion" expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
									<Typography sx={{ width: "100%", flexShrink: 0 }} variant="h4">
										Carica un pdf
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<BoatModifyPdf />
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12}>
							<Accordion className="accordion" expanded={expanded === "panel6"} onChange={handleChange("panel6")}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6bh-content" id="panel6bh-header">
									<Typography sx={{ width: "100%", flexShrink: 0 }} variant="h4">
										Elimina questo account
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<BoatDeleteAccount />
								</AccordionDetails>
							</Accordion>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
