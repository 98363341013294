import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

type TableNoteProps = {
	isCheckIn: boolean;
	checkInNote: string | undefined;
	checkOutNote: string | undefined;
};

export const TableNote = ({ isCheckIn, checkInNote, checkOutNote }: TableNoteProps) => (
	<Table className="table-note">
		<HeadTableNote />
		<BodyTableNote isCheckIn={isCheckIn} checkInNote={checkInNote} checkOutNote={checkOutNote} />
	</Table>
);

const HeadTableNote = () => (
	<TableHead>
		<TableRow>
			<TableCell align="center" colSpan={2}>
				<Typography variant="h5" fontWeight="bold">
					NOTE PER CONTROLLI E PROVE DI FUNZIONAMENTO - INVENTARIO
				</Typography>
			</TableCell>
		</TableRow>
		<TableRow>
			<TableCell align="center" colSpan={2}>
				<Typography variant="h5" fontWeight="bold">
					ZÁZNAMY FUNKČNÍCH ZKOUŠEK A KONTROLY INVENTÁŘE
				</Typography>
			</TableCell>
		</TableRow>
		<TableRow>
			<TableCell align="center" colSpan={2}>
				<Typography variant="h5" fontWeight="bold">
					NOTES FOR CHECK AND COTROL OF THE INVETORY LIST
				</Typography>
			</TableCell>
		</TableRow>
		<TableRow>
			<TableCell align="center" colSpan={1}>
				<Typography variant="h5" fontWeight="bold">
					Check-In
				</Typography>
			</TableCell>
			<TableCell align="center" colSpan={1}>
				<Typography variant="h5" fontWeight="bold">
					Check-Out
				</Typography>
			</TableCell>
		</TableRow>
	</TableHead>
);

const BodyTableNote = ({ isCheckIn, checkInNote, checkOutNote }: TableNoteProps) => (
	<TableBody>
		<TableRow>
			<TableCell align="left" colSpan={1} sx={{ borderRight: "1px solid #e6e6e6" }}>
				{checkInNote ? <Typography variant="h5">{checkInNote}</Typography> : ""}
			</TableCell>
			<TableCell align="left" colSpan={1}>
				{!isCheckIn && checkOutNote ? <Typography variant="h5">{checkOutNote}</Typography> : ""}
			</TableCell>
		</TableRow>
	</TableBody>
);
