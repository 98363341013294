import { Grid, Typography } from "@mui/material";
import YouTube from "react-youtube";
import "../../../styles/user/Tutorial.scss";
import { useTranslationProvider } from "../../providers/TranslationProvider";
import { TranslationType } from "../../../types/Generic";
export const TutorialVideo = ({ youtubeId, name }: { youtubeId: string; name: TranslationType }) => {
	const { language } = useTranslationProvider();
	return (
		<>
			<Grid item xs={12} md={6} className="tutorial-container">
				<div className="tutorial-wrapper">
					<YouTube videoId={youtubeId} />
					<div style={{ marginTop: "16px" }}>
						<Typography variant="h4" fontWeight={600} color="black !important">
							{name[language as keyof TranslationType]}
						</Typography>
					</div>
				</div>
			</Grid>
		</>
	);
};
