import { Button, Grid, Typography, Tooltip, TextField, useMediaQuery } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { orderStatuses } from "../../providers/OrderProvider";
import { useSnackbar } from "../../providers/SnackbackProvider";

const twentyDaysInMilliseconds = 1728000000;
export const Deposit = ({
	payDepositNow,
	setPayDepositNow,
	orderStatus,
	endDate,
	depositValue,
}: {
	payDepositNow: boolean;
	setPayDepositNow: Dispatch<SetStateAction<boolean>>;
	orderStatus: string;
	endDate: number;
	depositValue: number;
}): JSX.Element => {
	const {openSnackbar} = useSnackbar();
	const [nowTime, setNowTime] = useState<number>(Date.now());
	const [showIban, setShowIban] = useState<boolean>(false);
	const isMobile = useMediaQuery("(max-width: 768px)");

	const { t } = useTranslation();

	const handleChangePayDepositNow = (payNow: boolean) => {
		try{
		if (orderStatus !== orderStatuses.PENDING) throw new Error(t("orderIsNotPending")!);
		setPayDepositNow(payNow);
		}catch(err){
			const error = err as Error;
			openSnackbar("Error", error.message, "error");
		}
	};
	useEffect(() => {
		const interval = setInterval(() => setNowTime(Date.now()), 60000);
		return () => clearInterval(interval);
	}, []);
	useEffect(() => {
		if (endDate * 1000 - nowTime > twentyDaysInMilliseconds) {
			setShowIban(true);
			setPayDepositNow(false);
		} else {
			setShowIban(false);
		}
	}, [nowTime]);

	return (
		<>
			<Grid container rowGap={2}>
				<Grid item xs={12}>
					<Typography variant="h4">{t("deposit")}</Typography>
				</Grid>
				<Grid
					item
					xs={6}
					md
					sx={{ paddingRight: "6px", md: { flexBasis: "unset !important", flexGrow: "unset !important" } }}
					className="payment-button-container"
				>
					<Tooltip title={<Typography variant="body1">{t("iban-hover-message")}</Typography>} arrow disableHoverListener={!showIban}>
						<Button
							className={`form-button payment-choice ${payDepositNow ? "choice-selected" : ""} `}
							onClick={() => {
								if (!showIban) handleChangePayDepositNow(true);
							}}
						>
							{t("pay-now")}
						</Button>
					</Tooltip>
				</Grid>
				<Grid
					item
					xs={6}
					md
					sx={{ paddingLeft: "6px", md: { flexBasis: "unset !important", flexGrow: "unset !important" } }}
					className="payment-button-container"
				>
					<Button
						className={`form-button payment-choice ${!payDepositNow ? "choice-selected" : ""}`}
						onClick={() => handleChangePayDepositNow(false)}
					>
						{t("pay-in-place")}
					</Button>
				</Grid>
				{showIban && (
					<>
						<Grid item xs={12}>
							<Typography variant="body1" fontWeight={600}>
								{t("deposit-iban-title")}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1">{t("deposit-iban-message").replace("XXXXXXX", depositValue.toString())}</Typography>
						</Grid>
						<Grid item xs={12} className="order-extra-transfer-field-container">
							<TextField
								className="order-extra-transfer-field"
								variant="filled"
								label={t("iban-account-holder")}
								value={"Gais Charter s.r.o"}
								type="text"
							></TextField>
						</Grid>
						<Grid item xs={12} className="order-extra-transfer-field-container">
							<TextField
								className="order-extra-transfer-field"
								variant="filled"
								label={t("iban-bank")}
								value={"Československá obchodní banka "}
								type="text"
							></TextField>
						</Grid>
						<Grid item xs={12} className="order-extra-transfer-field-container">
							<TextField
								className="order-extra-transfer-field"
								variant="filled"
								label={t("IBAN")}
								value={"CZ18 0300 0000 0002 1638 6491"}
								type="text"
							></TextField>
						</Grid>
						<Grid item xs={12} className="order-extra-transfer-field-container">
							<TextField
								className={`order-extra-transfer-field ${isMobile ? "multiline" : ""}`}
								variant="filled"
								label={t("iban-reason")}
								multiline={isMobile}
								rows={6}
								value={t("iban-reason-message")}
								type="text"
							></TextField>
						</Grid>
					</>
				)}
			</Grid>
		</>
	);
};
