import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";

export const BoatDeleteAccountErrorDialog = ({ open, closeDialog }: { open: boolean; closeDialog: () => void }) => {
    return (
        <Dialog open={open} onClose={closeDialog} maxWidth="md">
            <DialogTitle mb={3}>
                <Typography variant="h4">IMPOSSIBILE CANCELLARE QUESTO ACCOUNT</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">Questo account non può essere cancellato perchè ha degli ordini futuri prenotati</Typography>
            </DialogContent>
        </Dialog>
    )
};