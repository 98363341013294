import { createTheme, Theme, ThemeOptions, responsiveFontSizes } from "@mui/material/styles";

//colors design tokens
export const colors = {
	black: {
		100: "#cccccc",
		200: "#999999",
		300: "#666666",
		400: "#333333",
		500: "#242424",
		600: "#000000",
	},
	white: {
		100: "#FFFFFF",
		200: "#EEEEEE",
		300: "#e2e2e2",
		400: "#d4d4d4",
		500: "#c5c5c5",
		600: "#B7B7B7",
		700: "#929292",
		800: "#6e6e6e",
		900: "#494949",
	},
	blue: {
		100: "#ccedff",
		200: "#99daff",
		300: "#66c8ff",
		400: "#33b5ff",
		500: "#00a3ff",
		600: "#0082cc",
		700: "#006299",
		800: "#004166",
		900: "#002133",
	},
	lightBlue: {
		100: "#d9f6ff",
		200: "#b4edff",
		300: "#8ee4ff",
		400: "#69dbff",
		500: "#43d2ff",
		600: "#36a8cc",
		700: "#287e99",
		800: "#149CD8",
		900: "#0d2a33",
	},
	green: {
		100: "#9bff99",
		200: "#69ff66",
		300: "#37ff33",
		400: "#00FF38",
		500: "#05ff00",
		600: "#04cc00",
		700: "#039900",
		800: "#026600",
		900: "#013300",
	},
	yellow: {
		100: "#fffdcc",
		200: "#fffb99",
		300: "#fff966",
		400: "#fff733",
		500: "#FFF500",
		600: "#FFDE59",
		700: "#ccc400",
		800: "#999300",
		900: "#666200",
	},
	red: {
		100: "#ffcccc",
		200: "#ff9999",
		300: "#ff6666",
		400: "#ff3333",
		500: "#FF0000",
		600: "#cc0000",
		700: "#990000",
		800: "#660000",
		900: "#330000",
	},
	gold: {
		100: "#fff8de",
		200: "#fff2bd",
		300: "#ffeb9b",
		400: "#ffe57a",
		500: "#FFDE59",
		600: "#ccb247",
		700: "#998535",
		800: "#665924",
		900: "#332c12",
	},
};

// MUI theme settings
export const themeSettings: ThemeOptions = {
	palette: {
		primary: {
			main: colors.lightBlue[800],
		},
		secondary: {
			main: colors.white[100],
		},
		error: {
			main: colors.red[500],
		},
		warning: {
			main: colors.yellow[500],
		},
		info: {
			main: colors.blue[100],
		},
		success: {
			main: colors.green[700],
		},
		text: {
			primary: colors.black[600],
			secondary: colors.black[400],
			disabled: colors.black[200],
		},
		background: {
			default: colors.white[100],
			paper: colors.white[100],
		},
	},
	typography: {
		fontFamily: "Poppins",
		h1: {
			fontFamily: "Poppins",
			fontWeight: 700,
			fontSize: "170px",
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
		},
		h2: {
			fontFamily: "Poppins",
			fontWeight: 500,
			fontSize: "40px",
			color: colors.black[600],
		},
		h3: {
			fontFamily: "Poppins",
			fontWeight: 700,
			fontSize: "35px",
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			color: colors.lightBlue[800],
		},

		h4: {
			fontFamily: "Poppins",
			fontWeight: 600,
			fontSize: "25px",

			color: colors.black[600],
		},
		h5: {
			fontFamily: "Poppins",
			fontWeight: 400,
			fontSize: "20px",

			color: colors.black[600],
		},
		body1: {
			fontWeight: 400,
			fontSize: "16px",

			color: colors.black[600],
		},
		body2: {
			fontFamily: "Poppins",
			fontWeight: 300,
			fontSize: "12px",

			color: colors.black[600],
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					background: colors.white[100],
					fontFamily: "Poppins",
					fontStyle: "normal",
					fontWeight: 700,
					fontSize: "34px",
					textTransform: "capitalize",
					padding: "0em .5em",
					boxShadow: "0px 4px 4px 0px #00000040",
					borderRadius: "15px",
				},
				containedPrimary: {
					paddingX: "10em",
					paddingY: "0.5em",
				},
				outlinedPrimary: {
					color: colors.lightBlue[800],
				},
				containedSecondary: {
					backgroundColor: colors.white[600],

					"&:hover": {
						backgroundColor: colors.white[500],
					},
				},
				outlinedError: {
					color: colors.red[500],
					borderWidth: 2,
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paperAnchorLeft: {
					background: colors.white[100],
					boxShadow: "4px -4px 4px 0px #00000040",
				},
			},
		},
		MuiBackdrop: {
			styleOverrides: {
				root: {
					backgroundColor: "rgba(255, 255, 255, 0.5)",
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					color: colors.blue[500],
					textDecoration: "none",
					cursor: "pointer",
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					background: colors.white[100],
					borderRadius: "15px",
					"& .Mui-error:after": {
						borderColor: "transparent !important",
					},
					"& .Mui-error:before": {
						borderColor: "transparent !important",
					},
				},
			},
		},
	},
};

export const useTheme = (): Theme => responsiveFontSizes(createTheme(themeSettings));
