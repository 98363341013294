import { Grid, Typography } from "@mui/material";
import { BoatMenu } from "./BoatMenu";
import { useAuth } from "../../components/providers/AuthProvider";
import { FeedbackBoat } from "../../components/boat/feedback/FeedbackBoat";
import { FeedbackMessanges } from "../../components/boat/feedback/FeedbackMessanges";
import { FeedbackTable } from "../../components/boat/feedback/FeedbackTable";

export const BoatFeedback = () => {
	const { boatProfile } = useAuth();

	return (
		<>
			<Grid container flexWrap="nowrap">
				<Grid item height="100%" className="menu-container">
					<BoatMenu />
				</Grid>
				<Grid item xs className="menu-content">
					<Typography variant="h3">Feedback Barca</Typography>
					{boatProfile != null && boatProfile.ratings != null ? (
						<>
							<div className="rating-container">
								<FeedbackBoat boatRatings={boatProfile.ratings} />
							</div>
							{/**Feedback messanges of boat */}
							<div style={{ marginTop: "20px" }}>
								<Grid item xs={12}>
									<Typography variant="h4">Messaggi dei Clienti</Typography>
								</Grid>
								{boatProfile.ratings.messages && boatProfile.ratings.messages.length > 0 ? (
									<FeedbackMessanges feedbackMessanges={boatProfile.ratings.messages} />
								) : (
									<Grid item xs={12}>
										<Typography variant="h5" marginTop="16px">
											Ancora nessun messaggio...
										</Typography>
									</Grid>
								)}
							</div>
						</>
					) : (
						<>
							<Typography variant="h4" marginTop="20px">
								Ancora nessun feedback della barca
							</Typography>
						</>
					)}
					{/**Feedback table of all boat */}
					<div style={{ marginTop: "60px" }}>
						<FeedbackTable />
					</div>
				</Grid>
			</Grid>
		</>
	);
};
