import { Button, Grid } from "@mui/material";

import { Order } from "../../../../types/documents/Order";
import { useEffect, useRef, useState } from "react";
import { ChecklistElementBoarding, WarningChecklistBoat } from "../../../../types/forms/Checklist";

import "../../../../styles/Print.scss";
import { useAuth } from "../../../providers/AuthProvider";
import { PopUpMnemonicInsertion } from "../../../popUps/PopUpMnemonicInsertion";
import { useSnackbar } from "../../../providers/SnackbackProvider";
import { useOrders } from "../../../providers/OrderProvider";
import { checkboatStatuses } from "../../../../utils/constants";
import { CheckBoatNotesAndImages } from "../../../common/CheckBoatNotesAndImages";
import { CheckBoatChecklist } from "../../../common/CheckBoatChecklist";
import { constructOrder } from "../../../../utils/orderChecklist";
import { UnlockCheckin } from "./UnlockCheckIn";
import { useBoatProvider } from "../../../providers/BoatProvider";
import { useTranslation } from "react-i18next";
import { useTranslationProvider } from "../../../providers/TranslationProvider";
import { TranslationType } from "../../../../types/Generic";

import { PrintCheck } from "../printCheck/PrintCheck";

export const BoatCheckContent = ({ order, isBoarding }: { order: Order; isBoarding: boolean }) => {
	const { t } = useTranslation();
	const { language } = useTranslationProvider();

	const [notes, setNotes] = useState("");
	const [images, setImages] = useState<File[]>([]);
	const [checklistInventory, setChecklistInventory] = useState<ChecklistElementBoarding[]>([] as ChecklistElementBoarding[]);
	const [checklistCheckin560, setChecklistCheckin560] = useState<ChecklistElementBoarding[]>([] as ChecklistElementBoarding[]);
	const [disabled, setDisabled] = useState<boolean>(false);
	const [warnings, setWarnings] = useState<WarningChecklistBoat[]>([] as WarningChecklistBoat[]);

	const [mnemonic, setMnemonic] = useState<string[]>(Array.from({ length: 2 }, () => ""));
	const [isOpenPopUpMnemonic, setIsOpenPopUpMnemonic] = useState<boolean>(false);
	const [openDialogUnlockCheckout, setOpenDialogUnlockCheckout] = useState<boolean>(false);
	const { getChecklistCollectionAssociatedWithBoat } = useAuth();
	const { openSnackbar, openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();
	const { submitBoatCheckForBoat } = useOrders();
	const { unlockUserSelfCheckin, handleCancelCheckin } = useBoatProvider();

	const setChecklistOfBoat = async (order: Order) => {
		let checklist: ChecklistElementBoarding[] = [] as ChecklistElementBoarding[];

		if (order.checkBoat && order.checkBoat.checkList.length > 0) {
			updateChecklistFromOrder(order.checkBoat.checkList);
			return;
		}

		checklist = (await getChecklistCollectionAssociatedWithBoat(order.boatUID)) as ChecklistElementBoarding[];

		if (checklist != null) {
			checklist.forEach((checklistElement) => {
				if (checklistElement.inputType === "binary") {
					checklistElement.binaryChoice = true;
				}
			});
			const newChecklistInventory = checklist.filter((checklistElement) => checklistElement.category === "inventory");
			const newChecklistCheckin560 = checklist.filter((checklistElement) => checklistElement.category === "checkin560");
			const newChecklistInventoryOrdered = constructOrder(newChecklistInventory);
			const newChecklistCheckin560Ordered = constructOrder(newChecklistCheckin560);
			setChecklistInventory(newChecklistInventoryOrdered);
			setChecklistCheckin560(newChecklistCheckin560Ordered);
		}
	};

	const updateChecklistFromOrder = (checklist: ChecklistElementBoarding[]) => {
		setChecklistInventory(constructOrder(checklist.filter((checklistElement) => checklistElement.category === "inventory")));
		setChecklistCheckin560(constructOrder(checklist.filter((checklistElement) => checklistElement.category === "checkin560")));
	};

	useEffect(() => {
		if (order != null) {
			(async () => {
				setChecklistOfBoat(order);
				setWarnings(order.warnings);

				if (!order.checkBoat) return;

				setNotes(order.checkBoat.note);

				if (order.checkBoat.status === checkboatStatuses.COUNTER_SIGNED) setDisabled(true);
			})();
		}
	}, [order]);

	const handleSubmitFirm = async () => {
		try {
			openLoadingSnackbar();
			for (let i = 0; i < mnemonic.length; i++) {
				if (mnemonic[i] === "") {
					openSnackbar("Errore", "Inserisci le 2 parole della chiave segreta", "error");
					return;
				}
			}
			const checkList = [...checklistCheckin560, ...checklistInventory];
			await submitBoatCheckForBoat({ checkList, notes, images, orderUID: order.orderUID, mnemonic, warnings: [] });
			closeLoadingSnackbar();
			openSnackbar("Successo", "Controllo barca firmato correttamente", "success");
		} catch (err) {
			const error = err as Error;
			closeLoadingSnackbar();
			openSnackbar("Errore", error.message, "error");
		} finally {
			closeLoadingSnackbar();
		}
	};

	const handleClickFirm = () => {
		try {
			const checkList = [...checklistCheckin560, ...checklistInventory];
			checkList
				.filter((el) => el.inputType === "text")
				.forEach((el) => {
					if (el.textChoice === undefined || el.textChoice === null || el.textChoice.length === 0)
						throw new Error(
							`${t("error-missing-field-checklist-1")} \"${el.name[language as keyof TranslationType]}\" ${t(
								"error-missing-field-checklist-2"
							)} \"${el.category === "inventory" ? t("inventory") : t("control-of-function")}\" ${t("error-missing-field-checklist-3")}`
						);
				});
			setIsOpenPopUpMnemonic(true);
		} catch (err) {
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		}
	};

	const handleClickCancelCheckin = async () => {
		await handleCancelCheckin(order.orderUID);
		setDisabled(false);
	};

	const handleSubmitAllowUserCheckboat = async () => {
		openLoadingSnackbar();
		await unlockUserSelfCheckin(order.orderUID, mnemonic.join(" "), notes, images, [...checklistCheckin560, ...checklistInventory]);
		closeLoadingSnackbar();
	};

	const handleClosePopUpMnemonic = () => {
		setIsOpenPopUpMnemonic(false);
	};

	const handleClosePupUpForUnlockCheckout = () => {
		setOpenDialogUnlockCheckout(false);
	};

	const openDialogForUnlockCheckout = () => {
		setOpenDialogUnlockCheckout(true);
	};

	return (
		<>
			<PopUpMnemonicInsertion
				isPopUpOpen={isOpenPopUpMnemonic}
				handleClosePopup={handleClosePopUpMnemonic}
				setMnemonic={setMnemonic}
				mnemonic={mnemonic}
				handleSubmit={handleSubmitFirm}
			/>
			<PopUpMnemonicInsertion
				isPopUpOpen={openDialogUnlockCheckout}
				handleClosePopup={handleClosePupUpForUnlockCheckout}
				setMnemonic={setMnemonic}
				mnemonic={mnemonic}
				handleSubmit={handleSubmitAllowUserCheckboat}
			/>
			<Grid container rowGap={6}>
				{isBoarding && (
					<>
						{/**Unlock user's check-in */}
						<Grid item xs={6}>
							<UnlockCheckin onClick={openDialogForUnlockCheckout} />
						</Grid>
						<Grid item xs={6}>
							<PrintCheck order={order} />
						</Grid>
						{/**Check list */}
						<Grid item xs={12}>
							<CheckBoatChecklist
								orderUID={order.orderUID}
								isAllowedToModifyChecklist={true}
								checklistInventory={checklistInventory}
								setChecklistInventory={setChecklistInventory}
								checklistCheckin560={checklistCheckin560}
								setChecklistCheckin560={setChecklistCheckin560}
								isUser={false}
								boardingStatus={order.checkBoat != null ? order.checkBoat.status : checkboatStatuses.PENDING}
								warnings={warnings}
								setWarnings={setWarnings}
							/>
						</Grid>
						{/**Notes and images */}
						<Grid item xs={12}>
							<CheckBoatNotesAndImages
								notes={notes}
								images={images}
								setNotes={setNotes}
								setImages={setImages}
								isUser={false}
								disabled={disabled}
								type="check-in"
								orderId={order.orderUID}
								imagesName={order.checkBoat?.images}
							/>
						</Grid>
						{/**SUBMITS */}
						{order.checkBoat?.status !== checkboatStatuses.COUNTER_SIGNED ? (
							<Grid item xs={12}>
								<Grid container gap={2} rowGap={2}>
									<Grid xs item display="flex" justifyContent="center">
										<Button className="form-button" onClick={() => handleClickFirm()}>
											FIRMA
										</Button>
									</Grid>
								</Grid>
							</Grid>
						) : (
							<Grid item xs={12}>
								<Grid container gap={2} rowGap={2}>
									<Grid xs item display="flex" justifyContent="center">
										<Button className="form-button" onClick={() => handleClickCancelCheckin()}>
											ANNULLA CHECKIN
										</Button>
									</Grid>
								</Grid>
							</Grid>
						)}
					</>
				)}
			</Grid>
		</>
	);
};
