import { Grid, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
export const ChecklistWarning = ({
	index,
	value,
	checked,
	handleToggleCheck,
	isUser,
}: {
	index: number;
	value: string;
	checked: boolean;
	handleToggleCheck: (index: number) => void;
	isUser: boolean;
}) => {
	const { t } = useTranslation();
	return (
		<>
			<Grid item xs={12} className="rounded-container" sx={{ marginTop: "8px", marginBottom: "8px" }}>
				<Grid container rowGap={4}>
					<Grid item xs={12}>
						<Typography variant="h5">{value}</Typography>
					</Grid>
					<Grid item xs={12}>
						<section style={{ display: "flex", alignItems: "center", gap: "8px" }}>
							<a
								onClick={() => {
									if (isUser) handleToggleCheck(index);
								}}
							>
								<div className={`order-checkbox ${checked ? "order-checkbox-filled" : ""} checkbox-checklist`}>
									{checked && <CheckIcon className="checkbox-icon" />}
								</div>
							</a>
							<Typography variant="h5" className="yes-no-text-checklist-check-desktop">
								{t("i-am-aware-of-it")}
							</Typography>
						</section>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
