import { Dispatch, SetStateAction, useRef, useState, useEffect } from "react";
import { CheckImagePopUp } from "./popup/CheckImagesPopUp";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useOrders } from "../providers/OrderProvider";
import { useSnackbar } from "../providers/SnackbackProvider";

export const CheckBoatNotesAndImages = ({
	notes,
	images,
	setNotes,
	setImages,
	disabled,
	isUser,
	type,
	isAllowedToModifyChecklist,
	orderId,
	imagesName,
}: {
	notes: string;
	images: File[];
	setNotes: Dispatch<SetStateAction<string>>;
	setImages: Dispatch<SetStateAction<File[]>>;
	disabled: boolean;
	isUser: boolean;
	isAllowedToModifyChecklist?: boolean;
	type?: string;
	orderId?: string;
	imagesName?: string[];
}) => {
	const [isOpenPopUpImages, setIsOpenPopUpImages] = useState<boolean>(false);
	const [notesOpen, setNotesOpen] = useState<boolean>(notes === "" ? false : true);
	const [notesValue, setNotesValue] = useState<string>(notes);
	const [imagesDownloaded, setImagesDownloaded] = useState<boolean>(false);
	const notesInputRef = useRef<HTMLInputElement>(null);

	const { getImageFromStorage } = useOrders();
	const { openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const handleClosePopUpImages = () => {
		setIsOpenPopUpImages(false);
	};
	const handleCloseNotes = () => {
		if (notesValue.length === 0) {
			setNotesOpen(false);
		}
	};
	const handleOpenNotes = () => {
		if (notesValue.length === 0 && !notesOpen) {
			setNotesOpen(true);
			if (notesInputRef.current) {
				notesInputRef.current.focus();
			}
		}
	};
	const getImagesFromStorage = async (imagesName: string[]) => {
		const images: File[] = [];
		for (let i = 0; i < imagesName.length; i++) {
			const imageName = imagesName[i];
			const image = await getImageFromStorage(imageName, `orders/${orderId}/${type == "check-in" ? "checkBoatImages" : "checkoutImages"}/`);
			if (image != null) {
				images.push(image);
			}
		}
		setImagesDownloaded(true);
		setImages(images);
	};
	const handleOpenPopUpImages = async () => {
		try {
			if (imagesName == null) return;
			openLoadingSnackbar();
			if (!imagesDownloaded) await getImagesFromStorage(imagesName);
			closeLoadingSnackbar();
			setIsOpenPopUpImages(true);
		} catch (err) {
			console.error(err);
			closeLoadingSnackbar();
		}
	};
	useEffect(() => {
		if (isUser && notes.length !== 0) {
			setNotesOpen(true);
		}
		setNotesValue(notes);
	}, [isUser, notes]);

	return (
		<>
			<CheckImagePopUp
				images={images}
				setImages={setImages}
				isPopUpOpen={isOpenPopUpImages}
				handleClosePopup={handleClosePopUpImages}
				isUser={isUser}
				disabled={disabled}
				isAllowedToModifyChecklist={isAllowedToModifyChecklist == null ? false : isAllowedToModifyChecklist}
			/>
			<Grid container gap={2} rowGap={3}>
				<Grid item xs={12}>
					{type != null && type === "check-in" ? (
						<Typography variant="h4">{t("notes-and-images-check-in")}</Typography>
					) : (
						<Typography variant="h4">{t("notes-and-images-check-out")}</Typography>
					)}
				</Grid>
				<Grid item xs={12} sm paddingRight={1}>
					<TextField
						inputRef={notesInputRef}
						multiline={notesOpen}
						rows={notesOpen ? 5 : 1}
						className="input-form multiline"
						sx={{ width: { xs: notesOpen ? "100%" : "fit-content" } }}
						id="notes"
						name="notes"
						label={t("note")}
						variant={"filled"}
						value={notesValue}
						disabled={disabled || !(isAllowedToModifyChecklist == null ? true : isAllowedToModifyChecklist)}
						onBlur={() => {
							handleCloseNotes();
							setNotes(notesValue);
						}}
						onClick={() => handleOpenNotes()}
						onChange={(e) => {
							setNotesValue(e.currentTarget.value);
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={4} paddingLeft={1}>
					<Button className="form-button check-images" sx={{ maxWidth: "100%" }} onClick={() => handleOpenPopUpImages()}>
						{t("images")}
					</Button>
				</Grid>
			</Grid>
		</>
	);
};
