import "./styles/App.scss";

import Root from "./components/Root";
import "./styles/Forms.scss";

function App() {
	return (
		<>
			<Root />
		</>
	);
}

export default App;
