export const api = {
	getEmailFromUsername: "/auth/getEmail",
	createBoat: "/auth/createBoatAccount",
	deleteBoat: "/auth/boat/account/delete",
	removeExtraFromBoat: "/boats/extras/remove",
	addExtraFromBoat: "/boats/extras/add",
	changePasswordBoat: "/auth/boat/changePassword",
	deleteUserAccount: "/users/accounts/delete",
	getBoatPdfs: "/boats/pdfs",
	createOrder: "/orders/create",
	deleteOrder: "/orders/delete",
	changeOrderBoat: "/orders/modify/changeBoat",
	addNewMemberToCrewlist: "/orders/modify/addCrewMember",
	removeMemberFromCrewlist: "/orders/modify/removeCrewMember",
	createCheckout: "/payments/checkout/create",
	sendChecking: "/orders/sendCheckin",
	sendCheckBoatForBoat: "/orders/sendCheckBoatForBoat",
	sendCheckBoatForUser: "/orders/sendCheckBoatForUser",
	submitCheckoutForBoat: "/orders/signCheckout/boat",
	submitCheckoutForUser: "/orders/signCheckout/user",
	createWallet: "/users/wallet/create",
	giveUserAccessToCheckboat: "/orders/modify/giveUserAccessToCheckboat",
	sendUserSelfBoarding: "/orders/user/self/sendCheckboat",
	generatePasswordResetLink: "/users/resetPassword",
};
