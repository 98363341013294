import { useState } from "react";
import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { UserMenu } from "./UserMenu";
import { useTranslation } from "react-i18next";
import { FormChangePassword } from "../../components/user/settings/FormChangePassword";
import { FormContactUs } from "../../components/user/settings/FormContactUs";
import { DownloadDocuments } from "../../components/user/settings/DownloadDocuments";
import { DeleteAccount } from "../../components/user/settings/DeleteAccount";

import "../../styles/user/Settings.scss";

export const UserSettings = () => {
	const [expanded, setExpanded] = useState<string | false>(false);
	const { t } = useTranslation();
	const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			<Grid container flexWrap="nowrap">
				<Grid item height="100%" className="menu-container">
					<UserMenu />
				</Grid>
				<Grid item xs className="menu-content">
					<Grid container justifyContent={"flex-start"} rowGap="24px">
						<Grid item xs={12}>
							<Typography variant="h3">{t("change-password")}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Accordion className="accordion" expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
									<Typography sx={{ width: "100%", flexShrink: 0, xs: { fontSize: "16px !important" } }} variant="h4">
										{t("change-your-password")}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<FormChangePassword />
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12} marginTop="24px">
							<Typography variant="h3">{t("contact-us")}</Typography>
						</Grid>
						<Grid item xs={12}>
							<FormContactUs />
						</Grid>

						<Grid item xs={12} marginTop="24px">
							<Typography variant="h3">{t("delete-your-account")}</Typography>
						</Grid>
						<Grid item xs={12}>
							<DeleteAccount />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
