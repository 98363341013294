import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";


export const BoatDeleteAccountPupUp = ({ open, closeDialog, confirmDeletion }: { open: boolean; closeDialog: () => void, confirmDeletion: () => Promise<void> }) => {
    return (
        <Dialog open={open} onClose={closeDialog} maxWidth="md">
            <DialogTitle mb={3}>
                <Typography variant="h4">STAI PER CANCELLARE IL TUO ACCOUNT PER SEMPRE</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">Sei sicuro di voler eliminare il tuo account? Se prosegui non c'è modo di recuperarlo</Typography>
                <Grid container style={{ textAlign: 'center' }}>
                    <Grid item xs={12} sx={{ marginTop: "16px" }}>
                        <Button onClick={confirmDeletion} className="form-button">
                            CONFERMA
                        </Button>
                    </Grid>

                </Grid>
            </DialogContent>
        </Dialog>
    );
};