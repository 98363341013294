import { createContext, useContext } from "react";
import { StripeProvider } from "../../types/providers/StripeProvider";
import { CreateCheckoutBody } from "../../types/requestBodies.ts/CreateCheckoutBody";
import { useNetwork } from "./NetworkProvider";
import { api } from "../../utils/network/api";
import { CreateCheckoutResponse } from "../../types/responses/CreateCheckoutResponse";
import { useAuth } from "./AuthProvider";

const StripeProviderContext = createContext<StripeProvider>({} as StripeProvider);
export const StripeProviderImpl = ({ children }: { children: JSX.Element }) => {
	const { makePost } = useNetwork();
	const { user } = useAuth();
	const createCheckoutSession = async (checkoutInfo: CreateCheckoutBody): Promise<CreateCheckoutResponse> => {
		if (!user) {
			throw new Error("Not logged in, please relogg in");
		}
		validateCheckoutBody(checkoutInfo);
		const idToken = await user.getIdToken();

		const res = await makePost<CreateCheckoutResponse>(api.createCheckout, checkoutInfo, idToken);
		if (res.status !== 200) throw new Error("Something went wrong while creating checkout");
		return res.data;
	};

	const validateCheckoutBody = (checkoutInfo: CreateCheckoutBody) => {
		if (!checkoutInfo || !checkoutInfo.boatUID || !checkoutInfo.orderID || !checkoutInfo.userEmail) throw new Error("Missing checkout info");
	};

	const stripeProviderData = {
		createCheckoutSession,
	};

	return <StripeProviderContext.Provider value={stripeProviderData}>{children}</StripeProviderContext.Provider>;
};
export const useStripe = () => useContext(StripeProviderContext);
