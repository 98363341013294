import { Grid, Typography } from "@mui/material";
import { Order } from "../../../types/documents/Order";
import { useTranslation } from "react-i18next";
import { useBoatProvider } from "../../../components/providers/BoatProvider";
import { BoatDocument } from "../../../types/documents/Boat";
import { useEffect, useState } from "react";
import { dateFormat, getDateIntervalFromTimestamps } from "../../../utils/dateOperation";
import { useTranslationProvider } from "../../../components/providers/TranslationProvider";
import info_circle from "../../../assets/info_circle_black.svg";
import { capitilizeFirstLetter } from "../../../utils/stringOperation";

export const LandingOrderInformation = ({ order }: { order: Order }) => {
	const { t } = useTranslation();
	const { getBoatFromUID } = useBoatProvider();
	const { language } = useTranslationProvider();
	const [boat, setBoat] = useState<BoatDocument | null>(null);

	useEffect(() => {
		(async () => {
			const boatSnap = await getBoatFromUID(order.boatUID);
			setBoat(boatSnap);
		})();
	}, [order]);

	return (
		<>
			<Typography variant="h3">{t("your-vacation-title")}</Typography>
			{boat != null && (
				<Grid container rowGap="16px" sx={{ marginTop: "16px" }}>
					<Grid item xs={12} className="default-bar">
						<OrderInfo name={t("boat").toUpperCase()} value={boat.username} />
					</Grid>
					<Grid item xs={12} className="default-bar">
						<OrderInfo name={t("date").toUpperCase()} value={getDateIntervalFromTimestamps(order.startDate, order.endDate, language)} />
					</Grid>
					<Grid item xs={12} className="default-bar">
						<OrderInfo name={t("port").toUpperCase()} value={boat.port} />
					</Grid>
					<Grid item xs={12} className="default-bar">
						<OrderInfo name={t("crew").toUpperCase()} value={`${order.crewList.length.toString()} ${t("people")}`} />
					</Grid>
					<Grid item xs={12} className="default-bar">
						<OrderInfo
							name={t("boarding-time").toUpperCase()}
							value={
								(boat.boardingTime || t("undefined").toUpperCase()) +
								" / " +
								capitilizeFirstLetter(dateFormat(order.startDate * 1000, language))
							}
						/>
					</Grid>
					<Grid item xs={12} className="default-bar">
						<OrderInfo
							name={t("disembarkation-time").toUpperCase()}
							value={
								(boat.disembarkationTime || t("undefined").toUpperCase()) +
								" / " +
								capitilizeFirstLetter(dateFormat(order.endDate * 1000, language))
							}
						/>
					</Grid>
					<Grid item xs={12} className="default-bar">
						<Grid container className="landing-deposit-info">
							<Grid item xs={6}>
								<Typography variant="h5" color="#149cd8" fontWeight={600}>
									{t("deposit").toUpperCase()}
								</Typography>
							</Grid>
							<Grid item xs={6} display="flex" gap={1} alignItems="center" justifyContent="flex-end">
								<Typography variant="h5" textAlign="right" color="#149cd8" fontWeight={600}>
									{`${order.deposit} €`}
								</Typography>
								<img src={info_circle} alt="info circle" className="info-circle-landing" />
							</Grid>
							<Grid item xs={12} className="landing-deposit-hover">
								<Typography variant="body1" color="black" fontWeight={600}>
									{t("deposit-landing-hover-text")}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};
const OrderInfo = ({ name, value }: { name: string; value: string }) => {
	return (
		<Grid container>
			<Grid item xs={6}>
				<Typography variant="h5" color="#149cd8" fontWeight={600}>
					{name}
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant="h5" textAlign="right" color="#149cd8" fontWeight={600}>
					{value}
				</Typography>
			</Grid>
		</Grid>
	);
};
