import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useOrders } from "../../../components/providers/OrderProvider";
import { UserMenu } from "../UserMenu";
import { UserCheckInOrder } from "../../../components/user/orders/UserCheckInOrder";
import { useTranslation } from "react-i18next";

export const UserCheckIn = () => {
	const { futureOrders } = useOrders();
	const { t } = useTranslation();

	return (
		<>
			<Grid container flexWrap="nowrap">
				<Grid item height="100%" className="menu-container">
					<UserMenu />
				</Grid>

				<Grid item xs className="menu-content">
					<Grid container rowGap="16px">
						<Grid item xs={12}>
							<Typography variant="h3">{t("your-bookings")}</Typography>
						</Grid>
						{futureOrders.map((value, index) => {
							return (
								<Grid item xs={12} marginY="2px" key={`order-index-${index}`}>
									<UserCheckInOrder order={value} />
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
