import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Order } from "../../../types/documents/Order";
import { useUserProvider } from "../../providers/UserProviderImpl";
import { UserDocument } from "../../../types/documents/User";

import "../../../styles/Orders.scss";
import { BoatOrderCheckInContent } from "../../boat/orders/BoatOrderCheckInContent";
import { orderStatuses } from "../../providers/OrderProvider";
import $ from "jquery";
import { getDateIntervalFromTimestamps } from "../../../utils/dateOperation";
export const BoatOrder = ({
	order,
	index,
	expanded,
	handleChangeAccordion,
}: {
	order: Order;
	index: number;
	expanded: string | boolean;
	handleChangeAccordion: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}) => {
	const { getUserFromUid } = useUserProvider();
	const [user, setUser] = useState<UserDocument>({} as UserDocument);
	const [statusName, setStatusName] = useState<string>("");

	useEffect(() => {
		(async () => {
			const user = await getUserFromUid(order.userUID);
			setUser(user);
		})();

		if (order.status === orderStatuses.PENDING) {
			$(`#panel${order.orderUID}bh-header .color-action`).addClass("pending-action");

			setStatusName("Imbarco in attesa...");
		}
		if (order.status === orderStatuses.SENT) {
			setStatusName("Imbarco inviato");
			$(`#panel${order.orderUID}bh-header .color-action`).removeClass("pending-action");
		}
		if (order.status === orderStatuses.ACCEPTED) {
			setStatusName("Imbarco eseguito");
			$(`#panel${order.orderUID}bh-header .color-action`).removeClass("pending-action");
		}
	}, [index, order]);

	return (
		<>
			<Accordion
				className="accordion"
				expanded={expanded === `panel${order.orderUID}`}
				onChange={handleChangeAccordion(`panel${order.orderUID}`)}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon className={`color-action pending-action`} />}
					aria-controls={`panel${order.orderUID}bh-content`}
					id={`panel${order.orderUID}bh-header`}
				>
					<Grid container justifyContent="space-between">
						<Grid item xs={12} md={4}>
							<Typography className={`color-action pending-action`} variant="h4" color="black">
								{user.name} {user.surname}
							</Typography>
						</Grid>
						<Grid item xs={12} md={3}>
							<Typography className={`color-action pending-action`} variant="h4" color="black">
								{getDateIntervalFromTimestamps(order.startDate, order.endDate, "it")}
							</Typography>
						</Grid>
						<Grid item xs={12} md={5}>
							<Typography className={`color-action pending-action`} variant="h4" color="black">
								{statusName}
							</Typography>
						</Grid>
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					<BoatOrderCheckInContent order={order} />
				</AccordionDetails>
			</Accordion>
		</>
	);
};
