import { FirebaseProvider } from "../components/providers/FirebaseProvider";
import { NetworkProvider } from "../components/providers/NetworkProvider";
import { TranslationProvider } from "../components/providers/TranslationProvider";
import { AuthProvider } from "../components/providers/AuthProvider";
import { useTheme } from "../utils/theme";
import { ThemeProvider } from "@mui/material";
import { BoatProvider } from "../components/providers/BoatProvider";
import { UserProviderImpl } from "../components/providers/UserProviderImpl";
import { OrderProvider } from "../components/providers/OrderProvider";
import { StripeProviderImpl } from "../components/providers/StripeProvider";
import { SnackbarProvider } from "../components/providers/SnackbackProvider";
import { BlockchainProvider } from "./providers/BlockchainProvider";
export const Provider = ({ children }: { children: JSX.Element }) => {
	const theme = useTheme();
	return (
		<>
			<ThemeProvider theme={theme}>
				<SnackbarProvider>
					<TranslationProvider>
						<FirebaseProvider>
							<NetworkProvider>
								<AuthProvider>
									<BlockchainProvider>
										<BoatProvider>
											<UserProviderImpl>
												<OrderProvider>
													<StripeProviderImpl>{children}</StripeProviderImpl>
												</OrderProvider>
											</UserProviderImpl>
										</BoatProvider>
									</BlockchainProvider>
								</AuthProvider>
							</NetworkProvider>
						</FirebaseProvider>
					</TranslationProvider>
				</SnackbarProvider>
			</ThemeProvider>
		</>
	);
};
