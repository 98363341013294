import { Dialog, DialogContent, DialogTitle, Grid, Typography, Button } from "@mui/material";

import "../../styles/popup/Credentials.scss";
import "../../styles/Buttons.scss";

const MnemonicField = ({ index, word }: { index: number; word: string }) => {
	return (
		<>
			<div className="popup-credentials-mnemonic-field">
				<Typography variant="body1" sx={{ marginLeft: "1em" }}>
					{index}.
				</Typography>
				<Typography variant="body1" sx={{ width: "100%" }} textAlign="center">
					{word}
				</Typography>
			</div>
		</>
	);
};

export const PopUpCredentials = ({
	mnemonic,
	isPopUpCrendentialsOpen,
	handleClosePopupCredentials,
}: {
	mnemonic: string[];
	isPopUpCrendentialsOpen: boolean;
	handleClosePopupCredentials: any;
}) => {
	return (
		<Dialog open={isPopUpCrendentialsOpen} fullWidth maxWidth="md">
			<DialogTitle mb={3}>
				<Typography variant="h4">Credenziali</Typography>
			</DialogTitle>
			<DialogContent>
				<>
					<Grid container gap={2} rowGap={2} justifyContent="center">
						{mnemonic.map((value, index) => {
							return (
								<>
									<Grid item xs={5}>
										<MnemonicField word={value} index={index + 1}></MnemonicField>
									</Grid>
								</>
							);
						})}
						<Grid xs={10} marginTop="16px">
							<Typography variant="h4">AVVISO IMPORTANTE</Typography>
							<Typography variant="body1">
								Segnati queste 2 parole su una nota o su un foglio di carta perchè ne avrai bisogno in seguito
							</Typography>
						</Grid>
						<Grid xs={12}>
							<Grid container justifyContent="center">
								<Grid item xs={4}>
									<Button onClick={handleClosePopupCredentials} fullWidth className="form-button">
										OK
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</>
			</DialogContent>
		</Dialog>
	);
};
