import { useState } from "react";
import { Grid, Button, TextField, Typography, Select, MenuItem } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import "../../../styles/Buttons.scss";
import { useBoatProvider } from "../../providers/BoatProvider";
import { PopUpCredentials } from "../../popUps/PopUpCredentials";
import { useSnackbar } from "../../providers/SnackbackProvider";
const validationSchema = yup.object({
	username: yup.string().required("Il nome della barca è richiesto"),
	licensePlate: yup.string().required("La targa della barca è richiesta"),
	password: yup.string().required("La password è richista"),
	deposit: yup.number().typeError("La cauzione deve essere un numero").required("Il prezzo della cauzione è richiesto"),
	confirmpassword: yup
		.string()
		.oneOf([yup.ref("password"), undefined], "Le password devono essere uguali")
		.required("Conferma la tua password"),
});

export const BoatCreationForm = () => {
	const { createBoat } = useBoatProvider();
	const availablePorts = {
		PORTO_ROSA: "Portorosa",
		PALERMO: "Palermo",
	};
	const { openSnackbar, openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();
	const [isPopUpCrendentialsOpen, setIsPopUpCrendentialsOpen] = useState<boolean>(false);
	const [mnemonic, setMnemonic] = useState<string[]>([""]);
	const formik = useFormik({
		initialValues: {
			username: "",
			licensePlate: "",
			password: "",
			confirmpassword: "",
			deposit: 0,
			port: availablePorts.PORTO_ROSA,
		},

		validationSchema: validationSchema,
		onSubmit: async (values) => await handleSubmit(values),
	});

	const handleSubmit = async (values: any) => {
		try {
			openLoadingSnackbar();
			const result = await createBoat(values.username, values.licensePlate, values.password, values.deposit, values.port);
			closeLoadingSnackbar();
			if (result) {
				const mnemonic = result?.wallet.mnemonic.phrase.split(" ");
				setMnemonic(mnemonic.slice(-2));
				setIsPopUpCrendentialsOpen(true);
			}
		} catch (err) {
			const error = err as Error;
			closeLoadingSnackbar();
			openSnackbar("ERROR", error.message, "error");
		}
	};
	const handlePopUpClose = () => {
		setIsPopUpCrendentialsOpen(false);
		setMnemonic([""]);
	};

	return (
		<>
			<PopUpCredentials
				mnemonic={mnemonic}
				isPopUpCrendentialsOpen={isPopUpCrendentialsOpen}
				handleClosePopupCredentials={handlePopUpClose}></PopUpCredentials>
			<form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
				<Grid container justifyContent={"space-between"} rowGap={4}>
					<Grid item xs={12} sm={6}>
						<TextField
							className="input-form"
							sx={{ width: { xs: "100%" } }}
							id="username"
							name="username"
							label="Nome della barca"
							variant={"filled"}
							value={formik.values.username}
							onChange={formik.handleChange}
							error={formik.touched.username && Boolean(formik.errors.username)}
							helperText={formik.touched.username && formik.errors.username}
						/>
						<TextField
							className="input-form"
							sx={{ width: { xs: "100%" }, marginTop: "16px" }}
							id="licensePlate"
							name="licensePlate"
							label="Targa della barca"
							variant={"filled"}
							value={formik.values.licensePlate}
							onChange={formik.handleChange}
							error={formik.touched.licensePlate && Boolean(formik.errors.licensePlate)}
							helperText={formik.touched.licensePlate && formik.errors.licensePlate}
						/>
					</Grid>
					<Grid item xs={12} sm={5}>
						<TextField
							className="input-form"
							sx={{ width: { xs: "100%" } }}
							id="password"
							name="password"
							label="Password"
							variant={"filled"}
							value={formik.values.password}
							onChange={formik.handleChange}
							error={formik.touched.password && Boolean(formik.errors.password)}
							helperText={formik.touched.password && formik.errors.password}
						/>
						<TextField
							className="input-form"
							sx={{ width: { xs: "100%" }, marginTop: "16px" }}
							id="confirmpassword"
							name="confirmpassword"
							label="Conferma la password"
							variant={"filled"}
							value={formik.values.confirmpassword}
							onChange={formik.handleChange}
							error={formik.touched.confirmpassword && Boolean(formik.errors.confirmpassword)}
							helperText={formik.touched.confirmpassword && formik.errors.confirmpassword}
						/>
					</Grid>
					<Grid item xs={12} sx={{ display: "flex", flexDirection: "column", rowGap: "16px" }}>
						<TextField
							select
							className="input-form"
							sx={{ width: { xs: "100%" } }}
							id="port-boat-select"
							name="port"
							label="Porto della barca"
							variant={"filled"}
							value={formik.values.port}
							onChange={formik.handleChange}
							error={formik.touched.port && Boolean(formik.errors.port)}
							helperText={formik.touched.port && formik.errors.port}>
							{Object.keys(availablePorts).map((key) => {
								return (
									<MenuItem key={`port-${key}`} value={availablePorts[key as keyof typeof availablePorts]}>
										{availablePorts[key as keyof typeof availablePorts]}
									</MenuItem>
								);
							})}
						</TextField>
					</Grid>
					<Grid item xs={8} sx={{ display: "flex", flexDirection: "column", rowGap: "16px" }}>
						<Typography variant="h4">Prezzo cauzione</Typography>
						<TextField
							className="input-form"
							sx={{ width: { xs: "100%" } }}
							id="deposit"
							name="deposit"
							label="Digita il prezzo"
							variant={"filled"}
							value={formik.values.deposit}
							onChange={formik.handleChange}
							error={formik.touched.deposit && Boolean(formik.errors.deposit)}
							helperText={formik.touched.deposit && formik.errors.deposit}
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: "16px" }} display={"flex"} justifyContent={"center"}>
						<Button type="submit" className="form-button ">
							CREA ACCOUNT
						</Button>
					</Grid>
				</Grid>
			</form>
		</>
	);
};
