import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { WarningChecklistBoat } from "../../../../types/forms/Checklist";
import { Order } from "../../../../types/documents/Order";

type TableWarningProps = {
	isCheckIn: boolean;
	warnings: WarningChecklistBoat[];
} & Pick<Order, "orderUID">;

export const TableWarning = ({orderUID, warnings, isCheckIn }: TableWarningProps) => (
	<Table className="table-warning">
		<HeadTableWarning />
		<BodyTableWarning orderUID={orderUID} isCheckIn={isCheckIn} warnings={warnings} />
	</Table>
);

const HeadTableWarning = () => (
	<TableHead>
		<TableRow>
			<TableCell align="center" colSpan={3}>
				<Typography variant="h5" fontWeight="bold">
					ATTENZIONE
				</Typography>
			</TableCell>
			<TableCell align="center" rowSpan={3} colSpan={1}>
				<Typography variant="h5" fontWeight="bold">
					Check-In
				</Typography>
			</TableCell>
			<TableCell align="center" rowSpan={3} colSpan={1}>
				<Typography variant="h5" fontWeight="bold">
					Check-Out
				</Typography>
			</TableCell>
		</TableRow>
		<TableRow>
			<TableCell align="center" colSpan={3}>
				<Typography variant="h5" fontWeight="bold">
					UPOZORNENI
				</Typography>
			</TableCell>
		</TableRow>
		<TableRow>
			<TableCell align="center" colSpan={3}>
				<Typography variant="h5" fontWeight="bold">
					PAY ATTENTION
				</Typography>
			</TableCell>
		</TableRow>
	</TableHead>
);

const BodyTableWarning = ({orderUID, isCheckIn, warnings }: TableWarningProps) => (
	<TableBody>
		{warnings &&
			warnings.map((warning, i) => (
				<TableRow key={`${orderUID}-row-checkin-warning-${i}`}>
					<TableCell align="left" colSpan={1}>
						<Typography variant="h5" color="red">
							{warning.value.it}
						</Typography>
					</TableCell>
					<TableCell align="left" colSpan={1}>
						<Typography variant="h5" color="red">
							{warning.value.cs}
						</Typography>
					</TableCell>
					<TableCell align="left" colSpan={1}>
						<Typography variant="h5" color="red">
							{warning.value.en}
						</Typography>
					</TableCell>
					<TableCell align="center" colSpan={1}>
						<Typography variant="h5">
							{warning.boatCheckChecked ? (
								<CheckIcon fontSize="large" sx={{ color: "green" }} />
							) : (
								<CloseIcon fontSize="large" sx={{ color: "red" }} />
							)}
						</Typography>
					</TableCell>
					<TableCell align="center" colSpan={1}>
						<Typography variant="h5">
							{!isCheckIn &&
								(warning.checkoutChecked ? (
									<CheckIcon fontSize="large" sx={{ color: "green" }} />
								) : (
									<CloseIcon fontSize="large" sx={{ color: "red" }} />
								))}
						</Typography>
					</TableCell>
				</TableRow>
			))}
	</TableBody>
);
