import { Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../providers/AuthProvider";
import { useBlockchainContext } from "../../../providers/BlockchainProvider";
import { useOrders } from "../../../providers/OrderProvider";
import { Order } from "../../../../types/documents/Order";
import { useState } from "react";
import { PopUpCredentials } from "../../../popUps/PopUpCredentials";
import { PopUpMnemonicInsertion } from "../../../popUps/PopUpMnemonicInsertion";
import { checkboatStatuses } from "../../../../utils/constants";
import { useSnackbar } from "../../../providers/SnackbackProvider";
import { ChecklistElementBoarding, WarningChecklistBoat } from "../../../../types/forms/Checklist";
import { UserCheckShowCheckList } from "./UserCheckShowCheckList";
import { TranslationType } from "../../../../types/Generic";
import { useTranslationProvider } from "../../../providers/TranslationProvider";

export const UserCheckContent = ({ order, isBoarding }: { order: Order; isBoarding: boolean }) => {
	const { t } = useTranslation();
	const { language } = useTranslationProvider();
	const { user, userProfile } = useAuth();
	const { createWallet } = useBlockchainContext();
	const { submitBoatCheckForUser, submitUserSelfBoarding } = useOrders();

	const [mnemonic, setMnemonic] = useState<string[]>(Array.from({ length: 2 }, () => ""));
	const [showCredentialsOpen, setShowCredentialsOpen] = useState<boolean>(false);
	const [isDialogForSelfSignOpen, setIsDialogForSelfSignOpen] = useState<boolean>(false);
	const [isOpenPopUpInsertionMnemonic, setIsOpenPopUpInsertionMnemonic] = useState<boolean>(false);
	const { openLoadingSnackbar, closeLoadingSnackbar, openSnackbar } = useSnackbar();

	const [boardingChecklist, setBoardingChecklist] = useState<ChecklistElementBoarding[]>([] as ChecklistElementBoarding[]);
	const [boardingNotes, setBoardingNotes] = useState<string>("");
	const [boardingImages, setBoardingImages] = useState<File[]>([] as File[]);
	const [warnings, setBoardingWarnings] = useState<WarningChecklistBoat[]>([] as WarningChecklistBoat[]);

	const handleSelfCheckboat = async () => {
		for (let i = 0; i < mnemonic.length; i++) {
			if (mnemonic[i] === "") {
				throw new Error(t("errorsMnemonicMissing")!);
			}
		}

		openLoadingSnackbar();
		await submitUserSelfBoarding({
			orderUID: order.orderUID,
			mnemonic: mnemonic,
			images: boardingImages,
			checkList: boardingChecklist,
			notes: boardingNotes,
			warnings: warnings,
		});
		closeLoadingSnackbar();
		openSnackbar(t("success"), t("thanks-self-checkin"), "success");
	};

	const handleCheckboat = async () => {
		for (let i = 0; i < warnings.length; i++) {
			if (warnings[i].boatCheckChecked == null || !warnings[i].boatCheckChecked) {
				throw new Error(t("must-accept-all-warnings") as string);
			}
		}
		for (let i = 0; i < mnemonic.length; i++) {
			if (mnemonic[i] === "") {
				throw new Error(t("errorsMnemonicMissing")!);
			}
		}

		openLoadingSnackbar();
		await submitBoatCheckForUser({
			orderUID: order.orderUID,
			warnings: warnings,
			mnemonic: mnemonic.join(" "),
		});
		closeLoadingSnackbar();
	};

	const handleOpenSelfSignDialog = () => {
		try {
			boardingChecklist
				.filter((el) => el.inputType === "text")
				.forEach((el) => {
					if (el.textChoice === undefined || el.textChoice === null || el.textChoice.length === 0)
						throw new Error(
							`${t("error-missing-field-checklist-1")} "${el.name[language as keyof TranslationType]}" ${t("error-missing-field-checklist-2")} "${
								el.category === "inventory" ? t("inventory") : t("control-of-function")
							}" ${t("error-missing-field-checklist-3")}`
						);
				});

			boardingChecklist.forEach((element) => {
				if (element.inputType === "binary" && element.binaryChoice !== null && element.binaryChoice !== undefined && element.binaryChoice === false) {
					openSnackbar(t("warning"), t("error-self-sign"), "warning");
				}
			});
			for (let i = 0; i < warnings.length; i++) {
				if (warnings[i].boatCheckChecked == null || !warnings[i].boatCheckChecked) {
					throw new Error(t("must-accept-all-warnings") as string);
				}
			}
			setIsDialogForSelfSignOpen(true);
		} catch (err) {
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		}
	};

	const createNewWallet = async (): Promise<string[] | undefined> => {
		if (!user) return;
		if (userProfile != null && userProfile.wallet != null) {
			if (order.checkBoat?.unlockUserAutoCheckin) {
				handleOpenSelfSignDialog();
			} else {
				setIsOpenPopUpInsertionMnemonic(true);
			}
			return;
		}
		openLoadingSnackbar();
		const rawMnemonic = await getUserMnemonic();
		setMnemonic([rawMnemonic[0], rawMnemonic[1]]);
		closeLoadingSnackbar();

		setShowCredentialsOpen(true);

		return mnemonic;
	};

	const getUserMnemonic = async () => {
		let rawMnemonic = "";
		let mnemonic: string[] = [];
		try {
			rawMnemonic = await createWallet();
			mnemonic = rawMnemonic.split(" ");
		} catch (error) {
			const message = (error as any).message;
			console.error(message);
		}
		return [mnemonic[mnemonic.length - 2], mnemonic[mnemonic.length - 1]];
	};

	const handleClosePopupCredentials = () => {
		setShowCredentialsOpen(false);
	};
	const handleClosePopupInsertionMnemonic = () => {
		setIsOpenPopUpInsertionMnemonic(false);
	};
	const handleClosePopupSelfSign = () => {
		setIsDialogForSelfSignOpen(false);
	};
	return (
		<>
			<PopUpCredentials mnemonic={mnemonic} isPopUpCrendentialsOpen={showCredentialsOpen} handleClosePopupCredentials={handleClosePopupCredentials} />
			<PopUpMnemonicInsertion
				mnemonic={mnemonic}
				isPopUpOpen={isOpenPopUpInsertionMnemonic}
				handleClosePopup={handleClosePopupInsertionMnemonic}
				setMnemonic={setMnemonic}
				handleSubmit={handleCheckboat}
			/>
			<PopUpMnemonicInsertion
				mnemonic={mnemonic}
				isPopUpOpen={isDialogForSelfSignOpen}
				handleClosePopup={handleClosePopupSelfSign}
				setMnemonic={setMnemonic}
				handleSubmit={handleSelfCheckboat}
			/>

			<Grid container p={1}>
				{isBoarding ? (
					<>
						<Grid item xs={12}>
							<UserCheckShowCheckList
								order={order}
								boardingChecklist={boardingChecklist}
								boardingNotes={boardingNotes}
								boardingImages={boardingImages}
								boardingWarnings={warnings}
								setBoardingChecklist={setBoardingChecklist}
								setBoardingNotes={setBoardingNotes}
								setBoardingImages={setBoardingImages}
								setBoardingWarnings={setBoardingWarnings}
							/>
							{order.checkBoat?.status !== checkboatStatuses.COUNTER_SIGNED ? (
								<>
									<Typography variant="h4" marginTop="30px">
										{t("user-check-request-firm-title")}
									</Typography>
									<Typography variant="h5">{t("user-check-request-firm")}</Typography>
									<Grid display="flex" marginTop="24px">
										<Button className="form-button" sx={{ fontSize: "16px !important" }} onClick={createNewWallet}>
											{t("user-check-request-firm-button")}
										</Button>
									</Grid>
								</>
							) : (
								<>
									<Typography variant="h5" marginTop="20px">
										{t("checklist-accepted")}
									</Typography>
								</>
							)}
						</Grid>
					</>
				) : (
					<Grid xs={12}>{t("user-check-not-available")}</Grid>
				)}
			</Grid>
		</>
	);
};
