export const responseMessages = {
	submitCheckoutForBoat: {
		success: "Checkout sent successfully",
		errors: {
			mnemonicMissing: "mnemonic is null or undefined when sending checkout for boat",
			imagesMissing: "images is null or undefined when sending checkout for boat",
			checklistMissing: "checkList is null or undefined when sending checkout for boat",
			invalidMnemonic: "invalid mnemonic",
		},
	},
	submitCheckoutForUser: {
		success: "Checkout sent successfully",
		errors: {
			mnemonicMissing: "mnemonic is null or undefined when sending checkout for user",
			walletNotFound: "Create a wallet for the user before sending the checkout",
			invalidMnemonic: "invalid mnemonic",
		},
	},
	submitCheckBoatForUser: {
		success: "Checkboat sent successfully",
		errors: {
			mnemonicMissing: "Mnemonic is required",
			walletNotFound: "Create a wallet for the user before sending the checkboat",
			invalidMnemonic: "invalid mnemonic",
		},
	},
	submitCheckBoatForBoat: {
		success: "Checkboat sent successfully",
		errors: {
			mnemonicMissing: "Mnemonic is required",
			checkListMissing: "Checklist is required",
			imagesMissing: "Images are required",
			invalidMnemonic: "invalid mnemonic",
		},
	},
	createWallet: {
		success: "wallet created",
		errors: {
			uidNotDefined: "uid is not defined",
			alreadyHasWallet: "User already has a wallet",
		},
	},
	addFeedback: {
		success: "Feedback added successfully",
		errors: {
			missingRatings: "Missin ratings",
			invalidRatings: "Invalid rating value",
		},
	},
};
