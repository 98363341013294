import { Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../providers/AuthProvider";
import { useOrders } from "../../../providers/OrderProvider";
import { Order } from "../../../../types/documents/Order";
import { useState } from "react";
import { PopUpCredentials } from "../../../popUps/PopUpCredentials";
import { PopUpMnemonicInsertion } from "../../../popUps/PopUpMnemonicInsertion";
import { checkboatStatuses } from "../../../../utils/constants";
import { useSnackbar } from "../../../providers/SnackbackProvider";
import { WarningChecklistBoat } from "../../../../types/forms/Checklist";
import { UserCheckoutShowCheckList } from "./UserCheckoutShowChecklist";

export const UserCheckoutContent = ({
	order,
	isBoarding,
}: {
	order: Order;
	isBoarding: boolean;
}) => {
	const { t } = useTranslation();
	const { user } = useAuth();
	const { submitCheckoutUser } = useOrders();
	const [mnemonic, setMnemonic] = useState<string[]>(Array.from({ length: 2 }, () => ""));
	const [showCredentialsOpen, setShowCredentialsOpen] = useState<boolean>(false);
	const [isOpenPopUpInsertionMnemonic, setIsOpenPopUpInsertionMnemonic] = useState<boolean>(false);
	const { openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();

	const [checkoutWarnings, setCheckoutWarnings] = useState<WarningChecklistBoat[]>([] as WarningChecklistBoat[]);
	

	const handleCheckboat = async () => {
		for (let i = 0; i < checkoutWarnings.length; i++) {
			if (checkoutWarnings[i].checkoutChecked == null || !checkoutWarnings[i].checkoutChecked) {
				throw new Error(t("must-accept-all-warnings") as string);
			}
		}
		for (let i = 0; i < mnemonic.length; i++) {
			if (mnemonic[i] === "") {
				throw new Error(t("insert-all-12-words-of-secret-key") as string);
			}
		}
		openLoadingSnackbar();
		await submitCheckoutUser({
			orderUID: order.orderUID,
			warnings: checkoutWarnings,
			mnemonic: mnemonic.join(" "),
		});
		closeLoadingSnackbar();
	};

	const createNewWallet = async (): Promise<void> => {
		if (!user) return;

		setIsOpenPopUpInsertionMnemonic(true);
	};

	const handleClosePopupCredentials = () => {
		setShowCredentialsOpen(false);
	};
	const handleClosePopupInsertionMnemonic = () => {
		setIsOpenPopUpInsertionMnemonic(false);
	};

	return (
		<>
			<PopUpCredentials mnemonic={mnemonic} isPopUpCrendentialsOpen={showCredentialsOpen} handleClosePopupCredentials={handleClosePopupCredentials} />
			<PopUpMnemonicInsertion
				mnemonic={mnemonic}
				isPopUpOpen={isOpenPopUpInsertionMnemonic}
				handleClosePopup={handleClosePopupInsertionMnemonic}
				setMnemonic={setMnemonic}
				handleSubmit={handleCheckboat}
			/>
			<Grid container p={1}>
				{isBoarding ? (
					<>
						<Grid item xs={12}>
							<UserCheckoutShowCheckList
								order={order}
								setCheckoutWarnings={setCheckoutWarnings}
							/>
							{order.checkout?.status !== checkboatStatuses.COUNTER_SIGNED ? (
								<>
									<Typography variant="h4" marginTop="30px">{t("user-check-request-firm-title")}</Typography>
									<Typography variant="h5">{t("user-check-request-firm")}</Typography>
									<Grid display="flex" marginTop="24px">
										<Button className="form-button" sx={{ fontSize: "16px !important" }} onClick={createNewWallet}>
											{t("user-check-request-firm-button")}
										</Button>
									</Grid>
								</>
							) : (
								<>
									<Typography variant="h5" marginTop="20px">{t("checklist-accepted")}</Typography>
								</>
							)}
						</Grid>
					</>
				) : (
					<>
						<Grid xs={12}>{t("user-check-out-not-available")}</Grid>
					</>
				)}
			</Grid>
		</>
	);
};
