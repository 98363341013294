import { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { BoatMenu } from "./BoatMenu";
import { useOrders } from "../../components/providers/OrderProvider";
import { BoatOrder } from "../../components/boat/orders/BoatOrder";
import { OrderCreationPopUp } from "../../components/boat/orders/OrderCreationPopUp";
import { Order, OrderCreationParams } from "../../types/documents/Order";
import { UserCredentialsPopUp } from "../../components/boat/orders/popup/UserCredentialsPopUp";
import { useSnackbar } from "../../components/providers/SnackbackProvider";
import { TranslationType } from "../../types/Generic";
import { useUserProvider } from "../../components/providers/UserProviderImpl";
import { useBoatProvider } from "../../components/providers/BoatProvider";
import { dateFormat } from "../../utils/dateOperation";
import { useTranslationProvider } from "../../components/providers/TranslationProvider";
import { generateMessageOrderCreated } from "../../utils/createMailMessages";

export const BoatOrders = () => {
	const { boatOrders, futureOrders, createOrder, sendEmailAfterOrderCreation } = useOrders();
	const { getUserFromUid } = useUserProvider();
	const { getBoatFromUID } = useBoatProvider();
	const { language } = useTranslationProvider();

	const [expanded, setExpanded] = useState<string | false>(false);
	const [openOrderCreationPopup, setOpenOrderCreationPopup] = useState<boolean>(false);
	const [openUserCredentialsPopup, setOpenUserCredentialsPopup] = useState<boolean>(false);
	const [userEmail, setUserEmail] = useState<string>("");
	const [userPassword, setUserPassword] = useState<string>("");
	const [order, setOrder] = useState<Order>({} as Order);
	const { openSnackbar, openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();

	const handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};
	const handleCloseOrderCreationPopup = () => {
		setOpenOrderCreationPopup(false);
	};
	const handleCloseUserCredentialsPopup = () => {
		setOpenUserCredentialsPopup(false);
	};
	const checkIfOrderOverlapsAnoterOrder = (orderParams: OrderCreationParams) => {
		const newOrderStartDate = new Date(orderParams.startDate * 1000);
		newOrderStartDate.setHours(23, 59, 59, 0); // need to set the time to 23:59:59 to check the start dates
		const newOrderStartTime = Math.round(newOrderStartDate.getTime() / 1000);

		for (let i = 0; i < boatOrders.length; i++) {
			if (boatOrders[i].startDate <= newOrderStartTime && newOrderStartTime < boatOrders[i].endDate) {
				throw new Error("La data di imbarco dell'ordine che stai creando si sovrappone ad un altro ordine");
			}

			if (boatOrders[i].startDate <= orderParams.endDate && orderParams.endDate <= boatOrders[i].endDate) {
				throw new Error("La data di sbarco dell'ordine che stai creando si sovrappone ad un altro ordine");
			}

			if (newOrderStartTime <= boatOrders[i].startDate && boatOrders[i].startDate <= orderParams.endDate) {
				throw new Error("L'ordine che stai creando si sovrappone ad un altro ordine");
			}
		}
	};

	const handleAddOrder = async (orderParams: OrderCreationParams) => {
		try {
			checkIfOrderOverlapsAnoterOrder(orderParams);
			openLoadingSnackbar();
			const result = await createOrder(orderParams);
			setOrder(result.order);
			if (result.isUserNew) {
				if (result.userRecord == null) throw new Error("UserRecord is null");
				setUserPassword(result.password as string);
				setUserEmail(result.userRecord.email as string);
				setOpenUserCredentialsPopup(true);
			} else {
				const { message, subject } = await createCredentialsMessage(language as keyof TranslationType, false, result.order);
				await sendEmailAfterOrderCreation(orderParams.userEmail, message, subject);
			}
			openSnackbar("Successo", "Ordine creato con successo", "success");
			closeLoadingSnackbar();
			setOpenOrderCreationPopup(false);
		} catch (err) {
			closeLoadingSnackbar();
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
			console.error(err);
		}
	};

	const handleSendCredentials = async (language: keyof TranslationType) => {
		try {
			openLoadingSnackbar();
			const { message, subject } = await createCredentialsMessage(language as keyof TranslationType, true, order);
			await sendEmailAfterOrderCreation(userEmail, message, subject);
			openSnackbar("Successo", "Credenziali inviate con successo", "success");
			handleCloseUserCredentialsPopup();
		} catch (err) {
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		} finally {
			closeLoadingSnackbar();
		}
	};

	const createCredentialsMessage = async (language: keyof TranslationType, isUserNew: boolean, order: Order) => {
		const user = await getUserFromUid(order.userUID);
		const boat = await getBoatFromUID(order.boatUID);
		return generateMessageOrderCreated({ user, boat, order, language, isUserNew, newEmail: userEmail, newPassword: userPassword });
	};

	return (
		<>
			<UserCredentialsPopUp
				mail={userEmail}
				password={userPassword}
				isPopUpOpen={openUserCredentialsPopup}
				handleClosePopup={handleCloseUserCredentialsPopup}
				handleSendCredentials={handleSendCredentials}
			/>
			<OrderCreationPopUp isPopUpOpen={openOrderCreationPopup} handleClosePopup={handleCloseOrderCreationPopup} handleAddOrder={handleAddOrder} />
			<Grid container flexWrap="nowrap">
				<Grid item height="100%" className="menu-container">
					<BoatMenu />
				</Grid>
				<Grid item xs className="menu-content">
					<Grid container rowGap="16px">
						<Grid item xs={12} marginBottom="5px">
							<Grid container justifyContent="space-between">
								<Grid item xs={12} sm={3}>
									<Typography variant="h3"> Ordini</Typography>
								</Grid>
								<Grid item xs={12} sm={9} display="flex" justifyContent="flex-end">
									<Button
										className="form-button"
										onClick={() => {
											setOpenOrderCreationPopup(true);
										}}>
										CREA UN ORDINE
									</Button>
								</Grid>
							</Grid>
						</Grid>

						{futureOrders.map((value, index) => {
							return (
								<Grid item xs={12} marginY="2px" key={`order-index-${index}`}>
									<BoatOrder order={value} index={index} expanded={expanded} handleChangeAccordion={handleChangeAccordion} />
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
