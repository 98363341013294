import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useAuth } from "../../providers/AuthProvider";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import { Order } from "../../../types/documents/Order";
import { CrewListMemberInOrder } from "../../../types/CrewListMemberInOrder";
import { capitilizeFirstLetter } from "../../../utils/stringOperation";
import { ExtraElementInOrder, ExtraElementInOrderKeys } from "../../../types/ExtraElementInOrder";
import { AddCrewListMemberPopUp } from "../../common/popup/AddCrewListMemberPopUp";
import { AddCrewMemberParams } from "../../../types/forms/CrewMemeber";
import { EliminateOrderPopUp } from "./popup/EliminateOrder";
import { orderStatuses, useOrders } from "../../providers/OrderProvider";
import { ChangeBoatPopUp } from "./popup/ChangeBoatPopUp";
import { RejectCheckInPopUp } from "./popup/RejectCheckInPopUp";
import { DocumentsCheckInPopUp } from "../../common/popup/DocumentsCheckInPopUp";
import { checkboatStatuses, paymentStatuses } from "../../../utils/constants";
import { sortExtras } from "../../../utils/sorting";
import { OrderToDelete } from "../../../types/orders/OrderToDelete";
import { OrderExtra } from "../../user/orders/OrderExtra";
import { useSnackbar } from "../../providers/SnackbackProvider";
import { DocumentToUpload } from "../../../types/forms/Documents";
import { useTranslationProvider } from "../../providers/TranslationProvider";
import { TranslationType } from "../../../types/Generic";

const CheckInDocuments = ({
	orderUID,
	documents,
	orderStatus,
	documentsToUpload,
	setDocumentsToUpload,
	setDocuments,
	imagesIdentityCardInStorage,
	imagesNauticLicenseInStorage,
}: {
	orderUID: string;
	documents: Order["documents"];
	orderStatus: string;
	documentsToUpload: DocumentToUpload[];
	setDocumentsToUpload: Dispatch<SetStateAction<DocumentToUpload[]>>;
	setDocuments: Dispatch<SetStateAction<Order["documents"]>>;
	imagesIdentityCardInStorage: boolean;
	imagesNauticLicenseInStorage: boolean;
}) => {
	const [nauticLicenseUploaded, setNauticLicenseUploaded] = useState<boolean>(false);
	const [identityCardUploaded, setIdentitycardUploaded] = useState<boolean>(false);

	const [nauticLicensePopUpOpen, setNauticLicensePopUpOpen] = useState<boolean>(false);
	const [identityCardPopUpOpen, setIdentityCardPopUpOpen] = useState<boolean>(false);
	const { downlaodDocumentOfOrder, uploadDocuments, removeDocument, updateDocuments } = useOrders();
	const { openLoadingSnackbar, closeLoadingSnackbar, openSnackbar } = useSnackbar();
	useEffect(() => {
		if (
			documents == null ||
			documents.length === 0 ||
			documents[0] == null ||
			documents[0].nauticLicenseImg == null ||
			documents[0].identityCardImg == null
		) {
			setNauticLicenseUploaded(false);
			setIdentitycardUploaded(false);
			return;
		}

		if (
			(documents[0].nauticLicenseImg.front == null || documents[0].nauticLicenseImg.front === "") &&
			(documents[0].nauticLicenseImg.back == null || documents[0].nauticLicenseImg.back === "")
		)
			setNauticLicenseUploaded(false);
		else setNauticLicenseUploaded(true);

		if (
			(documents[0].identityCardImg.front == null || documents[0].identityCardImg.front === "") &&
			(documents[0].identityCardImg.back == null || documents[0].identityCardImg.back === "")
		)
			setIdentitycardUploaded(false);
		else setIdentitycardUploaded(true);
	}, [documents]);
	const handleDownloadNauticLicense = async (name: string) => {
		try {
			openLoadingSnackbar();
			await downlaodDocumentOfOrder(orderUID, "nauticLicenseImg", name);
			closeLoadingSnackbar();
		} catch (err) {
			closeLoadingSnackbar();

			console.error(err);
		}
	};
	const handleUploadNauticLicense = async (name: string, file: File, isFront: boolean, index: number) => {
		try {
			openLoadingSnackbar();
			const newDocuments: Order["documents"] = [...documents];
			const newDocumentsToUpload: DocumentToUpload[] = [...documentsToUpload];
			const nameToUpload = `skipper_Nautic_License_${index + 1}_${isFront ? "front" : "back"}.${
				file.name.split(".")[file.name.split(".").length - 1]
			}`;
			const documentToUpload: DocumentToUpload = {
				name: nameToUpload,
				file: file,
				type: "nauticLicenseImg",
				isFront: isFront,
			};
			await uploadDocuments(orderUID, documentToUpload.file, documentToUpload.name, documentToUpload.type, documentToUpload.isFront);
			newDocumentsToUpload.push(documentToUpload);
			newDocuments[index].nauticLicenseImg[isFront ? "front" : "back"] = nameToUpload;
			setDocuments(newDocuments);
			setDocumentsToUpload(newDocumentsToUpload);
			setNauticLicenseUploaded(true);
			await updateDocuments(orderUID, newDocuments);
		} catch (err) {
			console.error(err);
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		} finally {
			closeLoadingSnackbar();
		}
	};
	const handleDownloadidentityCard = async (name: string) => {
		try {
			openLoadingSnackbar();
			await downlaodDocumentOfOrder(orderUID, "identityCardImg", name);
			closeLoadingSnackbar();
		} catch (err) {
			closeLoadingSnackbar();

			console.error(err);
		}
	};
	const handleUploadidentityCard = async (name: string, file: File, isFront: boolean, index: number) => {
		try {
			openLoadingSnackbar();
			const newDocuments: Order["documents"] = [...documents];
			const newDocumentsToUpload: DocumentToUpload[] = [...documentsToUpload];
			const nameToUpload = `skipper_Identity_Card_${index + 1}_${isFront ? "front" : "back"}.${
				file.name.split(".")[file.name.split(".").length - 1]
			}`;
			const documentToUpload: DocumentToUpload = {
				name: nameToUpload,
				file: file,
				type: "identityCardImg",
				isFront: isFront,
			};
			await uploadDocuments(orderUID, documentToUpload.file, documentToUpload.name, documentToUpload.type, documentToUpload.isFront);
			newDocumentsToUpload.push(documentToUpload);
			newDocuments[index].identityCardImg[isFront ? "front" : "back"] = nameToUpload;
			setDocuments(newDocuments);
			setDocumentsToUpload(newDocumentsToUpload);
			setIdentitycardUploaded(true);
			await updateDocuments(orderUID, newDocuments);
		} catch (err) {
			console.error(err);
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		} finally {
			closeLoadingSnackbar();
		}
	};

	const handleDeleteNauticLicense = async (isFront: boolean, index: number) => {
		try {
			openLoadingSnackbar();
			const newDocuments: Order["documents"] = [...documents];
			const newDocumentsToUpload: DocumentToUpload[] = [...documentsToUpload];
			const nameOfDocumentToBeDeleted = newDocuments[index].nauticLicenseImg[isFront ? "front" : "back"];

			const indexOfElementToDeleteToUpload = newDocumentsToUpload.findIndex((document) => document.name === nameOfDocumentToBeDeleted);

			await removeDocument(orderUID, "nauticLicenseImg", nameOfDocumentToBeDeleted);

			newDocumentsToUpload.splice(indexOfElementToDeleteToUpload, 1);
			newDocuments[index].nauticLicenseImg[isFront ? "front" : "back"] = "";
			setDocuments([...newDocuments]);
			setDocumentsToUpload([...newDocumentsToUpload]);
			setNauticLicenseUploaded(false);
			await updateDocuments(orderUID, newDocuments);
		} catch (err) {
			console.error(err);
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		} finally {
			closeLoadingSnackbar();
		}
	};
	const handleDeleteIdentityCard = async (isFront: boolean, index: number) => {
		try {
			const newDocuments: Order["documents"] = [...documents];
			const newDocumentsToUpload: DocumentToUpload[] = [...documentsToUpload];

			const nameOfDocumentToBeDeleted = newDocuments[index].identityCardImg[isFront ? "front" : "back"];

			const indexOfElementToDeleteToUpload = newDocumentsToUpload.findIndex((document) => document.name === nameOfDocumentToBeDeleted);

			await removeDocument(orderUID, "identityCardImg", nameOfDocumentToBeDeleted);

			newDocumentsToUpload.splice(indexOfElementToDeleteToUpload, 1);

			newDocuments[index].identityCardImg[isFront ? "front" : "back"] = "";

			setDocuments([...newDocuments]);
			setDocumentsToUpload([...newDocumentsToUpload]);
			setIdentitycardUploaded(false);
			await updateDocuments(orderUID, newDocuments);
		} catch (err) {
			console.error(err);
		}
	};
	const handleNauticLicensePopUpClose = () => {
		setNauticLicensePopUpOpen(false);
	};
	const handleIdentityCardPopUpClose = () => {
		setIdentityCardPopUpOpen(false);
	};

	return (
		<>
			{documents != null && (
				<>
					<DocumentsCheckInPopUp
						orderStatus={orderStatus}
						documents={documents}
						orderUID={orderUID}
						documentType="nauticLicenseImg"
						isPopUpOpen={nauticLicensePopUpOpen}
						handleClosePopup={handleNauticLicensePopUpClose}
						handleDownload={handleDownloadNauticLicense}
						handleUpload={handleUploadNauticLicense}
						handleDelete={handleDeleteNauticLicense}
						isUser={false}
						imagesIdentityCardInStorage={imagesIdentityCardInStorage}
						imagesNauticLicenseInStorage={imagesNauticLicenseInStorage}
					/>
					<DocumentsCheckInPopUp
						orderStatus={orderStatus}
						documents={documents}
						orderUID={orderUID}
						documentType="identityCardImg"
						isPopUpOpen={identityCardPopUpOpen}
						handleClosePopup={handleIdentityCardPopUpClose}
						handleDownload={handleDownloadidentityCard}
						handleUpload={handleUploadidentityCard}
						handleDelete={handleDeleteIdentityCard}
						isUser={false}
						imagesIdentityCardInStorage={imagesIdentityCardInStorage}
						imagesNauticLicenseInStorage={imagesNauticLicenseInStorage}
					/>
				</>
			)}
			<Grid container rowGap={2}>
				<Grid item xs={12} sm={6}>
					<Grid container gap={2}>
						<Grid item xs={2} sm={4} md={3} xl={2}>
							<a
								onClick={() => {
									setNauticLicensePopUpOpen(true);
								}}>
								<div className="order-check-in-document-checkbox order-check-in-document-checkbox-uploaded">
									{nauticLicenseUploaded ? <CheckIcon className="check-in-document-icon" /> : <AddIcon className="check-in-document-icon" />}
								</div>
							</a>
						</Grid>
						<Grid item xs={8} sm={6} md={6} display="flex" flexDirection="column" gap={2} rowGap={1}>
							<Typography variant="h4">Patente Nautica</Typography>
							<Typography variant="body2">Clicca sulla foto per controllare la patente o le patenti nautiche degli skipper presenti</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Grid container gap={2}>
						<Grid item xs={2} sm={4} md={3} xl={2}>
							<a
								onClick={() => {
									setIdentityCardPopUpOpen(true);
								}}>
								<div className="order-check-in-document-checkbox order-check-in-document-checkbox-uploaded">
									{identityCardUploaded ? <CheckIcon className="check-in-document-icon" /> : <AddIcon className="check-in-document-icon" />}
								</div>
							</a>
						</Grid>
						<Grid item xs={8} sm={6} md={6} display="flex" flexDirection="column" gap={2} rowGap={1}>
							<Typography variant="h4">Documenti</Typography>
							<Typography variant="body2">Clicca sulla foto per controllare i documenti degli skipper presenti a bordo</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

const CrewList = ({
	orderStatus,
	crewlist,
	availableSeats,
	orderId,
	disabled,
	isPastOrder,
}: {
	orderStatus: string;
	crewlist: CrewListMemberInOrder[];
	availableSeats: number;
	orderId: string;
	disabled?: boolean;
	isPastOrder?: boolean;
}) => {
	const [openAddCrewMemberPopup, setOpenAddCrewMemberPopup] = useState<boolean>(false);
	const [crewMemberSelected, setCrewMemeberSelected] = useState<AddCrewMemberParams>({} as AddCrewMemberParams);
	const { addNewMemberToCrewList, removeCrewMember } = useOrders();
	const handleAddNewMemberToCrewList = async (crewMemeberParams: AddCrewMemberParams) => {
		try {
			const result = await addNewMemberToCrewList(crewMemeberParams, orderId);
		} catch (err) {
			console.error(err);
		}
	};
	const handleRemoveCrewMember = async (idNumber: string) => {
		try {
			await removeCrewMember({ orderUID: orderId, idNumber: idNumber });
		} catch (err) {
			console.error(err);
		}
	};
	const handleCloseCrewMemberPopup = () => {
		setOpenAddCrewMemberPopup(false);
	};
	return (
		<>
			<AddCrewListMemberPopUp
				isPopUpOpen={openAddCrewMemberPopup}
				handleClosePopup={handleCloseCrewMemberPopup}
				handleAddCrewMember={handleAddNewMemberToCrewList}
				disabled={disabled}
				crewMemeber={crewMemberSelected}
				handleRemoveCrewMember={handleRemoveCrewMember}
				isUser={false}
				isPastOrder={isPastOrder}
			/>

			<Grid container rowGap={2}>
				<Grid item xs={12}>
					<Typography variant="h4">Crewlist</Typography>
				</Grid>
				{Array.from({ length: availableSeats }, (_, index) => {
					let seatFilledClass = "";
					if (crewlist[index] != null) seatFilledClass = "order-checkbox-filled";
					return (
						<Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={`crewlist-seat-${index}`} display="flex" alignItems="center">
							<a
								onClick={() => {
									setCrewMemeberSelected(crewlist[index] as AddCrewMemberParams);
									setOpenAddCrewMemberPopup(true);
								}}>
								<div className={`order-checkbox ${seatFilledClass}`}>
									{seatFilledClass === "" ? <AddIcon className="checkbox-icon " /> : <CheckIcon className="checkbox-icon" />}
								</div>
							</a>

							<Typography variant="body1" marginLeft="8px">
								{crewlist[index] != null ? (
									<>
										{capitilizeFirstLetter(crewlist[index].name)} {capitilizeFirstLetter(crewlist[index].surname)}
										{crewlist[index].isSkipper && <>(skipper)</>}
									</>
								) : (
									<>Posto disponibile</>
								)}
							</Typography>
						</Grid>
					);
				})}
			</Grid>
		</>
	);
};

const CheckArticles = ({
	articles,
	article34Enabled,
	article39Enabled,
}: {
	articles: Order["articles"];
	article34Enabled: boolean;
	article39Enabled: boolean;
}) => {
	return (
		<>
			<Grid container rowGap={2}>
				<Grid item xs={12}>
					<Typography variant="h4">Articoli da sottoscrivere</Typography>
				</Grid>
				{article34Enabled && (
					<Grid item xs={12} display="flex">
						<div className={`order-checkbox ${articles[34] ? "order-checkbox-filled" : ""}`}>
							{!articles[34] ? <></> : <CheckIcon className="checkbox-icon" />}
						</div>
						<div style={{ marginLeft: "8px", width: "100%" }}>
							<Typography variant="body1" sx={{ textDecoration: "underline", minHeight: "40px", display: "flex", alignItems: "center" }}>
								Accetto l’art. 34 Comando di unità da diporto da parte di stranieri in acque territoriali italiane
							</Typography>
							<Typography variant="body1" whiteSpace="pre-wrap" marginTop="8px">
								{
									"1. Gli stranieri e i cittadini italiani residenti all'estero, muniti di un titolo di abilitazione o documento riconosciuto equipollente dallo Stato di appartenenza o, rispettivamente, di residenza, possono comandare, purché a titolo gratuito, imbarcazioni e navi da diporto iscritte nei registri di cui all'articolo 15 del codice e natanti da diporto di cui all'articolo 27 del codice entro i limiti dell'abilitazione medesima. Il titolo o documento deve essere tenuto a bordo.\n2. Per gli stranieri ed i cittadini italiani residenti all'estero che comandano imbarcazioni e navi da diporto iscritte in registri stranieri, l'obbligo di patente nautica è regolato dalla legge dello Stato di bandiera dell'unità.\n3. Per i cittadini di Stati membri dell'Unione europea si prescinde dall'obbligo del titolo per comandare le unità da diporto di cui ai commi 1 e 2 del presente articolo, qualora esibiscano una dichiarazione rilasciata dalle proprie autorità da cui risulti che la legislazione, rispettivamente, del Paese di provenienza del soggetto o dello Stato di bandiera dell'unità non prevede il rilascio di alcun titolo di abilitazione.\n"
								}
							</Typography>
						</div>
					</Grid>
				)}
				{article39Enabled && (
					<Grid item xs={12} display="flex">
						<div className={`order-checkbox ${articles[39] ? "order-checkbox-filled" : ""}`}>
							{!articles[39] ? <></> : <CheckIcon className="checkbox-icon" />}
						</div>
						<div style={{ marginLeft: "8px", width: "100%" }}>
							<Typography variant="body1" sx={{ textDecoration: "underline", height: "40px", display: "flex", alignItems: "center" }}>
								Accetto l’art. 39
							</Typography>
							<Typography variant="body1" whiteSpace="pre-wrap">
								{
									"1. La patente nautica per unità da diporto di lunghezza non superiore a ventiquattro metri è obbligatoria nei seguenti casi, in relazione alla navigazione effettivamente svolta: \na) per la navigazione oltre le sei miglia dalla costa o, comunque, su moto d'acqua; \nb) per la navigazione nelle acque interne e per la navigazione nelle acque marittime entro sei miglia dalla costa, quando a bordo dell'unità è installato un motore di cilindrata superiore a 750 cc se a carburazione a due tempi ovvero a 900 cc se a iniezione a due tempi(1), o a 1.000 cc se a carburazione o a iniezione a quattro tempi fuori bordo, o a 1.300 cc se a carburazione o a iniezione a quattro tempi entro bordo, o a 2.000 cc se a ciclo diesel non sovralimentato, o a 1.300 cc se a ciclo diesel sovralimentato, comunque con potenza superiore a 30 kW o a 40,8 CV. \n2. Chi assume il comando di una unità da diporto di lunghezza superiore ai ventiquattro metri, deve essere in possesso della patente per nave da diporto."
								}
							</Typography>
						</div>
					</Grid>
				)}
			</Grid>
		</>
	);
};

const Deposit = ({ paymentStatus }: { paymentStatus: Order["paymentStatus"] }) => {
	return (
		<>
			<Grid container rowGap={2}>
				<Grid item xs={12}>
					<Typography variant="h4">Cauzione</Typography>
				</Grid>
				<Grid item xs={12}>
					{paymentStatus == paymentStatuses.PAYMENT_COMPLETED ? (
						<Typography variant="h4" fontSize="18px !important">
							Pagata
						</Typography>
					) : (
						<Typography variant="h4" fontSize="18px !important">
							Non pagata
						</Typography>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export const BoatOrderCheckInContent = ({ order, disabled }: { order: Order; disabled?: boolean }) => {
	const [isPopUpEliminateOrderOpen, setIsPopUpEliminateOrderOpen] = useState<boolean>(false);
	const [isPopUpChangeBoatOpen, setIsPopUpChangeBoatOpen] = useState<boolean>(false);
	const [isPopUpRejectOrderOpen, setIsPopUpRejectOrderOpen] = useState<boolean>(false);
	const [extras, setExtras] = useState<ExtraElementInOrder[]>(order.extras);
	const [article34Enabled, setArticle34Enabled] = useState<boolean>(false);
	const [article39Enabled, setArticle39Enabled] = useState<boolean>(false);
	const [documentsToUpload, setDocumentToUpload] = useState<DocumentToUpload[]>([]);
	const [documents, setDocuments] = useState<Order["documents"]>({} as Order["documents"]);
	const [imagesNauticLicenseInStorage, setImagesNauticLicenseInStorage] = useState<boolean>(false);
	const [imagesIdentityCardInStorage, setImagesIdentityCardInStorage] = useState<boolean>(false);

	const { deleteOrder, changeBoat, denyOrder, acceptOrder, modifyExtras } = useOrders();
	const { openLoadingSnackbar, closeLoadingSnackbar, openSnackbar } = useSnackbar();
	const { language } = useTranslationProvider();

	const handleClosePopUpEliminateOrder = () => {
		setIsPopUpEliminateOrderOpen(false);
	};
	const handleEliminateOrder = async () => {
		try {
			setIsPopUpEliminateOrderOpen(false);
			const result = await deleteOrder(order.orderUID);
		} catch (err) {
			console.error(err);
		}
	};
	const handleClosePopUpChangeBoat = () => {
		setIsPopUpChangeBoatOpen(false);
	};
	const handleChangeBoat = async (boatName: string) => {
		try {
			const result = await changeBoat(order.orderUID, boatName);
			setIsPopUpChangeBoatOpen(false);
		} catch (err) {
			console.error(err);
		}
	};
	const handleClosePopupRejectOrder = () => {
		setIsPopUpRejectOrderOpen(false);
	};
	const handleRejectCheckIn = async (
		reason: string,
		sectionsToBeReset: OrderToDelete["sectionsToBeReset"],
		articlesToBeEnabled: OrderToDelete["articlesToBeEnabled"]
	) => {
		try {
			const orderToDelete: OrderToDelete = {
				orderUID: order.orderUID,
				reason,
				userUID: order.userUID,
				extras: order.extras,
				sectionsToBeReset,
				articlesToBeEnabled,
				documents: order.documents,
			};
			await denyOrder(orderToDelete, language as keyof TranslationType);
			setIsPopUpRejectOrderOpen(false);
		} catch (err) {
			console.error(err);
		}
	};
	const handleAcceptCheckIn = async () => {
		try {
			await acceptOrder(order, language as keyof TranslationType);
		} catch (err) {
			console.error(err);
		}
	};
	const modifyExtraBoat = async () => {
		extras.sort(sortExtras).map((extra, index) => {
			Object.keys(extra).forEach((key: string) => {
				if (extra[key as ExtraElementInOrderKeys] === undefined) {
					delete extra[key as ExtraElementInOrderKeys];
				}
			});
		});

		await modifyExtras(order.orderUID, extras);
	};
	const changeCostAndTypeExtra = async ({
		extra,
		newCost,
		newType,
	}: {
		extra: ExtraElementInOrder;
		newCost: number;
		newType: ExtraElementInOrder["type"];
	}) => {
		const newExtras = [...extras];
		const index = newExtras.findIndex((e) => e.name.en === extra.name.en);

		if (index === -1) {
			openSnackbar("Errore", "C'è stato un problema con la modifica dell'extra", "error");
			return;
		}

		const newExtra = { ...newExtras[index] };

		if (newExtra.oldCost === undefined) newExtra.oldCost = newExtra.cost;
		if (newExtra.oldType === undefined) newExtra.oldType = newExtra.type;

		if (newExtra.oldCost === newCost && newExtra.oldType === newType) {
			newExtra.oldCost = undefined;
			newExtra.oldType = undefined;
		}

		newExtra.cost = newCost;
		newExtra.type = newType;

		newExtras[index] = newExtra;

		newExtras.sort(sortExtras).map((extra, index) => {
			Object.keys(extra).forEach((key: string) => {
				if (extra[key as ExtraElementInOrderKeys] === undefined) {
					delete extra[key as ExtraElementInOrderKeys];
				}
			});
		});

		openLoadingSnackbar();
		await modifyExtras(order.orderUID, newExtras);
		closeLoadingSnackbar();

		setExtras(newExtras);
	};
	useEffect(() => {
		setExtras(order.extras);
		if (order.articles.enabled34) {
			setArticle34Enabled(true);
		}
		if (order.articles.enabled39) {
			setArticle39Enabled(true);
		}
		setDocuments(order.documents);
		order.documents.map((skipper) => {
			if (skipper.identityCardImg.front !== "" || skipper.identityCardImg.back !== "") {
				setImagesIdentityCardInStorage(true);
			}
			if (skipper.nauticLicenseImg.front !== "" || skipper.nauticLicenseImg.back !== "") {
				setImagesNauticLicenseInStorage(true);
			}
		});
	}, [order]);

	return (
		<>
			<EliminateOrderPopUp
				isPopUpOpen={isPopUpEliminateOrderOpen}
				handleClosePopup={handleClosePopUpEliminateOrder}
				handleEliminateOrder={handleEliminateOrder}
			/>
			<ChangeBoatPopUp isPopUpOpen={isPopUpChangeBoatOpen} handleClosePopup={handleClosePopUpChangeBoat} handleChangeBoat={handleChangeBoat} />
			<RejectCheckInPopUp
				isPopUpOpen={isPopUpRejectOrderOpen}
				handleClosePopup={handleClosePopupRejectOrder}
				handleRejectCheckIn={handleRejectCheckIn}
				articles={order.articles}
			/>
			<Grid container gap={2} rowGap={2}>
				{/**DOCUMENTS */}
				<Grid item xs={12}>
					<CheckInDocuments
						orderStatus={order.status}
						documents={documents}
						orderUID={order.orderUID}
						documentsToUpload={documentsToUpload}
						setDocuments={setDocuments}
						setDocumentsToUpload={setDocumentToUpload}
						imagesIdentityCardInStorage={imagesIdentityCardInStorage}
						imagesNauticLicenseInStorage={imagesNauticLicenseInStorage}
					/>
				</Grid>
				{/**CREWLIST */}
				<Grid item xs={12}>
					<CrewList
						orderStatus={order.status}
						crewlist={order.crewList}
						orderId={order.orderUID}
						availableSeats={order.availableSeats}
						disabled={disabled || order.checkBoat?.status !== checkboatStatuses.PENDING}
						isPastOrder={disabled}
					/>
				</Grid>
				{/**CHECK ARTICLES */}
				{(article34Enabled || article39Enabled) && (
					<Grid item xs={12}>
						<CheckArticles articles={order.articles} article34Enabled={article34Enabled} article39Enabled={article39Enabled} />
					</Grid>
				)}
				{/**EXTRAS */}
				<Grid item xs={12}>
					<OrderExtra
						isBoat={true}
						orderStatus={order.status}
						setExtras={setExtras}
						extras={extras}
						changeCostAndTypeExtra={changeCostAndTypeExtra}
						crewlistLenght={order.crewList.length}
						disabled={disabled}
						orderStartDate={order.startDate}
						orderEndDate={order.endDate}
					/>
				</Grid>
				{/**PAYMENT */}
				<Grid item xs={12}>
					<Deposit paymentStatus={order.paymentStatus} />
				</Grid>
				{/**SUBMITS */}
				{!disabled && (
					<Grid item xs={12}>
						<Grid container gap={2} rowGap={2} className="container-order-check-in-buttons">
							{order.status !== orderStatuses.ACCEPTED && (
								<>
									<Button className="form-button" fullWidth sx={{ maxWidth: "280px" }} onClick={handleAcceptCheckIn}>
										CONFERMA
									</Button>
									{order.status === orderStatuses.SENT && (
										<Button className="form-button order-check-in-button" onClick={() => setIsPopUpRejectOrderOpen(true)}>
											RIFIUTA
										</Button>
									)}
								</>
							)}

							<Button className="form-button order-check-in-button" onClick={() => setIsPopUpChangeBoatOpen(true)}>
								CAMBIA BARCA
							</Button>

							<Button className="form-button order-check-in-button" onClick={modifyExtraBoat}>
								MODIFICA DATI
							</Button>

							<Button className="form-button order-check-in-button" onClick={() => setIsPopUpEliminateOrderOpen(true)}>
								ELIMINA
							</Button>
						</Grid>
					</Grid>
				)}
			</Grid>
		</>
	);
};
