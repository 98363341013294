import { useRef, Dispatch, SetStateAction } from "react";
import { Dialog, DialogContent, DialogTitle, Grid, Typography, Button } from "@mui/material";

import "../../../styles/popup/CheckImagePopUp.scss";
import Close from "@mui/icons-material/Close";
const DisplayImage = ({
	image,
	handleDeleteImage,
	index,
	isUser,
	disabled,
	isAllowedToModifyChecklist,
}: {
	image: File;
	handleDeleteImage: (index: number) => void;
	index: number;
	isUser: boolean;
	disabled: boolean;
	isAllowedToModifyChecklist: boolean;
}) => {
	return (
		<>
			<Grid item xs={12} sx={{ position: "relative" }}>
				<img src={URL.createObjectURL(image)} alt="image" width="100%" height="100%" />
				{(!isUser || isAllowedToModifyChecklist) && !disabled && <a className="image-delete-button" onClick={() => handleDeleteImage(index)}></a>}
			</Grid>
		</>
	);
};

export const CheckImagePopUp = ({
	isPopUpOpen,
	handleClosePopup,
	isUser,
	images,
	setImages,
	disabled,
	isAllowedToModifyChecklist,
}: {
	isPopUpOpen: boolean;
	handleClosePopup: () => void;
	isUser: boolean;
	images: File[];
	setImages: Dispatch<SetStateAction<File[]>>;
	disabled: boolean;
	isAllowedToModifyChecklist: boolean;
}) => {
	const inputTakePictureRef = useRef<HTMLInputElement>(null);
	const inputChoosePictureRef = useRef<HTMLInputElement>(null);

	const handleAddImages = () => {
		try {
			if (inputTakePictureRef == null || inputTakePictureRef.current == null || inputTakePictureRef.current.files == null) return;
			const image = inputTakePictureRef.current?.files[0];
			const newImages = [...images];
			newImages.push(image);
			setImages(newImages);
		} catch (err) {
			console.error(err);
		}
	};
	const handleAddImageButtonClick = () => {
		if (inputTakePictureRef.current != null) inputTakePictureRef.current.click();
	};
	const handleChooseImageButtonClick = () => {
		if (inputChoosePictureRef.current != null) inputChoosePictureRef.current.click();
	};
	const handleChooseImage = () => {
		try {
			if (inputTakePictureRef == null || inputChoosePictureRef.current == null || inputChoosePictureRef.current.files == null) return;
			const image = inputChoosePictureRef.current?.files[0];
			const newImages = [...images];
			newImages.push(image);
			setImages(newImages);
		} catch (err) {
			console.error(err);
		}
	};

	const handleDeleteImage = (index: number) => {
		const newImages = [...images];
		newImages.splice(index, 1);
		setImages(newImages);
	};

	return (
		<>
			<Dialog open={isPopUpOpen} onClose={handleClosePopup} fullWidth maxWidth="md" className="pop-up-container">
				<DialogTitle mb={3} display="flex" justifyContent="space-between">
					<Typography variant="h4">Immagini</Typography>
					<Close className="close-icon" fontSize="large" onClick={handleClosePopup} color="error" />
				</DialogTitle>
				<DialogContent>
					<>
						<Grid container rowGap={4} justifyContent="center" alignItems="center" minHeight="380px">
							{images != null &&
								images.length > 0 &&
								images.map((image, index) => {
									return (
										<DisplayImage
											image={image}
											key={"image-" + index}
											index={index}
											handleDeleteImage={handleDeleteImage}
											disabled={disabled}
											isUser={isUser}
											isAllowedToModifyChecklist={isAllowedToModifyChecklist}
										/>
									);
								})}
							{(!isUser || isAllowedToModifyChecklist) && !disabled && (
								<>
									{/* ENABLE TAKE A PICTURE FROM MOBILE  */}
									{/Mobi|Android/i.test(navigator.userAgent) && (
										<>
											<input
												ref={inputTakePictureRef}
												id="input-take-picture"
												capture="environment"
												type="file"
												style={{ display: "none" }}
												onChange={handleAddImages}
												accept="image/*,application/pdf"
											/>
											<Grid item xs display="flex" justifyContent="center">
												<Button onClick={handleAddImageButtonClick} className="form-button">
													Scatta una foto
												</Button>
											</Grid>
										</>
									)}
									<input
										ref={inputChoosePictureRef}
										id="input-choose-picture"
										type="file"
										style={{ display: "none" }}
										onChange={handleChooseImage}
										accept="image/*,application/pdf"
									/>
									<Grid item xs display="flex" justifyContent="center">
										<Button onClick={handleChooseImageButtonClick} className="form-button">
											Scegli da galleria
										</Button>
									</Grid>
								</>
							)}
						</Grid>
					</>
				</DialogContent>
			</Dialog>
		</>
	);
};
