import { Button, Grid } from "@mui/material";
import { useAuth } from "../../providers/AuthProvider";
import { useTranslation } from "react-i18next";
import { useUserProvider } from "../../providers/UserProviderImpl";
import { useState } from "react";
import { DeleteAccountConfirmationDialog } from "./DeleteAccountConfirmationDialog";

export const DeleteAccount = () => {
	const [dialogConfirmOpen, setDialogConfirmOpen] = useState(false);
	const { userProfile } = useAuth();
	const { deleteAccount } = useUserProvider();
	const { t } = useTranslation();

	const handleDeleteAccount = async () => {
		if (userProfile) {
			await deleteAccount(userProfile.uid);
		}
	};
	
	return (
		<>
			<DeleteAccountConfirmationDialog open={dialogConfirmOpen} closeDialog={() => setDialogConfirmOpen(false)} confirmDeletion={() => handleDeleteAccount} />
			<Grid container justifyContent={"space-between"} rowGap={4} gap={2}>
				<Grid item xs={12} sx={{ marginTop: "16px" }}>
					<Grid container>
						<Grid item xs>
							<Button onClick={() => { setDialogConfirmOpen(true) }} className="form-button ">
								{t("delete-account")}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
