import { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useBoatProvider } from "../../components/providers/BoatProvider";
import { Boat } from "../../types/documents/Boat";
import { BoatChangePassword } from "../../components/admin/BoatChangePassword";
import { useAuth } from "../../components/providers/AuthProvider";

export const AdminPage = () => {
	const { getAllBoats } = useBoatProvider();
	const [boats, setBoats] = useState<Boat[]>([] as Boat[]);
	const [expanded, setExpanded] = useState<string | false>(false);
	const { logout } = useAuth();

	const handleChangeAccordion =
		(panel: string): ((event: React.SyntheticEvent, isExpanded: boolean) => void) =>
		(event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	useEffect(() => {
		(async () => {
			const boats = await getAllBoats();
			setBoats(boats);
		})();
	}, []);
	return (
		<>
			<Grid container justifyContent="center" rowGap={2} marginTop="50px">
				<Grid item xs={10}>
					<Typography variant="h3">MODIFICA PASSWORD BARCA</Typography>
				</Grid>
				<Grid item xs={10} display="flex">
					<Button onClick={logout} className="form-button">
						LOGOUT
					</Button>
				</Grid>
				<Grid item xs={8}>
					<Grid container rowGap="16px">
						{boats.map((boat, index) => {
							return (
								<>
									<Grid item xs={12} key={index} m="4px">
										<BoatChangePassword
											boat={boat}
											index={index}
											expanded={expanded}
											handleChangeAccordion={handleChangeAccordion}
										></BoatChangePassword>
									</Grid>
								</>
							);
						})}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
