import { useEffect, useState } from "react";
import { useAuth } from "../../components/providers/AuthProvider";
import { Grid, Button, TextField, Typography, IconButton, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import logo_gais from "../../assets/logo_gais.svg";

import "../../styles/Login.scss";
import { PopUpResetPassword } from "../../components/popUps/PopUpResetPassword";
import { useUserProvider } from "../../components/providers/UserProviderImpl";
import { ChangeLanguage } from "../../components/menu/ChangeLanguage";
import { getDownloadURL, ref } from "firebase/storage";
import { useFirebase } from "../../components/providers/FirebaseProvider";
import { useSnackbar } from "../../components/providers/SnackbackProvider";

export const LoginUser = () => {
	const [openResetPasswordPopup, setOpenResetPasswordPopup] = useState<boolean>(false);
	const [videoLogInURL, setVideoLogInURL] = useState<string>("");
	const { openLoadingSnackbar, closeLoadingSnackbar, openSnackbar } = useSnackbar();
	const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
	const [showPassword, setShowPassword] = useState<boolean>(false);

	const navigate = useNavigate();
	const { loginWithEmailAndPassword } = useAuth();
	const { t } = useTranslation();
	const { myStorage } = useFirebase();
	const { sendResetPasswordEmailToUser } = useUserProvider();

	const validationSchema = yup.object({
		email: yup
			.string()
			.email(t("enterValidEmail") as string)
			.required(t("emailRequired") as string),
		password: yup.string().required(t("passwordRequired") as string),
	});
	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},

		validationSchema: validationSchema,
		onSubmit: async (values) => await handleLogin(values),
	});

	const handleLogin = async (values: { email: string; password: string }) => {
		try {
			openLoadingSnackbar();
			const isAdmin = await loginWithEmailAndPassword(values.email, values.password);
			closeLoadingSnackbar();

			if (isAdmin) navigate("admin");
			else navigate("user/landing");
		} catch (err) {
			const error = err as Error;
			closeLoadingSnackbar();
			openSnackbar("ERROR", error.message, "error");
			console.error(err);
		}
	};

	const handleOpenResetPasswordPopup = () => {
		setOpenResetPasswordPopup(true);
	};
	const handleResetPassword = async (email: string) => {
		await sendResetPasswordEmailToUser(email);
	};
	const handleClosePopup = () => {
		setOpenResetPasswordPopup(false);
	};

	const changeFontSizeLoginTitle = (widthContainer: number) => {
		const divider = 5.5;
		const fontSize = Math.floor(widthContainer / divider);
		$("#login-title").css("font-size", `${fontSize}px`);
	};

	const documentHeight = () => {
		setWindowHeight(window.innerHeight);
	};
	useEffect(() => {
		(async () => {
			const videoLogInRef = ref(myStorage, `videos/Video_logIn.mov`);
			const url = await getDownloadURL(videoLogInRef);
			setVideoLogInURL(url);
		})();
		const resizeObserverLoginForm = new ResizeObserver((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
			window.requestAnimationFrame(() => {
				if (!Array.isArray(entries) || !entries.length) {
					return;
				}
			});
			entries.forEach((entry) => {
				if (entry.target.id === "login-form") {
					const timer = setTimeout(() => {
						changeFontSizeLoginTitle(entry.contentRect.width);
					}, 20);
				}
			});
		});
		resizeObserverLoginForm.observe(document.querySelector("#login-form") as HTMLElement);
		window.addEventListener("resize", documentHeight);
		return () => {
			resizeObserverLoginForm.disconnect();
		};
	}, []);

	return (
		<>
			<PopUpResetPassword isPopUpOpen={openResetPasswordPopup} handleClosePopup={handleClosePopup} handleResetPassword={handleResetPassword} />
			<Grid
				container
				sx={{ height: windowHeight + "px", width: "100%", overflow: "hidden" }}
				justifyContent={"center"}
				alignItems={"center"}
				className="login-container"
			>
				<img alt="gais logo" src={logo_gais} className="gais-logo"></img>
				<video autoPlay loop muted className="login-background" src={videoLogInURL}></video>
				<ChangeLanguage />

				<Grid item xs={10} lg={8} xl={7}>
					<form onSubmit={formik.handleSubmit}>
						<Grid container justifyContent={"center"} alignItems={"center"} rowGap="16px" id="login-form">
							<Grid item xs={12}>
								<Typography variant="h1" id="login-title">
									GET READY
								</Typography>
							</Grid>
							<Grid item xs={6} sm={7} id="email-input-field">
								<TextField
									sx={{ width: { xs: "100%" } }}
									className="input-form"
									id="email"
									name="email"
									label={t("writeYourEmail")}
									variant={"filled"}
									value={formik.values.email}
									onChange={formik.handleChange}
									error={formik.touched.email && Boolean(formik.errors.email)}
									helperText={formik.touched.email && formik.errors.email}
								/>
							</Grid>
							<Grid item xs={6} sm={5} paddingLeft="16px" id="password-input-field">
								<TextField
									sx={{ width: { xs: "100%" } }}
									className="input-form login-password-field"
									id="password"
									name="password"
									type={showPassword ? "text" : "password"}
									label={t("writeYourPassword")}
									variant={"filled"}
									value={formik.values.password}
									onChange={formik.handleChange}
									error={formik.touched.password && Boolean(formik.errors.password)}
									helperText={formik.touched.password && formik.errors.password}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() => setShowPassword(!showPassword)}
													edge="end"
													aria-label="toggle password visibility"
												>
													{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={5} md={3} lg={2}>
								<Button type="submit" fullWidth>
									GO
								</Button>
							</Grid>
							<Grid item xs={5} md={3} lg={2}>
								<Button
									fullWidth
									onClick={() => {
										navigate("login/boat");
									}}
								>
									Boat
								</Button>
							</Grid>
							<Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }}>
								<Button onClick={handleOpenResetPasswordPopup} className="button-forgot-password">
									{t("password-forgotten")}
								</Button>
							</Grid>
						</Grid>
					</form>
				</Grid>
				<div className="wave-animation"></div>
			</Grid>
		</>
	);
};
