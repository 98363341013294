import { Dialog, DialogContent, DialogTitle, Grid, Typography, Button, TextField } from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";

const validationSchema = yup.object({
	boatName: yup.string().required("Il nome della barca è richiesto"),
});
export const ChangeBoatPopUp = ({
	isPopUpOpen,
	handleClosePopup,
	handleChangeBoat,
}: {
	isPopUpOpen: boolean;
	handleClosePopup: () => void;
	handleChangeBoat: (boatName: string) => Promise<void>;
}) => {
	const formik = useFormik({
		initialValues: {
			boatName: "",
		},

		validationSchema: validationSchema,
		onSubmit: async (values, { resetForm }) => await handleSubmit(values, resetForm),
	});

	const handleSubmit = async (values: any, resetForm: any) => {
		handleChangeBoat(values.boatName);
		resetForm();
		handleClosePopup();
	};

	return (
		<>
			<Dialog open={isPopUpOpen} onClose={handleClosePopup} fullWidth maxWidth="md">
				<DialogTitle mb={3}>
					<Typography variant="h4">Cambia barca</Typography>
				</DialogTitle>
				<DialogContent>
					<>
						<form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
							<Grid container rowGap={2}>
								<Grid item xs={12}>
									<TextField
										className="input-form"
										sx={{ width: { xs: "100%" } }}
										id="boatName"
										name="boatName"
										label="Inserire il nome della nuova barca"
										variant={"filled"}
										value={formik.values.boatName}
										onChange={formik.handleChange}
										error={formik.touched.boatName && Boolean(formik.errors.boatName)}
										helperText={formik.touched.boatName && formik.errors.boatName}
									/>
								</Grid>

								<Grid item xs={12} sx={{ marginTop: "16px" }}>
									<Grid container justifyContent={"center"}>
										<Grid item xs display="flex" justifyContent="center">
											<Button type="submit" className="form-button ">
												CAMBIA BARCA
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					</>
				</DialogContent>
			</Dialog>
		</>
	);
};
