import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const LandingTutorialSection = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const onClick = () => {
		navigate("/user/tutorial");
	};

	return (
		<>
			<Typography variant="h3">{t('explore-title')}</Typography>
			<Typography variant="h5" fontWeight={600} marginTop={"16px"}>
				{t('explore-message')}
			</Typography>
			<Button onClick={onClick} className="form-button" sx={{ marginTop: "16px" }}>
				{t('explore-button')}
			</Button>
		</>
	);
};
