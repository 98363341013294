import { useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
	Button,
	RadioGroup,
	FormControlLabel,
	Radio,
	TextField,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import * as yup from "yup";
import { useFormik } from "formik";
import { AddVideoParams } from "../../../types/forms/Video";

const validationSchema = yup.object({
	titleInItalian: yup.string().required("Il nome in italiano è richiesto"),
	titleInEnglish: yup.string().required("Il nome in inglese è richiesto"),
	titleInCzech: yup.string().required("Il nome in ceco è richiesto"),
	youtubeLink: yup.string().required("Il Link di youtube è richiesto"),
});
export const AddVideoPopUp = ({
	isPopUpOpen,
	handleClosePopup,
	handleAddVideo,
}: {
	isPopUpOpen: boolean;
	handleClosePopup: () => void;
	handleAddVideo: (videoParams: AddVideoParams) => Promise<void>;
}) => {
	const formik = useFormik({
		initialValues: {
			titleInItalian: "",
			titleInEnglish: "",
			titleInCzech: "",
			youtubeLink: "",
		},

		validationSchema: validationSchema,
		onSubmit: async (values, { resetForm }) => await handleSubmit(values, resetForm),
	});

	/**
	 *
	 * @param values.type identifies 0:A settimana, 1: A pezzo ,2: A prenotazione
	 */
	const handleSubmit = async (values: any, resetForm: any) => {
		const videoParams: AddVideoParams = {
			name: { it: values.titleInItalian, en: values.titleInEnglish, cs: values.titleInCzech },
			url: values.youtubeLink,
		};
		handleAddVideo(videoParams);
		resetForm();
		handleClosePopup();
	};

	return (
		<>
			<Dialog open={isPopUpOpen} onClose={handleClosePopup} fullWidth maxWidth="md">
				<DialogTitle mb={3}>
					<Typography variant="h4">CARICA VIDEO</Typography>
				</DialogTitle>
				<DialogContent>
					<>
						<form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
							<Grid container rowGap={2}>
								<Grid item xs={12}>
									<TextField
										className="input-form"
										sx={{ width: { xs: "100%" } }}
										id="youtubeLink"
										name="youtubeLink"
										label="Inserisci il link da YouTube"
										variant={"filled"}
										value={formik.values.youtubeLink}
										onChange={formik.handleChange}
										error={formik.touched.youtubeLink && Boolean(formik.errors.youtubeLink)}
										helperText={formik.touched.youtubeLink && formik.errors.youtubeLink}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										className="input-form"
										sx={{ width: { xs: "100%" } }}
										id="titleInItalian"
										name="titleInItalian"
										label="Titolo in italiano"
										variant={"filled"}
										value={formik.values.titleInItalian}
										onChange={formik.handleChange}
										error={formik.touched.titleInItalian && Boolean(formik.errors.titleInItalian)}
										helperText={formik.touched.titleInItalian && formik.errors.titleInItalian}
									/>
								</Grid>

								<Grid item xs={12}>
									<TextField
										className="input-form"
										sx={{ width: { xs: "100%" } }}
										id="titleInEnglish"
										name="titleInEnglish"
										label="Titolo in inglese"
										variant={"filled"}
										value={formik.values.titleInEnglish}
										onChange={formik.handleChange}
										error={formik.touched.titleInEnglish && Boolean(formik.errors.titleInEnglish)}
										helperText={formik.touched.titleInEnglish && formik.errors.titleInEnglish}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										className="input-form"
										sx={{ width: { xs: "100%" } }}
										id="titleInCzech"
										name="titleInCzech"
										label="Titolo in ceco"
										variant={"filled"}
										value={formik.values.titleInCzech}
										onChange={formik.handleChange}
										error={formik.touched.titleInCzech && Boolean(formik.errors.titleInCzech)}
										helperText={formik.touched.titleInCzech && formik.errors.titleInCzech}
									/>
								</Grid>

								<Grid item xs={12} sx={{ marginTop: "16px" }} display="flex" justifyContent="center">
									<Button type="submit" className="form-button">
										CREA
									</Button>
								</Grid>
							</Grid>
						</form>
					</>
				</DialogContent>
			</Dialog>
		</>
	);
};
