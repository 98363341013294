import { Grid, Button, Typography } from "@mui/material";
import { useState, useEffect } from "react";

import "../../../styles/Buttons.scss";
import trash_icon from "../../../assets/trash_icon.svg";
import { useAuth } from "../../providers/AuthProvider";
import { useBoatProvider } from "../../providers/BoatProvider";
import { useTranslationProvider } from "../../providers/TranslationProvider";
import { TranslationType } from "../../../types/Generic";
import { AddVideoParams, Video } from "../../../types/forms/Video";
import { AddVideoPopUp } from "./AddVideoPopUp";
import { useSnackbar } from "../../providers/SnackbackProvider";

export const BoatModifyVideo = () => {
	const { boatProfile } = useAuth();
	const { language } = useTranslationProvider();
	const { addVideoToBoat, removeVideoFromBoat } = useBoatProvider();
	const { openSnackbar, openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();

	const [videos, setVideos] = useState<Video[]>([]);
	const [openExtraPopup, setOpenExtraPopup] = useState<boolean>(false);

	const handleAddVideo = async (videoParams: AddVideoParams) => {
		try {
			openLoadingSnackbar();
			if (boatProfile) {
				await addVideoToBoat(boatProfile.uid, videoParams);
			}
			closeLoadingSnackbar();
		} catch (err) {
			closeLoadingSnackbar();
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		}
	};
	const handleDelete = async (index: number) => {
		if (boatProfile) {
			await removeVideoFromBoat(boatProfile.uid, videos[index]);
		}
	};

	useEffect(() => {
		(async () => {
			if (boatProfile != null) {
				openLoadingSnackbar();
				const newVideos: Video[] = [];
				if (boatProfile.videos) {
					boatProfile.videos.map((video) => {
						newVideos.push(video);
					});
					setVideos([...newVideos]);
				}
				closeLoadingSnackbar();
			}
		})();
	}, [boatProfile]);
	const handleClosePopup = () => {
		setOpenExtraPopup(false);
	};
	return (
		<>
			<AddVideoPopUp isPopUpOpen={openExtraPopup} handleClosePopup={handleClosePopup} handleAddVideo={handleAddVideo}></AddVideoPopUp>

			<Grid container justifyContent={"space-between"} rowGap={4}>
				<Grid item xs={12} sx={{ marginTop: "16px" }}>
					<Grid container>
						<Grid item xs>
							<Button
								onClick={() => {
									setOpenExtraPopup(true);
								}}
								className="form-button subbutton "
							>
								Carica un video
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12}>
							{videos.map((value, index) => {
								return (
									<Grid key={`video-form-index-${index}`} container paddingY="16px" sx={{ borderTop: "1px solid #B7B7B7" }}>
										<Grid item xs={8} display="flex" alignItems="center">
											<Typography variant="body1" fontSize="20px">
												{value.name[language as keyof TranslationType]}
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<Grid container justifyContent="flex-end" alignItems="center" gap={4}>
												<Grid item>
													<img src={trash_icon} alt="trash icon" onClick={() => handleDelete(index)}></img>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								);
							})}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
