import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "../../localizedFiles/translations.json";
import { createContext, useContext, useState } from "react";
import { TranslationProviderType } from "../../types/providers/TranslationProvider";

const TranslationContext = createContext<TranslationProviderType>({} as TranslationProviderType);
export const TranslationProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
	const [language, setLanguage] = useState(window.localStorage.getItem("language") || "en");

	const changeLanguage = (lang: string): void => {
		setLanguage(lang);
		window.localStorage.setItem("language", lang);
	};

	i18n.use(initReactI18next).init({
		resources,
		lng: language,
		interpolation: {
			escapeValue: false,
		},
	});

	const translationProviderData: TranslationProviderType = {
		language,
		changeLanguage,
		i18n,
	};

	return <TranslationContext.Provider value={translationProviderData}>{children}</TranslationContext.Provider>;
};
export const useTranslationProvider = () => useContext(TranslationContext);
