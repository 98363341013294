import { Dialog, DialogContent, DialogTitle, Grid, Typography, Button, TextField, FormControlLabel, Checkbox, FormGroup } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import * as yup from "yup";
import { Formik, Form } from "formik";
import "../../../../styles/popup/RejectOrder.scss";
import { OrderToDelete } from "../../../../types/orders/OrderToDelete";
import { Order } from "../../../../types/documents/Order";
import { useSnackbar } from "../../../providers/SnackbackProvider";
const validationSchema = yup.object({
	reason: yup.string().required("Inserire un motivo del rifiuto"),
});
export const RejectCheckInPopUp = ({
	isPopUpOpen,
	handleClosePopup,
	handleRejectCheckIn,
	articles,
}: {
	isPopUpOpen: boolean;
	handleClosePopup: () => void;
	handleRejectCheckIn: (
		reason: string,
		sectionsToBeReset: OrderToDelete["sectionsToBeReset"],
		articlesToBeEnabled: OrderToDelete["articlesToBeEnabled"]
	) => Promise<void>;
	articles: Order["articles"];
}) => {
	const { openSnackbar } = useSnackbar();

	const handleSubmit = async (values: any, resetForm: any): Promise<void> => {
		try {
			if (!values.nauticalLicense && !values.identityCard && !values.extras && !values.enableArt34 && !values.enableArt39)
				throw new Error("Seleziona almeno un campo");

			const sectionsToBeReset: OrderToDelete["sectionsToBeReset"] = {
				nauticalLicense: values.nauticalLicense,
				identityCard: values.identityCard,
				extras: values.extras,
			};

			handleRejectCheckIn(values.reason, sectionsToBeReset, { 34: values.enableArt34, 39: values.enableArt39 });
			resetForm();
			handleClosePopup();
		} catch (error) {
			const e = error as Error;
			openSnackbar("Errore", e.message, "error");
		}
	};

	return (
		<>
			<Dialog open={isPopUpOpen} onClose={handleClosePopup} fullWidth maxWidth="md">
				<DialogTitle mb={3}>
					<Typography variant="h4">Rifiuta check in</Typography>
				</DialogTitle>
				<DialogContent>
					<>
						<Formik
							initialValues={{
								reason: "",
								nauticalLicense: false,
								identityCard: false,
								extras: false,
								enableArt34: articles.enabled34 ? true : false,
								enableArt39: articles.enabled39 ? true : false,
							}}
							onSubmit={async (values, { resetForm }) => {
								await handleSubmit(values, resetForm);
							}}
							validationSchema={validationSchema}>
							{({ touched, errors, values, handleChange, setFieldValue }) => (
								<Form>
									<Grid container rowGap={2} justifyContent="center">
										<Grid item xs={12} sm={10}>
											<FormGroup sx={{ gap: "12px" }}>
												<FormControlLabel
													className="checkbox-section-to-be-removed"
													name="nauticalLicense"
													checked={values.nauticalLicense}
													onChange={handleChange}
													control={<Checkbox icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon color="success" />} />}
													label={<Typography variant="h5">{"Patente Nautica"}</Typography>}
												/>
												<FormControlLabel
													className="checkbox-section-to-be-removed"
													name="identityCard"
													checked={values.identityCard}
													onChange={handleChange}
													control={<Checkbox icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon color="success" />} />}
													label={<Typography variant="h5">{"Documenti"}</Typography>}
												/>
												<FormControlLabel
													className="checkbox-section-to-be-removed"
													name="extras"
													checked={values.extras}
													onChange={handleChange}
													control={<Checkbox icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon color="success" />} />}
													label={<Typography variant="h5">{"Extra"}</Typography>}
												/>
											</FormGroup>
											<section className="enable-articles-container">
												<Button
													className={`${values.enableArt34 ? "enable-article-selected" : ""} enable-articles-button form-button`}
													onClick={() => {
														setFieldValue("enableArt34", !values.enableArt34);
													}}>
													Sblocca art. 34
												</Button>
												<Button
													className={`${values.enableArt39 ? "enable-article-selected" : ""} enable-articles-button form-button`}
													onClick={() => {
														setFieldValue("enableArt39", !values.enableArt39);
													}}>
													Sblocca art. 39
												</Button>
											</section>

											<TextField
												className="input-form multiline"
												multiline
												sx={{ width: { xs: "100%" }, height: "80px", marginTop: "24px" }}
												id="reason"
												name="reason"
												label="Motivo del rifiuto"
												variant={"filled"}
												value={values.reason}
												onChange={handleChange}
												error={touched.reason && Boolean(errors.reason)}
												helperText={touched.reason && errors.reason}
											/>
										</Grid>

										<Grid item xs={12} sx={{ marginTop: "16px" }}>
											<Grid container justifyContent={"center"}>
												<Grid item xs display="flex" justifyContent="center">
													<Button type="submit" className="form-button ">
														INVIA
													</Button>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Form>
							)}
						</Formik>
					</>
				</DialogContent>
			</Dialog>
		</>
	);
};
