import { TimeFormat } from "../types/Generic";

export function formatTime(hours: number, minutes: number): TimeFormat {
	const formattedHours = hours.toString().padStart(2, "0");
	const formattedMinutes = minutes.toString().padStart(2, "0");
	return `${formattedHours}:${formattedMinutes}` as TimeFormat;
}

export function formatTimeFromDate(date: Date): TimeFormat {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return formatTime(hours, minutes);
}

export function extractHourAndMinute(time: TimeFormat): { hours: number; minutes: number } {
    const [hours, minutes] = time.split(":");
    return { hours: parseInt(hours), minutes: parseInt(minutes) };
}

export function extractDate(time: TimeFormat): Date{
    const { hours, minutes } = extractHourAndMinute(time);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
}