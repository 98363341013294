import { useAuth } from "../../../components/providers/AuthProvider";
import { Button, Grid, Typography } from "@mui/material";
import { UserMenu } from "../UserMenu";
import { useTranslation } from "react-i18next";
import { RatingCategory } from "./RatingCategory";
import "../../../styles/user/Feedback.scss";
import { useState } from "react";
import { useBoatProvider } from "../../../components/providers/BoatProvider";
import { useOrders } from "../../../components/providers/OrderProvider";
import { useSnackbar } from "../../../components/providers/SnackbackProvider";
import { MessageTextInput } from "./MessageTextInput";

export type Feedback = {
	ratings: Ratings;
	message?: string;
};

export type Ratings = {
	yacht: number;
	yachtCleaning: number;
	customerService: number;
	baseManager: number;
	ourService: number;
	checkInSoftware: number;
};

export type RatingCategoryAvailable = "yacht" | "yachtCleaning" | "customerService" | "baseManager" | "ourService" | "checkInSoftware";

export const UserRatings = () => {
	const { pastOrders } = useOrders();
	const { openLoadingSnackbar, closeLoadingSnackbar, openSnackbar } = useSnackbar();
	const { rateBoat } = useBoatProvider();
	const { t } = useTranslation();
	const [message, setMessage] = useState("");
	const [ratingsValue, setRatingsValue] = useState<Ratings>({
		yacht: 0,
		yachtCleaning: 0,
		customerService: 0,
		baseManager: 0,
		ourService: 0,
		checkInSoftware: 0,
	});

	const handleSetRatingsValue = (category: RatingCategoryAvailable, value: number) => {
		setRatingsValue({
			...ratingsValue,
			[category]: value,
		});
	};

	const handleSubmit = async () => {
		openLoadingSnackbar();
		try {
			await rateBoat(pastOrders[pastOrders.length - 1].orderUID, pastOrders[pastOrders.length - 1].boatUID, {
				ratings: ratingsValue,
				message: message,
			});
			openSnackbar(t("success"), t("feedbackSuccess"), "success");
		} catch (error) {
			const message = (error as any).message;
			openSnackbar("Error", message, "error");
		}
		closeLoadingSnackbar();
	};

	return (
		<>
			<Grid container flexWrap="nowrap">
				<Grid item height="100%" className="menu-container">
					<UserMenu />
				</Grid>
				<Grid
					item
					xs={10}
					marginLeft="10px"
					className="landing menu-content rating-container"
					sx={{
						flexDirection: "column",
					}}
				>
					<Typography id="rating-container-title" sx={{ width: "100%", flexShrink: 0, xs: { fontSize: "16px !important" } }} variant="h3">
						{t("feedbackPageTitle")}
					</Typography>
					{pastOrders.length === 0 ? (
						<>
							<Typography
								className="rating-star-text"
								sx={{ width: "100%", flexShrink: 0, xs: { fontSize: "16px !important" }, marginTop: "20px" }}
								variant="h4"
							>
								{t("feedbackNoOrder")}
							</Typography>
						</>
					) : (
						<>
							{pastOrders[pastOrders.length - 1].reviewed ? (
								<>
									<Typography
										className="rating-star-text"
										sx={{ width: "100%", flexShrink: 0, xs: { fontSize: "16px !important" }, marginTop: "20px" }}
										variant="h4"
									>
										{t("feedbackReviewed")}
									</Typography>
								</>
							) : (
								<>
									<Grid container spacing={2} marginTop="20px" id="rating-categories-container">
										<Grid item xs={12} md={6}>
											<RatingCategory handleSetRatingsValue={handleSetRatingsValue} title={t("yacht")} category={"yacht"} />
										</Grid>
										<Grid item xs={12} md={6}>
											<RatingCategory
												handleSetRatingsValue={handleSetRatingsValue}
												title={t("yachtCleaning")}
												category={"yachtCleaning"}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<RatingCategory
												handleSetRatingsValue={handleSetRatingsValue}
												title={t("customerService")}
												category={"customerService"}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<RatingCategory
												handleSetRatingsValue={handleSetRatingsValue}
												title={t("baseManager")}
												category={"baseManager"}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<RatingCategory
												handleSetRatingsValue={handleSetRatingsValue}
												title={t("ourService")}
												category={"ourService"}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<RatingCategory
												handleSetRatingsValue={handleSetRatingsValue}
												title={t("Check-In-Software")}
												category={"checkInSoftware"}
											/>
										</Grid>
										<Grid item xs={12}>
											<MessageTextInput handleChangeMessage={setMessage} message={message} />
										</Grid>
										<Grid item xs={12} sx={{ textAlign: "center" }}>
											<Button className="form-button" variant="contained" onClick={handleSubmit}>
												{t("send")}
											</Button>
										</Grid>
									</Grid>
								</>
							)}{" "}
						</>
					)}
				</Grid>
			</Grid>
		</>
	);
};
