import { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";
import { useOrders } from "../../providers/OrderProvider";
import { useBoatProvider } from "../../providers/BoatProvider";
import { Pdf } from "../../../types/forms/Pdf";
import { useTranslationProvider } from "../../providers/TranslationProvider";
import { PdfDownload, TranslationType } from "../../../types/Generic";
import { AutoTextSize } from "auto-text-size";

const Document = ({ name, boatUid, nameInIt }: { name: string; boatUid: string; nameInIt: string }) => {
	const { downlaodPdfOfBoat } = useBoatProvider();
	const handleDownloadDocument = async (boatUid: string) => {
		await downlaodPdfOfBoat(boatUid, nameInIt);
	};
	return (
		<>
			<Grid item xs={12} md className="container-settings-download-button">
				<Button
					onClick={() => {
						handleDownloadDocument(boatUid);
					}}
					className="settings-download-button">
					<AutoTextSize mode="oneline" minFontSizePx={1} maxFontSizePx={20}>
						{name}
					</AutoTextSize>
				</Button>
			</Grid>
		</>
	);
};

const DocumentsOfBoat = ({ pdfDownload }: { pdfDownload: PdfDownload }) => {
	const { language } = useTranslationProvider();
	return (
		<>
			<Grid item xs={12}>
				<Grid container gap={2} rowGap={2}>
					{pdfDownload.pdfs?.map((value, index) => {
						return (
							<>
								<Document
									key={`boat-documents-${value[language as keyof TranslationType]}-download`}
									name={value[language as keyof TranslationType]}
									boatUid={pdfDownload.boatUid}
									nameInIt={value.it}></Document>
							</>
						);
					})}
				</Grid>
			</Grid>
		</>
	);
};
export const DownloadDocuments = () => {
	const [documents, setDocuments] = useState<PdfDownload[]>([] as PdfDownload[]);
	const { futureOrders } = useOrders();
	const { fetchPdfsOfBoat } = useBoatProvider();

	useEffect(() => {
		(async () => {
			let documents = [] as PdfDownload[];
			let boatBooked: string[] = [];
			for (let i = 0; i < futureOrders.length; i++) {
				const order = futureOrders[i];
				if (!boatBooked.includes(order.boatUID)) {
					const pdfs = (await fetchPdfsOfBoat(order.boatUID)) as Pdf[];
					boatBooked.push(order.boatUID);
					documents.push({ pdfs: pdfs, boatUid: order.boatUID });
				}
			}
			setDocuments(documents);
		})();
	}, [futureOrders]);
	return (
		<>
			<Grid container gap={2} paddingY={{ xs: 1 }} padding={{ md: 2 }}>
				{documents.map((value, index) => {
					return <DocumentsOfBoat key={`boat-documents-${index}`} pdfDownload={value} />;
				})}
			</Grid>
		</>
	);
};
