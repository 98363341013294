import { TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const MessageTextInput = ({ handleChangeMessage, message }: { handleChangeMessage: (message: string) => void; message: string }) => {
	const { t } = useTranslation();
	return (
		<>
			<Typography className="rating-star-text" sx={{ width: "100%", flexShrink: 0, xs: { fontSize: "16px !important" } }} variant="h4">
				{t("leave-us-a-message")}
			</Typography>
			<TextField
				className="input-form multiline"
				sx={{ width: { xs: "100%" } }}
				id="username"
				name="username"
				label={t("message")}
				variant={"filled"}
				value={message}
				multiline
				onChange={(event: any) => handleChangeMessage(event.target.value)}
			/>
		</>
	);
};
