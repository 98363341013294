import { useState } from "react";

import { Grid, Typography } from "@mui/material";
import { BoatRatingMessanges } from "../../../types/documents/Boat";
import { FeedbackMessage } from "./FeedbackMessage";

export const FeedbackMessanges = ({ feedbackMessanges }: { feedbackMessanges: BoatRatingMessanges[] }) => {
	const [expanded, setExpanded] = useState<string | false>(false);
	const [messageToshow, setMessageToShow] = useState<number>(2);
	const handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			<Grid container rowGap="16px" marginTop="16px">
				{feedbackMessanges.map((value, index) => {
					if (index >= messageToshow) return <></>;
					return (
						<Grid item xs={12} marginY="2px" key={`order-index-${index}`}>
							<FeedbackMessage
								messageSenderName={value.name}
								message={value.message}
								index={index}
								expanded={expanded}
								handleChangeAccordion={handleChangeAccordion}
							/>
						</Grid>
					);
				})}
				{messageToshow < feedbackMessanges.length && (
					<Grid item xs={12} display="flex" justifyContent="center">
						<a
							onClick={() => {
								setMessageToShow(messageToshow + 5);
							}}
							style={{ cursor: "pointer" }}>
							<Typography variant="body1">Espandi...</Typography>
						</a>
					</Grid>
				)}
			</Grid>
		</>
	);
};
