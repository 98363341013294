export const checklistTypes = {
	text: "text",
	binary: "binary",
};
export const paymentStatuses = {
	IN_PROGRESS: "in progress",
	PAYMENT_COMPLETED: "payment completed",
	PAYMENT_IN_PLACE: "pay in place",
};

export const checkboatStatuses = {
	PENDING: "pending",
	SIGNED: "signed",
	COUNTER_SIGNED: "counterSigned",
};

