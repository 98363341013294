import { Dialog, DialogContent, DialogTitle, Grid, Typography, Button } from "@mui/material";

import "../../../../styles/Buttons.scss";
import { TranslationType } from "../../../../types/Generic";

export const UserCredentialsPopUp = ({
	mail,
	password,
	isPopUpOpen,
	handleClosePopup,
	handleSendCredentials,
}: {
	mail: string;
	password: string;
	isPopUpOpen: boolean;
	handleClosePopup: () => void;
	handleSendCredentials: (language: keyof TranslationType) => void;
}) => {
	return (
		<Dialog open={isPopUpOpen} fullWidth maxWidth="md">
			<DialogTitle mb={3}>
				<Typography variant="h4">Credenziali</Typography>
			</DialogTitle>
			<DialogContent>
				<>
					<Grid container gap={2} rowGap={2} justifyContent="center">
						<Grid item xs={12}>
							<div className="popup-user-credentials-container">
								<Typography variant="h4" sx={{ width: "100%" }} fontWeight={400}>
									Mail: {mail}
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div className="popup-user-credentials-container">
								<Typography variant="h4" sx={{ width: "100%" }} fontWeight={400}>
									Password: {password}
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} marginTop="16px" display="flex" gap={4}>
							<Button onClick={() => handleSendCredentials("it")} className="form-button">
								INVIA CREDENZIALI (IT)
							</Button>
							<Button onClick={() => handleSendCredentials("en")} className="form-button">
								INVIA CREDENZIALI (EN)
							</Button>
							<Button onClick={() => handleSendCredentials("cs")} className="form-button">
								INVIA CREDENZIALI (CZ)
							</Button>
						</Grid>
						{/* <Grid item xs={10} marginTop="16px">
							<Typography variant="h4">AVVISO IMPORTANTE</Typography>
							<Typography variant="body1">Segnati queste credenziali e mandale per e-mail al cliente</Typography>
						</Grid>
						<Grid xs={12}>
							<Grid container justifyContent="center">
								<Grid item xs display="flex" justifyContent="center">
									<Button onClick={handleClosePopup} className="form-button">
										CONFERMA
									</Button>
								</Grid>
							</Grid>
						</Grid> */}
					</Grid>
				</>
			</DialogContent>
		</Dialog>
	);
};
