import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { Order } from "../../../../types/documents/Order";
import { ChecklistElementBoarding } from "../../../../types/forms/Checklist";
import { SignatureRows } from "./TableSignature";

type TableInventoryProps = {
	boatUsername: string | undefined;
	order: Order;
	isCheckIn: boolean;
};

type HeadTableInventoryProps = Pick<TableInventoryProps, "boatUsername">;

type BodyTableInventoryProps = {
	checklist: ChecklistElementBoarding[] | undefined;
	checkoutList: ChecklistElementBoarding[] | undefined;
} & Pick<TableInventoryProps["order"], "orderUID"> &
	Pick<TableInventoryProps, "isCheckIn">;

export const TableInventory = ({ boatUsername, order, isCheckIn }: TableInventoryProps) => (
	<Table className="table-inventory">
		<HeadTableInventory boatUsername={boatUsername} />
		<BodyTableInventory orderUID={order.orderUID} isCheckIn={isCheckIn} checklist={order.checkBoat?.checkList} checkoutList={order.checkout?.checkList} />
		<TableFooter>
			<SignatureRows colSpanCheckIn={2} colSpanCheckOut={4} />
		</TableFooter>
	</Table>
);

const HeadTableInventory = ({ boatUsername }: HeadTableInventoryProps) => (
	<TableHead>
		<TableRow>
			<TableCell align="center" colSpan={6}>
				<Typography variant="h5" fontWeight="bold">
					INVENTARIO/ INVENTÁŘ / INVENTORY LIST {(boatUsername || "username").toUpperCase()}
				</Typography>
			</TableCell>
		</TableRow>
		<TableRow>
			<TableCell align="center" colSpan={1}>
				<Typography variant="h5">INVENTARIO</Typography>
			</TableCell>
			<TableCell align="center" colSpan={1}>
				<Typography variant="h5">INVENTÁŘ</Typography>
			</TableCell>
			<TableCell align="center" colSpan={1}>
				<Typography variant="h5">INVENTORY</Typography>
			</TableCell>
			<TableCell align="center" colSpan={1}>
				<Typography variant="h5">Prezzo / Cost / Cena</Typography>
			</TableCell>
			<TableCell align="center" colSpan={1}>
				<Typography variant="h5">Check-in</Typography>
			</TableCell>
			<TableCell align="center" colSpan={1}>
				<Typography variant="h5">Check-out</Typography>
			</TableCell>
		</TableRow>
	</TableHead>
);

const BodyTableInventory = ({ orderUID, isCheckIn, checklist, checkoutList }: BodyTableInventoryProps) => (
	<TableBody>
		{checklist &&
			checklist
				.filter((el) => el.category === "inventory")
				.map((checklistElement, i) => (
					<TableRow key={`${orderUID}-row-checkin-inventory-${i}`}>
						<TableCell align="left" colSpan={1}>
							<Typography variant="h5">{checklistElement.name.it}</Typography>
						</TableCell>
						<TableCell align="left" colSpan={1}>
							<Typography variant="h5">{checklistElement.name.cs}</Typography>
						</TableCell>
						<TableCell align="left" colSpan={1}>
							<Typography variant="h5">{checklistElement.name.en}</Typography>
						</TableCell>
						<TableCell align="left" colSpan={1}>
							{checklistElement.price === "deposit" ? (
								<Typography variant="h5" color="red">
									Cauzione / Deposit / Kauce{" "}
								</Typography>
							) : (
								<Typography variant="h5">€ {checklistElement.price}</Typography>
							)}
						</TableCell>
						<TableCell align="center" colSpan={1}>
							<Typography variant="h5">
								{checklistElement.inputType === "binary" ? (
									checklistElement.binaryChoice ? (
										<CheckIcon fontSize="large" sx={{ color: "green" }} />
									) : (
										<CloseIcon fontSize="large" sx={{ color: "red" }} />
									)
								) : (
									checklistElement.textChoice
								)}
							</Typography>
						</TableCell>
						<TableCell align="center" colSpan={1}>
							{!isCheckIn && checkoutList
								? checkoutList.map((el) => {
										if (
											el.name.en === checklistElement.name.en &&
											el.name.it === checklistElement.name.it &&
											el.name.cs === checklistElement.name.cs
										) {
											return el.inputType === "binary" ? (
												el.binaryChoice ? (
													<CheckIcon fontSize="large" sx={{ color: "green" }} />
												) : (
													<CloseIcon fontSize="large" sx={{ color: "red" }} />
												)
											) : (
												el.textChoice
											);
										}
								  })
								: ""}
						</TableCell>
					</TableRow>
				))}
	</TableBody>
);
