import { useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
	Button,
	RadioGroup,
	FormControlLabel,
	Radio,
	TextField,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import * as yup from "yup";
import { Form, Formik } from "formik";
import { AddExtraParams, EditExtraParams } from "../../../types/forms/Extras";

const validationSchema = yup.object({
	nameInItalian: yup.string().required("Il nome in italiano è richiesto"),
	nameInEnglish: yup.string().required("Il nome in inglese è richiesto"),
	nameInCzech: yup.string().required("Il nome in ceco è richiesto"),
	price: yup
		.number()
		.typeError("La cauzione deve essere un numero")
		.min(0, "Il prezzo non puo essere negativo")
		.required("Il prezzo della cauzione è richiesto"),
});
export const EditExtraPopUp = ({
	isPopUpOpen,
	extraProp,
	handleClosePopup,
	handleEditExtra,
}: {
	isPopUpOpen: boolean;
	extraProp?: EditExtraParams;
	handleClosePopup: () => void;
	handleEditExtra: (extra: EditExtraParams) => Promise<void>;
}) => {
	const [expanded, setExpanded] = useState<string | false>(false);
	const radioButtonInfo = ["A settimana", "A pezzo", "A prenotazione", "A persona"];

	/**
	 *
	 * @param values.type identifies 0:A settimana, 1: A pezzo ,2: A prenotazione
	 */
	const handleSubmit = async (values: any, resetForm: any) => {
		const type = radioButtonInfo[Number(values.type)];
		let typeToPass = "";

		switch (type) {
			case "A settimana":
				typeToPass = "week";
				break;
			case "A pezzo":
				typeToPass = "piece";
				break;
			case "A prenotazione":
				typeToPass = "booking";
				break;
			case "A persona":
				typeToPass = "person";
				break;
			default:
				throw new Error("Invalid type");
		}

		const extra: AddExtraParams & { pos: number } = {
			name: {
				it: values.nameInItalian,
				en: values.nameInEnglish,
				cs: values.nameInCzech,
			},
			cost: Number(values.price),
			type: typeToPass,
			pos: extraProp ? extraProp.pos : -1,
		};
		await handleEditExtra(extra);
		resetForm();
		handleClosePopup();
	};
	const handleAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			<Dialog open={isPopUpOpen} onClose={handleClosePopup} fullWidth maxWidth="md">
				<DialogTitle mb={3}>
					<Typography variant="h4">Modifica un extra</Typography>
				</DialogTitle>
				<DialogContent>
					<>
						<Formik
							initialValues={{
								nameInItalian: extraProp ? extraProp.name.it : "",
								nameInEnglish: extraProp ? extraProp.name.en : "",
								nameInCzech: extraProp ? extraProp.name.cs : "",
								price: extraProp ? extraProp.cost : 0,
								type: extraProp?.type === "week" ? 0 : extraProp?.type === "piece" ? 1 : extraProp?.type === "booking" ? 2 : 3,
							}}
							onSubmit={async (values, { resetForm }) => {
								await handleSubmit(values, resetForm);
							}}
							validationSchema={validationSchema}>
							{({ initialValues, touched, errors, values, setFieldValue, handleChange }) => (
								<Form>
									<Grid container rowGap={2}>
										<Grid item xs={12}>
											<TextField
												className="input-form"
												sx={{ width: { xs: "100%" } }}
												id="nameInItalian"
												name="nameInItalian"
												label="Nome in italiano"
												variant={"filled"}
												value={values.nameInItalian}
												onChange={handleChange}
												error={touched.nameInItalian && Boolean(errors.nameInItalian)}
												helperText={touched.nameInItalian && errors.nameInItalian}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												className="input-form"
												sx={{ width: { xs: "100%" } }}
												id="nameInEnglish"
												name="nameInEnglish"
												label="Nome in inglese"
												variant={"filled"}
												value={values.nameInEnglish}
												onChange={handleChange}
												error={touched.nameInEnglish && Boolean(errors.nameInEnglish)}
												helperText={touched.nameInEnglish && errors.nameInEnglish}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												className="input-form"
												sx={{ width: { xs: "100%" } }}
												id="nameInCzech"
												name="nameInCzech"
												label="Nome in ceco"
												variant={"filled"}
												value={values.nameInCzech}
												onChange={handleChange}
												error={touched.nameInCzech && Boolean(errors.nameInCzech)}
												helperText={touched.nameInCzech && errors.nameInCzech}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												className="input-form"
												sx={{ width: { xs: "100%" } }}
												id="price"
												name="price"
												label="Prezzo"
												variant={"filled"}
												value={values.price}
												onChange={handleChange}
												error={touched.price && Boolean(errors.price)}
												helperText={touched.price && errors.price}
											/>
										</Grid>
										<Grid item xs={12}>
											<Accordion
												className="accordion"
												sx={{ background: "#FFFFFF !important" }}
												expanded={expanded === "panel1"}
												onChange={handleAccordion("panel1")}>
												<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
													<Typography sx={{ width: "33%", flexShrink: 0 }} variant="h4">
														Tipologia prezzo
													</Typography>
												</AccordionSummary>
												<AccordionDetails>
													<RadioGroup defaultValue={initialValues.type}>
														{radioButtonInfo.map((label, index) => (
															<FormControlLabel
																key={`form-price-type-radio-${label}-${index}`}
																value={index}
																name="type"
																onChange={handleChange}
																control={
																	<Radio icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon color="success" />} />
																}
																label={label}
															/>
														))}
													</RadioGroup>
												</AccordionDetails>
											</Accordion>
										</Grid>
										<Grid item xs={12} sx={{ marginTop: "16px" }} display="flex" justifyContent="center">
											<Button type="submit" className="form-button ">
												MODIFICA
											</Button>
										</Grid>
									</Grid>
								</Form>
							)}
						</Formik>
					</>
				</DialogContent>
			</Dialog>
		</>
	);
};
