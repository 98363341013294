import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ChecklistElementBoarding, WarningChecklistBoat } from "../../types/forms/Checklist";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { checkboatStatuses } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useTranslationProvider } from "../providers/TranslationProvider";
import { TranslationType } from "../../types/Generic";
import { ChecklistWarning } from "./ChecklistWarning";

const DisplayChecklistElement = ({
	checkListElement,
	index,
	handleChageInChecklist,
	disabled,
	isUser,
}: {
	checkListElement: ChecklistElementBoarding;
	index: number;
	handleChageInChecklist: (checklistElement: ChecklistElementBoarding, index: number) => void;
	disabled: boolean;
	isUser: boolean;
}) => {
	const { t } = useTranslation();
	const { language } = useTranslationProvider();

	const [binaryChoice, setBinaryChoice] = useState<boolean>(checkListElement.binaryChoice ?? true);
	const [textChoice, setTextChoice] = useState<string>(checkListElement.textChoice ?? "");

	const handleChangeStatusChecklistElement = () => {
		const newCheckListElement = { ...checkListElement };
		if (checkListElement.inputType === "binary") {
			newCheckListElement.binaryChoice = binaryChoice;
		} else {
			newCheckListElement.textChoice = textChoice;
		}

		handleChageInChecklist(newCheckListElement, index);
	};
	// useEffect(() => {
	// 	setBinaryChoice(() => {
	// 		if (checkListElement.binaryChoice == null) {
	// 			if (isUser && disabled) {
	// 				return null;
	// 			}
	// 			if (isUser && !disabled) {
	// 				return true;
	// 			}
	// 			return false;
	// 		} else {
	// 			return checkListElement.binaryChoice;
	// 		}
	// 	});
	// }, [disabled]);
	useEffect(() => {
		handleChangeStatusChecklistElement();
	}, [binaryChoice, textChoice]);
	// useEffect(() => {
	// 	if (checkListElement.category == "binary") {
	// 		if (checkListElement.binaryChoice === null || checkListElement.binaryChoice === undefined) setBinaryChoice(true);
	// 		else setBinaryChoice(checkListElement.binaryChoice);
	// 	} else {
	// 		if (checkListElement.textChoice === null || checkListElement.textChoice === undefined) setTextChoice("");
	// 		else setTextChoice(checkListElement.textChoice);
	// 	}
	// }, []);

	return (
		<>
			<Grid item xs={12}>
				<Grid container>
					{index == 0 && (
						<>
							<Grid item xs={12} py={1.2} display="flex" alignItems="center" justifyContent="space-between">
								<Typography variant="h5" fontWeight={600}>
									{t("component")}
								</Typography>
								<div className="checklist-titles">
									<Typography variant="h5" fontWeight={600}>
										{t("boarding-checklist-title")}
									</Typography>
									<section className="yes-no-text-checklist-check-mobile-container">
										<Typography variant="h5" className="yes-no-text-checklist-check-mobile">
											{t("yes")}
										</Typography>

										<Typography variant="h5" className="yes-no-text-checklist-check-mobile">
											{t("no")}
										</Typography>
									</section>
								</div>
							</Grid>
						</>
					)}
				</Grid>
				<Divider />
				<Grid
					container
					sx={{ backgroundColor: checkListElement.binaryChoice != null && checkListElement.binaryChoice === false ? "rgba(255, 0, 0, 0.15)" : "" }}>
					<Grid item xs={12} py={1.2} display="flex" alignItems="center" justifyContent="space-between">
						<Typography variant="h5">
							{checkListElement.name[language as keyof TranslationType]}
							{checkListElement.category === "inventory" && (
								<>
									<span> - </span>
									{checkListElement.price === "deposit" ? (
										<span style={{ color: "red" }}>{t("deposit")}</span>
									) : (
										<>€ {checkListElement.price}</>
									)}
								</>
							)}
						</Typography>
						<div className="checklist-elemenet-input">
							{checkListElement.inputType === "binary" && (
								<>
									<div className="checklist-choice-container">
										<section style={{ display: "flex", alignItems: "center", gap: "8px" }}>
											<a
												onClick={() => {
													if (!disabled) setBinaryChoice(true);
												}}>
												<div
													className={`order-checkbox ${
														binaryChoice && binaryChoice != null ? "order-checkbox-filled" : ""
													} checkbox-checklist`}>
													{binaryChoice && binaryChoice != null && <CheckIcon className="checkbox-icon" />}
												</div>
											</a>
											<Typography variant="h5" className="yes-no-text-checklist-check-desktop">
												{t("yes")}
											</Typography>
										</section>
										<section style={{ display: "flex", alignItems: "center", gap: "8px" }}>
											<a
												onClick={() => {
													if (!disabled) setBinaryChoice(false);
												}}>
												<div
													className={`order-checkbox ${
														!binaryChoice && binaryChoice != null ? "order-checkbox-filled" : ""
													} checkbox-checklist`}>
													{!binaryChoice && binaryChoice != null && <CheckIcon className="checkbox-icon" />}
												</div>
											</a>
											<Typography variant="h5" className="yes-no-text-checklist-check-desktop">
												{t("no")}
											</Typography>
										</section>
									</div>
								</>
							)}
							{checkListElement.inputType === "text" && (
								<>
									<TextField
										className="input-form text-choice-checklist"
										id="notes"
										name="notes"
										label={isUser ? "" : "Inserisci"}
										variant={"filled"}
										size="small"
										disabled={disabled}
										value={textChoice}
										onChange={(e) => {
											setTextChoice(e.currentTarget.value);
										}}
										onBlur={(e) => {
											setTextChoice(e.currentTarget.value);
										}}
									/>
								</>
							)}
						</div>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export const CheckBoatChecklist = ({
	orderUID,
	checklistInventory,
	isAllowedToModifyChecklist,
	setChecklistInventory,
	checklistCheckin560,
	setChecklistCheckin560,
	isUser,
	boardingStatus,
	warnings,
	setWarnings,
}: {
	orderUID: string;
	isAllowedToModifyChecklist: boolean;
	checklistInventory: ChecklistElementBoarding[];
	setChecklistInventory: Dispatch<SetStateAction<ChecklistElementBoarding[]>>;
	checklistCheckin560: ChecklistElementBoarding[];
	setChecklistCheckin560: Dispatch<SetStateAction<ChecklistElementBoarding[]>>;
	isUser: boolean;
	boardingStatus: string;
	warnings: WarningChecklistBoat[];
	setWarnings: Dispatch<SetStateAction<WarningChecklistBoat[]>>;
}) => {
	const [disableInput, setDisableInput] = useState<boolean>(true);
	const { t } = useTranslation();
	const { language } = useTranslationProvider();
	const handleChageInChecklistInventory = (checklistElement: ChecklistElementBoarding, index: number) => {
		const newChecklist = [...checklistInventory];
		newChecklist[index] = checklistElement;
		setChecklistInventory(newChecklist);
	};
	const handleChageInChecklistCheckin560 = (checklistElement: ChecklistElementBoarding, index: number) => {
		const newChecklist = [...checklistCheckin560];
		newChecklist[index] = checklistElement;
		setChecklistCheckin560(newChecklist);
	};
	useEffect(() => {
		if (isUser) {
			if (isAllowedToModifyChecklist) {
				console.log(`User is allowed to modify the checklist`);
				setDisableInput(false);
			} else {
				console.log(`User is not allowed to modify the checklist`);
				setDisableInput(true);
			}
		} else if (boardingStatus === checkboatStatuses.COUNTER_SIGNED) {
			setDisableInput(true);
		} else {
			setDisableInput(false);
		}
	}, [boardingStatus, isUser, isAllowedToModifyChecklist]);

	const handleToggleWarningsCheck = (index: number) => {
		const newWarnings = [...warnings];
		newWarnings[index].boatCheckChecked = newWarnings[index].boatCheckChecked == null ? true : !newWarnings[index].boatCheckChecked;
		setWarnings(newWarnings);
	};
	return (
		<>
			<Grid container rowGap="32px">
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12} marginBottom="8px">
							<Typography variant="h4">{t("control-of-function")}</Typography>
						</Grid>
						{checklistCheckin560 != null &&
							checklistCheckin560.length > 0 &&
							checklistCheckin560.map((checkListElement, index) => (
								<DisplayChecklistElement
									key={`${orderUID}-check-list-element-functional-${index}`}
									index={index}
									handleChageInChecklist={handleChageInChecklistCheckin560}
									checkListElement={checkListElement}
									disabled={disableInput}
									isUser={isUser}
								/>
							))}
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12} marginBottom="8px">
							<Typography variant="h4">{t("inventory")}</Typography>
						</Grid>
						{checklistInventory != null &&
							checklistInventory.length > 0 &&
							checklistInventory.map((checkListElement, index) => (
								<DisplayChecklistElement
									key={`${orderUID}-check-list-element-inventory-${index}`}
									index={index}
									handleChageInChecklist={handleChageInChecklistInventory}
									checkListElement={checkListElement}
									disabled={disableInput}
									isUser={isUser}
								/>
							))}
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12} marginBottom="8px">
							<Typography variant="h4">Warnings</Typography>
						</Grid>
						{warnings != null &&
							warnings.map((warning, index) => (
								<ChecklistWarning
									key={`${orderUID}-check-list-warning-${index}`}
									index={index}
									handleToggleCheck={handleToggleWarningsCheck}
									value={warning.value[language as keyof TranslationType]}
									checked={warning.boatCheckChecked == null ? false : warning.boatCheckChecked}
									isUser={isUser}
								/>
							))}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
