import { Grid, Typography } from "@mui/material";
import { useOrders } from "../../components/providers/OrderProvider";
import { UserMenu } from "./UserMenu";
import { useEffect, useState } from "react";
import { BoatDocument } from "../../types/documents/Boat";
import { useBoatProvider } from "../../components/providers/BoatProvider";
import { TutorialVideo } from "../../components/user/tutorial/TutorialVideo";
import { useTranslation } from "react-i18next";

export const Tutorial = () => {
	const { futureOrders } = useOrders();
	const { getBoatFromUID } = useBoatProvider();
	const [boatToShowTutorial, setBoatToShowTutorial] = useState<Map<string, BoatDocument> | undefined>();
	const { t } = useTranslation();

	useEffect(() => {
		fetchAllBoatFromOrders();
	}, [futureOrders]);

	const fetchAllBoatFromOrders = async () => {
		let boatToShowTutorial = new Map<string, BoatDocument>();

		for (let i = 0; i < futureOrders.length; i++) {
			const boatDocumet = await getBoatFromUID(futureOrders[i].boatUID);
			boatToShowTutorial.set(futureOrders[i].boatUID, boatDocumet);
		}
		setBoatToShowTutorial(boatToShowTutorial);
	};

	return (
		<>
			<Grid container flexWrap="nowrap">
				<Grid item height="100%" className="menu-container">
					<UserMenu />
				</Grid>
				<Grid item xs={10} marginTop="40px" marginLeft="10px" className="landing menu-content">
					<Grid container rowGap="16px">
						<Grid item xs={12} marginBottom="16px">
							<Typography variant="h3">{t("tutorialTitle")}</Typography>
						</Grid>
						{boatToShowTutorial != null &&
							Array.from(boatToShowTutorial.values()).map((boat) => {
								if (boat.videos == null) return <></>;
								return boat.videos.map((video) => {
									let videoURL = new URL(video.url);
									let youtubeID = videoURL.searchParams.get("v");
									if (youtubeID == null) return <></>;
									return (
										<>
											<TutorialVideo youtubeId={youtubeID} name={video.name} />
										</>
									);
								});
							})}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
