import React, { useState, useRef } from "react";
import { Dialog, DialogContent, DialogTitle, Grid, Typography, Button, TextField } from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "../../providers/SnackbackProvider";

const validationSchema = yup.object({
	titleInItalian: yup.string().required("Il titolo in italiano è richiesto"),
	titleInEnglish: yup.string().required("Il titolo in inglese è richiesto"),
	titleInCzech: yup.string().required("Il titolo in ceco è richiesto"),
});
export const AddPdfPopUp = ({
	isPopUpOpen,
	handleClosePopup,
	handleAddPdf,
}: {
	isPopUpOpen: boolean;
	handleClosePopup: () => void;
	handleAddPdf: (name: any, pdfFile: File) => Promise<void>;
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const { openSnackbar, openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();

	const [temporaryPdfPath, setTemporaryPdfPath] = useState<string>("");
	const [pdfFile, setPdfFile] = useState<File>();
	const formik = useFormik({
		initialValues: {
			titleInItalian: "",
			titleInEnglish: "",
			titleInCzech: "",
		},

		validationSchema: validationSchema,
		onSubmit: async (values, { resetForm }) => await handleSubmit(values, resetForm),
	});

	/**
	 *
	 * @param values.type identifies 0:A settimana, 1: A pezzo ,2: A prenotazione
	 */
	const handleSubmit = async (values: any, resetForm: any) => {
		if (pdfFile == null) return;
		await handleAddPdf(
			{
				it: values.titleInItalian,
				en: values.titleInEnglish,
				cs: values.titleInCzech,
			},
			pdfFile
		);
		resetForm();
		handleClosePopup();
	};
	const handlePdfLoad = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		try {
			if (inputRef == null || inputRef.current == null || inputRef.current.files == null) return;
			const file = inputRef.current?.files[0];
			setPdfFile(file);
		} catch (err) {
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		}
	};
	return (
		<>
			<Dialog open={isPopUpOpen} onClose={handleClosePopup} fullWidth maxWidth="md">
				<DialogTitle mb={3}>
					<Typography variant="h4">CARICA PDF</Typography>
				</DialogTitle>
				<DialogContent>
					<>
						<form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
							<Grid container rowGap={2}>
								<Grid item xs={12}>
									<Grid container justifyContent={"flex-start"}>
										<Grid item xs>
											<input
												ref={inputRef}
												id="input-pdf"
												type="file"
												style={{ display: "none" }}
												value={temporaryPdfPath}
												onChange={(e) => handlePdfLoad(e)}
												accept="application/pdf"
											/>

											<Button
												className="form-button subbutton"
												onClick={() => {
													document.getElementById("input-pdf")?.click();
												}}
											>
												Carica un pdf
											</Button>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<TextField
										className="input-form"
										sx={{ width: { xs: "100%" } }}
										id="titleInItalian"
										name="titleInItalian"
										label="Titolo in italiano"
										variant={"filled"}
										value={formik.values.titleInItalian}
										onChange={formik.handleChange}
										error={formik.touched.titleInItalian && Boolean(formik.errors.titleInItalian)}
										helperText={formik.touched.titleInItalian && formik.errors.titleInItalian}
									/>
								</Grid>

								<Grid item xs={12}>
									<TextField
										className="input-form"
										sx={{ width: { xs: "100%" } }}
										id="titleInEnglish"
										name="titleInEnglish"
										label="Titolo in inglese"
										variant={"filled"}
										value={formik.values.titleInEnglish}
										onChange={formik.handleChange}
										error={formik.touched.titleInEnglish && Boolean(formik.errors.titleInEnglish)}
										helperText={formik.touched.titleInEnglish && formik.errors.titleInEnglish}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										className="input-form"
										sx={{ width: { xs: "100%" } }}
										id="titleInCzech"
										name="titleInCzech"
										label="Titolo in ceco"
										variant={"filled"}
										value={formik.values.titleInCzech}
										onChange={formik.handleChange}
										error={formik.touched.titleInCzech && Boolean(formik.errors.titleInCzech)}
										helperText={formik.touched.titleInCzech && formik.errors.titleInCzech}
									/>
								</Grid>

								<Grid item xs={12} sx={{ marginTop: "16px" }} display="flex" justifyContent="center">
									<Button type="submit" className="form-button">
										CREA
									</Button>
								</Grid>
							</Grid>
						</form>
					</>
				</DialogContent>
			</Dialog>
		</>
	);
};
