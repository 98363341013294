import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { createContext, useContext, useEffect } from "react";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { FirebaseProviderType, MapEmulators } from "../../types/providers/FirebaseProvider";
import { ReCaptchaEnterpriseProvider, initializeAppCheck } from "firebase/app-check";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_apiKey,
	authDomain: process.env.REACT_APP_authDomain,
	projectId: process.env.REACT_APP_projectId,
	storageBucket: process.env.REACT_APP_storageBucket,
	messagingSenderId: process.env.REACT_APP_messagingSenderId,
	appId: process.env.REACT_APP_appId,
};

const FirebaseContext = createContext<FirebaseProviderType>({} as FirebaseProviderType);
export const FirebaseProvider = ({ children }: { children: JSX.Element }) => {
	const myApp = initializeApp(firebaseConfig);
	const appCheck = initializeAppCheck(myApp, {
		provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_siteKey as string),
		isTokenAutoRefreshEnabled: true,
	});
	const myAuth = getAuth(myApp);
	const myFS = getFirestore(myApp);
	const myStorage = getStorage(myApp);

	useEffect(() => {
		const shouldUseEmulator = process.env.REACT_APP_USING_EMULATOR === "TRUE" ? true : false;

		if (shouldUseEmulator) {
			let mapEmulators: MapEmulators = {} as MapEmulators;

			let FS_HOST = "localhost";
			let FS_PORT = 8080;

			if (FS_HOST && FS_PORT) {
				connectFirestoreEmulator(myFS, FS_HOST, FS_PORT);
				console.log(`firestore().useEmulator(${FS_HOST}, ${FS_PORT})`);
				mapEmulators.FS_HOST = FS_HOST;
				mapEmulators.FS_PORT = FS_PORT;
			}

			let AUTH_HOST = "localhost";
			let AUTH_PORT = 9099; // or whatever you set the port to in firebase.json
			if (AUTH_HOST && AUTH_PORT) {
				let AUTH_URL = `http://${AUTH_HOST}:${AUTH_PORT}`;
				console.log(`connectAuthEmulator(${AUTH_URL}, {disableWarnings: true})`);
				//    warns you not to use any real credentials -- we don't need that noise :)
				connectAuthEmulator(myAuth, AUTH_URL, { disableWarnings: true });

				mapEmulators.AUTH_HOST = AUTH_HOST;
				mapEmulators.AUTH_PORT = AUTH_PORT;
				mapEmulators.AUTH_URL = AUTH_URL;
			}

			let STORAGE_HOST = "localhost";
			let STORAGE_PORT = 9199; // or whatever you have it set to in firebase.json
			if (STORAGE_HOST && STORAGE_PORT) {
				console.log(`connectStorageEmulator(${STORAGE_HOST}, ${STORAGE_PORT})`);
				connectStorageEmulator(myStorage, STORAGE_HOST, STORAGE_PORT);

				mapEmulators.STORAGE_HOST = STORAGE_HOST;
				mapEmulators.STORAGE_PORT = STORAGE_PORT;
			}

			console.log("FIREBASE STARTUP: using Firebase emulator:", JSON.stringify(mapEmulators, null, 2));
		}
	}, [myAuth, myFS]);

	const firebaseContextData = {
		myApp,
		myAuth,
		myFS,
		myStorage,
		appCheck,
	};

	return <FirebaseContext.Provider value={firebaseContextData}>{children}</FirebaseContext.Provider>;
};
export const useFirebase = () => useContext(FirebaseContext);
function setUsingEmulators(arg0: boolean) {
	throw new Error("Function not implemented.");
}

function setEmulatorsConfig(mapEmulators: MapEmulators) {
	throw new Error("Function not implemented.");
}
