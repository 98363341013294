import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router";

import { LoginBoat } from "../pages/boat/LoginBoat";
import { BoatOrders } from "../pages/boat/BoatOrders";
import { BoatSettings } from "../pages/boat/BoatSettings";
import { UserLanding } from "../pages/user/landing/UserLanding";
import { LoginUser } from "../pages/user/LoginUser";
import { AdminPage } from "../pages/admin/AdminPage";
import { UserSettings } from "../pages/user/UserSettings";
import { UserCheckIn } from "../pages/user/orders/UserCheckIn";
import { RedirectPagePayment } from "../pages/RedirectPagePayment";
import { Tutorial } from "../pages/user/Tutorial";
import "../styles/Snackbar.scss";
import { BoatChecks } from "../pages/boat/BoatChecks";
import BoatProtection from "./routesProtection/BoatProtection";
import UserProtection from "./routesProtection/UserProtection";
import AdminProtection from "./routesProtection/AdminProtection";
import { Provider } from "./Providers";
import { Snackbars } from "./Snackbars";
import { UserBoatChecks } from "../pages/user/orders/UserBoatChecks";
import { BoatCheckouts } from "../pages/boat/BoatCheckouts";
import { UserCheckouts } from "../pages/user/orders/UserCheckouts";
import { BoatPastOrders } from "../pages/boat/BoatPastOrders";
import { UserRatings } from "../pages/user/ratings/UserRatings";
import { BoatFeedback } from "../pages/boat/BoatFeedback";
import "../styles/Checkout.scss";
const Root = () => {
	return (
		<>
			<BrowserRouter>
				<Provider>
					<>
						<Snackbars />
						<Routes>
							<Route
								path="/login/boat"
								element={
									<>
										<LoginBoat />
									</>
								}
							/>
							<Route
								path="/"
								element={
									<>
										<LoginUser />
									</>
								}
							/>
							<Route
								path="/redirect/payment"
								element={
									<>
										<RedirectPagePayment />
									</>
								}
							/>

							<Route path="/boat" element={<BoatProtection />}>
								<Route
									path="orders"
									element={
										<>
											<BoatOrders />
										</>
									}
								/>
								<Route
									path="checkin"
									element={
										<>
											<BoatChecks />
										</>
									}
								/>
								<Route
									path="checkout"
									element={
										<>
											<BoatCheckouts />
										</>
									}
								/>
								<Route
									path="history"
									element={
										<>
											<BoatPastOrders />
										</>
									}
								/>
								<Route
									path="settings"
									element={
										<>
											<BoatSettings />
										</>
									}
								/>
								<Route
									path="feedback"
									element={
										<>
											<BoatFeedback />
										</>
									}
								/>
							</Route>
							<Route path="/user" element={<UserProtection />}>
								<Route
									path="landing"
									element={
										<>
											<UserLanding />
										</>
									}
								/>
								<Route path="checkin" element={<UserBoatChecks />} />
								<Route
									path="boarding"
									element={
										<>
											<UserCheckIn />
										</>
									}
								/>
								<Route
									path="checkout"
									element={
										<>
											<UserCheckouts />
										</>
									}
								/>

								<Route
									path="settings"
									element={
										<>
											<UserSettings />
										</>
									}
								/>
								<Route
									path="tutorial"
									element={
										<>
											<Tutorial />
										</>
									}
								/>
								<Route path="feedback" element={<UserRatings />} />
							</Route>

							<Route path="/admin" element={<AdminProtection />}>
								<Route
									path="/admin"
									element={
										<>
											<AdminPage />
										</>
									}
								/>
							</Route>
						</Routes>
					</>
				</Provider>
			</BrowserRouter>
		</>
	);
};

export default Root;
