import { useState } from "react";
import { Dialog, DialogContent, DialogTitle, Grid, Button, TextField, Typography, MenuItem } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
// import Datetime from "react-datetime";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import "react-datetime/css/react-datetime.css";
import "../../../styles/Buttons.scss";
import { Formik, Form } from "formik";
import { OrderCreationParams } from "../../../types/documents/Order";
import { useAuth } from "../../providers/AuthProvider";
import { useSnackbar } from "../../providers/SnackbackProvider";

const validationSchema = yup.object({
	name: yup.string().required("Il nome è richiesto"),
	surname: yup.string().required("Il cognome è richista"),
	email: yup.string().email("Inserire un email valida").required("L'email è richiesta"),
	startDate: yup.date().required("La data di imbarco è richiesta"),
	endDate: yup.date().when("startDate", (startDate, schema) => startDate && schema.min(startDate, "Lo sbarco deve essere dopo l'imbarco")),
	availableSeats: yup.number().min(0, "I posti disponibili sono richiesti").required("I posti disponibili sono richiesti"),
});

export const OrderCreationPopUp = ({
	isPopUpOpen,
	handleClosePopup,
	handleAddOrder,
}: {
	isPopUpOpen: boolean;
	handleClosePopup: () => void;
	handleAddOrder: (orderParams: OrderCreationParams) => Promise<void>;
}) => {
	const [isPopUpCrendentialsOpen, setIsPopUpCrendentialsOpen] = useState<boolean>(false);
	const { boatProfile } = useAuth();
	const { openSnackbar, openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();

	const handleSubmit = async (values: { name: string; surname: string; email: string; startDate: Date; endDate: Date; availableSeats: number }) => {
		openLoadingSnackbar();
		try {
			if (boatProfile == null) throw new Error("Documento della barca non trovato, ricaricare la pagina");
			if (values.availableSeats === 0) throw new Error("Deve esserci almeno un posto disponibile");
			values.startDate.setHours(0, 0, 0, 0);
			values.endDate.setHours(23, 59, 59, 0);

			const orderParams: OrderCreationParams = {
				boatUID: boatProfile.uid,
				name: values.name,
				surname: values.surname,
				startDate: Math.round(values.startDate.getTime() / 1000),
				endDate: Math.round(values.endDate.getTime() / 1000),
				availableSeats: values.availableSeats,
				userEmail: values.email,
				deposit: boatProfile.deposit,
			};

			await handleAddOrder(orderParams);
		} catch (error) {
			const message = (error as any).message;
			openSnackbar("Error", message, "error");
		} finally {
			closeLoadingSnackbar();
		}
	};
	const handleClosePopUpCrendentials = () => {
		setIsPopUpCrendentialsOpen(false);
	};

	return (
		<>
			<Dialog open={isPopUpOpen} onClose={handleClosePopup} fullWidth maxWidth="md">
				<DialogTitle mb={3}>
					<Typography variant="h4">CREA UN ORDINE</Typography>
				</DialogTitle>
				<DialogContent>
					<Formik
						initialValues={{ name: "", surname: "", email: "", startDate: new Date(), endDate: new Date(), availableSeats: 0 }}
						onSubmit={async (values) => {
							await handleSubmit(values);
						}}
						validationSchema={validationSchema}>
						{({ isSubmitting, touched, errors, values, setFieldValue, handleChange }) => (
							<Form>
								<Grid container justifyContent={"space-between"} rowGap={4}>
									<Grid item xs={5}>
										<TextField
											className="input-form"
											sx={{ width: { xs: "100%" } }}
											id="name"
											name="name"
											label="Nome"
											variant={"filled"}
											value={values.name}
											onChange={handleChange}
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
										/>
									</Grid>
									<Grid item xs={5}>
										<TextField
											className="input-form"
											sx={{ width: { xs: "100%" } }}
											id="surname"
											name="surname"
											label="Cognome"
											variant={"filled"}
											value={values.surname}
											onChange={handleChange}
											error={touched.surname && Boolean(errors.surname)}
											helperText={touched.surname && errors.surname}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											className="input-form"
											sx={{ width: { xs: "100%" } }}
											id="email"
											name="email"
											label="Email"
											variant={"filled"}
											value={values.email}
											onChange={handleChange}
											error={touched.email && Boolean(errors.email)}
											helperText={touched.email && errors.email}
										/>
									</Grid>
									<Grid item xs={12} className="input-date-container" sx={{ background: "white" }}>
										<DatePicker
											className="input-date"
											id="startDate"
											name="startDate"
											selected={values.startDate && new Date(values.startDate)}
											onChange={(date: Date | null) => setFieldValue("startDate", date)}
										/>
										<label className="input-date-label">Data di imbarco</label>
										<label className="input-date-error">{touched.startDate && <>{errors.startDate}</>}</label>
									</Grid>
									<Grid item xs={12} className="input-date-container" sx={{ background: "white" }}>
										<DatePicker
											className="input-date"
											id="endDate"
											name="endDate"
											selected={values.endDate && new Date(values.endDate)}
											onChange={(date: Date | null) => setFieldValue("endDate", date)}
										/>
										<label className="input-date-label">Data di sbarco</label>
										<label className="input-date-error">{touched.endDate && <>{errors.endDate}</>}</label>
									</Grid>
									<Grid item xs={12}>
										<TextField
											type="number"
											className="input-form"
											sx={{ width: { xs: "100%" } }}
											id="availableSeats"
											name="availableSeats"
											label="Max. posti disponibili sulla barca"
											variant={"filled"}
											value={values.availableSeats}
											placeholder="0"
											onClick={() => {
												if (values.availableSeats === 0) setFieldValue("availableSeats", "");
											}}
											onChange={handleChange}
											error={touched.availableSeats && Boolean(errors.availableSeats)}
											helperText={touched.availableSeats && errors.availableSeats}
										/>
									</Grid>
									<Grid item xs={12} sx={{ marginTop: "16px" }}>
										<Grid container justifyContent={"center"}>
											<Grid item xs={4}>
												<Button type="submit" fullWidth className="form-button ">
													CREA ORDINE
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Form>
						)}
					</Formik>
				</DialogContent>
			</Dialog>
		</>
	);
};
