import { Dialog, DialogContent, DialogTitle, Grid, Typography, Button } from "@mui/material";

export const EliminateOrderPopUp = ({
	isPopUpOpen,
	handleClosePopup,
	handleEliminateOrder,
}: {
	isPopUpOpen: boolean;
	handleClosePopup: () => void;
	handleEliminateOrder: () => Promise<void>;
}) => {
	return (
		<>
			<Dialog open={isPopUpOpen} onClose={handleClosePopup} fullWidth maxWidth="md">
				<DialogTitle mb={3}>
					<Typography variant="h4">Elimina ordine</Typography>
				</DialogTitle>
				<DialogContent>
					<>
						<Grid container rowGap={2} className="input-form-container-crew-member">
							<Grid item xs={12}>
								<Typography variant="h4">STAI PER ELIMINARE DEFINITAVEMENTE L’ORDINE</Typography>

								<Typography variant="body1"> Sei sicuro di voler eliminare questo ordine barca in maniera definitiva</Typography>
							</Grid>
							<Grid item xs={12} sx={{ marginTop: "16px" }}>
								<Grid container justifyContent={"center"}>
									<Grid item xs display="flex" justifyContent="center">
										<Button onClick={handleEliminateOrder} className="form-button ">
											Elimina
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</>
				</DialogContent>
			</Dialog>
		</>
	);
};
