import { Button, Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import $ from "jquery";
import { Order } from "../../../../types/documents/Order";
import { getDateIntervalFromTimestamps } from "../../../../utils/dateOperation";
import { orderStatuses } from "../../../providers/OrderProvider";
import { useEffect, useState } from "react";
import { useUserProvider } from "../../../providers/UserProviderImpl";
import { UserDocument } from "../../../../types/documents/User";
import { BoatCheckContent } from "./BoatCheckContent";
import { checkboatStatuses } from "../../../../utils/constants";
import { BoatDocument } from "../../../../types/documents/Boat";
import { useBoatProvider } from "../../../providers/BoatProvider";

export const BoatCheck = ({
	order,
	index,
	expanded,
	handleChangeAccordion,
}: {
	order: Order;
	index: number;
	expanded: string | boolean;
	handleChangeAccordion: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}) => {
	const { getUserFromUid } = useUserProvider();
	const { getBoatFromUID } = useBoatProvider();
	const [user, setUser] = useState<UserDocument>({} as UserDocument);
	const [boat, setBoat] = useState<BoatDocument>({} as BoatDocument);
	const [statusName, setStatusName] = useState<string>("");
	const [controlSameDate, setControlSameDate] = useState<boolean>(true);
	const [isBoarding, setIsBoarding] = useState<boolean>(false);
	useEffect(() => {
		(async () => {
			const user = await getUserFromUid(order.userUID);
			const boat = await getBoatFromUID(order.boatUID);
			setUser(user);
			setBoat(boat);
		})();
		const startDate = new Date(order.startDate * 1000);

		if (order.status === orderStatuses.ACCEPTED && (!controlSameDate || datesAreOnSameDay(startDate, new Date()))) {
			if (order.checkBoat?.status === checkboatStatuses.SIGNED) {
				setStatusName("Richiesta di firma inoltrata");
				$(`#panel${order.orderUID}bh-header .color-action`).addClass("pending-action");
				setIsBoarding(true);
			} else if (order.checkBoat?.status === checkboatStatuses.COUNTER_SIGNED) {
				setStatusName("Check-In effettuato");
				$(`#panel${order.orderUID}bh-header .color-action`).addClass("pending-action");
				setIsBoarding(true);
			} else {
				setStatusName("Check-In disponibile");
				$(`#panel${order.orderUID}bh-header .color-action`).addClass("pending-action");
				setIsBoarding(true);
			}
		} else {
			setStatusName("Check-In non disponibile");
			$(`#panel${order.orderUID}bh-header .color-action`).removeClass("pending-action");
		}
	}, [index, order, controlSameDate]);
	const datesAreOnSameDay = (orderStartDate: Date, todayDate: Date) =>
		orderStartDate.getFullYear() === todayDate.getFullYear() &&
		orderStartDate.getMonth() === todayDate.getMonth() &&
		orderStartDate.getDate() === todayDate.getDate();
	return (
		<>
			<Accordion className="accordion" expanded={expanded === `panel${order.orderUID}`} onChange={handleChangeAccordion(`panel${order.orderUID}`)}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon className={`color-action pending-action`} />}
					aria-controls={`panel${order.orderUID}bh-content`}
					id={`panel${order.orderUID}bh-header`}>
					<Grid container justifyContent="space-between">
						<Grid item xs={12} md={4}>
							<Typography className={`color-action pending-action`} variant="h4" color="black">
								{user.name} {user.surname}
							</Typography>
						</Grid>
						<Grid item xs={12} md={3}>
							<Typography className={`color-action pending-action`} variant="h4" color="black">
								{getDateIntervalFromTimestamps(order.startDate, order.endDate, "it")}
							</Typography>
						</Grid>
						<Grid item xs={12} md={5}>
							<Typography className={`color-action pending-action`} variant="h4" color="black">
								{statusName}
							</Typography>
						</Grid>
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					{boat.isTest && (
						<Button
							variant="text"
							size="small"
							sx={{ fontSize: "16px", marginTop: "16px", marginBottom: "16px" }}
							onClick={() => setControlSameDate(false)}>
							Sorpassa controllo stesso giorno
						</Button>
					)}
					<BoatCheckContent order={order} isBoarding={isBoarding} />
				</AccordionDetails>
			</Accordion>
		</>
	);
};
