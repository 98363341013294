import { Table, TableCell, TableBody, TableRow, Typography } from "@mui/material";

type HeadTableInfoProps = {
	startDate: number;
	endDate: number;
	boatLicensePlate: string | undefined;
	boatUsername: string | undefined;
};

export const TableInfo = ({ startDate, endDate, boatLicensePlate, boatUsername }: HeadTableInfoProps) => (
	<Table className="table-info">
		<TableBody>
			<TableRow>
				<TableCell align="center" colSpan={2}>
					<Typography variant="h5" fontWeight="bold">
						ALLEGATO A - PŘÍLOHA A - ATTACHMENT A
					</Typography>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell align="center" colSpan={1}>
					<Typography variant="h5" fontWeight="bold">
						DEL CONTRATTO DI LOCAZIONE
					</Typography>
					<Typography variant="h5" fontWeight="bold">
						SMLOUVY O PRONÁJMU
					</Typography>
					<Typography variant="h5" fontWeight="bold">
						RENTAL AGREEMENT
					</Typography>
				</TableCell>
				<TableCell align="center" colSpan={1}>
					<Typography variant="h5" fontWeight="bold">
						DAL/ OD/ FROM {new Date(startDate * 1000).toLocaleDateString()}
					</Typography>
					<Typography variant="h5" fontWeight="bold">
						AL/ DO/ TO {new Date(endDate * 1000).toLocaleDateString()}
					</Typography>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell align="right" colSpan={1}>
					<Typography variant="h5">IMBARCAZIONE A VELA CON MOTORE AUSILIARIO TG</Typography>
				</TableCell>
				<TableCell align="left" rowSpan={3} colSpan={1} width="45%">
					<Typography variant="h2">
						{(boatLicensePlate || "no license plate").toUpperCase()} {(boatUsername || "username").toUpperCase()}
					</Typography>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell align="right" colSpan={1}>
					<Typography variant="h5">MOTOROVÁ PLACHETNICE REJSTŘÍKOVÉ ČÍSLO</Typography>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell align="right" colSpan={1}>
					<Typography variant="h5">SAIL BOAT WITH AUXILIARY ENGINE REG. NUMBER</Typography>
				</TableCell>
			</TableRow>
		</TableBody>
	</Table>
);
