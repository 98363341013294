import { Grid, Typography } from "@mui/material";
import { DownloadDocuments } from "../../../components/user/settings/DownloadDocuments";
import { useTranslation } from "react-i18next";

export const LandingDocs = () => {
	const { t } = useTranslation();

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="h3">{t("documents")}</Typography>
				</Grid>
				<Grid item xs={12}>
					<DownloadDocuments />
				</Grid>
			</Grid>
		</>
	);
};
