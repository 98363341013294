import { TranslationType } from "../types/Generic";
import { BoatDocument } from "../types/documents/Boat";
import { Order } from "../types/documents/Order";
import { UserDocument } from "../types/documents/User";
import { OrderToDelete } from "../types/orders/OrderToDelete";
import { GeneratePasswordResetLinkResponse } from "../types/responses/GeneratePasswordResetLinkResponse";
import { dateFormat } from "./dateOperation";

type PasswordReset = {
	language: keyof TranslationType;
} & Pick<GeneratePasswordResetLinkResponse, "info" | "link">;

type OrderCreated = {
	user: UserDocument;
	boat: BoatDocument;
	order: Order;
	language: keyof TranslationType;
	isUserNew: boolean;
	newEmail: string;
	newPassword: string;
};

type OrderDenied = {
	user: UserDocument;
	order: OrderToDelete;
	language: keyof TranslationType;
};

type OrderAccepted = {
	user: UserDocument;
	boat: BoatDocument;
	order: Order;
	language: keyof TranslationType;
};

const footerMailIT = `Buon vento e a presto,<br/>
Il Team della Gais<br/><br/>

Contatti:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;+39 3397245989<br/>
&nbsp;&nbsp;&nbsp;&nbsp;+420 736117206<br/>
&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:info@gaischarter.com" target="_blank" rel="noreferrer noopener nofollow">info@gaischarter.com</a><br/>
&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:gaischarter@gmail.com" target="_blank" rel="noreferrer noopener nofollow">gaischarter@gmail.com</a>`;

const footerMailCS = `Kontakt pro podporu<br/>
&nbsp;&nbsp;&nbsp;&nbsp;+39 3397245989<br/>
&nbsp;&nbsp;&nbsp;&nbsp;+420 736117206<br/>
&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:info@gaischarter.com" target="_blank" rel="noreferrer noopener nofollow">info@gaischarter.com</a><br/>
&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:gaischarter@gmail.com" target="_blank" rel="noreferrer noopener nofollow">gaischarter@gmail.com</a>`;

const footerMailEN = `Contact for support<br/>
&nbsp;&nbsp;&nbsp;&nbsp;+39 3397245989<br/>
&nbsp;&nbsp;&nbsp;&nbsp;+420 736117206<br/>
&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:info@gaischarter.com" target="_blank" rel="noreferrer noopener nofollow">info@gaischarter.com</a><br/>
&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:gaischarter@gmail.com" target="_blank" rel="noreferrer noopener nofollow">gaischarter@gmail.com</a><br/>`;

export const generateMessagePasswordReset = ({ info, link, language }: PasswordReset) => {
	let message = "",
		subject = "";
	switch (language) {
		case "it":
			message = `Naviga verso la tua nuova password! 🌊<br/><br/>

        Ciao ${info.name} ${info.surname},<br/>
        Sembra che tu abbia dimenticato la tua password. Niente paura, siamo qui per aiutarti a rimetterti in rotta! 🚤<br/><br/>

        Clicca sul link qui sotto per generare la tua nuova password e tornare a navigare con noi:<br/>
        <a href="${link}" target="_blank" rel="noreferrer noopener nofollow">Reimposta la tua password</a><br/><br/>

        <b>Se non hai richiesto il recupero della password, ignora semplicemente questa email.<br/>
        La tua sicurezza è la nostra priorità!</b><br/><br/>

        Ti aspettiamo a bordo! Ricorda che ogni viaggio inizia con un semplice clic. ⚓<br/><br/>
        
        ${footerMailIT}
        `;
			subject = "Naviga verso la tua nuova password!";
			break;

		case "cs":
			message = "";
			subject = "";
			break;

		default:
			message = "";
			subject = "";
			break;
	}

	return { message, subject };
};

export const generateMessageOrderCreated = ({ order, user, boat, newEmail, newPassword, language, isUserNew }: OrderCreated) => {
	let message = "",
		subject = "";
	switch (language) {
		case "it":
			message = `Ciao ${user.name} ${user.surname},<br/><br/>

				${
					isUserNew
						? `Siamo entusiasti di darti il benvenuto nella nostra famiglia di charter nautico!<br/>
				Abbiamo creato un account per te in modo che tu possa gestire e visualizzare tutti i dettagli del tuo viaggio in mare.`
						: `Siamo felici di rivederti di nuovo!<br/>
						Abbiamo registrato un tuo nuovo ordine e non vediamo l'ora di offrirti un'altra indimenticabile avventura in mare.`
				}
				<br/><br/>

				<b>Dettagli del tuo viaggio</b>:<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Data di imbarco: <b>${boat.boardingTime} / ${dateFormat(order.startDate * 1000, language)}</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Data di sbarco: <b>${boat.disembarkationTime} / ${dateFormat(order.endDate * 1000, language)}</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Nome della barca: <b>${boat.username}</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Numero di persone a bordo: <b>${order.availableSeats}</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Porto di partenza: <b>${boat.port}</b><br/>

				${
					isUserNew
						? `<div style="display:inline-block; border:2px solid red; padding: .5em;">
					<b>Accesso al tuo account</b>:<br/>
					&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; E-mail di accesso: <i>${newEmail}</i><br/>
					&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Password autogenerata: <i>${newPassword}</i>
				</div>
				<br/>`
						: ""
				}

				<br/><b>Come fare il check-in</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Clicca su questo link <a href="https://gaischeckin.com" target="_blank" rel="noreferrer noopener nofollow">gaischeckin.com</a>, inserisci le tua email e la password autogenerata seguendo le istruzioni per completare il check-in.<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Conferma i dettagli del tuo viaggio e sarai pronto a salpare!<br/><br/>
				
				${
					isUserNew
						? `<b>Come cambiare la tua password</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Accedi al tuo account con la password autogenerata.<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Vai alla sezione "Impostazioni" del tuo profilo.<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Clicca su "Cambia password".<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Inserisci la tua vecchia password e scegli una nuova.<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Conferma e... voilà! La tua passowrd è stata aggiornata.`
						: `<b>Se hai dimenticato la tua password, non preoccuparti! Puoi facilmente recuperarla dalla schermata di login sul nostro sito.</b>`
				}<br/><br/>

				Se hai domande o hai bisogno di assistenza, siamo qui per aiutarti.<br/>
				Non vediamo l'ora di vederti a bordo e di offrirti un'esperienza indimenticabile!<br/><br/>
				
                ${footerMailIT}
				`;
			subject = "Benvenuto a bordo con Gais!";
			break;

		case "cs":
			message = `Vážený zákazníku ${user.name} ${user.surname},<br/><br/>
				
				Děkujeme za rezervaci vašeho výletu u nás!<br/>
				Jsme nadšeni, že vás máme na palubě.<br/><br/>
				
				Zde je souhrn informací o vaší rezervaci:<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;Jméno plavidla: <b>${boat.username}</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;Datum odjezdu: <b>${new Date(order.startDate * 1000).toLocaleDateString()}</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;Datum příjezdu: <b>${new Date(order.endDate * 1000).toLocaleDateString()}</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;Přístav odjezdu: <b>${boat.port}</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;Počet osob na palubě: <b>${order.availableSeats}</b>

				<div style="display:inline-block; border:2px solid red; padding: .5em;">
					Vaše nové přihlašovací údaje jsou následující:<br/>
					&nbsp;&nbsp;&nbsp;&nbsp;E-mail: <i>${newEmail}</i><br/>
					&nbsp;&nbsp;&nbsp;&nbsp;Heslo: <i>${newPassword}</i>
				</div>
				<br/><br/>

				<i><b>Uchovejte tyto informace na bezpečném místě.</b></i><br/>
				Pro přístup k informacím o vaší rezervaci a správu cesty budete potřebovat svou e-mailovou adresu a heslo.<br/><br/>
				
				<b>Tutoriál online registrace</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;1) Přihlaste se na webovou stránku <a href="https://gaischeckin.com" target="_blank" rel="noreferrer noopener nofollow">gaischeckin.com</a> s přihlašovacími údaji uvedenými v e-mailu.<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;2) Přejděte do sekce "Registrace".<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;3) Klikněte na objednávku, pro kterou chcete provést online registraci.<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;4) Vyplňte formulář a nahrajte požadované dokumenty.<br/><br/>
				
				Jakmile formulář vyplníte a odešlete, jeden z našich operátorů vaši žádost zkontroluje.<br/>
				Pokud máte jakékoli dotazy nebo potřebujete pomoc, neváhejte nás kontaktovat. Jsme tu, abychom vám pomohli prožít nádherný zážitek z plavby.<br/><br/>
				
				S pozdravem,<br/>
				Tým Gais<br/><br/>
				
				Kontakt pro podporu<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;+39 3397245989<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;+420 736117206<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:info@gaischarter.com" target="_blank" rel="noreferrer noopener nofollow">info@gaischarter.com</a><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:gaischarter@gmail.com" target="_blank" rel="noreferrer noopener nofollow">gaischarter@gmail.com</a>`;
			subject = "";
			break;

		default:
			message = `Dear ${user.name} ${user.surname},<br/><br/>
			
				Thank you for booking your trip with us!<br/>
				We are excited to have you on board.<br/><br/>
				
				Here is a summary of your booking information:<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;Boat name: <b>${boat.username}</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;Date of departure: <b>${new Date(order.startDate * 1000).toLocaleDateString()}</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;Date of arrival: <b>${new Date(order.endDate * 1000).toLocaleDateString()}</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;Port of departure: <b>${boat.port}</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;Number of people on board: <b>${order.availableSeats}</b>

				<div style="display:inline-block; border:2px solid red; padding: .5em;">
					Your new credentials are as follows:<br/>
					&nbsp;&nbsp;&nbsp;&nbsp;E-mail: <i>${newEmail}</i><br/>
					&nbsp;&nbsp;&nbsp;&nbsp;Password: <i>${newPassword}</i>
				</div>
				<br/><br/>
				
				<i><b>Please keep this information in a safe place.</b></i><br/>
				You will need your email address and password to access your booking information and manage your trip.<br/><br/>
				
				<b>Online Onboarding Tutorial</b><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;1) Log in to the website <a href="https://gaischeckin.com" target="_blank" rel="noreferrer noopener nofollow">gaischeckin.com</a> with the credentials given in the email.<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;2) Go to the "Boarding" section.<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;3) Click on the order you want to do the online onboarding for.<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;4) Fill in the form and upload the required documents.<br/><br/>
				
				As soon as you have filled in and submitted the form, one of our operators will review your submission.<br/>
				If you have any questions or need assistance, please do not hesitate to contact us. We are here to help you have a wonderful sailing experience.<br/><br/>
				
				Sincerely,<br/>
				Gais Team<br/><br/>
				
				Contact for support<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;+39 3397245989<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;+420 736117206<br/>
				&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:info@gaischarter.com" target="_blank" rel="noreferrer noopener nofollow">info@gaischarter.com</a><br/>
				&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:gaischarter@gmail.com" target="_blank" rel="noreferrer noopener nofollow">gaischarter@gmail.com</a><br/>`;
			subject = "";
			break;
	}

	return { message, subject };
};

export const generateMessageOrderDenied = ({ user, order, language }: OrderDenied) => {
	let message = "",
		subject = "";

	switch (language) {
		case "it":
			message = `Ciao ${user.name} ${user.surname},<br/><br/>
            
            Ci dispiace informarti che ci sono alcuni dettagli mancanti oppure non corretti relativi al tuo imbarco.<br/>
            Vogliamo assicurarci che tutto sia in ordine per garantirti un viaggio sicuro e senza intoppi.<br/><br/>

            <b>Sezioni da ricompilare:</b><br/>
            ${order.sectionsToBeReset.extras ? "&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Extra<br/>" : ""}
            ${order.sectionsToBeReset.identityCard ? "&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Documenti d'Identità<br/>" : ""}
            ${order.sectionsToBeReset.nauticalLicense ? "&nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Patente Nautica<br/>" : ""}
            <br/>

            <b>Nota dell'operatore Gais:</b><br/>
            ${order.reason.length > 0 ? order.reason : "Nessuna nota."}<br/><br/>

            Ti preghiamo di accedere al tuo account e completare o correggere le informazioni richieste il prima possibile.<br/>
            Una volta fatto, procederemo con la conferma del tuo imbarco.<br/>
            Siamo qui per aiutarti in ogni passo del processo. <br/><br/>

            Se hai domande o hai bisogno di assistenza, non esitare a contattarci.<br/>
            Grazie per la tua comprensione e collaborazione.<br/>
            Non vediamo l'ora di vederti a bordo!<br/><br/>

            ${footerMailIT}
            `;
			subject = "Attenzione: dettagli mancanti per il tuo imbarco con la Gais";
			break;

		case "cs":
			message = "";
			subject = "";
			break;

		default:
			message = "";
			subject = "";
			break;
	}

	return { message, subject };
};

export const generateMessageOrderAccepted = ({ user, boat, order, language }: OrderAccepted) => {
	let message = "",
		subject = "";

	switch (language) {
		case "it":
			message = `Ciao ${user.name} ${user.surname},<br/><br/>
            Abbiamo ottime notizie per te!<br/>
            Dopo aver esaminato tutti i dettagli del tuo imbarco, siamo felici di informarti che è stato accettato e confermato.<br/>
            Tutto è pronto per la tua prossima avventura in mare con noi!<br/><br/>

            <b>Dettagli del tuo viaggio</b>:<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Data di imbarco: <b>${boat.boardingTime} / ${dateFormat(order.startDate * 1000, language)}</b><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Data di sbarco: <b>${boat.disembarkationTime} / ${dateFormat(order.endDate * 1000, language)}</b><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Nome della barca: <b>${boat.username}</b><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Numero di persone a bordo: <b>${order.availableSeats}</b><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&#x2192; Porto di partenza: <b>${boat.port}</b><br/><br/>

            <b><i>Ti consigliamo di controllare nuovamente tutti i dettagli e di prepararti per il viaggio.</b></i>
            <br/><br/>

            Se hai delle domande o hai bisogno di ulteriori informazioni, siamo qui per aiutarti.<br/>
            Grazie per aver scelto la Gais per la tua esperienza del charter nautico.<br/> 
            Non vediamo l'ora di darti il benvenuto a bordo e di offrirti un viaggio indimenticabile!<br/><br/>

            ${footerMailIT}
            `;
			subject = "Imbarco confermato con la Gais!";
			break;

		case "cs":
			message = "";
			subject = "";
			break;

		default:
			message = "";
			subject = "";
			break;
	}

	return { message, subject };
};
