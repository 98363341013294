import { useRef, Dispatch, SetStateAction, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, Grid, Typography, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import "../../../styles/popup/DocumentsCheckInPopUp.scss";
// import trash_icon from "../../../assets/trash_icon.svg";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import { useTranslation } from "react-i18next";
import { Order } from "../../../types/documents/Order";
import { orderStatuses } from "../../providers/OrderProvider";
import Close from "@mui/icons-material/Close";

const SkipperDocuments = ({
	orderUID,
	documentType,
	front,
	back,
	handleDownload,
	handleUpload,
	orderStatus,
	index,
	handleDelete,
	isUser,
	imagesIdentityCardInStorage,
	imagesNauticLicenseInStorage,
}: {
	documentType: "nauticLicenseImg" | "identityCardImg";
	orderUID: string;
	front: string;
	back: string;
	handleDownload: (name: string, index: number) => Promise<void>;
	handleUpload: (name: string, file: File, isFront: boolean, index: number) => Promise<void>;
	orderStatus: string;
	index: number;
	handleDelete?: (isFront: boolean, index: number) => void;
	isUser: boolean;
	imagesIdentityCardInStorage?: boolean;
	imagesNauticLicenseInStorage?: boolean;
}) => {
	const inputFrontRef = useRef<HTMLInputElement>(null);
	const inputBackRef = useRef<HTMLInputElement>(null);
	const { t } = useTranslation();
	const handleFrontUpload = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (inputFrontRef == null || inputFrontRef.current == null || inputFrontRef.current.files == null) return;
		const file = inputFrontRef.current?.files[0];
		handleUpload(file.name, file, true, index);
	};
	const handleBackUpload = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (inputBackRef == null || inputBackRef.current == null || inputBackRef.current.files == null) return;
		const file = inputBackRef.current?.files[0];
		handleUpload(file.name, file, false, index);
	};
	const handleFrontClick = () => {
		if (front != null && front != "") handleDownload(front, index);
		else {
			if (inputFrontRef.current != null) inputFrontRef.current.click();
		}
	};
	const handleBackClick = () => {
		if (back != null && back != "") handleDownload(back, index);
		else {
			if (inputBackRef.current != null) inputBackRef.current.click();
		}
	};
	return (
		<>
			<Grid item xs={12}>
				<Grid container gap={8} rowGap={2} justifyContent="center">
					<Grid item xs={10}>
						<Typography variant="h4">Skipper N.{index + 1}</Typography>
					</Grid>
					<Grid item xs={12} md={4} sx={{ position: "relative" }}>
						<>
							<input
								ref={inputFrontRef}
								id="input-front-document"
								type="file"
								style={{ display: "none" }}
								onChange={(e) => handleFrontUpload(e)}
								accept="image/*,application/pdf"
							/>

							<div
								onClick={handleFrontClick}
								className={`${front != null && front != "" ? "document-element-selected document-element" : "document-element"}`}
								id={`order-${orderUID}-${documentType}-skipper-${index}-front`}>
								{front != null && front != "" ? (
									<CheckIcon className="check-in-document-icon" />
								) : (
									<AddIcon className="check-in-document-icon" />
								)}
							</div>
							<div className="container-document-option">
								{front != null && front !== "" ? (
									handleDelete != null &&
									orderStatus === orderStatuses.PENDING &&
									(documentType === "nauticLicenseImg" ? imagesNauticLicenseInStorage : imagesIdentityCardInStorage) && (
										<DeleteForeverRoundedIcon
											sx={{ fontSize: "3rem", cursor: "pointer" }}
											htmlColor="#ffffffaa"
											onClick={() => handleDelete(true, index)}
											className="documents-trash-icon"
										/>
									)
								) : (
									<Typography variant="h4" sx={{ width: "100%" }} fontWeight={400} textAlign="center">
										{t("front")}
									</Typography>
								)}
							</div>
						</>
					</Grid>
					<Grid item xs={12} md={4} sx={{ position: "relative" }}>
						<>
							<input
								ref={inputBackRef}
								id="input-back-document"
								type="file"
								style={{ display: "none" }}
								onChange={(e) => handleBackUpload(e)}
								accept="image/*,application/pdf"
							/>

							<div
								onClick={handleBackClick}
								className={`${back != null && back != "" ? "document-element-selected document-element" : "document-element"}`}
								id={`order-${orderUID}-${documentType}-skipper-${index}-back`}>
								{back != null && back != "" ? <CheckIcon className="check-in-document-icon" /> : <AddIcon className="check-in-document-icon" />}
							</div>
							<div className="container-document-option">
								{back != null && back != "" ? (
									handleDelete != null &&
									isUser &&
									orderStatus === orderStatuses.PENDING &&
									(documentType === "nauticLicenseImg" ? imagesNauticLicenseInStorage : imagesIdentityCardInStorage) && (
										<DeleteForeverRoundedIcon
											sx={{ fontSize: "3rem", cursor: "pointer" }}
											htmlColor="#ffffffaa"
											onClick={() => handleDelete(false, index)}
											className="documents-trash-icon"
										/>
									)
								) : (
									<Typography variant="h4" sx={{ width: "100%" }} fontWeight={400} textAlign="center">
										{t("back")}
									</Typography>
								)}
							</div>
						</>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export const DocumentsCheckInPopUp = ({
	documents,
	setDocuments,
	orderUID,
	documentType,
	isUser,
	handleDownload,
	handleUpload,
	isPopUpOpen,
	handleClosePopup,
	orderStatus,
	handleDelete,
	imagesIdentityCardInStorage,
	imagesNauticLicenseInStorage,
}: {
	documents: Order["documents"];
	setDocuments?: Dispatch<SetStateAction<Order["documents"]>>;
	documentType: "nauticLicenseImg" | "identityCardImg";
	orderUID: string;
	isUser: boolean;
	handleDownload: (name: string, index: number) => Promise<void>;
	handleUpload: (name: string, file: File, isFront: boolean, index: number) => Promise<void>;
	isPopUpOpen: boolean;
	handleClosePopup: () => void;
	orderStatus: string;
	handleDelete?: (isFront: boolean, index: number) => void;
	imagesIdentityCardInStorage?: boolean;
	imagesNauticLicenseInStorage?: boolean;
}) => {
	const { t } = useTranslation();
	const handleAddSkipper = () => {
		if (documents.length > 3) {
			throw new Error("Max number of skipper is 3");
		}
		const newDocuments = [...documents];
		newDocuments.push({ nauticLicenseImg: { front: "", back: "" }, identityCardImg: { front: "", back: "" } });
		setDocuments!(newDocuments);
	};
	return (
		<>
			<Dialog open={isPopUpOpen} onClose={handleClosePopup} fullWidth maxWidth="md" className="pop-up-container">
				<DialogTitle mb={3} display="flex" justifyContent="space-between">
					<Typography variant="h4">{t("upload-documents")}</Typography>
					<Close className="close-icon" fontSize="large" onClick={handleClosePopup} color="error" />
				</DialogTitle>
				<DialogContent>
					<>
						<Grid container rowGap={4} justifyContent="center" alignItems="center" minHeight="380px">
							{documents != null &&
								documents.length > 0 &&
								documents.map((document, index) => {
									return (
										<SkipperDocuments
											documentType={documentType}
											orderUID={orderUID}
											front={document[documentType].front}
											back={document[documentType].back}
											handleDownload={handleDownload}
											handleUpload={handleUpload}
											index={index}
											orderStatus={orderStatus}
											handleDelete={handleDelete}
											isUser={isUser}
											imagesNauticLicenseInStorage={imagesNauticLicenseInStorage}
											imagesIdentityCardInStorage={imagesIdentityCardInStorage}
										/>
									);
								})}
							{isUser &&
								!imagesNauticLicenseInStorage &&
								!imagesIdentityCardInStorage &&
								documents.length < 3 &&
								orderStatus === orderStatuses.PENDING && (
									<Grid item xs display="flex" justifyContent="center">
										<Button onClick={handleAddSkipper} className="form-button">
											{t("add-skipper")}
										</Button>
									</Grid>
								)}
						</Grid>
					</>
				</DialogContent>
			</Dialog>
		</>
	);
};
