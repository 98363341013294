import { Button, Grid, Typography } from "@mui/material"

export const UnlockCheckin = ({onClick}: {
    onClick: () => void
}) => {
    return (
        <>
            <Grid container gap={2} rowGap={3}>
                <Typography variant="h4">Sblocca Check-in all'utente</Typography>
            </Grid>
            <Grid item xs={12} paddingTop={'16px'}>
                <Button className="form-button" sx={{ maxWidth: "100%" }} onClick={onClick}>
                    Sblocca
                </Button>
            </Grid>
        </>
    )
}