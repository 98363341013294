import React from "react";
import { TableContainer, TableFooter as TableFoot } from "@mui/material";
import { TableCheckin560 } from "./TableCheckin560";
import { TableInventory } from "./TableInventory";
import { TableWarning } from "./TableWarning";
import { TableNote } from "./TableNote";
import { TableFooter } from "./TableFooter";

import { Boat } from "../../../../types/documents/Boat";
import { Order } from "../../../../types/documents/Order";
import { TableInfo } from "./TableInfo";
import { TableSignature } from "./TableSignature";

type TableToPrintProps = {
	isCheckIn: boolean;
	order: Order;
	boatProfile: Boat | null;
};

export const ContainerTableToPrint = React.forwardRef<HTMLDivElement, TableToPrintProps>(({ isCheckIn, order, boatProfile }: TableToPrintProps, ref) => (
	<TableContainer
		className="table-to-print"
		ref={ref}
		sx={{ display: "none", backgroundColor: "white", position: "absolute", top: "0", left: "0", zIndex: "-999" }}>
		<TableInfo boatLicensePlate={boatProfile?.licensePlate} boatUsername={boatProfile?.username} startDate={order.startDate} endDate={order.endDate} />

		<TableCheckin560 isCheckIn={isCheckIn} order={order} />

		<TableInventory boatUsername={boatProfile?.username} isCheckIn={isCheckIn} order={order} />

		<TableWarning orderUID={order.orderUID} isCheckIn={isCheckIn} warnings={order.warnings} />

		<TableNote isCheckIn={isCheckIn} checkInNote={order.checkBoat?.note} checkOutNote={order.checkout?.note} />

		<TableFooter port={boatProfile?.port} startDate={order.startDate} endDate={order.endDate} />

		<TableSignature />
	</TableContainer>
));
