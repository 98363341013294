import { Button, Grid } from "@mui/material";
import { useAuth } from "../../providers/AuthProvider";
import { useUserProvider } from "../../providers/UserProviderImpl";
import { useTranslation } from "react-i18next";

export const FormChangePassword = () => {
	const { userProfile } = useAuth();
	const { sendResetPasswordEmailToUser } = useUserProvider();
	const { t } = useTranslation();
	const handleDeleteAccount = async () => {
		if (userProfile) {
			await sendResetPasswordEmailToUser(userProfile.email);
		}
	};
	return (
		<>
			<Grid container justifyContent={"space-between"} rowGap={4} gap={2}>
				<Grid item xs={12} sx={{ marginTop: "16px" }}>
					<Grid container>
						<Grid item xs>
							<Button onClick={handleDeleteAccount} className="form-button ">
								{t("send-the-email")}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
