import { Grid, Button, Typography, Backdrop } from "@mui/material";
import { useState, useEffect, Dispatch, SetStateAction } from "react";

import "../../../styles/Buttons.scss";
import { AddExtraParams, EditExtraParams, Extra } from "../../../types/forms/Extras";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import { useAuth } from "../../providers/AuthProvider";
import { useBoatProvider } from "../../providers/BoatProvider";
import { useTranslationProvider } from "../../providers/TranslationProvider";
import { AddExtraPopUp } from "./AddExtraPopUp";
import { TranslationType } from "../../../types/Generic";
import { useSnackbar } from "../../providers/SnackbackProvider";
import { EditExtraPopUp } from "./EditExtraPopup";
export const BoatModifyExtraForm = () => {
	// providers
	const { boatProfile } = useAuth();
	const { language } = useTranslationProvider();
	const { removeExtraFromBoat, addExtraToBoat, editExtraFromBoat } = useBoatProvider();
	const { openSnackbar, openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();

	const [extras, setExtras] = useState<Extra[]>([]);
	const [extraToEdit, setExtraToEdit] = useState<EditExtraParams>();
	const [openExtraPopup, setOpenExtraPopup] = useState<boolean>(false);
	const [openEditPopup, setOpenEditPopup] = useState<boolean>(false);

	const handleAddExtra = async (extra: AddExtraParams) => {
		if (boatProfile == null) return;
		try {
			openLoadingSnackbar();
			await addExtraToBoat(boatProfile.uid, extra);
			closeLoadingSnackbar();
			openSnackbar("Successo", "Extra aggiunto", "success");
		} catch (err) {
			closeLoadingSnackbar();
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		}
	};
	const handleDelete = async (extraNameInEng: string) => {
		if (boatProfile == null) return;
		try {
			openLoadingSnackbar();
			await removeExtraFromBoat(boatProfile.uid, extraNameInEng);
			closeLoadingSnackbar();
			openSnackbar("Successo", "Extra eliminato", "success");
		} catch (err) {
			closeLoadingSnackbar();
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		}
	};
	const handleEditExtra = async (extra: EditExtraParams) => {
		if (boatProfile == null) return;
		try {
			openLoadingSnackbar();
			await editExtraFromBoat(boatProfile.uid, extra);
			closeLoadingSnackbar();
			openSnackbar("Successo", "Extra modificato", "success");
		} catch (err) {
			closeLoadingSnackbar();
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		}
	};

	useEffect(() => {
		if (boatProfile != null) {
			setExtras([...boatProfile.extras]);
		}
	}, [boatProfile]);

	const handleClosePopup = () => {
		setOpenExtraPopup(false);
		setOpenEditPopup(false);
	};

	return (
		<>
			<AddExtraPopUp isPopUpOpen={openExtraPopup} handleClosePopup={handleClosePopup} handleAddExtra={handleAddExtra}></AddExtraPopUp>
			<EditExtraPopUp
				isPopUpOpen={openEditPopup}
				handleClosePopup={handleClosePopup}
				extraProp={extraToEdit}
				handleEditExtra={handleEditExtra}></EditExtraPopUp>

			<Grid container justifyContent={"space-between"} rowGap={4}>
				<Grid item xs={12} sx={{ marginTop: "16px" }}>
					<Grid container>
						<Grid item xs>
							<Button
								onClick={() => {
									setOpenExtraPopup(true);
								}}
								className="form-button subbutton">
								Aggiungi una voce
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12}>
							{extras.map((value, index) => {
								return (
									<Grid key={value.uid} container marginBottom="16px">
										<Grid item xs={8}>
											<Typography variant="body1" fontSize="20px">
												{value.name[language as keyof TranslationType]}
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<Grid container justifyContent="flex-end" alignItems="center" gap={4}>
												<Grid item>
													<Typography variant="body1" fontSize="20px" textAlign="center">
														{value.cost}€/{value.type}
													</Typography>
												</Grid>
												<Grid item className="icon-checklist">
													<ModeEditOutlineRoundedIcon
														sx={{ fontSize: "2.7rem" }}
														htmlColor="#959595"
														onClick={() => {
															setExtraToEdit({ ...value, pos: index });
															setOpenEditPopup(true);
														}}
													/>
												</Grid>
												<Grid item className="icon-checklist">
													<DeleteForeverRoundedIcon
														sx={{ fontSize: "3rem" }}
														htmlColor="#959595"
														onClick={() => handleDelete(value.name.en)}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								);
							})}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
