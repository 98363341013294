import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useAuth } from "../../providers/AuthProvider";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import { Order } from "../../../types/documents/Order";
import { CrewListMemberInOrder } from "../../../types/CrewListMemberInOrder";
import { capitilizeFirstLetter } from "../../../utils/stringOperation";
import { ExtraElementInOrder } from "../../../types/ExtraElementInOrder";
import { AddCrewListMemberPopUp } from "../../common/popup/AddCrewListMemberPopUp";
import { AddCrewMemberParams } from "../../../types/forms/CrewMemeber";
import { orderStatuses, useOrders } from "../../providers/OrderProvider";
import { DocumentsCheckInPopUp } from "../../common/popup/DocumentsCheckInPopUp";
import { DocumentToUpload } from "../../../types/forms/Documents";
import { Deposit } from "./Deposit";
import { OrderExtra } from "./OrderExtra";
import { useTranslation } from "react-i18next";
import { CreateCheckoutBody } from "../../../types/requestBodies.ts/CreateCheckoutBody";
import { useStripe } from "../../providers/StripeProvider";
import { useTranslationProvider } from "../../providers/TranslationProvider";
import { useSnackbar } from "../../providers/SnackbackProvider";
import { paymentStatuses } from "../../../utils/constants";
import { useBoatProvider } from "../../providers/BoatProvider";

const CheckInDocuments = ({
	orderUID,
	documents,
	orderStatus,
	documentsToUpload,
	setDocumentsToUpload,
	setDocuments,
	nauticLicenseRejected,
	identityCardRejected,
	imagesIdentityCardInStorage,
	imagesNauticLicenseInStorage,
}: {
	documentsToUpload: DocumentToUpload[];
	setDocumentsToUpload: Dispatch<SetStateAction<DocumentToUpload[]>>;
	orderUID: string;
	orderStatus: string;
	documents: Order["documents"];
	setDocuments: Dispatch<SetStateAction<Order["documents"]>>;
	nauticLicenseRejected: boolean;
	identityCardRejected: boolean;
	imagesIdentityCardInStorage: boolean;
	imagesNauticLicenseInStorage: boolean;
}) => {
	const [nauticLicenseUploaded, setNauticLicenseUploaded] = useState<boolean>(false);
	const [identityCardUploaded, setIdentitycardUploaded] = useState<boolean>(false);

	const [nauticLicensePopUpOpen, setNauticLicensePopUpOpen] = useState<boolean>(false);
	const [identityCardPopUpOpen, setIdentityCardPopUpOpen] = useState<boolean>(false);
	const { t } = useTranslation();
	const { downlaodDocumentOfOrder, uploadDocuments, removeDocument, updateDocuments } = useOrders();
	const { openLoadingSnackbar, closeLoadingSnackbar, openSnackbar } = useSnackbar();
	useEffect(() => {
		if (
			documents == null ||
			documents.length === 0 ||
			documents[0] == null ||
			documents[0].nauticLicenseImg == null ||
			documents[0].identityCardImg == null
		) {
			setNauticLicenseUploaded(false);
			setIdentitycardUploaded(false);
			return;
		}

		if (
			(documents[0].nauticLicenseImg.front == null || documents[0].nauticLicenseImg.front === "") &&
			(documents[0].nauticLicenseImg.back == null || documents[0].nauticLicenseImg.back === "")
		)
			setNauticLicenseUploaded(false);
		else setNauticLicenseUploaded(true);

		if (
			(documents[0].identityCardImg.front == null || documents[0].identityCardImg.front === "") &&
			(documents[0].identityCardImg.back == null || documents[0].identityCardImg.back === "")
		)
			setIdentitycardUploaded(false);
		else setIdentitycardUploaded(true);
	}, [documents]);
	const handleDownloadNauticLicense = async (name: string) => {
		try {
			openLoadingSnackbar();
			await downlaodDocumentOfOrder(orderUID, "nauticLicenseImg", name);
			closeLoadingSnackbar();
		} catch (err) {
			closeLoadingSnackbar();

			console.error(err);
		}
	};
	const handleUploadNauticLicense = async (name: string, file: File, isFront: boolean, index: number) => {
		try {
			openLoadingSnackbar();
			const newDocuments: Order["documents"] = [...documents];
			const newDocumentsToUpload: DocumentToUpload[] = [...documentsToUpload];
			const nameToUpload = `skipper_Nautic_License_${index + 1}_${isFront ? "front" : "back"}.${
				file.name.split(".")[file.name.split(".").length - 1]
			}`;
			const documentToUpload: DocumentToUpload = {
				name: nameToUpload,
				file: file,
				type: "nauticLicenseImg",
				isFront: isFront,
			};
			await uploadDocuments(orderUID, documentToUpload.file, documentToUpload.name, documentToUpload.type, documentToUpload.isFront);
			newDocumentsToUpload.push(documentToUpload);
			newDocuments[index].nauticLicenseImg[isFront ? "front" : "back"] = nameToUpload;
			setDocuments(newDocuments);
			setDocumentsToUpload(newDocumentsToUpload);
			setNauticLicenseUploaded(true);
			await updateDocuments(orderUID, newDocuments);
		} catch (err) {
			console.error(err);
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		} finally {
			closeLoadingSnackbar();
		}
	};
	const handleDownloadidentityCard = async (name: string) => {
		try {
			openLoadingSnackbar();
			await downlaodDocumentOfOrder(orderUID, "identityCardImg", name);
			closeLoadingSnackbar();
		} catch (err) {
			closeLoadingSnackbar();

			console.error(err);
		}
	};
	const handleUploadidentityCard = async (name: string, file: File, isFront: boolean, index: number) => {
		try {
			openLoadingSnackbar();
			const newDocuments: Order["documents"] = [...documents];
			const newDocumentsToUpload: DocumentToUpload[] = [...documentsToUpload];
			const nameToUpload = `skipper_Identity_Card_${index + 1}_${isFront ? "front" : "back"}.${
				file.name.split(".")[file.name.split(".").length - 1]
			}`;
			const documentToUpload: DocumentToUpload = {
				name: nameToUpload,
				file: file,
				type: "identityCardImg",
				isFront: isFront,
			};
			await uploadDocuments(orderUID, documentToUpload.file, documentToUpload.name, documentToUpload.type, documentToUpload.isFront);
			newDocumentsToUpload.push(documentToUpload);
			newDocuments[index].identityCardImg[isFront ? "front" : "back"] = nameToUpload;
			setDocuments(newDocuments);
			setDocumentsToUpload(newDocumentsToUpload);
			setIdentitycardUploaded(true);
			await updateDocuments(orderUID, newDocuments);
		} catch (err) {
			console.error(err);
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		} finally {
			closeLoadingSnackbar();
		}
	};
	const handleDeleteNauticLicense = async (isFront: boolean, index: number) => {
		try {
			openLoadingSnackbar();
			const newDocuments: Order["documents"] = [...documents];
			const newDocumentsToUpload: DocumentToUpload[] = [...documentsToUpload];
			const nameOfDocumentToBeDeleted = newDocuments[index].nauticLicenseImg[isFront ? "front" : "back"];

			const indexOfElementToDeleteToUpload = newDocumentsToUpload.findIndex((document) => document.name === nameOfDocumentToBeDeleted);

			await removeDocument(orderUID, "nauticLicenseImg", nameOfDocumentToBeDeleted);

			newDocumentsToUpload.splice(indexOfElementToDeleteToUpload, 1);
			newDocuments[index].nauticLicenseImg[isFront ? "front" : "back"] = "";
			setDocuments([...newDocuments]);
			setDocumentsToUpload([...newDocumentsToUpload]);
			setNauticLicenseUploaded(false);
			await updateDocuments(orderUID, newDocuments);
		} catch (err) {
			console.error(err);
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		} finally {
			closeLoadingSnackbar();
		}
	};
	const handleDeleteIdentityCard = async (isFront: boolean, index: number) => {
		try {
			const newDocuments: Order["documents"] = [...documents];
			const newDocumentsToUpload: DocumentToUpload[] = [...documentsToUpload];

			const nameOfDocumentToBeDeleted = newDocuments[index].identityCardImg[isFront ? "front" : "back"];

			const indexOfElementToDeleteToUpload = newDocumentsToUpload.findIndex((document) => document.name === nameOfDocumentToBeDeleted);

			await removeDocument(orderUID, "identityCardImg", nameOfDocumentToBeDeleted);

			newDocumentsToUpload.splice(indexOfElementToDeleteToUpload, 1);

			newDocuments[index].identityCardImg[isFront ? "front" : "back"] = "";

			setDocuments([...newDocuments]);
			setDocumentsToUpload([...newDocumentsToUpload]);
			setIdentitycardUploaded(false);
			await updateDocuments(orderUID, newDocuments);
		} catch (err) {
			console.error(err);
		}
	};

	const handleNauticLicensePopUpClose = () => {
		setNauticLicensePopUpOpen(false);
	};
	const handleIdentityCardPopUpClose = () => {
		setIdentityCardPopUpOpen(false);
	};

	return (
		<>
			{documents != null && (
				<>
					<DocumentsCheckInPopUp
						documents={documents}
						setDocuments={setDocuments}
						orderUID={orderUID}
						orderStatus={orderStatus}
						documentType="nauticLicenseImg"
						isPopUpOpen={nauticLicensePopUpOpen}
						handleClosePopup={handleNauticLicensePopUpClose}
						handleDownload={handleDownloadNauticLicense}
						handleUpload={handleUploadNauticLicense}
						isUser={true}
						handleDelete={handleDeleteNauticLicense}
						imagesIdentityCardInStorage={imagesIdentityCardInStorage}
						imagesNauticLicenseInStorage={imagesNauticLicenseInStorage}
					/>
					<DocumentsCheckInPopUp
						documents={documents}
						setDocuments={setDocuments}
						orderUID={orderUID}
						orderStatus={orderStatus}
						documentType="identityCardImg"
						isPopUpOpen={identityCardPopUpOpen}
						handleClosePopup={handleIdentityCardPopUpClose}
						handleDownload={handleDownloadidentityCard}
						handleUpload={handleUploadidentityCard}
						isUser={true}
						handleDelete={handleDeleteIdentityCard}
						imagesIdentityCardInStorage={imagesIdentityCardInStorage}
						imagesNauticLicenseInStorage={imagesNauticLicenseInStorage}
					/>
				</>
			)}
			<Grid container rowGap={2}>
				<Grid item xs={12} sm={6}>
					<Grid container gap={2}>
						<Grid item xs sm={4} md={3} xl={2} sx={{ xs: { height: "100%" } }}>
							<div
								onClick={() => {
									setNauticLicensePopUpOpen(true);
								}}
								className="order-check-in-document-checkbox order-check-in-document-checkbox-uploaded">
								{nauticLicenseUploaded ? <CheckIcon className="check-in-document-icon" /> : <AddIcon className="check-in-document-icon" />}
							</div>
						</Grid>
						<Grid item xs={8} sm={6} md={6} display="flex" flexDirection="column" gap={2} rowGap={1}>
							<Typography variant="h4" sx={{ color: nauticLicenseRejected ? "red" : "inherit" }}>
								{t("nautic-license")}
							</Typography>
							{nauticLicenseRejected && (
								<Typography variant="h4" sx={{ color: nauticLicenseRejected ? "red" : "inherit" }} className="check-in-section-rejected-message">
									{t("toBeRecompiled")}
								</Typography>
							)}
							<Typography variant="body2">{t("nautic-license-upload-description")}</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Grid container gap={2}>
						<Grid item xs sm={4} md={3} xl={2} sx={{ xs: { height: "100%" } }}>
							<div
								onClick={() => {
									setIdentityCardPopUpOpen(true);
								}}
								className="order-check-in-document-checkbox order-check-in-document-checkbox-uploaded ">
								{identityCardUploaded ? <CheckIcon className="check-in-document-icon" /> : <AddIcon className="check-in-document-icon" />}
							</div>
						</Grid>
						<Grid item xs={8} sm={6} md={6} display="flex" flexDirection="column" gap={2} rowGap={1}>
							<Typography variant="h4" sx={{ color: identityCardRejected ? "red" : "inherit" }}>
								{t("check-in-documents")}
							</Typography>
							{identityCardRejected && (
								<Typography variant="h4" sx={{ color: identityCardRejected ? "red" : "inherit" }} className="check-in-section-rejected-message">
									{t("toBeRecompiled")}
								</Typography>
							)}

							<Typography variant="body2">{t("check-in-documents-description")}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

const CrewList = ({
	orderStatus,
	orderId,
	crewlist,
	availableSeats,
	setCrewList,
}: {
	orderStatus: string;
	orderId: string;
	crewlist: CrewListMemberInOrder[];
	availableSeats: number;
	setCrewList: Dispatch<SetStateAction<Order["crewList"]>>;
}) => {
	const [openAddCrewMemberPopup, setOpenAddCrewMemberPopup] = useState<boolean>(false);
	const [crewMemberSelected, setCrewMemeberSelected] = useState<AddCrewMemberParams>({} as AddCrewMemberParams);
	const { t } = useTranslation();
	const { addNewMemberToCrewList, removeCrewMember } = useOrders();

	const handleAddNewMemberToCrewList = async (crewMemeberParams: AddCrewMemberParams) => {
		try {
			const result = await addNewMemberToCrewList(crewMemeberParams, orderId);
			if (result.ok) {
				const newCrewList: CrewListMemberInOrder[] = [...crewlist];
				newCrewList.push(crewMemeberParams);
				setCrewList(newCrewList);
			}
		} catch (err) {
			console.error(err);
		}
	};
	const handleRemoveCrewMember = async (idNumber: string) => {
		try {
			const result = await removeCrewMember({ orderUID: orderId, idNumber: idNumber });
			if (result.ok) {
				const newCrewList: CrewListMemberInOrder[] = [...crewlist];
				const index = newCrewList.findIndex((crewMember) => crewMember.idNumber === idNumber);
				newCrewList.splice(index, 1);
				setCrewList(newCrewList);
			}
		} catch (err) {
			console.error(err);
		}
	};
	const handleCloseCrewMemberPopup = () => {
		setOpenAddCrewMemberPopup(false);
	};
	const handleOpenCrewMemberPopup = () => {
		setOpenAddCrewMemberPopup(true);
	};
	return (
		<>
			<AddCrewListMemberPopUp
				isPopUpOpen={openAddCrewMemberPopup}
				handleClosePopup={handleCloseCrewMemberPopup}
				handleAddCrewMember={handleAddNewMemberToCrewList}
				handleRemoveCrewMember={handleRemoveCrewMember}
				isUser={true}
				crewMemeber={crewMemberSelected}
				disabled={orderStatus !== orderStatuses.PENDING}
			/>

			<Grid container rowGap={2}>
				<Grid item xs={12}>
					<Typography variant="h4">{t("crewlist")}</Typography>
				</Grid>
				{Array.from({ length: availableSeats }, (_, index) => {
					let seatFilledClass = "";
					if (crewlist[index] != null) seatFilledClass = "order-checkbox-filled";
					return (
						<Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={`crewlist-seat-${index}`} display="flex" alignItems="center">
							<a
								onClick={() => {
									setCrewMemeberSelected(crewlist[index] as AddCrewMemberParams);
									handleOpenCrewMemberPopup();
								}}>
								<div className={`order-checkbox ${seatFilledClass}`}>
									{seatFilledClass === "" ? <AddIcon className="checkbox-icon" /> : <CheckIcon className="checkbox-icon" />}
								</div>
							</a>

							<Typography variant="body1" marginLeft="8px">
								{crewlist[index] != null ? (
									<>
										{capitilizeFirstLetter(crewlist[index].name)} {capitilizeFirstLetter(crewlist[index].surname)}
									</>
								) : (
									<>{t("add-member")}</>
								)}
							</Typography>
						</Grid>
					);
				})}
			</Grid>
		</>
	);
};

const CheckArticles = ({
	setArticles,
	articles,
	orderStatus,
	article34Enabled,
	article39Enabled,
}: {
	setArticles: Dispatch<SetStateAction<Order["articles"]>>;
	articles: Order["articles"];
	orderStatus: string;
	article34Enabled: boolean;
	article39Enabled: boolean;
}) => {
	const { t } = useTranslation();
	const handleAcceptArticles = async (nameArticle: string) => {
		try {
			if (orderStatus !== orderStatuses.PENDING) throw new Error("Order status is not PENDING");

			const article: Order["articles"] = { ...articles };
			article[Number(nameArticle) as 34 | 39] = !article[Number(nameArticle) as 34 | 39];
			setArticles(article);
		} catch (err) {
			console.error(err);
		}
	};
	return (
		<>
			<Grid container rowGap={2}>
				<Grid item xs={12}>
					<Typography variant="h4">{t("articles-to-subscribe")}</Typography>
				</Grid>
				{article34Enabled && (
					<Grid item xs={12} display="flex">
						<a
							onClick={async () => {
								await handleAcceptArticles("34");
							}}>
							<div className={`order-checkbox ${articles[34] ? "order-checkbox-filled" : ""}`}>
								{!articles[34] ? <></> : <CheckIcon className="checkbox-icon" />}
							</div>
						</a>
						<div style={{ marginLeft: "8px", width: "100%" }}>
							<Typography variant="body1" sx={{ textDecoration: "underline", minHeight: "40px", display: "flex", alignItems: "center" }}>
								{t("accept-art.34")}
							</Typography>
							<Typography variant="body1" whiteSpace="pre-wrap" marginTop="8px">
								{t("art.34")}
							</Typography>
						</div>
					</Grid>
				)}
				{article39Enabled && (
					<Grid item xs={12} display="flex">
						<a
							onClick={async () => {
								await handleAcceptArticles("39");
							}}>
							<div className={`order-checkbox ${articles[39] ? "order-checkbox-filled" : ""}`}>
								{!articles[39] ? <></> : <CheckIcon className="checkbox-icon" />}
							</div>
						</a>
						<div style={{ marginLeft: "8px", width: "100%" }}>
							<Typography variant="body1" sx={{ textDecoration: "underline", height: "40px", display: "flex", alignItems: "center" }}>
								{t("accept-art.39")}
							</Typography>
							<Typography variant="body1" whiteSpace="pre-wrap">
								{t("art.39")}
							</Typography>
						</div>
					</Grid>
				)}
			</Grid>
		</>
	);
};

export const UserCheckInOrderContent = ({ order }: { order: Order }) => {
	const [extras, setExtras] = useState<ExtraElementInOrder[]>(order.extras);
	const [articles, setArticles] = useState<Order["articles"]>(order.articles);
	const [crewlist, setCrewList] = useState<Order["crewList"]>(order.crewList);
	const [didAlreadyPayDeposit, setDidAlreadyPayDeposit] = useState<boolean>(order.paymentStatus === paymentStatuses.PAYMENT_COMPLETED);
	const [documentsToUpload, setDocumentToUpload] = useState<DocumentToUpload[]>([]);
	const [documents, setDocuments] = useState<Order["documents"]>({} as Order["documents"]);
	const [payDepositNow, setPayDepositNow] = useState<boolean>(true);
	const [identityCardRejected, setIndetityCardRejected] = useState<boolean>(false);
	const [nauticLicenseRejected, setNauticLicenseRejected] = useState<boolean>(false);
	const [extrasRejected, setExtrasRejected] = useState<boolean>(false);
	const [article34Enabled, setArticle34Enabled] = useState<boolean>(false);
	const [article39Enabled, setArticle39Enabled] = useState<boolean>(false);
	const [depositValue, setDepositValue] = useState<number>(0);
	const [imagesNauticLicenseInStorage, setImagesNauticLicenseInStorage] = useState<boolean>(false);
	const [imagesIdentityCardInStorage, setImagesIdentityCardInStorage] = useState<boolean>(false);

	const { getBoatFromUID } = useBoatProvider();
	const { sendCheckIn } = useOrders();
	const { t } = useTranslation();
	const { createCheckoutSession } = useStripe();
	const { userProfile } = useAuth();
	const { language } = useTranslationProvider();
	const { openSnackbar, openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();

	useEffect(() => {
		if (order == null) return;

		setArticles(order.articles);
		setExtras(order.extras);
		setCrewList(order.crewList);
		setDocuments(order.documents);
		setDidAlreadyPayDeposit(order.paymentStatus === paymentStatuses.PAYMENT_COMPLETED);
		if (order.identityCardRejected) {
			setIndetityCardRejected(true);
		}
		if (order.nauticLicenseRejected) {
			setNauticLicenseRejected(true);
		}
		if (order.extraRejected) {
			setExtrasRejected(true);
		}

		if (order.articles.enabled34) {
			setArticle34Enabled(true);
		}
		if (order.articles.enabled39) {
			setArticle39Enabled(true);
		}

		(async () => {
			const boat = await getBoatFromUID(order.boatUID);
			setDepositValue(boat.deposit);
		})();
		order.documents.map((skipper) => {
			if (skipper.identityCardImg.front !== "" || skipper.identityCardImg.back !== "") {
				setImagesIdentityCardInStorage(true);
			}
			if (skipper.nauticLicenseImg.front !== "" || skipper.nauticLicenseImg.back !== "") {
				setImagesNauticLicenseInStorage(true);
			}
		});
		if (order.paymentStatus === paymentStatuses.PAYMENT_COMPLETED) {
			setDidAlreadyPayDeposit(true);
		}
	}, [order]);
	const handleSendCheckIn = async () => {
		try {
			openLoadingSnackbar();
			InputCheckInValidation();
			if (payDepositNow && !didAlreadyPayDeposit) await handlePayment();

			await sendCheckIn(order.orderUID, documents, documentsToUpload, extras, crewlist, articles, payDepositNow);
			closeLoadingSnackbar();
			openSnackbar("Success", t("successBoardingSent"), "success");
		} catch (err) {
			closeLoadingSnackbar();
			console.error(err);
			const error = err as Error;
			openSnackbar("Error", error.message, "error");
		}
	};
	const InputCheckInValidation = (): void => {
		if (userProfile == null) {
			throw new Error("User not logged in");
		}
		if (order.status === orderStatuses.ACCEPTED) throw new Error(t("errorOrderAlreadyAccepted")!);
		if (!articlesValidation()) throw new Error(t("errorArticlesNotAccepted")!);
		if (crewlist.length === 0) throw new Error(t("errorNotMemberInCrew")!);
	};
	const articlesValidation = (): boolean => {
		let articlesValid = true;
		if (article34Enabled && !articles[34]) {
			articlesValid = false;
		}
		if (article39Enabled && !articles[39]) {
			articlesValid = false;
		}
		return articlesValid;
	};
	const handlePayment = async () => {
		const checkoutInfo: CreateCheckoutBody = {
			boatUID: order.boatUID,
			orderID: order.orderUID,
			userEmail: userProfile!.email,
			languageUsedToShowProducts: language,
		};

		const checkout = await createCheckoutSession(checkoutInfo);

		if (checkout) {
			if (checkout.redirectUrl) {
				const pupup = window.open(checkout.redirectUrl, "_blank", "height=900,width=900");
				if (!pupup || pupup.closed || typeof pupup.closed == "undefined") {
					throw new Error(t("paymentPupupBlocked")!);
				}
			}
		}
	};

	return (
		<>
			<Grid container gap={2} rowGap={6}>
				{/**DOCUMENTS */}
				<Grid item xs={12}>
					<CheckInDocuments
						orderStatus={order.status}
						documentsToUpload={documentsToUpload}
						setDocumentsToUpload={setDocumentToUpload}
						orderUID={order.orderUID}
						documents={documents}
						setDocuments={setDocuments}
						identityCardRejected={identityCardRejected}
						nauticLicenseRejected={nauticLicenseRejected}
						imagesNauticLicenseInStorage={imagesNauticLicenseInStorage}
						imagesIdentityCardInStorage={imagesIdentityCardInStorage}
					/>
				</Grid>
				{/**CREWLIST */}
				<Grid item xs={12}>
					<CrewList
						orderStatus={order.status}
						orderId={order.orderUID}
						crewlist={crewlist}
						setCrewList={setCrewList}
						availableSeats={order.availableSeats}
					/>
				</Grid>
				{/**CHECK ARTICLES */}
				{(article34Enabled || article39Enabled) && (
					<Grid item xs={12}>
						<CheckArticles
							orderStatus={order.status}
							setArticles={setArticles}
							articles={articles}
							article34Enabled={article34Enabled}
							article39Enabled={article39Enabled}
						/>
					</Grid>
				)}

				{/**EXTRAS */}
				<Grid item xs={12}>
					<OrderExtra
						orderStatus={order.status}
						setExtras={setExtras}
						extras={extras}
						orderStartDate={order.startDate}
						orderEndDate={order.endDate}
						extrasRejected={extrasRejected}
						crewlistLenght={crewlist.length}
					/>
				</Grid>
				{/**PAYMENT */}
				{!didAlreadyPayDeposit && (
					<Grid item xs={12}>
						<Deposit
							orderStatus={order.status}
							setPayDepositNow={setPayDepositNow}
							payDepositNow={payDepositNow}
							endDate={order.endDate}
							depositValue={depositValue}
						/>
					</Grid>
				)}

				{/**NOTIFY */}
				<Grid item xs={12}>
					<Typography variant="body1" fontWeight={600}>
						{t("important-warning")}
					</Typography>
					<Typography variant="body1">{t("it-is-suggested-to-pay-online")}</Typography>
				</Grid>
				<Grid item xs={12} marginBottom="45px">
					<Typography variant="body1" fontWeight={600}>
						{t("attention")}
					</Typography>
					<Typography variant="body1"> {t("once-this-form-has-been-sent")}</Typography>
				</Grid>
				{/**SUBMITS */}
				{order.status === orderStatuses.PENDING && (
					<Grid item xs={12}>
						<Grid container gap={2} rowGap={2}>
							<Grid item xs className="container-order-check-in-button" display="flex" justifyContent="center">
								<Button className="form-button check-in-submit-button" sx={{ maxWidth: "280px" }} onClick={handleSendCheckIn}>
									{t("send")}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
		</>
	);
};
