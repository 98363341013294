import { capitilizeFirstLetter } from "./stringOperation";

export const getDayFromTimestamp = (timestamp: number): number => {
	const date = new Date(timestamp);
	return date.getDate();
};
export const getMonthFromTimestamp = (timestamp: number, language: string): string => {
	const date = new Date(timestamp);
	let monthLanguage = "it-IT";
	if (language === "en") monthLanguage = "en-US";
	if (language === "cs") monthLanguage = "cs-CZ";

	return date.toLocaleString(monthLanguage, { month: "short" });
};
export const getDateIntervalFromTimestamps = (startTimestamp: number, endTimestamp: number, language: string): string => {
	const startDay = getDayFromTimestamp(startTimestamp * 1000);
	const startMonth = getMonthFromTimestamp(startTimestamp * 1000, language);
	const endDay = getDayFromTimestamp(endTimestamp * 1000);
	const endMonth = getMonthFromTimestamp(endTimestamp * 1000, language);

	let intervalDate = ``;
	if (startMonth === endMonth) intervalDate = `${startDay} - ${endDay} ${capitilizeFirstLetter(endMonth)}`;
	else intervalDate = `${startDay} ${capitilizeFirstLetter(startMonth)} - ${endDay} ${capitilizeFirstLetter(endMonth)}`;
	return intervalDate;
};

export const dateFormat = (timestamp: number, language: string): string => {
	const date = new Date(timestamp);
	let monthLanguage = "it-IT";
	if (language === "en") monthLanguage = "en-US";
	if (language === "cs") monthLanguage = "cs-CZ";
	return date.toLocaleDateString(monthLanguage, { month: "long", weekday: "long", day: "numeric" });
};
