import { useEffect, useState } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { useAuth } from "../../components/providers/AuthProvider";
import { useFirebase } from "../../components/providers/FirebaseProvider";
import { Grid, Button, TextField, Typography, IconButton, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import $ from "jquery";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import logo_gais from "../../assets/logo_gais.svg";

import "../../styles/Login.scss";
import { useSnackbar } from "../../components/providers/SnackbackProvider";

const validationSchema = yup.object({
	username: yup.string().required("Il nome della barca è richiesto"),
	password: yup.string().required("La password è richista"),
});
export const LoginBoat = () => {
	const navigate = useNavigate();
	const { loginWithUsernameAndPassword } = useAuth();
	const { myStorage } = useFirebase();
	const { openLoadingSnackbar, closeLoadingSnackbar, openSnackbar } = useSnackbar();
	const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
	const [showPassword, setShowPassword] = useState<boolean>(false);

	const [videoLogInURL, setVideoLogInURL] = useState<string>("");

	const formik = useFormik({
		initialValues: {
			username: "",
			password: "",
		},

		validationSchema: validationSchema,
		onSubmit: async (values) => await handleLogin(values),
	});

	const handleLogin = async (values: { username: string; password: string }) => {
		try {
			openLoadingSnackbar();
			await loginWithUsernameAndPassword(values.username, values.password);
			closeLoadingSnackbar();
			navigate("/boat/orders");
		} catch (err) {
			const error = err as Error;
			closeLoadingSnackbar();
			openSnackbar("Error", error.message, "error");

			console.error(err);
		}
	};
	const documentHeight = () => {
		setWindowHeight(window.innerHeight);
	};
	const changeFontSizeLoginTitle = (widthContainer: number) => {
		const divider = 5.5;
		const fontSize = Math.floor(widthContainer / divider);
		$("#login-title").css("font-size", `${fontSize}px`);
	};
	useEffect(() => {
		(async () => {
			const videoLogInRef = ref(myStorage, `videos/Video_logIn.mov`);
			const url = await getDownloadURL(videoLogInRef);
			setVideoLogInURL(url);
		})();
		const resizeObserverLoginForm = new ResizeObserver((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
			window.requestAnimationFrame(() => {
				if (!Array.isArray(entries) || !entries.length) {
					return;
				}
			});
			entries.forEach((entry) => {
				if (entry.target.id === "login-form") {
					const timer = setTimeout(() => {
						changeFontSizeLoginTitle(entry.contentRect.width);
					}, 20);
				}
			});
		});
		resizeObserverLoginForm.observe(document.querySelector("#login-form") as HTMLElement);

		window.addEventListener("resize", documentHeight);
		return () => {
			resizeObserverLoginForm.disconnect();
		};
	}, []);
	return (
		<>
			<Grid container sx={{ height: windowHeight + "px", width: "100%", overflow: "hidden" }} justifyContent={"center"} alignItems={"center"}>
				<img alt="gais logo" src={logo_gais} className="gais-logo"></img>
				<video autoPlay loop muted className="login-background" src={videoLogInURL}></video>
				<Grid item xs={10} lg={8} xl={7}>
					<form onSubmit={formik.handleSubmit}>
						<Grid container justifyContent={"center"} alignItems={"center"} id="login-form">
							<Grid item xs={12}>
								<Typography variant="h1" id="login-title">
									GET READY
								</Typography>
							</Grid>
							<Grid item xs={7} id="username-input-field">
								<TextField
									sx={{ width: { xs: "100%" } }}
									className="input-form"
									id="username"
									name="username"
									label="Nome della barca"
									variant={"filled"}
									value={formik.values.username}
									onChange={formik.handleChange}
									error={formik.touched.username && Boolean(formik.errors.username)}
									helperText={formik.touched.username && formik.errors.username}
								/>
							</Grid>
							<Grid item xs={5} paddingLeft="16px" id="password-input-field">
								<TextField
									sx={{ width: { xs: "100%" } }}
									className="input-form login-password-field"
									id="password"
									name="password"
									label="Password"
									type={showPassword ? "text" : "password"}
									variant={"filled"}
									value={formik.values.password}
									onChange={formik.handleChange}
									error={formik.touched.password && Boolean(formik.errors.password)}
									helperText={formik.touched.password && formik.errors.password}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() => setShowPassword(!showPassword)}
													edge="end"
													aria-label="toggle password visibility"
												>
													{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={2} sx={{ marginTop: "16px" }}>
								<Button type="submit" fullWidth>
									GO
								</Button>
							</Grid>
						</Grid>
					</form>
				</Grid>
				<div className="wave-animation"></div>
			</Grid>
		</>
	);
};
