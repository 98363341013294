import { Grid, TextField, Select, MenuItem } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";

import { ExtraElementInOrder, Transfer, TransferField } from "../../../types/ExtraElementInOrder";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslationProvider } from "../../providers/TranslationProvider";
import { TranslationType } from "../../../types/Generic";
import { useTranslation } from "react-i18next";
import { orderStatuses } from "../../providers/OrderProvider";

const areDataValid = (field: TransferField) => {
	return (
		field.date > 0 &&
		field.passengers != null &&
		field.time != null &&
		field.flightNumber != null &&
		field.from != null &&
		field.to != null &&
		field.passengers != 0 &&
		field.time != "" &&
		field.flightNumber != "" &&
		field.from != "" &&
		field.to != ""
	);
};

const defaultTransfer: Transfer = {
	arrival: { date: 0, time: "", passengers: 0, flightNumber: "", from: "", to: "" },
	departure: { date: 0, time: "", passengers: 0, flightNumber: "", from: "", to: "" },
};

const ExtraComponentForTransfer = ({
	extra,
	index,
	handleSelectExtra,
	isDisabled,
	isBoat,
	changeCostAndTypeExtra,
}: {
	extra: ExtraElementInOrder;
	index: number;
	handleSelectExtra: (extra: ExtraElementInOrder, index: number) => void;
	isDisabled: boolean;
	isBoat: boolean;
	changeCostAndTypeExtra?: ({
		extra,
		newCost,
		newType,
	}: {
		extra: ExtraElementInOrder;
		newCost: number;
		newType: ExtraElementInOrder["type"];
	}) => Promise<void>;
}) => {
	const { language } = useTranslationProvider();
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState<string | false>(false);
	const [transferExtra, setTransferExtra] = useState<Transfer>(defaultTransfer);
	const [extraChoosenClass, setExtraChoosenClass] = useState<string>("");
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [newCost, setNewCost] = useState<number>(extra.cost);

	const handleToggleAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	useEffect(() => {
		if (extra.transfer !== undefined) {
			setTransferExtra(extra.transfer);
			if (areDataValid(extra.transfer.arrival) || areDataValid(extra.transfer.departure)) setExtraChoosenClass("order-checkbox-filled");
			else setExtraChoosenClass("");
		} else {
			setTransferExtra(defaultTransfer);
			setExtraChoosenClass("");
		}
	}, [extra.transfer]);

	const confirmEdit = async () => {
		if (changeCostAndTypeExtra) {
			await changeCostAndTypeExtra({ extra, newCost, newType: extra.type });
		}
		setIsEditing(false);
	};

	const cancelEdit = () => {
		setNewCost(extra.cost);
		setIsEditing(false);
	};

	const handleChangeExtra = (updatedExtra: Transfer) => {
		setTransferExtra(updatedExtra);
		if (areDataValid(updatedExtra.arrival) || areDataValid(updatedExtra.departure)) {
			extra.checked = true;
			extra.transfer = transferExtra;
		} else {
			extra.checked = false;
			extra.transfer = undefined;
		}
		handleSelectExtra({ ...extra, transfer: updatedExtra }, index);
	};

	return (
		<>
			<Accordion
				className="accordion accordion-extras extra-component-container"
				expanded={expanded === `panel-extra-${index}`}
				onChange={handleToggleAccordion(`panel-extra-${index}`)}
				sx={{ width: "100%" }}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon className="accordion-summary-icon" />}
					aria-controls={`panel-extra-${index}bh-content`}
					id={`panel-extra-${index}bh-header`}>
					<Grid item xs={12} display="flex" alignItems="center">
						<div className={`order-checkbox ${extraChoosenClass}`}>
							{extraChoosenClass === "" ? <AddIcon className="checkbox-icon " /> : <CheckIcon className="checkbox-icon" />}
						</div>
						<div className="extra-summary-container generic-extra-content">
							<Typography variant="body1" fontSize="20px" marginLeft="8px" paddingRight="16px">
								{extra.name[language as keyof TranslationType]}
							</Typography>
							<Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "1em", marginLeft: { xs: "8px", md: "0" } }}>
								{extra.oldCost && extra.oldCost !== extra.cost && (
									<>
										<Typography
											variant="body1"
											fontSize="20px"
											sx={{ textDecorationLine: "line-through", textDecorationColor: "red", textDecorationThickness: 2 }}>
											{extra.oldCost}€
										</Typography>
										<ArrowForwardIcon sx={{ color: "#149cd8", display: { xs: "none", md: "block" } }} fontSize="large" />
									</>
								)}
								{isBoat && isEditing ? (
									<>
										<CheckIcon fontSize="large" sx={{ color: "green", cursor: "pointer" }} onClick={confirmEdit} />
										<CloseIcon fontSize="large" sx={{ color: "red", cursor: "pointer" }} onClick={cancelEdit} />
										<TextField
											type="number"
											sx={{ width: "15%" }}
											inputProps={{ style: { textAlign: "right" } }}
											defaultValue={newCost}
											onChange={(e) => {
												if (!isNaN(Number(e.currentTarget.value))) setNewCost(Number(e.currentTarget.value));
											}}></TextField>
									</>
								) : (
									<>
										{isBoat && (
											<ModeEditOutlineRoundedIcon
												sx={{ cursor: "pointer" }}
												fontSize="large"
												htmlColor="#959595"
												onClick={() => setIsEditing(true)}
											/>
										)}
										<Typography variant="body1" fontSize="20px">
											{extra.cost}€
										</Typography>
									</>
								)}
							</Grid>
						</div>
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container>
						<Grid item xs={12}>
							<Grid container rowGap={2}>
								<Grid item xs={12}>
									<Typography variant="body1" fontSize="20px" fontWeight={300}>
										{t("arrival")}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Grid container rowGap={2}>
										<Grid item xs={12}>
											<Grid container rowGap={2}>
												<Grid item xs={12} sm={4} paddingRight="16px">
													<section className="order-extra-transfer-field-container input-date-container">
														<DatePicker
															disabled={isDisabled}
															className=" order-extra-transfer-field input-date"
															name="arrivalDate"
															selected={transferExtra.arrival.date > 0 ? new Date(transferExtra.arrival.date) : null}
															onChange={(date: Date | null) => {
																let newTransferExtra = { ...transferExtra };

																if (date == null) newTransferExtra.arrival.date = 0;
																else newTransferExtra.arrival.date = date.getTime();

																handleChangeExtra(newTransferExtra as Transfer);
															}}
														/>
														<label className="input-date-label">{t("date")}</label>
													</section>
												</Grid>
												<Grid item xs={12} sm={4} className="order-extra-transfer-field-container">
													<TextField
														disabled={isDisabled}
														className="order-extra-transfer-field"
														variant="filled"
														label={t("passengers")}
														type="number"
														value={transferExtra.arrival.passengers <= 0 ? "" : transferExtra.arrival.passengers}
														onChange={(e) => {
															let newTransferExtra = { ...transferExtra };
															if (isNaN(Number(e.currentTarget.value)) && Number(e.currentTarget.value) <= 0) {
																newTransferExtra.arrival.passengers = undefined as unknown as number;
															} else {
																newTransferExtra.arrival.passengers = Number(e.currentTarget.value);
															}
															handleChangeExtra(newTransferExtra as Transfer);
														}}></TextField>
												</Grid>
												<Grid item xs={12} sm={4} className="order-extra-transfer-field-container last">
													<TextField
														disabled={isDisabled}
														className="order-extra-transfer-field"
														variant="filled"
														label={t("time")}
														type="time"
														value={transferExtra.arrival.time}
														onChange={(e) => {
															let newTransferExtra = { ...transferExtra };

															newTransferExtra.arrival.time = e.currentTarget.value;

															handleChangeExtra(newTransferExtra as Transfer);
														}}></TextField>
												</Grid>
											</Grid>
										</Grid>

										<Grid item xs={12}>
											<Grid container rowGap={2}>
												<Grid item xs={12} sm={4} className="order-extra-transfer-field-container">
													<TextField
														disabled={isDisabled}
														className="order-extra-transfer-field"
														variant="filled"
														label={t("flight-number")}
														value={transferExtra.arrival.flightNumber}
														onChange={(e) => {
															let newTransferExtra = { ...transferExtra };
															newTransferExtra.arrival.flightNumber = e.currentTarget.value;
															handleChangeExtra(newTransferExtra as Transfer);
														}}></TextField>
												</Grid>
												<Grid item xs={12} sm={4} className="order-extra-transfer-field-container">
													<TextField
														disabled={isDisabled}
														className="order-extra-transfer-field"
														variant="filled"
														label={t("from")}
														value={transferExtra.arrival.from}
														onChange={(e) => {
															let newTransferExtra = { ...transferExtra };
															newTransferExtra.arrival.from = e.currentTarget.value;
															handleChangeExtra(newTransferExtra as Transfer);
														}}></TextField>
												</Grid>
												<Grid item xs={12} sm={4} className="order-extra-transfer-field-container last">
													<TextField
														disabled={isDisabled}
														className="order-extra-transfer-field"
														variant="filled"
														label={t("to")}
														value={transferExtra.arrival.to}
														onChange={(e) => {
															let newTransferExtra = { ...transferExtra };
															newTransferExtra.arrival.to = e.currentTarget.value;
															handleChangeExtra(newTransferExtra as Transfer);
														}}></TextField>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body1" fontSize="20px" fontWeight={300}>
										{t("departure")}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Grid container rowGap={2}>
										<Grid item xs={12}>
											<Grid container rowGap={2}>
												<Grid item xs={12} sm={4} paddingRight="16px">
													<section className="order-extra-transfer-field-container input-date-container">
														<DatePicker
															disabled={isDisabled}
															className=" order-extra-transfer-field input-date"
															name="departureDate"
															selected={transferExtra.departure.date > 0 ? new Date(transferExtra.departure.date) : null}
															onChange={(date: Date | null) => {
																let newTransferExtra = { ...transferExtra };

																if (date == null) newTransferExtra.departure.date = 0;
																else newTransferExtra.departure.date = date.getTime();

																handleChangeExtra(newTransferExtra as Transfer);
															}}
														/>
														<label className="input-date-label">{t("date")}</label>
													</section>
												</Grid>
												<Grid item xs={12} sm={4} className="order-extra-transfer-field-container">
													<TextField
														disabled={isDisabled}
														className="order-extra-transfer-field"
														variant="filled"
														label={t("passengers")}
														type="number"
														value={transferExtra.departure.passengers <= 0 ? "" : transferExtra.departure.passengers}
														onChange={(e) => {
															let newTransferExtra = { ...transferExtra };
															if (isNaN(Number(e.currentTarget.value)) && Number(e.currentTarget.value) <= 0) {
																newTransferExtra.departure.passengers = undefined as unknown as number;
															} else {
																newTransferExtra.departure.passengers = Number(e.currentTarget.value);
															}
															handleChangeExtra(newTransferExtra as Transfer);
														}}></TextField>
												</Grid>
												<Grid item xs={12} sm={4} className="order-extra-transfer-field-container last">
													<TextField
														disabled={isDisabled}
														className="order-extra-transfer-field"
														variant="filled"
														label={t("time")}
														value={transferExtra.departure.time}
														type="time"
														onChange={(e) => {
															let newTransferExtra = { ...transferExtra };

															newTransferExtra.departure.time = e.currentTarget.value;

															handleChangeExtra(newTransferExtra as Transfer);
														}}></TextField>
												</Grid>
											</Grid>
										</Grid>

										<Grid item xs={12}>
											<Grid container rowGap={2}>
												<Grid item xs={12} sm={4} className="order-extra-transfer-field-container">
													<TextField
														disabled={isDisabled}
														className="order-extra-transfer-field"
														variant="filled"
														label={t("flight-number")}
														value={transferExtra.departure.flightNumber}
														onChange={(e) => {
															let newTransferExtra = { ...transferExtra };
															newTransferExtra.departure.flightNumber = e.currentTarget.value;
															handleChangeExtra(newTransferExtra as Transfer);
														}}></TextField>
												</Grid>
												<Grid item xs={12} sm={4} className="order-extra-transfer-field-container">
													<TextField
														disabled={isDisabled}
														className="order-extra-transfer-field"
														variant="filled"
														label={t("from")}
														value={transferExtra.departure.from}
														onChange={(e) => {
															let newTransferExtra = { ...transferExtra };
															newTransferExtra.departure.from = e.currentTarget.value;
															handleChangeExtra(newTransferExtra as Transfer);
														}}></TextField>
												</Grid>
												<Grid item xs={12} sm={4} className="order-extra-transfer-field-container last">
													<TextField
														disabled={isDisabled}
														className="order-extra-transfer-field"
														variant="filled"
														label={t("to")}
														value={transferExtra.departure.to}
														onChange={(e) => {
															let newTransferExtra = { ...transferExtra };
															newTransferExtra.departure.to = e.currentTarget.value;
															handleChangeExtra(newTransferExtra as Transfer);
														}}></TextField>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</>
	);
};

const ExtraComponentForPiece = ({
	extra,
	index,
	handleSelectExtra,
	isDisabled,
	isBoat,
	changeCostAndTypeExtra,
}: {
	extra: ExtraElementInOrder;
	index: number;
	handleSelectExtra(extra: ExtraElementInOrder, index: number): void;
	isDisabled: boolean;
	isBoat: boolean;
	changeCostAndTypeExtra?: ({
		extra,
		newCost,
		newType,
	}: {
		extra: ExtraElementInOrder;
		newCost: number;
		newType: ExtraElementInOrder["type"];
	}) => Promise<void>;
}) => {
	const { language } = useTranslationProvider();
	const { t } = useTranslation();

	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [newCost, setNewCost] = useState<number>(extra.cost);
	const [newType, setNewType] = useState<ExtraElementInOrder["type"]>(extra.type);

	const [expanded, setExpanded] = useState<string | false>(isBoat ? `panel-extra-${index}` : false);
	const [quantity, setQuantity] = useState<number | undefined>(extra.quantity ? extra.quantity : undefined);
	const [extraChoosenClass, setExtraChoosenClass] = useState<string>("");
	const handleToggleAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	const confirmEdit = async () => {
		if (changeCostAndTypeExtra) {
			await changeCostAndTypeExtra({ extra, newCost, newType });
		}
		setIsEditing(false);
	};

	const cancelEdit = () => {
		setNewCost(extra.cost);
		setNewType(extra.type);
		setIsEditing(false);
	};

	useEffect(() => {
		if (!isNaN(quantity as number)) {
			setExtraChoosenClass("order-checkbox-filled");
			extra.checked = true;
			extra.quantity = quantity;
			handleSelectExtra(extra, index);
		} else {
			setExtraChoosenClass("");

			extra.checked = false;
			extra.quantity = undefined;
			handleSelectExtra(extra, index);
		}
	}, [quantity, extra]);

	return (
		<>
			<Accordion
				className="accordion accordion-extras extra-component-container"
				expanded={expanded === `panel-extra-${index}`}
				onChange={handleToggleAccordion(`panel-extra-${index}`)}
				sx={{ width: "100%" }}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon className="accordion-summary-icon" />}
					aria-controls={`panel-extra-${index}bh-content`}
					id={`panel-extra-${index}bh-header`}>
					<div className={`order-checkbox ${extraChoosenClass}`}>
						{extraChoosenClass === "" ? <AddIcon className="checkbox-icon " /> : <CheckIcon className="checkbox-icon" />}
					</div>
					<div className="extra-summary-container">
						<Typography variant="body1" fontSize="20px" paddingRight="16px" marginLeft="8px">
							{extra.name[language as keyof TranslationType]}
						</Typography>
						<Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "1em", marginLeft: { xs: "8px", md: "0" } }}>
							{extra.oldCost && extra.oldType && (extra.oldCost !== extra.cost || extra.oldType !== extra.type) && (
								<>
									<Typography
										variant="body1"
										fontSize="20px"
										sx={{ textDecorationLine: "line-through", textDecorationColor: "red", textDecorationThickness: 2 }}>
										{extra.oldCost}€{extra.oldType !== "discount" && `/${t(extra.oldType)}`}
									</Typography>
									<ArrowForwardIcon sx={{ color: "#149cd8", display: { xs: "none", md: "block" } }} fontSize="large" />
								</>
							)}
							{isBoat && isEditing ? (
								<>
									<CheckIcon fontSize="large" sx={{ color: "green", cursor: "pointer" }} onClick={confirmEdit} />
									<CloseIcon fontSize="large" sx={{ color: "red", cursor: "pointer" }} onClick={cancelEdit} />
									<TextField
										type="number"
										sx={{ width: "15%" }}
										inputProps={{ style: { textAlign: "right" } }}
										defaultValue={newCost}
										onChange={(e) => {
											if (!isNaN(Number(e.currentTarget.value))) setNewCost(Number(e.currentTarget.value));
										}}></TextField>
									<Select
										defaultValue={newType}
										onChange={(e) => {
											setNewType(e.target.value as ExtraElementInOrder["type"]);
										}}>
										<MenuItem value="person">{t("person")}</MenuItem>
										<MenuItem value="piece">{t("piece")}</MenuItem>
										<MenuItem value="week">{t("week")}</MenuItem>
										<MenuItem value="booking">{t("booking")}</MenuItem>
										<MenuItem value="discount">{t("discount")}</MenuItem>
									</Select>
								</>
							) : (
								<>
									{isBoat && (
										<ModeEditOutlineRoundedIcon sx={{ cursor: "pointer" }} fontSize="large" htmlColor="#959595" onClick={() => setIsEditing(true)} />
									)}
									{extra.type === "discount" ? (
										<Typography variant="h4" fontSize="20px" sx={{ color: "#149cd8" }} fontWeight="bold">
											{extra.cost}€
										</Typography>
									) : (
										<Typography variant="body1" fontSize="20px">
											{extra.cost}€/{t(extra.type)}
										</Typography>
									)}
								</>
							)}
						</Grid>
					</div>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container width="100%">
						<Grid item xs={12} md={4}>
							<TextField
								disabled={isDisabled}
								className="input-form"
								sx={{ width: { xs: "100%" } }}
								type="number"
								name="quantity"
								label={t("quantity")}
								variant={"filled"}
								value={quantity}
								onChange={(e) => {
									if (!isNaN(Number(e.currentTarget.value)) && Number(e.currentTarget.value) > 0) setQuantity(Number(e.currentTarget.value));
									else {
										setQuantity(undefined);
									}
								}}
							/>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</>
	);
};

const ExtraComponentGeneric = ({
	extra,
	index,
	handleSelectExtra,
	isDisabled,
	isBoat,
	changeCostAndTypeExtra,
}: {
	extra: ExtraElementInOrder;
	index: number;
	handleSelectExtra: (extra: ExtraElementInOrder, index: number) => void;
	isDisabled: boolean;
	isBoat: boolean;
	changeCostAndTypeExtra?: ({
		extra,
		newCost,
		newType,
	}: {
		extra: ExtraElementInOrder;
		newCost: number;
		newType: ExtraElementInOrder["type"];
	}) => Promise<void>;
}) => {
	const { language } = useTranslationProvider();
	const { t } = useTranslation();

	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [newCost, setNewCost] = useState<number>(extra.cost);
	const [newType, setNewType] = useState<ExtraElementInOrder["type"]>(extra.type);

	let extraChoosenClass = "";
	if (extra.checked) extraChoosenClass = "order-checkbox-filled";
	const handleGenericExtraSelect = () => {
		extra.checked = !extra.checked;
		handleSelectExtra(extra, index);
	};

	const confirmEdit = async () => {
		if (changeCostAndTypeExtra) {
			await changeCostAndTypeExtra({ extra, newCost, newType });
		}
		setIsEditing(false);
	};

	const cancelEdit = () => {
		setNewCost(extra.cost);
		setNewType(extra.type);
		setIsEditing(false);
	};

	return (
		<>
			<a
				onClick={() => {
					if (!isDisabled) handleGenericExtraSelect();
				}}>
				<div className={`order-checkbox ${extraChoosenClass}`}>
					{extraChoosenClass === "" ? <AddIcon className="checkbox-icon " /> : <CheckIcon className="checkbox-icon" />}
				</div>
			</a>

			<div className="extra-summary-container generic-extra-content">
				<Typography variant="body1" fontSize="20px" marginLeft="8px" paddingRight="16px">
					{extra.name[language as keyof TranslationType]}
				</Typography>
				<Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "1em", marginLeft: { xs: "8px", md: "0" } }}>
					{extra.oldCost && extra.oldType && (extra.oldCost !== extra.cost || extra.oldType !== extra.type) && (
						<>
							<Typography
								variant="body1"
								fontSize="20px"
								sx={{ textDecorationLine: "line-through", textDecorationColor: "red", textDecorationThickness: 2 }}>
								{extra.oldCost}€{extra.oldType !== "discount" && `/${t(extra.oldType)}`}
							</Typography>
							<ArrowForwardIcon sx={{ color: "#149cd8", display: { xs: "none", md: "block" } }} fontSize="large" />
						</>
					)}
					{isBoat && isEditing ? (
						<>
							<CheckIcon fontSize="large" sx={{ color: "green", cursor: "pointer" }} onClick={confirmEdit} />
							<CloseIcon fontSize="large" sx={{ color: "red", cursor: "pointer" }} onClick={cancelEdit} />
							<TextField
								type="number"
								sx={{ width: "15%" }}
								inputProps={{ style: { textAlign: "right" } }}
								defaultValue={newCost}
								onChange={(e) => {
									if (!isNaN(Number(e.currentTarget.value))) setNewCost(Number(e.currentTarget.value));
								}}></TextField>
							<Select
								defaultValue={newType}
								onChange={(e) => {
									setNewType(e.target.value as ExtraElementInOrder["type"]);
								}}>
								<MenuItem value="person">{t("person")}</MenuItem>
								<MenuItem value="piece">{t("piece")}</MenuItem>
								<MenuItem value="week">{t("week")}</MenuItem>
								<MenuItem value="booking">{t("booking")}</MenuItem>
								<MenuItem value="discount">{t("discount")}</MenuItem>
							</Select>
						</>
					) : (
						<>
							{isBoat && (
								<ModeEditOutlineRoundedIcon sx={{ cursor: "pointer" }} fontSize="large" htmlColor="#959595" onClick={() => setIsEditing(true)} />
							)}
							{extra.type === "discount" ? (
								<Typography variant="h4" fontSize="20px" sx={{ color: "#149cd8" }} fontWeight="bold">
									{extra.cost}€
								</Typography>
							) : (
								<Typography variant="body1" fontSize="20px">
									{extra.cost}€/{t(extra.type)}
								</Typography>
							)}
						</>
					)}
				</Grid>
			</div>
		</>
	);
};

export const ExtraComponent = ({
	extra,
	index,
	handleSelectExtra,
	orderStatus,
	isBoat,
	disabled,
	changeCostAndTypeExtra,
}: {
	extra: ExtraElementInOrder;
	index: number;
	handleSelectExtra: (extra: ExtraElementInOrder, index: number) => void;
	orderStatus: string;
	isBoat?: boolean;
	disabled?: boolean;
	changeCostAndTypeExtra?: ({
		extra,
		newCost,
		newType,
	}: {
		extra: ExtraElementInOrder;
		newCost: number;
		newType: ExtraElementInOrder["type"];
	}) => Promise<void>;
}) => {
	const isDisabled = isBoat == null || (!isBoat ? orderStatus === orderStatuses.ACCEPTED : false) || disabled == null ? false : disabled;
	return (
		<Grid item xs={12} display="flex" sx={{ alignItems: { xs: "flex-start", md: "center" } }}>
			{extra.type === "transfer" && (
				<ExtraComponentForTransfer
					isDisabled={isDisabled}
					isBoat={isBoat || false}
					handleSelectExtra={handleSelectExtra}
					extra={extra}
					changeCostAndTypeExtra={changeCostAndTypeExtra}
					index={index}
				/>
			)}

			{extra.type === "piece" && (
				<ExtraComponentForPiece
					isDisabled={isDisabled}
					isBoat={isBoat || false}
					handleSelectExtra={handleSelectExtra}
					extra={extra}
					index={index}
					changeCostAndTypeExtra={changeCostAndTypeExtra}
				/>
			)}

			{extra.type !== "transfer" && extra.type !== "piece" && (
				<ExtraComponentGeneric
					isDisabled={isDisabled}
					isBoat={isBoat || false}
					handleSelectExtra={handleSelectExtra}
					extra={extra}
					index={index}
					changeCostAndTypeExtra={changeCostAndTypeExtra}
				/>
			)}
		</Grid>
	);
};
