import { Navigate, Outlet } from "react-router";
import { useAuth } from "../providers/AuthProvider";

const BoatProtection = () => {
	const { boatProfile } = useAuth();

	if (boatProfile == null || boatProfile.uid == "" || boatProfile.uid == undefined) {
		return <Navigate to="/login/boat" />;
	}

	return <Outlet />;
};

export default BoatProtection;
