import { Dialog, DialogContent, DialogTitle, Grid, Typography, Button, TextField } from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
	resetPasswordEmail: yup.string().email("insert a valid email").required("Email is required"),
});

export const PopUpResetPassword = ({
	isPopUpOpen,
	handleClosePopup,
	handleResetPassword,
}: {
	isPopUpOpen: boolean;
	handleClosePopup: () => void;
	handleResetPassword: (email: string) => Promise<void>;
}) => {
	const formik = useFormik({
		initialValues: {
			resetPasswordEmail: "",
		},

		validationSchema: validationSchema,
		onSubmit: async (values, { resetForm }) => await handleSubmit(values, resetForm),
	});
	const handleSubmit = async (values: any, resetForm: any) => {
		handleResetPassword(values.resetPasswordEmail);
		resetForm();
		handleClosePopup();
	};
	const { t } = useTranslation();
	return (
		<>
			<Dialog open={isPopUpOpen} onClose={handleClosePopup} fullWidth maxWidth="md">
				<DialogTitle mb={3}>
					<Typography variant="h4">{t("resetPassword")}</Typography>
				</DialogTitle>
				<DialogContent>
					<>
						<form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
							<Grid container rowGap={2}>
								<Grid item xs={12}>
									<TextField
										className="input-form"
										sx={{ width: { xs: "100%" } }}
										id="resetPasswordEmail"
										name="resetPasswordEmail"
										label="Email"
										variant={"filled"}
										value={formik.values.resetPasswordEmail}
										onChange={formik.handleChange}
										error={formik.touched.resetPasswordEmail && Boolean(formik.errors.resetPasswordEmail)}
										helperText={formik.touched.resetPasswordEmail && formik.errors.resetPasswordEmail}
									/>
								</Grid>

								<Grid item xs={12} sx={{ marginTop: "16px" }}>
									<Grid container justifyContent={"center"}>
										<Grid item xs={4}>
											<Button type="submit" fullWidth className="form-button ">
												{t("send")}
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					</>
				</DialogContent>
			</Dialog>
		</>
	);
};
