import { Dialog, DialogContent, DialogTitle, Grid, Typography, Button, TextField } from "@mui/material";

import "../../styles/popup/MnemonicInsertion.scss";
import "../../styles/Buttons.scss";
import { Dispatch, SetStateAction } from "react";
import { useSnackbar } from "../providers/SnackbackProvider";
import { useTranslation } from "react-i18next";

const MnemonicField = ({ index, word, handleSetWord }: { index: number; word: string; handleSetWord: (word: string, index: number) => void }) => {
	const { t } = useTranslation();
	return (
		<>
			<TextField
				className="field-word input-form"
				variant="filled"
				label={`${t("word")} N.` + index}
				value={word}
				onChange={(e) => {
					handleSetWord(e.currentTarget.value, index - 1);
				}}
			></TextField>
		</>
	);
};

export const PopUpMnemonicInsertion = ({
	mnemonic,
	setMnemonic,
	isPopUpOpen,
	handleClosePopup,
	handleSubmit,
}: {
	mnemonic: string[];
	setMnemonic: Dispatch<SetStateAction<string[]>>;
	isPopUpOpen: boolean;
	handleClosePopup: () => void;
	handleSubmit: () => Promise<void>;
}) => {
	const { openSnackbar, closeLoadingSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const handleSetWord = (word: string, index: number) => {
		if (word.split("\n").length === 12) {
			setMnemonic(word.split("\n"));
			return;
		}
		if (word.split(" ").length === 12) {
			setMnemonic(word.split(" "));
			return;
		}
		const newMnemonic = [...mnemonic];
		newMnemonic[index] = word.trim();
		setMnemonic(newMnemonic);
	};
	const handlesubmitFirm = async () => {
		try {
			await handleSubmit();
			handleClosePopup();
		} catch (err) {
			const error = err as Error;
			openSnackbar(t("error") as string, error.message, "error");
			console.error(err);
			closeLoadingSnackbar();
		}
	};

	return (
		<Dialog open={isPopUpOpen} onClose={handleClosePopup} fullWidth maxWidth="md">
			<DialogTitle mb={3}>
				<Typography variant="h4">{t("insert-private-key")}</Typography>
			</DialogTitle>
			<DialogContent>
				<>
					<Grid container gap={2} rowGap={2} justifyContent="center">
						{mnemonic.map((value, index) => {
							return (
								<>
									<Grid item xs={5} key={"mnemonic-field-" + index}>
										<MnemonicField word={value} handleSetWord={handleSetWord} index={index + 1}></MnemonicField>
									</Grid>
								</>
							);
						})}

						<Grid xs={12}>
							<Grid container justifyContent="center">
								<Grid item xs={4}>
									<Button
										onClick={async () => {
											await handlesubmitFirm();
										}}
										fullWidth
										className="form-button"
									>
										{t("firm")}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</>
			</DialogContent>
		</Dialog>
	);
};
