import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Grid, Typography, Button, TextField } from "@mui/material";

import * as yup from "yup";
import { Formik, Form } from "formik";
import { AddCrewMemberParams } from "../../../types/forms/CrewMemeber";
import { useTranslation } from "react-i18next";

export const AddCrewListMemberPopUp = ({
	isPopUpOpen,
	handleClosePopup,
	handleAddCrewMember,
	disabled,
	crewMemeber,
	isUser,
	handleRemoveCrewMember,
	isPastOrder,
}: {
	isPopUpOpen: boolean;
	handleClosePopup: () => void;
	handleAddCrewMember: (crewMemeberParams: AddCrewMemberParams) => Promise<void>;
	disabled?: boolean;
	crewMemeber?: AddCrewMemberParams;
	isUser?: boolean;
	handleRemoveCrewMember?: (idNumber: string) => Promise<void>;
	isPastOrder?: boolean;
}) => {
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const { t } = useTranslation();
	const validationSchema = yup.object({
		name: yup.string().required(t("name-required") as string),
		surname: yup.string().required("surname-required" as string),
		address: yup.string().required(t("address-required") as string),
		phoneNumber: yup.string().required(t("phone-number-required") as string),
		idNumber: yup.string().required(t("id-number-required") as string),
	});

	useEffect(() => {
		setIsDisabled(disabled == null ? false : disabled);
		if (crewMemeber != null) setIsDisabled(true);
	}, [crewMemeber, isPopUpOpen]);

	const handleSubmit = async (
		values: {
			name: string;
			surname: string;
			address: string;
			phoneNumber: string;
			idNumber: string;
		},
		resetForm: any
	) => {
		const crewMemeberParams: AddCrewMemberParams = {
			name: values.name,
			surname: values.surname,
			address: values.address,
			phoneNumber: values.phoneNumber,
			idNumber: values.idNumber,
			isSkipper: false,
		};
		handleAddCrewMember(crewMemeberParams);
		resetForm();
		handleClosePopup();
	};

	return (
		<>
			<Dialog open={isPopUpOpen} onClose={handleClosePopup} fullWidth maxWidth="md" className="pop-up-container">
				<DialogTitle mb={3}>
					{crewMemeber != null || isDisabled ? (
						<>
							<Typography variant="h4">{t("member-crewlist")}</Typography>
						</>
					) : (
						<>
							<Typography variant="h4">{t("add-member-to-crewlist")}</Typography>
						</>
					)}
				</DialogTitle>
				<DialogContent>
					<>
						<Formik
							initialValues={{
								name: crewMemeber != null ? crewMemeber.name : "",
								surname: crewMemeber != null ? crewMemeber.surname : "",
								address: crewMemeber != null ? crewMemeber.address : "",
								phoneNumber: crewMemeber != null ? crewMemeber.phoneNumber : "",
								idNumber: crewMemeber != null ? crewMemeber.idNumber : "",
							}}
							onSubmit={async (values, { resetForm }) => {
								await handleSubmit(values, resetForm);
							}}
							validationSchema={validationSchema}>
							{({ touched, errors, values, handleChange }) => (
								<Form>
									<Grid container rowGap={2} className="input-form-container-crew-member">
										<Grid item xs={6}>
											<TextField
												className="input-form"
												sx={{ width: { xs: "100%" } }}
												id="name"
												name="name"
												label={t("name")}
												disabled={isDisabled}
												variant={"filled"}
												value={values.name}
												onChange={handleChange}
												error={touched.name && Boolean(errors.name)}
												helperText={touched.name && errors.name}
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												className="input-form"
												sx={{ width: { xs: "100%" } }}
												id="surname"
												name="surname"
												label={t("surname")}
												disabled={isDisabled}
												variant={"filled"}
												value={values.surname}
												onChange={handleChange}
												error={touched.surname && Boolean(errors.surname)}
												helperText={touched.surname && errors.surname}
											/>
										</Grid>

										<Grid item xs={12}>
											<TextField
												className="input-form"
												sx={{ width: { xs: "100%" } }}
												id="address"
												name="address"
												label={t("address")}
												disabled={isDisabled}
												variant={"filled"}
												value={values.address}
												onChange={handleChange}
												error={touched.address && Boolean(errors.address)}
												helperText={touched.address && errors.address}
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												className="input-form"
												sx={{ width: { xs: "100%" } }}
												id="phoneNumber"
												name="phoneNumber"
												label={t("cell-number")}
												disabled={isDisabled}
												variant={"filled"}
												type="tel"
												value={values.phoneNumber}
												onChange={handleChange}
												error={touched.phoneNumber && Boolean(errors.phoneNumber)}
												helperText={touched.phoneNumber && errors.phoneNumber}
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												className="input-form"
												sx={{ width: { xs: "100%" } }}
												id="idNumber"
												name="idNumber"
												label={t("document-number")}
												disabled={isDisabled}
												variant={"filled"}
												value={values.idNumber}
												onChange={handleChange}
												error={touched.idNumber && Boolean(errors.idNumber)}
												helperText={touched.idNumber && errors.idNumber}
											/>
										</Grid>

										{!isDisabled && crewMemeber == null && (
											<Grid item xs={12} sx={{ marginTop: "16px" }}>
												<Grid container justifyContent={"center"}>
													<Grid item xs display="flex" justifyContent="center">
														<Button type="submit" className="form-button ">
															{t("add")}
														</Button>
													</Grid>
												</Grid>
											</Grid>
										)}
										{(isPastOrder != null ? (isPastOrder ? false : true) : true) &&
											crewMemeber != null &&
											isUser != null &&
											handleRemoveCrewMember != null && (
												<>
													<Grid item xs={12} sx={{ marginTop: "16px" }}>
														<Grid container justifyContent={"center"}>
															<Grid item xs display="flex" justifyContent="center">
																<Button
																	onClick={async () => {
																		await handleRemoveCrewMember(crewMemeber.idNumber);
																		handleClosePopup();
																	}}
																	className="form-button">
																	Rimuovi
																</Button>
															</Grid>
														</Grid>
													</Grid>
												</>
											)}
									</Grid>
								</Form>
							)}
						</Formik>
					</>
				</DialogContent>
			</Dialog>
		</>
	);
};
