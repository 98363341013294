import { createContext, useContext, useEffect } from "react";
import { UserProvider } from "../../types/providers/UserProvider";
import { useAuth } from "./AuthProvider";
import { signOut, updatePassword } from "firebase/auth";
import { useNetwork } from "./NetworkProvider";
import { api } from "../../utils/network/api";
import { UserDocument } from "../../types/documents/User";
import { useFirebase } from "./FirebaseProvider";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "./SnackbackProvider";
import { useTranslationProvider } from "./TranslationProvider";

const UserContext = createContext<UserProvider>({} as UserProvider);
export const UserProviderImpl = ({ children }: { children: JSX.Element }) => {
	const { sendResetPasswordEmail, user } = useAuth();
	const { myFS, myAuth } = useFirebase();
	const navigate = useNavigate();
	const { makePost } = useNetwork();
	const { openSnackbar } = useSnackbar();

	const sendResetPasswordEmailToUser = async (email: string) => {
		try {
			await sendResetPasswordEmail(email);
			await signOut(myAuth);
			navigate("/");
			openSnackbar("Success", "Email sent", "success");
		} catch (err) {
			console.error(err);
			const error = err as Error;
			openSnackbar("Error", error.message, "error");
		}
	};

	const changePassword = async (email: string, oldPassword: string, newPassword: string) => {
		if (!user) {
			throw new Error("Not logged in, please relogg in");
		}
		if (!email) throw new Error("emailRequired");
		if (!oldPassword) throw new Error("oldPasswordRequired");
		if (!newPassword) throw new Error("newPasswordRequired");
		await updatePassword(user, newPassword);
		await signOut(myAuth);
	};

	const deleteAccount = async () => {
		try {
			if (!user) {
				throw new Error("Not logged in, please relogg in");
			}
			const tokenId = await user.getIdToken();
			const result = await makePost(api.deleteUserAccount, {}, tokenId);
			if (result.data.ok) window.location.href = "/";
		} catch (err) {
			console.error(err);
			throw new Error("Something went wrong");
		}
	};
	const getUserFromUid = async (userUid: string): Promise<UserDocument> => {
		let user: UserDocument = {} as UserDocument;
		const docRef = doc(myFS, "users", userUid);

		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			user = docSnap.data() as UserDocument;
		}
		return user;
	};

	const userContextData = {
		sendResetPasswordEmailToUser,
		changePassword,
		deleteAccount,
		getUserFromUid,
	};

	return <UserContext.Provider value={userContextData}>{children}</UserContext.Provider>;
};
export const useUserProvider = () => useContext(UserContext);
