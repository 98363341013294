import { Grid, Button, Typography } from "@mui/material";
import { useState, useEffect } from "react";

import "../../../styles/Buttons.scss";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import { useAuth } from "../../providers/AuthProvider";
import { useBoatProvider } from "../../providers/BoatProvider";
import { useTranslationProvider } from "../../providers/TranslationProvider";
import { ChecklistElement, EditChecklistElement, EditElements, WarningChecklistBoat } from "../../../types/forms/Checklist";
import { TranslationType } from "../../../types/Generic";
import { AddCheckListPopUp } from "./AddCheckListPopUp";
import { useSnackbar } from "../../providers/SnackbackProvider";
import { constructOrder, findFirstElement, returnChecklistElementIndexFromUID } from "../../../utils/orderChecklist";
import { useTranslation } from "react-i18next";
import { EditCheckListPopUp } from "./EditCheckListPopup";
export const BoatModifyChecklist = () => {
	// providers
	const { boatProfile, getChecklistCollectionAssociatedWithBoat } = useAuth();
	const {
		removeCheckElementFromBoat,
		addCheckElementToBoat,
		handleUpdateChecklistElement,
		addWarningToBoatChecklist,
		removeWarningChecklistFromBoat,
		handleUpdateWarningElement,
		handleRepositioningWarningsBoat,
	} = useBoatProvider();

	const [checklistInventory, setChecklistInventory] = useState<ChecklistElement[]>([] as ChecklistElement[]);
	const [checklistCheckin560, setChecklistCheckin560] = useState<ChecklistElement[]>([] as ChecklistElement[]);
	const [warnings, setWarnings] = useState<WarningChecklistBoat[]>([] as WarningChecklistBoat[]);
	const [elementToBeEdited, setElementToBeEdited] = useState<ChecklistElement>();
	const [warningToBeEdited, setWarningToBeEdited] = useState<WarningChecklistBoat>();
	const [warningPosition, setWarningPosition] = useState<number>(-1);

	const [openChecklistPopup, setOpenChecklistPopup] = useState<boolean>(false);
	const [openEditChecklistPopup, setOpenEditChecklistPopup] = useState<boolean>(false);
	const { openSnackbar, openLoadingSnackbar, closeLoadingSnackbar } = useSnackbar();

	const handleAddCheckListElement = async (type: string, name: TranslationType, category: string, price?: number, warning?: WarningChecklistBoat) => {
		try {
			if (boatProfile) {
				openLoadingSnackbar();
				if (category === "warning") {
					if (warning == null) throw new Error("Inserirce il campo di Warning");
					await addWarningToBoatChecklist(boatProfile.uid, warning);
				} else {
					let firstElement: ChecklistElement | null | undefined = null;
					// find the first element of the the respective list
					if (category === "inventory") {
						firstElement = findFirstElement(checklistInventory);
					} else {
						firstElement = findFirstElement(checklistCheckin560);
					}
					if (firstElement == null) {
						throw new Error("Impossibile trovare l'elemento di inizio ");
					}
					let uid: string = "";

					if (category === "inventory" && price != null) {
						//if the category is inventory and price is different from null add the price to the document
						let price_: number | "deposit";
						if (price > boatProfile.deposit) price_ = "deposit";
						else price_ = price;
						//get the uid of the element created, it will be needed to insert it in the list
						uid = await addCheckElementToBoat(boatProfile.uid, {
							inputType: type,
							name: name,
							category: category,
							next: firstElement.uid!,
							before: "first",
							price: price_,
						});
					} else {
						uid = await addCheckElementToBoat(boatProfile.uid, {
							inputType: type,
							name: name,
							category: category,
							next: firstElement.uid!,
							before: "first",
						});
					}
					//handle adding te element in the local list
					let newChecklistElement: ChecklistElement = {
						inputType: type,
						name: name,
						category: category,
						next: firstElement.uid!,
						before: "first",
						uid: uid,
					};
					if (category === "inventory" && price) {
						let price_: number | "deposit";
						if (price > boatProfile.deposit) price_ = "deposit";
						else price_ = price;
						newChecklistElement = { ...newChecklistElement, price: price_ };
					}
					// Add the new element to the checklist in order to have immidiate feedback, and in DB
					await handleAddInOrder(
						newChecklistElement,
						uid,
						category === "inventory" ? checklistInventory : checklistCheckin560,
						category === "inventory" ? setChecklistInventory : setChecklistCheckin560
					);
				}
				closeLoadingSnackbar();
			}
		} catch (err) {
			closeLoadingSnackbar();
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		}
	};
	const handleAddInOrder = async (
		newChecklistElement: ChecklistElement,
		uidChecklistElementAdded: string,
		checklist: ChecklistElement[],
		setChecklist: React.Dispatch<React.SetStateAction<ChecklistElement[]>>
	): Promise<void> => {
		//Add the element in the first posion of the list
		const newChecklist = [...checklist];
		const firstElement = findFirstElement(checklist);
		if (firstElement == null) {
			throw new Error("Impossibile trovare l'elemento di inizio ");
		}
		// find the first position
		const indexFirstElement = returnChecklistElementIndexFromUID(firstElement.uid!, newChecklist);
		// update the before of the previuos first element
		newChecklist[indexFirstElement].before = uidChecklistElementAdded;
		// update the elements in DB
		await handleUpdatePositionInDB([newChecklist[indexFirstElement]]);
		// push the new element in the local list
		newChecklist.push(newChecklistElement);
		setChecklist(constructOrder(newChecklist));
	};

	const handleDeleteInFromOrder = async (
		checklistElementToBeRemoved: ChecklistElement,
		checklist: ChecklistElement[],
		setChecklist: React.Dispatch<React.SetStateAction<ChecklistElement[]>>
	): Promise<void> => {
		//in order to remove an element we need remove from the order first

		const newChecklist = [...checklist];
		const elemtentToBeChanged: ChecklistElement[] = [] as ChecklistElement[];

		if (checklistElementToBeRemoved.before !== "first" && checklistElementToBeRemoved.next !== "last") {
			let checklistElementToBeRemovedBefore = returnChecklistElementIndexFromUID(checklistElementToBeRemoved.before, newChecklist);
			let checklistElementToBeRemovedNext = returnChecklistElementIndexFromUID(checklistElementToBeRemoved.next, newChecklist);
			newChecklist[checklistElementToBeRemovedBefore].next = checklistElementToBeRemoved.next;
			newChecklist[checklistElementToBeRemovedNext].before = checklistElementToBeRemoved.before;
			elemtentToBeChanged.push(newChecklist[checklistElementToBeRemovedBefore]);
		}

		if (checklistElementToBeRemoved.before === "first") {
			let checklistElementToBeRemovedNext = returnChecklistElementIndexFromUID(checklistElementToBeRemoved.next, newChecklist);
			newChecklist[checklistElementToBeRemovedNext].before = "first";
			elemtentToBeChanged.push(newChecklist[checklistElementToBeRemovedNext]);
		}

		if (checklistElementToBeRemoved.next === "last") {
			let checklistElementToBeRemovedBefore = returnChecklistElementIndexFromUID(checklistElementToBeRemoved.before, newChecklist);
			newChecklist[checklistElementToBeRemovedBefore].next = "last";
			elemtentToBeChanged.push(newChecklist[checklistElementToBeRemovedBefore]);
		}

		// find the index of the element locally and remove it from the checklist
		let indexOfElementToBeRemoved = returnChecklistElementIndexFromUID(checklistElementToBeRemoved.uid!, newChecklist);
		if (indexOfElementToBeRemoved !== -1) {
			newChecklist.splice(indexOfElementToBeRemoved, 1);
		}
		// update the elements that have changed in DB.
		await handleUpdatePositionInDB(elemtentToBeChanged);
		// set the checklist in order to have the new checklist in order
		setChecklist(constructOrder(newChecklist));
	};
	const handleDelete = async (uid: string, category: string) => {
		try {
			if (boatProfile) {
				openLoadingSnackbar();
				let checklistElementToBeRemoved: ChecklistElement | undefined;
				if (category === "inventory") {
					//find the element based on uid and pass  it to the function handleDeleteInFromOrder to be removed in the order
					checklistElementToBeRemoved = checklistInventory.find((checklistElement) => checklistElement.uid === uid);
					if (checklistElementToBeRemoved == null) throw new Error("Impossibile trovare l'elemento, ricarica la pagina e riprova");

					await handleDeleteInFromOrder(checklistElementToBeRemoved, checklistInventory, setChecklistInventory);
				} else {
					//find the element based on uid and pass  it to the function handleDeleteInFromOrder to be removed in the order
					checklistElementToBeRemoved = checklistCheckin560.find((checklistElement) => checklistElement.uid === uid);
					if (checklistElementToBeRemoved == null) throw new Error("Impossibile trovare l'elemento, ricarica la pagina e riprova");
					await handleDeleteInFromOrder(checklistElementToBeRemoved, checklistCheckin560, setChecklistCheckin560);
				}
				//Delete the actual element in DB
				await removeCheckElementFromBoat(boatProfile.uid, uid);
				closeLoadingSnackbar();
				openSnackbar("Successo", "Elemento checklist eliminato", "success");
			}
		} catch (err) {
			closeLoadingSnackbar();
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		}
	};

	const handleDeleteWarning = async (warningElement: WarningChecklistBoat) => {
		try {
			if (boatProfile) {
				openLoadingSnackbar();
				//Delete the actual element in DB
				await removeWarningChecklistFromBoat(boatProfile.uid, warningElement);
				closeLoadingSnackbar();
				openSnackbar("Successo", "Elemento warning eliminato", "success");
			}
		} catch (err) {
			closeLoadingSnackbar();
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		}
	};

	useEffect(() => {
		let timeout: NodeJS.Timeout | undefined = undefined;
		if (boatProfile != null) {
			// On load create the checklist inventory and chechlist Checkin560, constructing the order and setting it to the local list
			const newChecklist: ChecklistElement[] = [...boatProfile.checklist];

			openLoadingSnackbar();
			timeout = setTimeout(() => {
				updateViewChecklistInventory(newChecklist);
				updateViewChecklistCheckin560(newChecklist);
				closeLoadingSnackbar();
			}, 5000);

			if (boatProfile.warnings) setWarnings(boatProfile.warnings);
		}
		return () => {
			clearTimeout(timeout);
		};
	}, [boatProfile]);

	const updateViewChecklistInventory = (newChecklist: ChecklistElement[]) => {
		const newChecklistInventory = newChecklist.filter((checklistElement) => checklistElement.category === "inventory");
		setChecklistInventory(constructOrder(newChecklistInventory));
	};

	const updateViewChecklistCheckin560 = (newChecklist: ChecklistElement[]) => {
		const newChecklistCheckin560 = newChecklist.filter((checklistElement) => checklistElement.category === "checkin560");
		setChecklistCheckin560(constructOrder(newChecklistCheckin560));
	};

	const handleUpdatePositionInDB = async (elemtentToBeChanged: ChecklistElement[]) => {
		if (boatProfile == null) return;
		for (let i = 0; i < elemtentToBeChanged.length; i++) {
			try {
				openLoadingSnackbar();
				//update all elements in DB that need to be changed
				await handleUpdateChecklistElement(boatProfile.uid, elemtentToBeChanged[i]);
				closeLoadingSnackbar();
			} catch (err) {
				console.error(err);
				closeLoadingSnackbar();
			}
		}
	};

	const handleRepositioningInventory = async (oldPositionUID: string, newPositionIndex: number) => {
		// copy the current checklistInventory
		const newChecklistInventory = [...checklistInventory];
		//find the old position of the checklist element that is going to be repositioned
		let oldPositionIndex = returnChecklistElementIndexFromUID(oldPositionUID, newChecklistInventory);
		const oldPosition = newChecklistInventory.find((element) => element.uid === oldPositionUID);

		//find the new position of the checklist element
		const indexOrderedOldPosition = newChecklistInventory.findIndex((element) => element.uid === oldPositionUID);

		/*
			find the offset of the the new position, this is needed because if the new position is less than the old position you need to add one to the position to effectly take the element to the new position correctly,
			if the new position is greater that the polf positoin the offeset is not required,
			the offset is not required olso if the new position is the last element of the list
		*/
		const offset = indexOrderedOldPosition < newPositionIndex && newPositionIndex != newChecklistInventory.length - 1 ? 1 : 0;
		const newPosition = newPositionIndex + offset;

		if (oldPosition == null) return false;
		try {
			if (isNaN(newPosition) || newPosition < 0 || newPosition > checklistInventory.length - 1)
				throw new Error("Nuova posizione non valida", {
					cause: "warning",
				});
			if (oldPositionIndex === newPosition)
				throw new Error("La nuova posizione è uguale alla vecchia", {
					cause: "warning",
				});

			const elemtentToBeChanged: ChecklistElement[] = [] as ChecklistElement[];

			//find the position of the elements before and after the old position element

			let oldPositionIndexBefore = returnChecklistElementIndexFromUID(oldPosition.before, newChecklistInventory);
			let oldPositionIndexAfter = returnChecklistElementIndexFromUID(oldPosition.next, newChecklistInventory);
			//if the before element of the oldPosition is not the first one update the next
			//push the element that have changed in the array to be modified in the DB at the last step

			if (oldPosition.before !== "first") {
				newChecklistInventory[oldPositionIndexBefore].next = oldPosition.next;
				elemtentToBeChanged.push(newChecklistInventory[oldPositionIndexBefore]);
			}
			//if the next element of the oldPosition is not the first one update the next
			//push the element that have changed in the array to be modified in the DB at the last step

			if (oldPosition.next !== "last") {
				newChecklistInventory[oldPositionIndexAfter].before = oldPosition.before;
				elemtentToBeChanged.push(newChecklistInventory[oldPositionIndexAfter]);
			}

			/*
			If the new positionIndex is the last one.
			update the old positio before and after to remove the element from the old position in the list
			update the next of the last element to the UID of the element
			push the element that have changed in the array to be modified in the DB at the last step
		*/
			if (newPositionIndex === newChecklistInventory.length - 1) {
				newChecklistInventory[oldPositionIndex].before = newChecklistInventory[newPosition].uid!;
				newChecklistInventory[oldPositionIndex].next = newChecklistInventory[newPosition].next;
				newChecklistInventory[newPosition].next = oldPosition.uid!;
				elemtentToBeChanged.push(newChecklistInventory[oldPositionIndex]);
				elemtentToBeChanged.push(newChecklistInventory[newPosition]);
			} else {
				/**
				 * if the newPositionIndex is not the last one.
				 * update the before of the element to be changed to the before of the new postion
				 * if the before of  the new Element si different from the first, update the new position after of the before element to the uid of the element.
				 * remove element from his old positon of the list
				 */
				newChecklistInventory[oldPositionIndex].before = newChecklistInventory[newPosition].before;
				let newPositionIndex = returnChecklistElementIndexFromUID(newChecklistInventory[newPosition].uid!, newChecklistInventory);
				if (newChecklistInventory[newPosition].before !== "first") {
					let newPositionBefore = returnChecklistElementIndexFromUID(newChecklistInventory[newPosition].before, newChecklistInventory);
					newChecklistInventory[newPositionBefore].next = oldPosition.uid!;
					elemtentToBeChanged.push(newChecklistInventory[newPositionBefore]);
				}
				newChecklistInventory[oldPositionIndex].next = newChecklistInventory[newPosition].uid!;
				newChecklistInventory[newPositionIndex].before = oldPosition.uid!;

				elemtentToBeChanged.push(newChecklistInventory[oldPositionIndex]);
				elemtentToBeChanged.push(newChecklistInventory[newPosition]);
			}
			// pass the element to be changed for changing in the DB.
			await handleUpdatePositionInDB(elemtentToBeChanged);

			//set new checklist to have an immidiate feedback.
			// setChecklistInventory(constructOrder(newChecklistInventory));
			return true;
		} catch (err) {
			const error = err as Error;
			if (error.cause === "warning") openSnackbar("Attenzione", error.message, "warning");
			else openSnackbar("Errore", error.message, "error");
			return false;
		}
	};
	const handleRepositioningCheckin560 = async (oldPositionUID: string, newPositionIndex: number) => {
		//For the repositioning look the repositioning of the inventory is the same logic
		const newChecklistCheckin560 = [...checklistCheckin560];
		let oldPositionIndex = returnChecklistElementIndexFromUID(oldPositionUID, newChecklistCheckin560);
		const oldPosition = newChecklistCheckin560.find((element) => element.uid === oldPositionUID);

		const indexOrderedOldPosition = newChecklistCheckin560.findIndex((element) => element.uid === oldPositionUID);
		const offset = indexOrderedOldPosition < newPositionIndex && newPositionIndex != newChecklistCheckin560.length - 1 ? 1 : 0;

		const newPosition = newPositionIndex + offset;
		if (oldPosition == null) return false;
		try {
			if (isNaN(newPosition) || newPosition < 0 || newPosition > checklistCheckin560.length - 1)
				throw new Error("Nuova posizione non valida", {
					cause: "warning",
				});
			if (oldPositionIndex === newPosition)
				throw new Error("La nuova posizione è uguale alla vecchia", {
					cause: "warning",
				});

			const elemtentToBeChanged: ChecklistElement[] = [] as ChecklistElement[];

			let oldPositionIndexBefore = returnChecklistElementIndexFromUID(oldPosition.before, newChecklistCheckin560);
			let oldPositionIndexAfter = returnChecklistElementIndexFromUID(oldPosition.next, newChecklistCheckin560);
			if (oldPosition.before !== "first") {
				newChecklistCheckin560[oldPositionIndexBefore].next = oldPosition.next;
				elemtentToBeChanged.push(newChecklistCheckin560[oldPositionIndexBefore]);
			}

			if (oldPosition.next !== "last") {
				newChecklistCheckin560[oldPositionIndexAfter].before = oldPosition.before;
				elemtentToBeChanged.push(newChecklistCheckin560[oldPositionIndexAfter]);
			}

			if (newPositionIndex === newChecklistCheckin560.length - 1) {
				newChecklistCheckin560[oldPositionIndex].before = newChecklistCheckin560[newPosition].uid!;
				newChecklistCheckin560[oldPositionIndex].next = newChecklistCheckin560[newPosition].next;
				newChecklistCheckin560[newPosition].next = oldPosition.uid!;
				elemtentToBeChanged.push(newChecklistCheckin560[oldPositionIndex]);
				elemtentToBeChanged.push(newChecklistCheckin560[newPosition]);
			} else {
				newChecklistCheckin560[oldPositionIndex].before = newChecklistCheckin560[newPosition].before;
				let newPositionIndex = returnChecklistElementIndexFromUID(newChecklistCheckin560[newPosition].uid!, newChecklistCheckin560);
				if (newChecklistCheckin560[newPosition].before !== "first") {
					let newPositionBefore = returnChecklistElementIndexFromUID(newChecklistCheckin560[newPosition].before, newChecklistCheckin560);
					newChecklistCheckin560[newPositionBefore].next = oldPosition.uid!;
					elemtentToBeChanged.push(newChecklistCheckin560[newPositionBefore]);
				}
				newChecklistCheckin560[oldPositionIndex].next = newChecklistCheckin560[newPosition].uid!;
				newChecklistCheckin560[newPositionIndex].before = oldPosition.uid!;

				elemtentToBeChanged.push(newChecklistCheckin560[oldPositionIndex]);
				elemtentToBeChanged.push(newChecklistCheckin560[newPosition]);
			}

			await handleUpdatePositionInDB(elemtentToBeChanged);
			// setChecklistCheckin560(constructOrder(newChecklistCheckin560));
			return true;
		} catch (err) {
			const error = err as Error;
			if (error.cause === "warning") openSnackbar("Attenzione", error.message, "warning");
			else openSnackbar("Errore", error.message, "error");
			return false;
		}
	};

	const isChecklist = (element: EditElements): element is EditChecklistElement => {
		return (element as EditChecklistElement).checklistElement !== undefined;
	};

	const handleElementToBeEdited = (el: EditElements) => {
		setOpenEditChecklistPopup(true);
		if (isChecklist(el)) setElementToBeEdited(el.checklistElement);
		else {
			setWarningToBeEdited(el.warningElement);
			setWarningPosition(el.pos);
		}
	};

	const handleEditChecklistElement = async (checkListElement: ChecklistElement) => {
		try {
			if (boatProfile) {
				openLoadingSnackbar();

				await handleUpdateChecklistElement(boatProfile.uid, checkListElement);
				closeLoadingSnackbar();
				openSnackbar("Successo", "Elemento checklist modificato", "success");

				const checklist = await getChecklistCollectionAssociatedWithBoat(boatProfile.uid);
				if (checklist == null) throw new Error("Impossibile trovare la checklist");

				if (checkListElement.category === "checkin560") updateViewChecklistCheckin560(checklist);
				else updateViewChecklistInventory(checklist);
			}
		} catch (err) {
			closeLoadingSnackbar();
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		} finally {
			setElementToBeEdited(undefined);
			setWarningToBeEdited(undefined);
			setWarningPosition(-1);
		}
	};
	const handleEditWarning = async (pos: number, warningElement: WarningChecklistBoat) => {
		try {
			if (boatProfile) {
				openLoadingSnackbar();
				await handleUpdateWarningElement(boatProfile.uid, pos, warningElement);
				closeLoadingSnackbar();
				openSnackbar("Successo", "Elemento warning modificato", "success");
			}
		} catch (err) {
			closeLoadingSnackbar();
			const error = err as Error;
			openSnackbar("Errore", error.message, "error");
		} finally {
			setElementToBeEdited(undefined);
			setWarningToBeEdited(undefined);
			setWarningPosition(-1);
		}
	};

	const handleRepositioningWarning = async (oldPosition: number, newPosition: number) => {
		if (boatProfile == null) return false;
		if (boatProfile.warnings == null) return false;
		try {
			if (newPosition < 0 || newPosition > boatProfile.warnings.length - 1) throw new Error("Nuova posizione non valida", { cause: "warning" });
			if (oldPosition === newPosition) throw new Error("La nuova posizione è uguale alla vecchia", { cause: "warning" });

			const newWarnings = [...boatProfile.warnings];
			const warningToBeMoved = newWarnings[oldPosition];
			newWarnings.splice(oldPosition, 1);
			newWarnings.splice(newPosition, 0, warningToBeMoved);

			openLoadingSnackbar();
			await handleRepositioningWarningsBoat(boatProfile.uid, newWarnings);
			closeLoadingSnackbar();
			setWarnings(newWarnings);
			openSnackbar("Successo", "Elemento warning modificato", "success");
			return true;
		} catch (err) {
			closeLoadingSnackbar();
			const error = err as Error;
			if (error.cause === "warning") openSnackbar("Attenzione", error.message, "warning");
			else openSnackbar("Errore", error.message, "error");
			return false;
		}
	};

	const handleClosePopup = () => {
		setOpenChecklistPopup(false);
		setOpenEditChecklistPopup(false);
		setElementToBeEdited(undefined);
		setWarningToBeEdited(undefined);
		setWarningPosition(-1);
	};
	return (
		<>
			<AddCheckListPopUp
				isPopUpOpen={openChecklistPopup}
				handleClosePopup={handleClosePopup}
				handleAddCheckListElement={handleAddCheckListElement}></AddCheckListPopUp>
			<EditCheckListPopUp
				isPopUpOpen={openEditChecklistPopup}
				checkListElement={elementToBeEdited}
				warning={warningToBeEdited}
				posWarning={warningPosition}
				handleClosePopup={handleClosePopup}
				handleEditCheckListElement={handleEditChecklistElement}
				handleEditWarning={handleEditWarning}></EditCheckListPopUp>

			<Grid container justifyContent={"space-between"} rowGap={4}>
				<Grid item xs={12} sx={{ marginTop: "16px" }} display="flex" justifyContent="center">
					<Grid container>
						<Grid item xs>
							<Button
								onClick={() => {
									setOpenChecklistPopup(true);
								}}
								className="form-button subbutton ">
								Aggiungi una voce
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12} marginBottom="8px">
							<Typography variant="h4">Warning</Typography>
						</Grid>
						<Grid item xs={12}>
							{warnings.map((value, index) => {
								if (value == null) return <></>;
								return (
									<WarningChecklist
										key={"warning-" + value.value.en.toLowerCase().replaceAll(" ", "-")}
										warningElement={value}
										index={index}
										handleRepositioning={handleRepositioningWarning}
										handleDelete={handleDeleteWarning}
										handleEdit={() => handleElementToBeEdited({ warningElement: value, pos: index })}></WarningChecklist>
								);
							})}
						</Grid>
						<Grid item xs={12} marginBottom="8px">
							<Typography variant="h4">Prove di funzionamento</Typography>
						</Grid>
						<Grid item xs={12}>
							{checklistCheckin560.map((value, index) => {
								if (value == null) return <></>;
								return (
									<ChecklistElementSettings
										key={value.uid}
										checklistElement={value}
										index={index}
										handleRepositioning={handleRepositioningCheckin560}
										handleDelete={handleDelete}
										handleEdit={() => handleElementToBeEdited({ checklistElement: value })}></ChecklistElementSettings>
								);
							})}
						</Grid>
						<Grid item xs={12} marginBottom="8px">
							<Typography variant="h4">Inventario</Typography>
						</Grid>
						<Grid item xs={12}>
							{checklistInventory.map((value, index) => {
								if (value == null) return <></>;
								return (
									<ChecklistElementSettings
										key={value.uid + "-" + index}
										checklistElement={value}
										index={index}
										handleRepositioning={handleRepositioningInventory}
										handleDelete={handleDelete}
										handleEdit={() => handleElementToBeEdited({ checklistElement: value })}></ChecklistElementSettings>
								);
							})}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

interface ChecklistElementSettingsProps {
	checklistElement: ChecklistElement;
	index: number;
	handleRepositioning: (oldPositionUID: string, newPositionIndex: number) => Promise<boolean>;
	handleDelete: (uid: string, category: string) => Promise<void>;
	handleEdit: () => void;
}

const ChecklistElementSettings = ({ checklistElement, index, handleRepositioning, handleDelete, handleEdit }: ChecklistElementSettingsProps) => {
	const [orderIndex, setOrderIndex] = useState<number>(index + 1);
	const { language } = useTranslationProvider();
	const { t } = useTranslation();
	useEffect(() => {
		setOrderIndex(index + 1);
	}, [index]);
	return (
		<Grid container paddingY="16px" sx={{ borderTop: "1px solid #B7B7B7" }}>
			<Grid item xs={8} display="flex" alignItems="center">
				<Typography variant="body1" fontSize="20px">
					{checklistElement.name[language as keyof TranslationType]}
					{checklistElement.category === "inventory" && (
						<>
							<span> - </span>
							{checklistElement.price === "deposit" ? <span style={{ color: "red" }}>{t("deposit")}</span> : <>€ {checklistElement.price}</>}
						</>
					)}
				</Typography>
			</Grid>
			<Grid item xs={4}>
				<Grid container justifyContent="flex-end" alignItems="center" gap={4}>
					<Grid item className="icon-checklist">
						<ModeEditOutlineRoundedIcon sx={{ fontSize: "2.7rem" }} htmlColor="#959595" onClick={handleEdit} />
					</Grid>
					<Grid item className="icon-checklist">
						<DeleteForeverRoundedIcon
							sx={{ fontSize: "3rem" }}
							htmlColor="#959595"
							onClick={() => handleDelete(checklistElement.uid!, checklistElement.category!)}
						/>
					</Grid>
					<Grid item>
						<input
							className="input-order-checklist"
							type="number"
							value={orderIndex}
							onChange={(e) => setOrderIndex(parseInt(e.currentTarget.value))}
							onBlur={async (e) => {
								const success = await handleRepositioning(checklistElement.uid!, orderIndex - 1);
								if (!success) setOrderIndex(index + 1);
							}}></input>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

interface WarningElementSettingsProps {
	warningElement: WarningChecklistBoat;
	index: number;
	handleRepositioning: (oldPosition: number, newPosition: number) => Promise<boolean>;
	handleDelete: (warningElement: WarningChecklistBoat) => Promise<void>;
	handleEdit: () => void;
}

const WarningChecklist = ({ warningElement, index, handleRepositioning, handleDelete, handleEdit }: WarningElementSettingsProps) => {
	const { language } = useTranslationProvider();
	const [position, setPosition] = useState<number>(index + 1);

	useEffect(() => {
		setPosition(index + 1);
	}, [index]);

	return (
		<>
			<Grid container paddingY="16px" sx={{ borderTop: "1px solid #B7B7B7" }}>
				<Grid item xs={8} display="flex" alignItems="center">
					<Typography variant="body1" fontSize="20px">
						{warningElement.value[language as keyof TranslationType]}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Grid container justifyContent="flex-end" alignItems="center" gap={4}>
						<Grid item className="icon-checklist">
							<ModeEditOutlineRoundedIcon sx={{ fontSize: "2.7rem" }} htmlColor="#959595" onClick={handleEdit} />
						</Grid>
						<Grid item className="icon-checklist">
							<DeleteForeverRoundedIcon sx={{ fontSize: "3rem" }} htmlColor="#959595" onClick={() => handleDelete(warningElement)} />
						</Grid>
						<Grid item>
							<input
								className="input-order-checklist"
								type="number"
								value={position}
								onChange={(e) => setPosition(e.currentTarget.valueAsNumber)}
								onBlur={async (e) => {
									const sucess = await handleRepositioning(index, position - 1);
									if (!sucess) setPosition(index + 1);
								}}></input>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
