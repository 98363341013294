import { ChecklistElement } from "../types/forms/Checklist";

export const findFirstElement = (checklist: ChecklistElement[]): ChecklistElement | null | undefined => {
	let firstElement: ChecklistElement | undefined | null = null;
	firstElement = checklist.find((checklistElement) => checklistElement.before === "first");

	return firstElement;
};

export const constructOrder = (checklist: ChecklistElement[]): ChecklistElement[] => {
	const checklistOrdered = [];
	try {
		const firstElement = findFirstElement(checklist);
		if (firstElement == null) {
			throw new Error("Impossibile trovare l'elemento di inizio ");
		}
		//push the first element in the list
		checklistOrdered.push(firstElement);
		let nextElementUID = firstElement.next;
		while (checklistOrdered.length < checklist.length) {
			//find the next element on the list
			const nextElement = returnChecklistElementFromUID(nextElementUID, checklist);
			//push the element in the list
			checklistOrdered.push(nextElement);
			// set the new next element on the list
			nextElementUID = nextElement.next;
		}
	} catch (err) {
		console.error(err);
	}
	return checklistOrdered;
};

export const returnChecklistElementFromUID = (checklistUID: string, checklist: ChecklistElement[]) => {
	const checklistElement = checklist.find((checklistElement) => checklistElement.uid === checklistUID);
	if (checklistElement == null) throw new Error("Impossibile trovare l'elemento, UID:" + checklistUID);

	return checklistElement;
};

export const returnChecklistElementIndexFromUID = (checklistUID: string, checklist: ChecklistElement[]) => {
	const checklistElement = checklist.findIndex((checklistElement) => checklistElement.uid === checklistUID);
	if (checklistElement == null) throw new Error("Impossibile trovare l'elemento, UID:" + checklistUID);

	return checklistElement;
};
