import { Grid, Button, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";

import logo_menu from "../../assets/logo_menu.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/providers/AuthProvider";

import "../../styles/Menu.scss";
import $ from "jquery";

export const BoatMenu = (): JSX.Element => {
	const { boatProfile, logout } = useAuth();
	const [menuWidth, setMenuWidth] = useState<number>(0);

	const navigate = useNavigate();
	const myObserver = new ResizeObserver((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
		for (let entry of entries) {
			if (entry.target.id === "menu") {
				setMenuWidth(entry.contentRect.width);
				$(".menu-content").css("width", `calc(100% - ${entry.contentRect.width}px)`);
				$(".menu-container").css("width", `${entry.contentRect.width}`);
			}
		}
	});
	useEffect(() => {
		const menuWidth = $(".menu").width() as number;
		setMenuWidth(menuWidth);
		myObserver.observe(document.getElementById("menu") as Element);
	}, []);
	return (
		<>
			<div style={{ position: "relative", width: menuWidth }}>
				<Grid item className="menu" id="menu">
					<div className="menu-background"></div>

					<Grid container marginTop="8px" paddingLeft="1em">
						<Grid item display="flex">
							<img src={logo_menu} alt="logo menu" width="50px" height="50px" className="logo-menu" />
							<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} className="header-menu-name">
								<Typography variant="h4" color="white" className="header-name-menu">
									{boatProfile && boatProfile.username}
								</Typography>
							</div>
						</Grid>
					</Grid>
					<Grid container className="menu-buttons-container" justifyContent="flex-start" flexDirection="column" gap={2} marginTop="50px">
						<Grid item className="menu-button">
							<Button
								fullWidth
								onClick={() => {
									navigate("/boat/orders");
								}}
							>
								ORDINI
							</Button>
						</Grid>
						<Grid item className="menu-button">
							<Button fullWidth onClick={() => navigate("/boat/history")}>
								STORICO
							</Button>
						</Grid>
						<Grid item className="menu-button">
							<Button fullWidth onClick={() => navigate("/boat/checkin")}>
								CHECK-IN
							</Button>
						</Grid>

						<Grid item className="menu-button">
							<Button
								fullWidth
								onClick={() => {
									navigate("/boat/settings");
								}}
							>
								IMPOSTAZIONI
							</Button>
						</Grid>
						<Grid item className="menu-button">
							<Button fullWidth onClick={() => navigate("/boat/checkout")}>
								CHECK-OUT
							</Button>
						</Grid>
						<Grid item className="menu-button">
							<Button fullWidth onClick={() => navigate("/boat/feedback")}>
								FEEDBACK
							</Button>
						</Grid>
						<Grid item className="menu-button">
							<Button
								fullWidth
								onClick={() => {
									logout();
									navigate("/login/boat");
								}}
							>
								LOG OUT
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<button className="icon-close-mobile-container">
					<CloseIcon fontSize="large" className="icon-close-mobile" />
				</button>
			</div>
		</>
	);
};
