import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useBoatProvider } from "../../providers/BoatProvider";
import { BoatDocument, RatingsAvailable } from "../../../types/documents/Boat";
import { Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { Button, Grid } from "@mui/material";

import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
const columns = [
	{ id: "baseManager", label: "Base Manager", minWidth: 170 },
	{ id: "checkInSoftware", label: "Check In Software", minWidth: 100 },
	{
		id: "customerService",
		label: "Customer Service",
		minWidth: 170,
	},
	{
		id: "ourService",
		label: "Our Service",
		minWidth: 170,
	},
	{
		id: "yacht",
		label: "Yacht",
		minWidth: 170,
	},
	{
		id: "yachtCleaning",
		label: "Yacht Cleaning",
		minWidth: 170,
	},
];

export const FeedbackTable = () => {
	const { getAllBoats } = useBoatProvider();

	useEffect(() => {
		(async () => {
			try {
				const boats = await getAllBoats();
				setBoats(boats);
			} catch (err) {
				console.error(err);
			}
		})();
	}, []);
	const [boats, setBoats] = useState<BoatDocument[]>([] as BoatDocument[]);
	const [page, setPage] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(2);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const createJsonFromBoats = () => {
		const json = [];
		for (let i = 0; i < boats.length; i++) {
			const boat = boats[i];
			const ratings = boat.ratings;

			let jsonBoat;
			if (ratings == null) {
				jsonBoat = {
					Username: boat.username,
					Port: boat.port,
					"Base Manager": 0,
					"Check In Software": 0,
					"Customer Service": 0,
					"Our Service": 0,
					Yacht: 0,
					"Yacht Cleaning": 0,
				};
			} else {
				jsonBoat = {
					Username: boat.username,
					Port: boat.port,
					"Base Manager": ratings.baseManager,
					"Check In Software": ratings.checkInSoftware,
					"Customer Service": ratings.customerService,
					"Our Service": ratings.ourService,
					Yacht: ratings.yacht,
					"Yacht Cleaning": ratings.yachtCleaning,
				};
			}
			json.push(jsonBoat);
		}
		return json;
	};
	const handleDownloadTable = async () => {
		const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		const fileExtension = ".xlsx";
		const boatFeedbackInJson = createJsonFromBoats();
		const ws = XLSX.utils.json_to_sheet(boatFeedbackInJson);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		const date = new Date();
		const fileName = "boats_feedback_" + date.toLocaleDateString() + fileExtension;
		FileSaver.saveAs(data, fileName);
	};

	return (
		<>
			<Typography variant="h3" marginBottom="16px">
				Feedback Complessivo
			</Typography>
			<Paper sx={{ width: "100%", overflow: "hidden" }}>
				<TableContainer sx={{ maxHeight: 440 }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell style={{ minWidth: "100px", border: "unset" }}></TableCell>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										sx={{
											minWidth: column.minWidth,
											border: "unset",
											borderRight: "1px solid #5E5D5D",
										}}
									>
										<Typography variant="body1" fontWeight={600}>
											{column.label}
										</Typography>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{boats.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((boat) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={boat.username}>
										<TableCell sx={{ border: "unset", borderBottom: "1px solid #5E5D5D" }}>
											<Typography variant="body1" fontWeight={600}>
												{boat.username}
											</Typography>
										</TableCell>
										{columns.map((column) => {
											if (boat.ratings == null)
												return (
													<TableCell
														key={column.id}
														sx={{ border: "unset", borderRight: "1px solid #5E5D5D", borderBottom: "1px solid #5E5D5D" }}
													>
														No ratings yet
													</TableCell>
												);
											const value = boat.ratings[column.id as RatingsAvailable] as number;
											return (
												<TableCell
													key={column.id}
													sx={{ border: "unset", borderRight: "1px solid #5E5D5D", borderBottom: "1px solid #5E5D5D" }}
												>
													<Rating
														emptyIcon={<StarIcon fontSize="inherit" />}
														name="simple-controller"
														value={value}
														max={5}
														precision={1}
														size="large"
														sx={{
															color: "#68C5DA",
															opacity: "1 !important",
														}}
														disabled={true}
													/>
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[2, 10, 25, 100]}
					component="div"
					count={boats.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
			<Grid container marginTop={3} marginBottom={8}>
				<Grid item xs={12} display="flex">
					<Button
						onClick={() => {
							handleDownloadTable();
						}}
						className="form-button"
					>
						SCARICA TABELLA
					</Button>
				</Grid>
			</Grid>
		</>
	);
};
