import { Grid } from "@mui/material";
import { FeedbackStarElement } from "./FeedbackStarElement";
import { BoatRatings } from "../../../types/documents/Boat";

export const FeedbackBoat = ({ boatRatings }: { boatRatings: BoatRatings }) => {
	return (
		<>
			<Grid container spacing={2} marginTop="20px" id="rating-categories-container">
				<Grid item xs={12} md={6}>
					<FeedbackStarElement title="Media “Yatch”" stars={boatRatings.yacht} />
				</Grid>
				<Grid item xs={12} md={6}>
					<FeedbackStarElement title="Media “Yacth Cleaning”" stars={boatRatings.yachtCleaning} />
				</Grid>
				<Grid item xs={12} md={6}>
					<FeedbackStarElement title="Media “Customer Service”" stars={boatRatings.customerService} />
				</Grid>
				<Grid item xs={12} md={6}>
					<FeedbackStarElement title="Media “Base Manager”" stars={boatRatings.baseManager} />
				</Grid>
				<Grid item xs={12} md={6}>
					<FeedbackStarElement title="Media “Our Service”" stars={boatRatings.ourService} />
				</Grid>
				<Grid item xs={12} md={6}>
					<FeedbackStarElement title="Media “Check-In Software”" stars={boatRatings.checkInSoftware} />
				</Grid>
			</Grid>
		</>
	);
};
