import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";

const NUMBER_OF_DOTS = 100;

const SIGNATURE_INFO = ["Il Conduttore/Nájemce/The lessee", "Il Concedente/Pronajímatel/The lessor"];

export const TableSignature = () => (
	<Table className="table-signature">
		<TableBody>
			<SignatureRows colSpanCheckIn={1} colSpanCheckOut={1} />
		</TableBody>
	</Table>
);

type SignatureRowsProps = {
	colSpanCheckIn: number;
	colSpanCheckOut: number;
};

export const SignatureRows = ({ colSpanCheckIn, colSpanCheckOut }: SignatureRowsProps) => (
	<>
		<TableRow>
			<TableCell align="center" colSpan={colSpanCheckIn}>
				<Typography variant="h5">Check-In</Typography>
			</TableCell>
			<TableCell align="center" colSpan={colSpanCheckOut}>
				<Typography variant="h5">Check-Out</Typography>
			</TableCell>
		</TableRow>
		{SIGNATURE_INFO.map((info, i) => (
			<TableRow key={`table-signature-${i}`} className={`raw-signature-info-${i}`}>
				<TableCell align="left" colSpan={colSpanCheckIn}>
					<Typography variant="h5">
						{info}
						<br />
						<br />
						<br />
						{[...Array(NUMBER_OF_DOTS)].map((_) => ".").join("")}
					</Typography>
				</TableCell>
				<TableCell align="left" colSpan={colSpanCheckOut}>
					<Typography variant="h5">
						{info}
						<br />
						<br />
						<br />
						{[...Array(NUMBER_OF_DOTS)].map((_) => ".").join("")}
					</Typography>
				</TableCell>
			</TableRow>
		))}
	</>
);
