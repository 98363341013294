import { createContext, useContext } from "react";
import { BlockchainContext } from "../../types/providers/BlockchainContext";
import { useNetwork } from "./NetworkProvider";
import { api } from "../../utils/network/api";
import { useAuth } from "./AuthProvider";
import { CreateWalletResponse } from "../../types/responses/CreateWalletResponse";
import { responseMessages } from "../../utils/responseMessages";

const BlockchainContextImpl = createContext<BlockchainContext>({} as BlockchainContext);
export const BlockchainProvider = ({ children }: { children: JSX.Element }) => {
	const { makePost } = useNetwork();
	const { user } = useAuth();

	const createWallet = async (): Promise<string> => {
		let newMnemonic = "";
		if (!user) {
			throw new Error("errors.userNotLogged");
		}
		try {
			const token = await user?.getIdToken();
			const {
				data: { mnemonic },
			} = await makePost<CreateWalletResponse>(api.createWallet, {}, token);
			newMnemonic = mnemonic;
		} catch (error) {
			handleCreateWalletErrors((error as any).message);
		}

		return newMnemonic;
	};

	const handleCreateWalletErrors = async (message: string) => {
		const errors = responseMessages.createWallet.errors;
		switch (message) {
			case errors.alreadyHasWallet:
				throw new Error("errors.alreadyHasWallet");
			default:
				throw new Error("errorsSomethingWentWrong");
		}
	};

	const values = {
		createWallet,
	};

	return <BlockchainContextImpl.Provider value={values}>{children}</BlockchainContextImpl.Provider>;
};

export const useBlockchainContext = () => useContext(BlockchainContextImpl);
