import { Navigate, Outlet } from "react-router";
import { useAuth } from "../providers/AuthProvider";

const UserProtection = () => {
	const { userProfile } = useAuth();

	if (userProfile == null || userProfile.uid == "" || userProfile.uid == undefined) {
		return <Navigate to="/" />;
	}

	return <Outlet />;
};

export default UserProtection;
