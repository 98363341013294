import { Grid, Button, TextField } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import "../../../styles/Buttons.scss";
import { useTranslation } from "react-i18next";

export const FormContactUs = () => {
	const { t } = useTranslation();
	const validationSchema = yup.object({
		name: yup.string().required(t("name-required") as string),
		surname: yup.string().required(t("surname-required") as string),
		boat: yup.string().required(t("boat-required") as string),
		title: yup.string().required(t("title-required") as string),
		message: yup.string().required(t("message-required") as string),
	});
	const formik = useFormik({
		initialValues: {
			name: "",
			surname: "",
			boat: "",
			title: "",
			message: "",
		},

		validationSchema: validationSchema,
		onSubmit: async (values) => await handleSubmit(values),
	});

	const handleSubmit = async (values: any) => {
		window.open(
			`mailto:gais@gmail.com?subject=${values.title}&body=` +
				encodeURIComponent(`${values.name} ${values.surname}\n\n${t("boat")} : ${values.boat}\n\n${values.message}\n\n`)
		);
	};

	return (
		<>
			<form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
				<Grid container justifyContent={"space-between"} rowGap={4}>
					<Grid item xs={12} sm={4}>
						<TextField
							className="input-form gray-background"
							sx={{ width: { xs: "100%", md: "calc(100% - 8px)" }, margin: { sm: "0px 8px 0px 0px" } }}
							id="name"
							name="name"
							label={t("name")}
							variant={"filled"}
							value={formik.values.name}
							onChange={formik.handleChange}
							error={formik.touched.name && Boolean(formik.errors.name)}
							helperText={formik.touched.name && formik.errors.name}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							className="input-form gray-background"
							sx={{ width: { xs: "100%", md: "calc(100% - 16px)" }, margin: { sm: "0px 8px" } }}
							id="surname"
							name="surname"
							label={t("surname")}
							variant={"filled"}
							value={formik.values.surname}
							onChange={formik.handleChange}
							error={formik.touched.surname && Boolean(formik.errors.surname)}
							helperText={formik.touched.surname && formik.errors.surname}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							className="input-form gray-background"
							sx={{ width: { xs: "100%", md: "calc(100% - 8px)" }, margin: { sm: "0px 0px 0px 8px" } }}
							id="boat"
							name="boat"
							label={t("boat")}
							variant={"filled"}
							value={formik.values.boat}
							onChange={formik.handleChange}
							error={formik.touched.boat && Boolean(formik.errors.boat)}
							helperText={formik.touched.boat && formik.errors.boat}
						/>
					</Grid>
					<Grid item xs={12} sx={{ display: "flex", flexDirection: "column", rowGap: "16px" }}>
						<TextField
							className="input-form gray-background"
							sx={{ width: { xs: "100%" } }}
							id="title"
							name="title"
							label={t("title")}
							variant={"filled"}
							value={formik.values.title}
							onChange={formik.handleChange}
							error={formik.touched.title && Boolean(formik.errors.title)}
							helperText={formik.touched.title && formik.errors.title}
						/>
					</Grid>
					<Grid item xs={12} sx={{ display: "flex", flexDirection: "column", rowGap: "16px" }}>
						<TextField
							className="input-form gray-background multiline"
							multiline
							sx={{ width: { xs: "100%" } }}
							id="message"
							name="message"
							label={t("message")}
							variant={"filled"}
							value={formik.values.message}
							onChange={formik.handleChange}
							error={formik.touched.message && Boolean(formik.errors.message)}
							helperText={formik.touched.message && formik.errors.message}
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: "16px" }} display={"flex"} justifyContent={"center"}>
						<Button type="submit" className="form-button ">
							{t("send")}
						</Button>
					</Grid>
				</Grid>
			</form>
		</>
	);
};
