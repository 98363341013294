import { useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
	Button,
	RadioGroup,
	FormControlLabel,
	Radio,
	TextField,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import * as yup from "yup";
import { useFormik } from "formik";
import { AddExtraParams } from "../../../types/forms/Extras";

const validationSchema = yup.object({
	nameInItalian: yup.string().required("Il nome in italiano è richiesto"),
	nameInEnglish: yup.string().required("Il nome in inglese è richiesto"),
	nameInCzech: yup.string().required("Il nome in ceco è richiesto"),
	price: yup
		.number()
		.typeError("La cauzione deve essere un numero")
		.min(0, "Il prezzo non puo essere negativo")
		.required("Il prezzo della cauzione è richiesto"),
});
export const AddExtraPopUp = ({
	isPopUpOpen,
	handleClosePopup,
	handleAddExtra,
}: {
	isPopUpOpen: boolean;
	handleClosePopup: () => void;
	handleAddExtra: (extra: AddExtraParams) => Promise<void>;
}) => {
	const [expanded, setExpanded] = useState<string | false>(false);
	const radioButtonInfo = ["A settimana", "A pezzo", "A prenotazione", "A persona"];

	const formik = useFormik({
		initialValues: {
			nameInItalian: "",
			nameInEnglish: "",
			nameInCzech: "",
			price: 0,
			type: 0,
		},

		validationSchema: validationSchema,
		onSubmit: async (values, { resetForm }) => await handleSubmit(values, resetForm),
	});

	/**
	 *
	 * @param values.type identifies 0:A settimana, 1: A pezzo ,2: A prenotazione
	 */
	const handleSubmit = async (values: any, resetForm: any) => {
		const type = radioButtonInfo[Number(values.type)];
		let typeToPass = "";

		switch (type) {
			case "A settimana":
				typeToPass = "week";
				break;
			case "A pezzo":
				typeToPass = "piece";
				break;
			case "A prenotazione":
				typeToPass = "booking";
				break;
			case "A persona":
				typeToPass = "person";
				break;
			default:
				throw new Error("Invalid type");
		}

		const extra: AddExtraParams = {
			name: {
				it: values.nameInItalian,
				en: values.nameInEnglish,
				cs: values.nameInCzech,
			},
			cost: Number(values.price),
			type: typeToPass,
		};
		await handleAddExtra(extra);
		resetForm();
		handleClosePopup();
	};
	const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			<Dialog open={isPopUpOpen} onClose={handleClosePopup} fullWidth maxWidth="md">
				<DialogTitle mb={3}>
					<Typography variant="h4">Aggiungi un extra</Typography>
				</DialogTitle>
				<DialogContent>
					<>
						<form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
							<Grid container rowGap={2}>
								<Grid item xs={12}>
									<TextField
										className="input-form"
										sx={{ width: { xs: "100%" } }}
										id="nameInItalian"
										name="nameInItalian"
										label="Nome in italiano"
										variant={"filled"}
										value={formik.values.nameInItalian}
										onChange={formik.handleChange}
										error={formik.touched.nameInItalian && Boolean(formik.errors.nameInItalian)}
										helperText={formik.touched.nameInItalian && formik.errors.nameInItalian}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										className="input-form"
										sx={{ width: { xs: "100%" } }}
										id="nameInEnglish"
										name="nameInEnglish"
										label="Nome in inglese"
										variant={"filled"}
										value={formik.values.nameInEnglish}
										onChange={formik.handleChange}
										error={formik.touched.nameInEnglish && Boolean(formik.errors.nameInEnglish)}
										helperText={formik.touched.nameInEnglish && formik.errors.nameInEnglish}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										className="input-form"
										sx={{ width: { xs: "100%" } }}
										id="nameInCzech"
										name="nameInCzech"
										label="Nome in ceco"
										variant={"filled"}
										value={formik.values.nameInCzech}
										onChange={formik.handleChange}
										error={formik.touched.nameInCzech && Boolean(formik.errors.nameInCzech)}
										helperText={formik.touched.nameInCzech && formik.errors.nameInCzech}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										className="input-form"
										sx={{ width: { xs: "100%" } }}
										id="price"
										name="price"
										label="Prezzo"
										variant={"filled"}
										value={formik.values.price}
										placeholder="0"
										onClick={() => {
											if (formik.values.price === 0) formik.setFieldValue("price", "");
										}}
										onChange={formik.handleChange}
										error={formik.touched.price && Boolean(formik.errors.price)}
										helperText={formik.touched.price && formik.errors.price}
									/>
								</Grid>
								<Grid item xs={12}>
									<Accordion
										className="accordion"
										sx={{ background: "#FFFFFF !important" }}
										expanded={expanded === "panel1"}
										onChange={handleChange("panel1")}>
										<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
											<Typography sx={{ width: "33%", flexShrink: 0 }} variant="h4">
												Tipologia prezzo
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<RadioGroup defaultValue={formik.initialValues.type}>
												{radioButtonInfo.map((label, index) => (
													<FormControlLabel
														key={`form-price-type-radio-${label}-${index}`}
														value={index}
														name="type"
														onChange={formik.handleChange}
														control={<Radio icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon color="success" />} />}
														label={label}
													/>
												))}
											</RadioGroup>
										</AccordionDetails>
									</Accordion>
								</Grid>
								<Grid item xs={12} sx={{ marginTop: "16px" }} display="flex" justifyContent="center">
									<Button type="submit" className="form-button ">
										AGGIUNGI
									</Button>
								</Grid>
							</Grid>
						</form>
					</>
				</DialogContent>
			</Dialog>
		</>
	);
};
